import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { useFormStore } from "../Store/Forms";

// FORM 10  Saranabalyam (M)

function AReport11(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { downloadExcel }] = useFormStore();
  const Qyear = props.Qyear
  const Qmonth = props.Qmonth
  const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
  const DisplayQuarter = Quarter[Qmonth]

  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };
  function createPDF() {
    var sTable = document.getElementById('table').innerHTML;
    var style = "<style>";
    style = style + "table {width: 100%;font: 10px Calibri;}";
    style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "</style>";
    var win = window.open('', '', 'height=700,width=700');
    win.document.write('<html><head>');
    win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
    win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
    win.document.write('</head>');
    win.document.write('<body>');
    win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
    win.document.write('</body></html>');
    win.document.close(); 	// CLOSE THE CURRENT WINDOW.
    win.print();    // PRINT THE CONTENTS.
  }
  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {" "}
            Violence against children{" "}
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <h4>{DisplayQuarter} {Qyear}</h4>
        <div className="modal-body">
        <div class="table-responsive" id="table" height="100%" width="100%" media="print">
            <table class="table" id="violence_against_children">
              <thead>
                <tr>
                  <th scope="col" rowspan="3">
                    #
                  </th>

                  <th scope="col" rowSpan="3">
                    Category
                  </th>
                  <th scope="col" colspan="10">
                    Age
                  </th>
                  <th scope="col" colspan="2" rowspan="2">
                    Children in CCIs
                  </th>
                </tr>

                <tr>
                  <th scope="col" colspan="2">
                    {" "}
                    0-3{" "}
                  </th>
                  <th scope="col" colspan="2">
                    4-7
                  </th>
                  <th scope="col" colspan="2">
                    8-11
                  </th>
                  <th scope="col" colspan="2">
                    12-15
                  </th>
                  <th scope="col" colspan="2">
                    16-18
                  </th>
                </tr>

                <tr>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row"></th>

                  <td>Total Murder (Infanticide and Other)</td>
                  <td>{props.report.sum_total_murder_male_0_3}</td>
                  <td>{props.report.sum_total_murder_female_0_3}</td>
                  <td>{props.report.sum_total_murder_male_4_7}</td>
                  <td>{props.report.sum_total_murder_female_4_7}</td>
                  <td>{props.report.sum_total_murder_male_8_11}</td>
                  <td>{props.report.sum_total_murder_female_8_11}</td>
                  <td>{props.report.sum_total_murder_male_12_15}</td>
                  <td>{props.report.sum_total_murder_female_12_15}</td>
                  <td>{props.report.sum_total_murder_male_16_18}</td>
                  <td>{props.report.sum_total_murder_female_16_18}</td>
                  <td>{props.report.sum_total_murder_cci_male}</td>
                  <td>{props.report.sum_total_murder_cci_female}</td>
                </tr>
                <tr>
                  <th scope="row"></th>

                  <td>Section 4 & 6 of  PoCSO Act r/w Sec. 376 IPC</td>
                  <td>{props.report.sum_section_4_6_male_0_3}</td>
                  <td>{props.report.sum_section_4_6_female_0_3}</td>
                  <td>{props.report.sum_section_4_6_male_4_7}</td>
                  <td>{props.report.sum_section_4_6_female_4_7}</td>
                  <td>{props.report.sum_section_4_6_male_8_11}</td>
                  <td>{props.report.sum_section_4_6_female_8_11}</td>
                  <td>{props.report.sum_section_4_6_male_12_15}</td>
                  <td>{props.report.sum_section_4_6_female_12_15}</td>
                  <td>{props.report.sum_section_4_6_male_16_18}</td>
                  <td>{props.report.sum_section_4_6_female_16_18}</td>
                  <td>{props.report.sum_section_4_6_cci_male}</td>
                  <td>{props.report.sum_section_4_6_cci_female}</td>
                </tr>
                <tr>
                  <th scope="row"></th>

                  <td>Kidnapping and Abduction</td>
                  <td>{props.report.sum_kidnapping_male_0_3}</td>
                  <td>{props.report.sum_kidnapping_female_0_3}</td>
                  <td>{props.report.sum_kidnapping_male_4_7}</td>
                  <td>{props.report.sum_kidnapping_female_4_7}</td>
                  <td>{props.report.sum_kidnapping_male_8_11}</td>
                  <td>{props.report.sum_kidnapping_female_8_11}</td>
                  <td>{props.report.sum_kidnapping_male_12_15}</td>
                  <td>{props.report.sum_kidnapping_female_12_15}</td>
                  <td>{props.report.sum_kidnapping_male_16_18}</td>
                  <td>{props.report.sum_kidnapping_female_16_18}</td>
                  <td>{props.report.sum_kidnapping_cci_male}</td>
                  <td>{props.report.sum_kidnapping_cci_female}</td>
                </tr>
                <tr>
                  <th scope="row"></th>

                  <td>Foeticide</td>
                  <td>{props.report.sum_foeticide_male_0_3}</td>
                  <td>{props.report.sum_foeticide_female_0_3}</td>
                  <td>{props.report.sum_foeticide_male_4_7}</td>
                  <td>{props.report.sum_foeticide_female_4_7}</td>
                  <td>{props.report.sum_foeticide_male_8_11}</td>
                  <td>{props.report.sum_foeticide_female_8_11}</td>
                  <td>{props.report.sum_foeticide_male_12_15}</td>
                  <td>{props.report.sum_foeticide_female_12_15}</td>
                  <td>{props.report.sum_foeticide_male_16_18}</td>
                  <td>{props.report.sum_foeticide_female_16_18}</td>
                  <td>{props.report.sum_foeticide_cci_male}</td>
                  <td>{props.report.sum_foeticide_cci_female}</td>
                </tr>
                <tr>
                  <th scope="row"></th>

                  <td>Abetment of Suicide</td>
                  <td>{props.report.sum_abetment_male_0_3}</td>
                  <td>{props.report.sum_abetment_female_0_3}</td>
                  <td>{props.report.sum_abetment_male_4_7}</td>
                  <td>{props.report.sum_abetment_female_4_7}</td>
                  <td>{props.report.sum_abetment_male_8_11}</td>
                  <td>{props.report.sum_abetment_female_8_11}</td>
                  <td>{props.report.sum_abetment_male_12_15}</td>
                  <td>{props.report.sum_abetment_female_12_15}</td>
                  <td>{props.report.sum_abetment_male_16_18}</td>
                  <td>{props.report.sum_abetment_female_16_18}</td>
                  <td>{props.report.sum_abetment_cci_male}</td>
                  <td>{props.report.sum_abetment_cci_female}</td>
                </tr>
                <tr>
                  <th scope="row"></th>

                  <td>Exposure and Abandonment</td>
                  <td>{props.report.sum_exposure_male_0_3}</td>
                  <td>{props.report.sum_exposure_female_0_3}</td>
                  <td>{props.report.sum_exposure_male_4_7}</td>
                  <td>{props.report.sum_exposure_female_4_7}</td>
                  <td>{props.report.sum_exposure_male_8_11}</td>
                  <td>{props.report.sum_exposure_female_8_11}</td>
                  <td>{props.report.sum_exposure_male_12_15}</td>
                  <td>{props.report.sum_exposure_female_12_15}</td>
                  <td>{props.report.sum_exposure_male_16_18}</td>
                  <td>{props.report.sum_exposure_female_16_18}</td>
                  <td>{props.report.sum_exposure_cci_male}</td>
                  <td>{props.report.sum_exposure_cci_female}</td>
                </tr>
                <tr>
                  <th scope="row"></th>

                  <td>Procuration of Minor Girls</td>
                  <td>{props.report.sum_procuration_male_0_3}</td>
                  <td>{props.report.sum_procuration_female_0_3}</td>
                  <td>{props.report.sum_procuration_male_4_7}</td>
                  <td>{props.report.sum_procuration_female_4_7}</td>
                  <td>{props.report.sum_procuration_male_8_11}</td>
                  <td>{props.report.sum_procuration_female_8_11}</td>
                  <td>{props.report.sum_procuration_male_12_15}</td>
                  <td>{props.report.sum_procuration_female_12_15}</td>
                  <td>{props.report.sum_procuration_male_16_18}</td>
                  <td>{props.report.sum_procuration_female_16_18}</td>
                  <td>{props.report.sum_procuration_cci_male}</td>
                  <td>{props.report.sum_procuration_cci_female}</td>
                </tr>
                <tr>
                  <th scope="row"></th>

                  <td>Buying Girls for Prostitution</td>
                  <td>{props.report.sum_buying_girls_male_0_3}</td>
                  <td>{props.report.sum_buying_girls_female_0_3}</td>
                  <td>{props.report.sum_buying_girls_male_4_7}</td>
                  <td>{props.report.sum_buying_girls_female_4_7}</td>
                  <td>{props.report.sum_buying_girls_male_8_11}</td>
                  <td>{props.report.sum_buying_girls_female_8_11}</td>
                  <td>{props.report.sum_buying_girls_male_12_15}</td>
                  <td>{props.report.sum_buying_girls_female_12_15}</td>
                  <td>{props.report.sum_buying_girls_male_16_18}</td>
                  <td>{props.report.sum_buying_girls_female_16_18}</td>
                  <td>{props.report.sum_buying_girls_cci_male}</td>
                  <td>{props.report.sum_buying_girls_cci_female}</td>
                </tr>
                <tr>
                  <th scope="row"></th>

                  <td>Selling Girls for Prostitution</td>
                  <td>{props.report.sum_selling_girls_male_0_3}</td>
                  <td>{props.report.sum_selling_girls_female_0_3}</td>
                  <td>{props.report.sum_selling_girls_male_4_7}</td>
                  <td>{props.report.sum_selling_girls_female_4_7}</td>
                  <td>{props.report.sum_selling_girls_male_8_11}</td>
                  <td>{props.report.sum_selling_girls_female_8_11}</td>
                  <td>{props.report.sum_selling_girls_male_12_15}</td>
                  <td>{props.report.sum_selling_girls_female_12_15}</td>
                  <td>{props.report.sum_selling_girls_male_16_18}</td>
                  <td>{props.report.sum_selling_girls_female_16_18}</td>
                  <td>{props.report.sum_selling_girls_cci_male}</td>
                  <td>{props.report.sum_selling_girls_cci_female}</td>
                </tr>
                <tr>
                  <th scope="row"></th>

                  <td>Prohibition of Child marriage Act</td>
                  <td>{props.report.sum_prohibition_male_0_3}</td>
                  <td>{props.report.sum_prohibition_female_0_3}</td>
                  <td>{props.report.sum_prohibition_male_4_7}</td>
                  <td>{props.report.sum_prohibition_female_4_7}</td>
                  <td>{props.report.sum_prohibition_male_8_11}</td>
                  <td>{props.report.sum_prohibition_female_8_11}</td>
                  <td>{props.report.sum_prohibition_male_12_15}</td>
                  <td>{props.report.sum_prohibition_female_12_15}</td>
                  <td>{props.report.sum_prohibition_male_16_18}</td>
                  <td>{props.report.sum_prohibition_female_16_18}</td>
                  <td>{props.report.sum_prohibition_cci_male}</td>
                  <td>{props.report.sum_prohibition_cci_female}</td>
                </tr>
                <tr>
                  <th scope="row"></th>

                  <td>Other Crimes against Children</td>
                  <td>{props.report.sum_other_crimes_male_0_3}</td>
                  <td>{props.report.sum_other_crimes_female_0_3}</td>
                  <td>{props.report.sum_other_crimes_male_4_7}</td>
                  <td>{props.report.sum_other_crimes_female_4_7}</td>
                  <td>{props.report.sum_other_crimes_male_8_11}</td>
                  <td>{props.report.sum_other_crimes_female_8_11}</td>
                  <td>{props.report.sum_other_crimes_male_12_15}</td>
                  <td>{props.report.sum_other_crimes_female_12_15}</td>
                  <td>{props.report.sum_other_crimes_male_16_18}</td>
                  <td>{props.report.sum_other_crimes_female_16_18}</td>
                  <td>{props.report.sum_other_crimes_cci_male}</td>
                  <td>{props.report.sum_other_crimes_cci_female}</td>
                </tr>
                <tr>
                  <th scope="row"></th>

                  <td>Total</td>
                  <td>{props.report.sum_total_male_0_3}</td>
                  <td>{props.report.sum_total_female_0_3}</td>
                  <td>{props.report.sum_total_male_4_7}</td>
                  <td>{props.report.sum_total_female_4_7}</td>
                  <td>{props.report.sum_total_male_8_11}</td>
                  <td>{props.report.sum_total_female_8_11}</td>
                  <td>{props.report.sum_total_male_12_15}</td>
                  <td>{props.report.sum_total_female_12_15}</td>
                  <td>{props.report.sum_total_male_16_18}</td>
                  <td>{props.report.sum_total_female_16_18}</td>
                  <td>{props.report.sum_total_cci_male}</td>
                  <td>{props.report.sum_total_cci_female}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("violence_against_children")}
          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-primary"
            onClick={createPDF}
            type="button"
            data-bs-dismiss="modal"
          >
            Download as Pdf
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default AReport11;
