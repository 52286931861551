import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";

// FORM 7 Details of crimes committed by children (Q)

function Report7(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { downloadExcel }] = useFormStore();
  const Qyear = props.Qyear
  const Qmonth = props.Qmonth
  const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
  const DisplayQuarter = Quarter[Qmonth]


  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };
  // object assigned to a variable to obtain total
  let newArray = props.report.filter(function (d) {
    return d.year == Qyear && d.month == Qmonth;
  })

  // sum_array = total sum data of district
  const sum_array = newArray.reduce((sum_array, value) => {
    for (const [key, count] of Object.entries(value)) {
      if (!sum_array[key]) {
        sum_array[key] = 0;
      }
      sum_array[key] += count;
    }
    return sum_array;
  }, {});

  let newArrayCumulative = props.report.filter(function (d) {
    return d.year == Qyear && d.month <= Qmonth;
  })

  let result = [];

  newArrayCumulative.forEach(function (a) {
    if (!this[a.username]) {
      this[a.username] = {
        username: a.username, total_current_quarter: 0,
        sbr_total_current_quarter: 0,
        sir_total_current_quarter: 0,
        before_jjb_current_quarter: 0,
        reffered_to_kaval_current_quarter: 0,
      };
      result.push(this[a.username]);
    }
    this[a.username].total_current_quarter += a.total_current_quarter;
    this[a.username].sbr_total_current_quarter += a.sbr_total_current_quarter;
    this[a.username].sir_total_current_quarter += a.sir_total_current_quarter;
    this[a.username].before_jjb_current_quarter += a.before_jjb_current_quarter;
    this[a.username].children_attended_on_the_day += a.children_attended_on_the_day;
    this[a.username].reffered_to_kaval_current_quarter += a.reffered_to_kaval_current_quarter;
  }, Object.create(null));

  // console.log(result);
  for (let object of newArray) {
    for (let i of result) {
      if (object.username === i.username) {
        object.total_cumulative = i.total_current_quarter
        object.sbr_total_cumulative = i.sbr_total_current_quarter
        object.sir_total_cumulative = i.sir_total_current_quarter
        object.follow_up_cases_cumulative = i.follow_up_cases_during_the_month
        object.before_jjb_cumulative = i.before_jjb_current_quarter
        object.reffered_to_kaval_cumulative = i.reffered_to_kaval_current_quarter
      }
    }
  }

  function createPDF() {
    var sTable = document.getElementById('table').innerHTML;
    var style = "<style>";
    style = style + "table {width: 100%;font: 10px Calibri;}";
    style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "</style>";
    var win = window.open('', '', 'height=700,width=700');
    win.document.write('<html><head>');
    win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
    win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
    win.document.write('</head>');
    win.document.write('<body>');
    win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
    win.document.write('</body></html>');
    win.document.close(); 	// CLOSE THE CURRENT WINDOW.
    win.print();    // PRINT THE CONTENTS.
  }

  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {" "}
            Details of crimes committed by children{" "}
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <h4>{DisplayQuarter} {Qyear}</h4>
        <div className="modal-body">
          <div class="table-responsive" id="table" height="100%" width="100%" media="print">
            <table class="table" id="crime_committed">
              <thead>
                <tr>
                  <th scope="col" rowspan="2">
                    #
                  </th>
                  <th scope="col" rowspan="2">
                    District
                  </th>
                  <th scope="col" colspan="2">
                    Total no:of cases{" "}
                  </th>
                  <th scope="col" colspan="2">
                    SBR Registered{" "}
                  </th>
                  <th scope="col" colspan="2">
                    SIR Registered{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Produced before JJB{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Referred to Kaval
                  </th>
                </tr>

                <tr>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                </tr>
              </thead>
              <tbody>
                {newArray.map((r) => (
                  <tr key={r?.id}>
                    <th scope="row">{r?.id}</th>
                    <td>{r?.username}</td>
                    <td>{r?.total_current_quarter}</td>
                    <td>{r?.total_cumulative}</td>
                    <td>{r?.sbr_total_current_quarter}</td>
                    <td>{r?.sbr_total_cumulative}</td>
                    <td>{r?.sir_total_current_quarter}</td>
                    <td>{r?.sir_total_cumulative}</td>
                    <td>{r?.before_jjb_current_quarter}</td>
                    <td>{r?.before_jjb_cumulative}</td>
                    <td>{r?.reffered_to_kaval_current_quarter}</td>
                    <td>{r?.reffered_to_kaval_cumulative}</td>
                  </tr>
                ))}
                <tr>
                  <th scope="row"></th>
                  <td>Total</td>
                  <td>{sum_array.total_current_quarter}</td>
                  <td>{sum_array.total_cumulative}</td>
                  <td>{sum_array.sbr_total_current_quarter}</td>
                  <td>{sum_array.sbr_total_cumulative}</td>
                  <td>{sum_array.sir_total_current_quarter}</td>
                  <td>{sum_array.sir_total_cumulative}</td>
                  <td>{sum_array.before_jjb_current_quarter}</td>
                  <td>{sum_array.before_jjb_cumulative}</td>
                  <td>{sum_array.reffered_to_kaval_current_quarter}</td>
                  <td>{sum_array.reffered_to_kaval_cumulative}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("crime_committed")}
          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-primary"
            onClick={createPDF}
            type="button"
            data-bs-dismiss="modal"
          >
            Download as Pdf
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default Report7;