import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";
import { Toaster, toast } from "react-hot-toast";

// FORM 1  CHILD MARRIAGE

const Form1 = React.memo(function (props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { onSubmit }] = useFormStore();
    const [{ }, { updateReport }] = useFormStore();
    const [{ report }, { getReport }] = useFormStore();
    const [{ }, { formUpdated }] = useFormStore();
    const month = props.month;
    const year = props.year;
    const Month = ["Month not selected", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const DisplayMonth = Month[month];


    const [data, setdata] = useState({
        male: 0,
        female: 0,
        child_marriage: 0,
        complaints_recieved: 0,
        enquiries_conducted: 0,
        report_submitted_to_court: 0,
        injuction_order_recieved: 0,
        violation_made_by_parties: 0,
        maintanance_order_issued: 0,
        punishment_order_made: 0,
        child_marriage_prevented: 0,
        residence_order_made: 0,
        awarness_program_conductted: 0,
        review_meeting_of_assistance_conductted: 0,
        month: month,
        year: year,
    });

    let reportSorted = {};

    useEffect(() => {
        if ((props || {}).isEdit) {
            getReport('child_marriage', month, year);
            reportSorted = report.find((res) => { return res.month === month && res.year === year; }) || {};
            setdata({
                ...data,
                male: reportSorted.male,
                female: reportSorted.female,
                child_marriage: reportSorted.child_marriage,
                complaints_recieved: reportSorted.complaints_recieved,
                enquiries_conducted: reportSorted.enquiries_conducted,
                report_submitted_to_court: reportSorted.report_submitted_to_court,
                injuction_order_recieved: reportSorted.injuction_order_recieved,
                violation_made_by_parties: reportSorted.violation_made_by_parties,
                maintanance_order_issued: reportSorted.maintanance_order_issued,
                punishment_order_made: reportSorted.punishment_order_made,
                child_marriage_prevented: reportSorted.child_marriage_prevented,
                residence_order_made: reportSorted.residence_order_made,
                awarness_program_conductted: reportSorted.awarness_program_conductted,
                review_meeting_of_assistance_conductted: reportSorted.review_meeting_of_assistance_conductted,
            });
        }
    }, []);


    const openForm1 = () => {
        // props.close();
        if (props.isEdit) {
            return toast.promise(
                updateReport(data, "update_child_marriage"),
                {
                    loading: 'Updating Record',
                    success: 'Record Updated',
                    error: 'Error while saving'
                }
            ).then(() => formUpdated(true));
        } else {
            onSubmit(data, "child_marriage").then(() => setmodalIsOpen(false));
        }
    };
    const openForm = () => {
        props.close();
        setmodalIsOpen(false);
    };

    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <Toaster toastOptions={{ style: { zIndex: 999 } }} position="bottom-right" />
                <div>
                    <div className="modal-header">
                        <span>
                            {" "}
                            <h5 className="modal-title" id="exampleModalLongTitle">
                                Child Marriage
                            </h5>{" "}
                        </span>
                        <button
                            className="btn-close"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <h4>{DisplayMonth} {year}</h4>
                    <div className="modal-body">
                        <form>
                            <div className="new-expense__control">
                                <label> Number of males </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    defaultValue={data.male}
                                    onChange={(e) => setdata({ ...data, male: Number(e.target.value) })}
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Number of females </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    defaultValue={data.female}
                                    onChange={(e) => setdata({ ...data, female: e.target.value })}
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Number of child marriages happened </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    defaultValue={data.child_marriage}
                                    onChange={(e) =>
                                        setdata({ ...data, child_marriage: e.target.value })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Number of complaints received </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    defaultValue={data.complaints_recieved}
                                    onChange={(e) =>
                                        setdata({ ...data, complaints_recieved: e.target.value })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Number of enquiries conducted </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    defaultValue={data.enquiries_conducted}
                                    onChange={(e) =>
                                        setdata({ ...data, enquiries_conducted: e.target.value })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Number of reports submitted to the court </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    defaultValue={data.report_submitted_to_court}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            report_submitted_to_court: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Number of injunction orders received </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    defaultValue={data.injuction_order_recieved}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            injuction_order_recieved: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Number of violations made by parties </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    defaultValue={data.violation_made_by_parties}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            violation_made_by_parties: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Number of maintenance orders issued </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    defaultValue={data.maintanance_order_issued}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            maintanance_order_issued: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Number of punishment orders made </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    defaultValue={data.punishment_order_made}
                                    onChange={(e) =>
                                        setdata({ ...data, punishment_order_made: e.target.value })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Number of child marriages successfully prevented </label>{" "}
                                :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    defaultValue={data.child_marriage_prevented}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            child_marriage_prevented: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Number of residence orders made </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    defaultValue={data.residence_order_made}
                                    onChange={(e) =>
                                        setdata({ ...data, residence_order_made: e.target.value })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Number of awareness programs conducted </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    defaultValue={data.awarness_program_conductted}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            awarness_program_conductted: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    Number of review meetings of assistance conducted{" "}
                                </label>{" "}
                                :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    defaultValue={data.review_meeting_of_assistance_conductted}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            review_meeting_of_assistance_conductted: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={openForm1}
                            type="button"
                        >
                            {!props.isEdit ? 'Save & Submit' : 'Save Edit'}
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
});

export default Form1;
