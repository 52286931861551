import React from "react";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Home.css";
import Sidenav from "./Sidenav";

import Header from "./Header";

import Pending from "./Pending";

import Form1 from "./Form1"; // FORM 1  CHILD MARRIAGE (M)
import Form2 from "./Form2"; // FORM 2  CHILD LABOUR (M)
import Form10 from "./Form10"; // FORM 10  Saranabalyam (M)
import Form11 from "./Form11"; // FORM 11 Details of Violence against Children (M)
import Form25 from "./Form25"; // FORM 25 PARENTING CLINIC REPORTING FORMAT	(M)

import Form3 from "./Form3"; // FORM 3 : State sponsorship	(Q)
import Form4 from "./Form4"; // FORM 4 Central sponsorship	(Q)
import Form5 from "./Form5"; // FORM 5 After Care services  (Govt) (Q)
import Form6 from "./Form6"; // FORM 6 Aftercare offered to children in CCIs (age 18-21) (Q)
import Form7 from "./Form7"; // FORM 7 Details of crimes committed by children (Q)
import Form9 from "./Form9"; // FORM 9  Information regarding Juvenile Justice (Care and Protection of Children) Act (Q)
import Form12 from "./Form12"; // FORM 12 JJA (Q)
import Form13 from "./Form13"; // FORM 13 POCSO (Q)
import Form14 from "./Form14"; // FORM 14 Details of de-institutionalised children (Govt)	(Q)
import Form15 from "./Form15"; // FORM 15  Juvenile Justice Boards
import Form16 from "./Form16"; // FORM 16 Institutional Care of Children in Conflict with Law	(Q)
import Form17 from "./Form17"; // FORM 17 1. Child Welfare Committees (Constituted under section 27(1) of the JJ Act, 2015):- (Q)
import Form18 from "./Form18"; // FORM 18 Institutional Care of Children in Need of Care and Protection	(Q)
import Form19 from "./Form19"; // FORM 19 3. Adoption under section 56 of the JJ Act, 2015	(Q)
import Form20 from "./Form20"; // FORM 20 4. Institutional Mechanisms for protection of children	(Q)
import Form21 from "./Form21"; // FORM 21  Foster Care	(Q)
import Form22 from "./Form22"; // FORM 22  Repatriation (Q)
import Form23 from "./Form23"; // FORM 23  Information regarding The Prohibition of Child Marriage Act
import Form24 from "./Form24"; // FORM 24 Information regarding Protection of Children from Sexual Offences (POCSO) Act
import Form27 from "./Form27"; // FORM 27 QPR supreme court
import Form28 from "./Form28"; // FORM 28 NCPCR
import Form29 from "./Form29"; // FORM 28 QPR Highcourt

import Form8 from "./Form8"; // FORM 8 Details of resource persons/experts	(Bi)
import Form30 from "./Form30"; // FORM 30 parenting outreach
import { useAuthStore } from "../Store/Auth";
import { useHomeStore } from "../Store/Home";
import { useFormStore } from "../Store/Forms";

import moment from "moment";
import Form26 from "./Form26";

function Home() {
  const [modalform2, setModalForm2] = React.useState(false);
  const [modalIsOpen, setmodalIsOpen] = React.useState(false);
  const [selectedSection, setSelectedSection] = React.useState("");
  const [stateSponsorshipIsOpen, setstateSponsorshipIsOpen] =
    React.useState(false);
  const [centralSponsorshipIsOpen, setcentralSponsorshipIsOpen] =
    React.useState(false);
  const [afterCareIsOpen, setafterCareIsOpen] = React.useState(false);
  const [afterCareCCIIsOpen, setafterCareCCIIsOpen] = React.useState(false);
  const [crimesIsOpen, setcrimesIsOpen] = React.useState(false);
  const [resourceIsOpen, setresourceIsOpen] = React.useState(false);
  const [juvenileJusticeIsOpen, setjuvenileJusticeIsOpen] =
    React.useState(false);
  const [saranabalyamIsOpen, setsaranabalyamIsOpen] = React.useState(false);
  const [violenceIsOpen, setviolenceIsOpen] = React.useState(false);
  const [ParentingOutreachIsOpen, setParentingOutreachIsOpen] = React.useState(false);
  const [CWCIsOpen, setCWCIsOpen] = React.useState(false);

  // const [JJAIsOpen, setJJAIsOpen] = React.useState(false);
  // const [POCSOIsOpen, setPOCSOIsOpen] = React.useState(false);
  const [DeinstitutionalisedIsOpen, setDeinstitutionalisedIsOpen] =
    React.useState(false);
  const [JuvenileJusticeBoardsIsOpen, setJuvenileJusticeBoardsIsOpen] =
    React.useState(false);
  const [InstitutionalCareIsOpen, setInstitutionalCareIsOpen] =
    React.useState(false);
  const [ChildWelfareCommitteesIsOpen, setChildWelfareCommitteesIsOpen] =
    React.useState(false);
  const [ChildrenCareIsOpen, setChildrenCareIsOpen] = React.useState(false);
  const [AdoptionIsOpen, setAdoptionIsOpen] = React.useState(false);
  const [ProtectionofChildrenIsOpen, setProtectionofChildrenIsOpen] =
    React.useState(false);
  const [FosterCareIsOpen, setFosterCareIsOpen] = React.useState(false);
  const [RepatriationIsOpen, setRepatriationIsOpen] = React.useState(false);
  const [NcpcrIsOpen, setNcpcrIsOpen] = React.useState(false);
  const [
    ProhibitionofChildMarriageIsOpen,
    setProhibitionofChildMarriageIsOpen,
  ] = React.useState(false);
  const [SexualOffencesIsOpen, setSexualOffencesIsOpen] = React.useState(false);
  const [ParentingClinicIsOpen, setParentingClinicIsOpen] =
    React.useState(false);
  const [PendingIsOpen, setPendingIsOpen] = React.useState(false);
  const [QprSupremeCourtIsOpen, setQprSupremeCourtIsOpen] =
    React.useState(false);
  const [QprHighCourtIsOpen, setQprHighCourtIsOpen] = React.useState(false);
  const [month, setMonth] = React.useState("");
  const [year, setYear] = React.useState("");
  const [Qmonth, setMonthQuarterly] = React.useState("");
  const [Qyear, setYearQuarterly] = React.useState("");
  const [annually, setAnnually] = React.useState("");
  const date = new Date();
  const currentYear = Number(date.getFullYear());
  const [{ report }, { getReport }] = useFormStore();
  // const [{ user }, { checkAuthenticate }] = useAuthStore();
  const [
    { reportList, saranyabalam, violenceAgainstChildren },
    { getReportList },
  ] = useHomeStore();
  const [{ }, { setCurrentFormData }] = useFormStore();

  // const [month, setMonth] = React.useState();
  React.useEffect(() => {
    // checkAuthenticate();
    getReportList();
  }, []);

  const openForm = (data) => {
    if (month == "" || year == "" || year == "") {
      alert("Date not selected")
      return false
    };
    setmodalIsOpen(true);
    setSelectedSection(data);
  };

  const openForm2 = () => {
    if (month == "" || year == "") {
      alert("Date not selected")
      return false
    };
    getReport("child_labour");
    setModalForm2(true);
  };

  const stateSponsorship = () => {
    setstateSponsorshipIsOpen(true);
  };

  const centralSponsorship = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    setcentralSponsorshipIsOpen(true);
  };

  const afterCare = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    setafterCareIsOpen(true);
  };

  const afterCareCCI = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    setafterCareCCIIsOpen(true);
  };

  const crimes = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    setcrimesIsOpen(true);
  };

  const resource = () => {
    if (annually == "") {
      alert("Year not selected")
      return false
    };
    setresourceIsOpen(true);
  };

  const juvenileJustice = () => {
    setjuvenileJusticeIsOpen(true);
  };

  const saranabalyam = () => {
    if (month == "" || year == "") {
      alert("Date not selected")
      return false
    };
    // setCurrentFormData(id);
    setsaranabalyamIsOpen(true);
  };

  const violence = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    // setCurrentFormData(id);
    setviolenceIsOpen(true);
  };
  const ParentingOutreach = () => {
    if (month == "" || year == "") {
      alert("Date not selected")
      return false
    };
    setParentingOutreachIsOpen(true);
  };

  const CWC = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    setCWCIsOpen(true);
  };

  // const JJA = () => {
  //   setJJAIsOpen(true);
  // };

  // const POCSO = () => {
  //   setPOCSOIsOpen(true);
  // };

  const Deinstitutionalised = () => {
    setDeinstitutionalisedIsOpen(true);
  };

  const JuvenileJusticeBoards = () => {
    setJuvenileJusticeBoardsIsOpen(true);
  };

  const InstitutionalCare = () => {
    setInstitutionalCareIsOpen(true);
  };

  const ChildWelfareCommittees = () => {
    setChildWelfareCommitteesIsOpen(true);
  };

  const ChildrenCare = () => {
    setChildrenCareIsOpen(true);
  };

  const Adoption = () => {
    setAdoptionIsOpen(true);
  };

  const ProtectionofChildren = () => {
    setProtectionofChildrenIsOpen(true);
  };

  const FosterCare = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    setFosterCareIsOpen(true);
  };

  const Repatriation = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    setRepatriationIsOpen(true);
  };

  const ncpcr = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    setNcpcrIsOpen(true);
  };

  const ProhibitionofChildMarriage = () => {
    setProhibitionofChildMarriageIsOpen(true);
  };

  const SexualOffences = () => {
    setSexualOffencesIsOpen(true);
  };

  const ParentingClinic = () => {
    if (month == "" || year == "") {
      alert("Date not selected")
      return false
    };
    setParentingClinicIsOpen(true);
  };

  const Pending = () => {
    setPendingIsOpen(true);
  };
  const QprSupremeCourt = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    setQprSupremeCourtIsOpen(true);
  };
  const QprHighCourt = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    setQprHighCourtIsOpen(true);
  };

  const handleChange = (e) => {
    setMonth(e.target.value);
    // setMonth(e.target.value);
    // console.log(e.target.value)
  };
  const handleChangeYear = (e) => {
    setYear(e.target.value);
    // setMonth(e.target.value);
    // console.log(e.target.value)
  };
  const QuarterlyhandleChange = (e) => {
    setMonthQuarterly(e.target.value);
    // setMonth(e.target.value);
    // console.log(e.target.value)
  };
  const QuarterlyhandleChangeYear = (e) => {
    setYearQuarterly(e.target.value);
    // setMonth(e.target.value);
    // console.log(e.target.value)
  };
  const SelectAnnually = (e) => {
    setAnnually(e.target.value);
    // setMonth(e.target.value);
    // console.log(e.target.value)
  };
  return (
    <div>
      <div className="page-wrapper" id="pageWrapper">
        {/* Header  */}
        <Header />

        {/* <!-- Page Body Start--> */}
        <div className="page-body-wrapper horizontal-menu">
          {/* Sidebar  */}
          <Sidenav />

          <div className="page-body">
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid general-widget">
              <div className="row">
                {/* Stats */}
                {/* <div className="col-sm-6 col-xl-3 col-lg-6">
                  <div className="card o-hidden border-0">
                    <div className="bg-secondary b-r-4 card-body">
                      <div className="media static-top-widget">
                        <div className="media-body">
                          <span className="m-0">Total :</span>
                          <h4 className="mb-0 counter">15</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-xl-3 col-lg-6">
                  <div className="card o-hidden border-0">
                    <div className="bg-primary b-r-4 card-body">
                      <div className="media static-top-widget">
                        <div className="media-body">
                          <span className="m-0">Submitted :</span>
                          <h4 className="mb-0 counter">15</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-xl-3 col-lg-6">
                  <div className="card o-hidden border-0">
                    <div className="bg-primary b-r-4 card-body">
                      <div className="media static-top-widget">
                        <div className="media-body" onClick={Pending}>
                          <span className="m-0">Pending :</span>
                          <h4 className="mb-0 counter">0</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* Monthly */}
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header pb-0 d-flex justify-content-between">
                      <h5> Monthly </h5>
                      <div className="w-50 mt-15 d-flex justify-content-end">
                        <select
                          class="form-select w-25"
                          aria-label="Default select example"
                          // onChange={(e) => setMonth(e.target.value)}
                          onChange={handleChange}
                        >
                          <option value="">Select Month</option>
                          <option value="01">January</option>
                          <option value="02">February</option>
                          <option value="03">March</option>
                          <option value="04">April</option>
                          <option value="05">May</option>
                          <option value="06">June</option>
                          <option value="07">July</option>
                          <option value="08">August</option>
                          <option value="09">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </select>
                        <select
                          class="form-select w-25"
                          aria-label="Default select example"
                          // onChange={(e) => setMonth(e.target.value)}
                          onChange={handleChangeYear}
                        >
                          <option value="">Select Year</option>
                          <option value={currentYear - 5}>{currentYear - 5}</option>
                          <option value={currentYear - 4}>{currentYear - 4}</option>
                          <option value={currentYear - 3}>{currentYear - 3}</option>
                          <option value={currentYear - 2}>{currentYear - 2}</option>
                          <option value={currentYear - 1}>{currentYear - 1}</option>
                          <option value={currentYear}>{currentYear}</option>
                          <option value={currentYear + 1}>{currentYear + 1}</option>
                          <option value={currentYear + 2}>{currentYear + 2}</option>
                          <option value={currentYear + 3}>{currentYear + 3}</option>
                          <option value={currentYear + 4}>{currentYear + 4}</option>
                          <option value={currentYear + 5}>{currentYear + 5}</option>
                          <option value={currentYear + 6}>{currentYear + 6}</option>
                        </select>
                      </div>

                    </div>
                    <div className="card-body btn-showcase">
                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        onClick={() => openForm("monthly")}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Child marriage{" "}
                      </button>

                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={openForm2}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Child labour{" "}
                      </button>

                      {/* <!-- Scrolling long content--> */}
                      <div class=" dropdown-basic">
                        <div class="dropdown">
                          <button
                            className=" btn btn-primary"
                            type="button"
                            onClick={saranabalyam}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModalLong1"
                          >
                            {" "}
                            Saranabalyam{" "}
                            {/* <span>
                              <i class="icofont icofont-arrow-down"></i>
                            </span>{" "} */}
                          </button>
                          {/* <div class="dropdown-content">
                            {saranyabalam.map((r) => (
                              <a>
                                {" "}
                                <button
                                  className="btn"
                                  type="button"
                                  onClick={() => saranabalyam(r?.id)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModalLong1"
                                >
                                  {r?.name}
                                </button>{" "}
                              </a>
                            ))} */}
                          {/* <a>
                              {" "}
                              <button
                                className="btn"
                                type="button"
                                onClick={saranabalyam}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalLong1"
                              >
                                {" "}
                                Child begging{" "}
                              </button>{" "}
                            </a>
                            <a>
                              {" "}
                              <button
                                className="btn"
                                type="button"
                                onClick={saranabalyam}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalLong1"
                              >
                                {" "}
                                Street children{" "}
                              </button>{" "}
                            </a>
                            <a>
                              {" "}
                              <button
                                className="btn"
                                type="button"
                                onClick={saranabalyam}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalLong1"
                              >
                                {" "}
                                Abandoned children{" "}
                              </button>{" "}
                            </a>
                            <a>
                              {" "}
                              <button
                                className="btn"
                                type="button"
                                onClick={saranabalyam}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalLong1"
                              >
                                {" "}
                                Child Trafficking{" "}
                              </button>{" "}
                            </a>
                            <a>
                              {" "}
                              <button
                                className="btn"
                                type="button"
                                onClick={saranabalyam}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalLong1"
                              >
                                {" "}
                                Child Marriage{" "}
                              </button>{" "}
                            </a>
                            <a>
                              {" "}
                              <button
                                className="btn"
                                type="button"
                                onClick={saranabalyam}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalLong1"
                              >
                                {" "}
                                POCSO{" "}
                              </button>{" "}
                            </a>
                            <a>
                              {" "}
                              <button
                                className="btn"
                                type="button"
                                onClick={saranabalyam}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalLong1"
                              >
                                {" "}
                                Physical, emotional, neglect{" "}
                              </button>{" "}
                            </a>
                            <a>
                              {" "}
                              <button
                                className="btn"
                                type="button"
                                onClick={saranabalyam}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalLong1"
                              >
                                {" "}
                                Others (Please mention){" "}
                              </button>{" "}
                            </a> */}
                          {/* </div> */}
                        </div>
                      </div>

                      {/* <!-- Scrolling long content--> */}
                      <div class=" dropdown-basic">
                        <div class="dropdown">

                          {/*<div class="dropdown-content">
                            {violenceAgainstChildren.map((v) => (
                              <a>
                                <button
                                  className="btn"
                                  type="button"
                                  onClick={() => violence(v?.id)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModalLong1"
                                >
                                  {v?.name}
                                </button>
                              </a>
                            ))}
                             <a>
                              {" "}
                              <button
                                className="btn"
                                type="button"
                                onClick={violence}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalLong1"
                              >
                                {" "}
                                Section 4 & 6 of PoCSO Act r/w Sec. 376 IPC{" "}
                              </button>{" "}
                            </a>
                            <a>
                              {" "}
                              <button
                                className="btn"
                                type="button"
                                onClick={violence}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalLong1"
                              >
                                {" "}
                                Kidnapping and Abduction{" "}
                              </button>{" "}
                            </a>
                            <a>
                              {" "}
                              <button
                                className="btn"
                                type="button"
                                onClick={violence}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalLong1"
                              >
                                {" "}
                                Foeticide{" "}
                              </button>{" "}
                            </a>
                            <a>
                              {" "}
                              <button
                                className="btn"
                                type="button"
                                onClick={violence}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalLong1"
                              >
                                {" "}
                                Abetment of Suicide{" "}
                              </button>{" "}
                            </a>
                            <a>
                              {" "}
                              <button
                                className="btn"
                                type="button"
                                onClick={violence}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalLong1"
                              >
                                {" "}
                                Exposure and Abandonment{" "}
                              </button>{" "}
                            </a>
                            <a>
                              {" "}
                              <button
                                className="btn"
                                type="button"
                                onClick={violence}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalLong1"
                              >
                                {" "}
                                Procuration of Minor Girls{" "}
                              </button>{" "}
                            </a>
                            <a>
                              {" "}
                              <button
                                className="btn"
                                type="button"
                                onClick={violence}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalLong1"
                              >
                                {" "}
                                Buying Girls for Prostitution{" "}
                              </button>{" "}
                            </a> 
                          </div>*/}
                        </div>
                      </div>

                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={ParentingClinic}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Parenting Clinic{" "}
                      </button>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={ParentingOutreach}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Parenting Outreach Camps{" "}
                      </button>
                    </div>
                  </div>
                </div>
                {/* Quarterly */}
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header pb-0 pb-0 d-flex justify-content-between">
                      <h5> Quarterly </h5>
                      <div className="w-50 mt-15 d-flex justify-content-end">
                        <select
                          class="form-select w-25"
                          aria-label="Default select example"
                          // value={Qmonth}
                          onChange={QuarterlyhandleChange}
                        >
                          <option value="">Select-Quarter</option>
                          <option value="1">January - March</option>
                          <option value="2">April - June</option>
                          <option value="3">July - September</option>
                          <option value="4">October - December</option>
                        </select>
                        <select
                          class="form-select w-25"
                          aria-label="Default select example"
                          // onChange={(e) => setMonth(e.target.value)}
                          onChange={QuarterlyhandleChangeYear}
                        >
                          <option value="">Select Year</option>
                          <option value={currentYear - 5}>{currentYear - 5}</option>
                          <option value={currentYear - 4}>{currentYear - 4}</option>
                          <option value={currentYear - 3}>{currentYear - 3}</option>
                          <option value={currentYear - 2}>{currentYear - 2}</option>
                          <option value={currentYear - 1}>{currentYear - 1}</option>
                          <option value={currentYear}>{currentYear}</option>
                          <option value={currentYear + 1}>{currentYear + 1}</option>
                          <option value={currentYear + 2}>{currentYear + 2}</option>
                          <option value={currentYear + 3}>{currentYear + 3}</option>
                          <option value={currentYear + 4}>{currentYear + 4}</option>
                          <option value={currentYear + 5}>{currentYear + 5}</option>
                          <option value={currentYear + 6}>{currentYear + 6}</option>
                        </select>
                      </div>
                    </div>
                    <div className="card-body btn-showcase">
                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        onClick={stateSponsorship}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        State Sponsorship{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        type="button"
                        onClick={centralSponsorship}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Central Sponsorship{" "}
                      </button> */}
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={centralSponsorship}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Details of children availing sponsorship schemes{" "}
                      </button>
                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={afterCare}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Aftercare Services{" "}
                      </button>

                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={afterCareCCI}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Details of aftercare offered to children (age 18-21){" "}
                      </button>

                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={crimes}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Details of crimes committed by children{" "}
                      </button>

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        type="button"
                        onClick={juvenileJustice}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Juvenile Justice{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={CWC}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        Details of cases produced before CWC
                      </button>

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        type="button"
                        onClick={JJA}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        JJA{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        type="button"
                        onClick={POCSO}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        POCSO{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        type="button"
                        onClick={Deinstitutionalised}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        De-institutionalised children{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        type="button"
                        onClick={JuvenileJusticeBoards}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Juvenile Justice Boards{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        type="button"
                        onClick={InstitutionalCare}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Institutional Care of Children in Conflict with Law{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        type="button"
                        onClick={ChildWelfareCommittees}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Child Welfare Committees{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        type="button"
                        onClick={ChildrenCare}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Institutional Care of Children in Need of Care and
                        Protection{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        type="button"
                        onClick={Adoption}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Adoption{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        type="button"
                        onClick={ProtectionofChildren}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Protection of Children{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={FosterCare}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Number of children in foster care{" "}
                      </button>

                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={Repatriation}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Repatriation{" "}
                      </button>
                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={ncpcr}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        NCPCR
                      </button>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={QprSupremeCourt}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        QPR-SC
                      </button>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={QprHighCourt}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        QPR-HC
                      </button>
                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        type="button"
                        onClick={ProhibitionofChildMarriage}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Prohibition of Child Marriage Act{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        type="button"
                        onClick={SexualOffences}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Protection of Children from Sexual Offences{" "}
                      </button> */}
                      <button
                        className=" btn btn-primary"
                        onClick={violence}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Violence against Children{" "}
                        {/* <span>
                              <i class="icofont icofont-arrow-down"></i>
                            </span>{" "} */}
                      </button>
                    </div>
                  </div>
                </div>

                {/* Bi-annually */}
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header pb-0 d-flex justify-content-between">
                      <h5> Bi-annually </h5>
                      <div className="w-50 mt-15 d-flex justify-content-end">
                        <select
                          class="form-select w-25"
                          aria-label="Default select example"
                          // onChange={(e) => setMonth(e.target.value)}
                          onChange={SelectAnnually}
                        >
                          <option value="">Select Year</option>
                          <option value={currentYear - 5}>{currentYear - 5}</option>
                          <option value={currentYear - 4}>{currentYear - 4}</option>
                          <option value={currentYear - 3}>{currentYear - 3}</option>
                          <option value={currentYear - 2}>{currentYear - 2}</option>
                          <option value={currentYear - 1}>{currentYear - 1}</option>
                          <option value={currentYear}>{currentYear}</option>
                          <option value={currentYear + 1}>{currentYear + 1}</option>
                          <option value={currentYear + 2}>{currentYear + 2}</option>
                          <option value={currentYear + 3}>{currentYear + 3}</option>
                          <option value={currentYear + 4}>{currentYear + 4}</option>
                          <option value={currentYear + 5}>{currentYear + 5}</option>
                          <option value={currentYear + 6}>{currentYear + 6}</option>
                        </select>
                      </div>
                    </div>
                    <div className="card-body btn-showcase">
                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        onClick={resource}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Details of resource persons/experts{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {modalIsOpen && (
              <Form1
                close={() => setmodalIsOpen(false)}
                selectedSection={selectedSection}
                month={month}
                year={year}
              />
            )}
            {modalform2 && <Form2 close={() => setModalForm2(false)}
              month={month}
              year={year} report={report} />}
            {stateSponsorshipIsOpen && (
              <Form3 close={() => setstateSponsorshipIsOpen(false)} />
            )}
            {centralSponsorshipIsOpen && (
              <Form4 close={() => setcentralSponsorshipIsOpen(false)}
                Qmonth={Qmonth}
                Qyear={Qyear} />
            )}
            {afterCareIsOpen && (
              <Form5 close={() => setafterCareIsOpen(false)} Qmonth={Qmonth}
                Qyear={Qyear} />
            )}
            {afterCareCCIIsOpen && (
              <Form6 close={() => setafterCareCCIIsOpen(false)} Qmonth={Qmonth}
                Qyear={Qyear} />
            )}
            {crimesIsOpen && <Form7 close={() => setcrimesIsOpen(false)} Qmonth={Qmonth}
              Qyear={Qyear} />}
            {resourceIsOpen && <Form8 close={() => setresourceIsOpen(false)} annually={annually} />}
            {juvenileJusticeIsOpen && (
              <Form9 close={() => setjuvenileJusticeIsOpen(false)} />
            )}
            {saranabalyamIsOpen && (
              <Form10 close={() => setsaranabalyamIsOpen(false)}
                month={month}
                year={year}
              />
            )}
            {violenceIsOpen && (
              <Form11 close={() => setviolenceIsOpen(false)} Qmonth={Qmonth}
                Qyear={Qyear}
              />
            )}
            {ParentingOutreachIsOpen && (
              <Form30 close={() => setParentingOutreachIsOpen(false)} month={month}
                year={year} />
            )}

            {CWCIsOpen && <Form12 close={() => setCWCIsOpen(false)} Qmonth={Qmonth}
              Qyear={Qyear} />}
            {/* {JJAIsOpen && <Form12 close={() => setJJAIsOpen(false)} />}
            {POCSOIsOpen && <Form13 close={() => setPOCSOIsOpen(false)} />} */}
            {DeinstitutionalisedIsOpen && (
              <Form14 close={() => setDeinstitutionalisedIsOpen(false)} />
            )}
            {JuvenileJusticeBoardsIsOpen && (
              <Form15 close={() => setJuvenileJusticeBoardsIsOpen(false)} />
            )}
            {InstitutionalCareIsOpen && (
              <Form16 close={() => setInstitutionalCareIsOpen(false)} />
            )}
            {ChildWelfareCommitteesIsOpen && (
              <Form17 close={() => setChildWelfareCommitteesIsOpen(false)} />
            )}
            {ChildrenCareIsOpen && (
              <Form18 close={() => setChildrenCareIsOpen(false)} />
            )}
            {AdoptionIsOpen && (
              <Form19 close={() => setAdoptionIsOpen(false)} />
            )}
            {ProtectionofChildrenIsOpen && (
              <Form20 close={() => setProtectionofChildrenIsOpen(false)} />
            )}
            {FosterCareIsOpen && (
              <Form21 close={() => setFosterCareIsOpen(false)} Qmonth={Qmonth}
                Qyear={Qyear} />
            )}
            {RepatriationIsOpen && (
              <Form22 close={() => setRepatriationIsOpen(false)} Qmonth={Qmonth}
                Qyear={Qyear} />
            )}
            {NcpcrIsOpen && (<Form28 close={() => setNcpcrIsOpen(false)} Qmonth={Qmonth}
              Qyear={Qyear} />)}
            {/* {ProhibitionofChildMarriageIsOpen && (
              <Form23
                close={() => setProhibitionofChildMarriageIsOpen(false)}
              />
            )}
            {SexualOffencesIsOpen && (
              <Form24 close={() => setSexualOffencesIsOpen(false)} />
            )} */}
            {ParentingClinicIsOpen && (
              <Form25 close={() => setParentingClinicIsOpen(false)} month={month}
                year={year} />
            )}

            {PendingIsOpen && <Pending close={() => setPendingIsOpen(false)} />}
            {QprSupremeCourtIsOpen && (
              <Form27 close={() => setQprSupremeCourtIsOpen(false)} Qmonth={Qmonth}
                Qyear={Qyear} />
            )}
            {QprHighCourtIsOpen && (
              <Form29 close={() => setQprHighCourtIsOpen(false)} Qmonth={Qmonth}
                Qyear={Qyear} />
            )}
            {/* <!-- Container-fluid Ends--> */}
          </div>
        </div>
      </div>
    </div >
  );
}

export default Home;