import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";

// FORM 3 : State sponsorship	(Q)

function Report3(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{}, { downloadExcel }] = useFormStore();

  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };

  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {" "}
            State Sponsorship{" "}
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <div class="table-responsive">
            <table class="table" id="state_sponsership">
              <thead>
                <tr>
                  <th scope="col" rowspan="2">
                    Sponsorship provider
                  </th>
                  <th scope="col" rowspan="2">
                    District
                  </th>
                  <th scope="col" colspan="2">
                    Total No. of children availing sponsorship{" "}
                  </th>
                  <th scope="col" rowspan="1">
                    No. of children whose scholarship was exempted in this
                    quarter
                  </th>
                  <th scope="col" rowspan="1">
                    No. of children whose scholarship was added in this quarter
                  </th>
                </tr>

                <tr>
                  <th scope="col"> State (cumulative) </th>
                  <th scope="col">State (current quarter)</th>
                  <th scope="col">State (current quarter)</th>
                  <th scope="col">State (current quarter)</th>
                </tr>
              </thead>
              <tbody>
                {props.report.map((r) => (
                  <tr key={r?.id}>
                    <th scope="row">{r?.id}</th>
                    <td>{JSON.parse(getStorage("user")).username}</td>
                    <td>{r?.children_availing_sponsership_cumulative}</td>
                    <td>{r?.children_availing_sponsership_quarterly}</td>
                    <td>{r?.children_scolership_exempted}</td>
                    <td>{r?.children_scolership_added}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("state_sponsership")}
          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default Report3;
