import { postNoToken, post } from "../common/base_api";

export const onLogin = (data) => {
  return postNoToken("/accounts/subadmin/login", data);
};
export const onAdminLogin = (data) => {
  return postNoToken("/accounts/admin/login", data);
};
export const onLogout = () => {
  return post("/accounts/user/logout");
};