import React, { useState } from 'react';
import Modal from 'react-modal';

import '../assets/css/feather-icon.css';
import '../assets/css/bootstrap.css';
import '../assets/css/responsive.css';
import '../assets/css/themify.css';
import '../assets/css/style.css';
import '../assets/css/fontawesome.css';
import '../assets/css/icofont.css';
import '../assets/css/flag-icon.css';

import './Form.css';

// FORM 24 Information regarding Protection of Children from Sexual Offences (POCSO) Act	

function Form24 (props) {

  const [modalIsOpen, setmodalIsOpen] = useState(true)

  const openForm1 = () => {
    props.close();
    setmodalIsOpen(false)
  }

  return (
    <div>

      <Modal isOpen={modalIsOpen}>

        <div>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle"> Information regarding Protection of Children from Sexual Offences (POCSO) Act			 </h5>
            <button className="btn-close" onClick={openForm1} type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div className="modal-body">
            <form>

              <label> Infrastructure facilities in Special Courts u/s 28 POCSO Act 2012 </label> 
              <div className='new-expense__control'>
                <label>No of Districts	 </label> : <input type='number' min='1'  />
                <label>No of Special Courts established u/s 28 of POCSO Act	 </label> : <input type='number' min='1'  />
                <label>No of Special Courts dealing exclusively with POCSO cases </label> : <input type='number' min='1'  />
                <label>No of Special Courts designated with additional charge other than POCSO cases	 </label> : <input type='number' min='1'  />
                <label>No of Special courts equipped for following provisions u/s 36 POCSO Act 2012 (for video conferencing)	 </label> : <input type='number' min='1'  />
                <label>No of Special courts having availability of interpreter or expert u/s 38 POCSO Act 2012	 </label> : <input type='number' min='1'  />
              </div> <br /> <br /> 

              <label> Special Public Prosecutors u/s 32 POCSO Act 2012 </label> 
              <div className='new-expense__control'>
                <label>No of Special Public Prosecutors appointed u/s 32 of POCSO Act	 </label> : <input type='number' min='1'  />
                <label>Special Public Prosecutors conducting exclusively with POCSO cases </label> : <input type='number' min='1'  />
                <label>Special Public Prosecutors conducting POCSO cases in addition to other cases </label> : <input type='number' min='1'  />
              </div> <br /> <br /> 

              <label> Public Awareness about the Act u/s 43 POCSO Act </label> 
              <div className='new-expense__control'>
                <label>Whether any awareness programs/campaigns have been conducted for provisions of POCSO Act </label> : <input type='number' min='1'  />
                <label>No of trainings specifically imparted on POCSO Act to State Government officers (Yes or No) if yes, how many? If no, reasons thereof </label> : <input type='number' min='1'  />
                <label>No of trainings specifically imparted on POCSO Act to Police officers </label> : <input type='number' min='1'  />
              </div> <br /> <br /> 

              <label> Implementation of Rule 6 of POCSO Rules  </label> 
              <div className='new-expense__control'>
                <label>Whether Guidelines for professionals to assist the child at pre trial and trial stage have been prepared u/s 39 of POCSO Act, 2012 </label> : <input type='number' min='1'  />
                <label>Whether Training modules have been prepared for effective discharge of functions under the Act. [Rule 6 (d) POCSO Rules 2012]</label> : <input type='number' min='1'  />
              </div> <br /> <br /> 

              <label> Implementation of Rule 7 of POCSO Rules 2012 </label> 
              <div className='new-expense__control'>
                <label>Whether any victim compensation fund/scheme has been created? </label> : <input type='number' min='1'  />
                <label>No of cases where compensation has been provided to victim (interim and final) </label> : <input type='number' min='1'  />
                <label>No of cases where compensation has been paid within 30 days of order of Special Court u/r 7 (5) of POCSO Rules 2012</label> : <input type='number' min='1'  />
              </div>


            </form>
          </div>
          
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={openForm1} type="button" data-bs-dismiss="modal">Close</button>
            <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button>
          </div>
        </div>        
        
      </Modal>
    </div>
    )
}

export default Form24;