import React from "react";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Home.css";
import Sidenav from "./Sidenav";

import Header from "./Header";

import Report1 from "./Report1"; // Report 1  CHILD MARRIAGE (M)
import Report2 from "./Report2"; // Report 2  CHILD LABOUR (M)
import Report10 from "./Report10"; // Report 10  Saranabalyam (M)
import Report11 from "./Report11"; // Report 11 Details of Violence against Children (M)
import Report25 from "./Report25"; // FORM 25 PARENTING CLINIC REPORTING FORMAT	(M)

import Report3 from "./Report3"; // Report 3 : State sponsorship	(Q)
import Report4 from "./Report4"; // Report 4 Central sponsorship	(Q)
import Report5 from "./Report5"; // Report 5 After Care services  (Govt) (Q)
import Report6 from "./Report6"; // Report 6 Aftercare offered to children in CCIs (age 18-21) (Q)
import Report7 from "./Report7"; // Report 7 Details of crimes committed by children (Q)
import Report9 from "./Report9"; // Report 9  InReportation regarding Juvenile Justice (Care and Protection of Children) Act (Q)
import Report12 from "./Report12"; // Report 12 JJA (Q)
import Report13 from "./Report13"; // Report 13 POCSO (Q)
import Report14 from "./Report14"; // Report 14 Details of de-institutionalised children (Govt)	(Q)
import Report15 from "./Report15"; // FORM 15  Juvenile Justice Boards
import Report16 from "./Report16"; // FORM 16 Institutional Care of Children in Conflict with Law	(Q)
import Report17 from "./Report17"; // FORM 17 1. Child Welfare Committees (Constituted under section 27(1) of the JJ Act, 2015):- (Q)
import Report18 from "./Report18"; // FORM 18 Institutional Care of Children in Need of Care and Protection	(Q)
import Report19 from "./Report19"; // FORM 19 3. Adoption under section 56 of the JJ Act, 2015	(Q)
import Report20 from "./Report20"; // FORM 20 4. Institutional Mechanisms for protection of children	(Q)
import Report21 from "./Report21"; // FORM 21  Foster Care	(Q)
import Report22 from "./Report22"; // FORM 22  Repatriation (Q)

import Report8 from "./Report8"; // Report 8 Details of resource persons/experts	(Bi)
import Report26 from "./Report26"; // Ncpcr
import Report27 from "./Report27"; // qprsc
import Report28 from "./Report28"; // qprhc
import Report30 from "./Report30"; // parentingoutreach
import Edit1 from "./Report28"; // qprhc
import { useFormStore } from "../Store/Forms";
import moment from "moment";

function Reports() {
    const [modalIsOpen, setmodalIsOpen] = React.useState(false);
    const [modalIsOpen2, setmodalIsOpen2] = React.useState(false);
    const [saranabalyamReportIsOpen, setsaranabalyamReportIsOpen] =
        React.useState(false);
    const [violenceReportIsOpen, setviolenceReportIsOpen] = React.useState(false);
    const [ParentingClinicReportIsOpen, setParentingClinicReportIsOpen] =
        React.useState(false);
    const [ParentingOutreachReportIsOpen, setParentingOutreachReportIsOpen] =
        React.useState(false);

    const [stateSponsorshipReportsIsOpen, setstateSponsorshipReportsIsOpen] =
        React.useState(false);
    const [centralSponsorshipReportsIsOpen, setcentralSponsorshipReportsIsOpen] =
        React.useState(false);
    const [afterCareReportsIsOpen, setafterCareReportsIsOpen] =
        React.useState(false);
    const [afterCareCCIReportsIsOpen, setafterCareCCIReportsIsOpen] =
        React.useState(false);
    const [
        DeinstitutionalisedReportsIsOpen,
        setDeinstitutionalisedReportsIsOpen,
    ] = React.useState(false);
    const [crimesReportsIsOpen, setcrimesReportsIsOpen] = React.useState(false);
    const [juvenileJusticeReportsIsOpen, setjuvenileJusticeReportsIsOpen] =
        React.useState(false);
    const [CWCIsOpen, setCWCIsOpen] = React.useState(false);

    // const [JJAReportsIsOpen, setJJAReportsIsOpen] = React.useState(false);
    // const [POCSOReportsIsOpen, setPOCSOReportsIsOpen] = React.useState(false);
    const [
        JuvenileJusticeBoardsReportsIsOpen,
        setJuvenileJusticeBoardsReportsIsOpen,
    ] = React.useState(false);
    const [InstitutionalCareReportsIsOpen, setInstitutionalCareReportsIsOpen] =
        React.useState(false);
    const [
        ChildWelfareCommitteesReportsIsOpen,
        setChildWelfareCommitteesReportsIsOpen,
    ] = React.useState(false);
    const [ChildrenCareReportsIsOpen, setChildrenCareReportsIsOpen] =
        React.useState(false);
    const [AdoptionReportsIsOpen, setAdoptionReportsIsOpen] =
        React.useState(false);
    const [
        ProtectionofChildrenReportsIsOpen,
        setProtectionofChildrenReportsIsOpen,
    ] = React.useState(false);
    const [FosterCareReportsIsOpen, setFosterCareReportsIsOpen] =
        React.useState(false);
    const [RepatriationReportsIsOpen, setRepatriationReportsIsOpen] =
        React.useState(false);

    const [resourceReportsIsOpen, setresourceReportsIsOpen] =
        React.useState(false);
    const [NcpcrReportsIsOpen, setNcpcrReportsIsOpen] = React.useState(false);
    const [QprscReportsIsOpen, setQprscReportsIsOpen] = React.useState(false);
    const [QprhcReportsIsOpen, setQprhcReportsIsOpen] = React.useState(false);
    // const [month, setMonth] = React.useState(moment().format("M"));
    const [month, setMonth] = React.useState("");
    const [year, setYear] = React.useState("");
    const [Qmonth, QuarterlysetMonth] = React.useState("");
    const [Qyear, QuarterlysetYear] = React.useState("");
    const [annually, setAnnually] = React.useState("");

    const [{ report }, { getReport }] = useFormStore();
    const [{ updatedReport }] = useFormStore();

    const openReportForm1 = () => {
        if (month === "" || year === "") {
            alert("Date not selected");
            return false;
        };
        getReport("child_marriage");
        setmodalIsOpen(true);
    };

    const openReportForm2 = () => {
        if (month === "" || year === "") {
            alert("Date not selected");
            return false;
        };
        getReport("child_labour");
        setmodalIsOpen2(true);
    };

    const saranabalyamReport = () => {
        if (month === "" || year === "") {
            alert("Date not selected");
            return false;
        };
        getReport("saranabalyam");
        setsaranabalyamReportIsOpen(true);
    };

    const violenceReport = () => {
        if (Qmonth === "" || Qyear === "") {
            alert("Date not selected");
            return false;
        };
        getReport("violence_against_children");
        setviolenceReportIsOpen(true);
    };

    const stateSponsorshipReports = () => {
        getReport("state_sponsership");
        setstateSponsorshipReportsIsOpen(true);
    };

    const centralSponsorshipReports = () => {
        if (Qmonth === "" || Qyear === "") {
            alert("Date not selected");
            return false;
        };
        getReport("central_sponsership");
        setcentralSponsorshipReportsIsOpen(true);
    };

    const afterCareReports = () => {
        if (Qmonth === "" || Qyear === "") {
            alert("Date not selected");
            return false;
        };
        getReport("after_care_service");
        setafterCareReportsIsOpen(true);
    };

    const afterCareCCIReports = () => {
        if (Qmonth === "" || Qyear === "") {
            alert("Date not selected");
            return false;
        };
        getReport("after_care_children_cci");
        setafterCareCCIReportsIsOpen(true);
    };

    const DeinstitutionalisedReports = () => {
        getReport("deinstitutional");
        setDeinstitutionalisedReportsIsOpen(true);
    };

    const crime_committed = () => {
        if (Qmonth === "" || Qyear === "") {
            alert("Date not selected");
            return false;
        };
        getReport("crime_committed");
        setcrimesReportsIsOpen(true);
    };

    const juvenileJusticeReports = () => {
        getReport("juvenile_justice");
        setjuvenileJusticeReportsIsOpen(true);
    };

    const CWC = () => {
        if (Qmonth === "" || Qyear === "") {
            alert("Date not selected");
            return false;
        };
        getReport("cwc");
        setCWCIsOpen(true);
    };

    // const JJAReports = () => {
    //   getReport("jja");
    //   setJJAReportsIsOpen(true);
    // };

    // const POCSOReports = () => {
    //   getReport("pocso");
    //   setPOCSOReportsIsOpen(true);
    // };

    const resourceReports = () => {
        if (annually == "") {
            alert("Year not selected");
            return false;
        };
        getReport("resource_person");
        setresourceReportsIsOpen(true);
    };

    const JuvenileJusticeBoardsReports = () => {
        getReport("jjb");
        setJuvenileJusticeBoardsReportsIsOpen(true);
    };

    const InstitutionalCareReports = () => {
        getReport("institutional_care");
        setInstitutionalCareReportsIsOpen(true);
    };

    const ChildWelfareCommitteesReports = () => {
        getReport("cwc");
        setChildWelfareCommitteesReportsIsOpen(true);
    };

    const ChildrenCareReports = () => {
        getReport("institutional_care_protection");
        setChildrenCareReportsIsOpen(true);
    };

    const AdoptionReports = () => {
        getReport("adoption");
        setAdoptionReportsIsOpen(true);
    };

    const ProtectionofChildrenReports = () => {
        getReport("child_protection");
        setProtectionofChildrenReportsIsOpen(true);
    };

    const FosterCareReports = () => {
        if (Qmonth === "" || Qyear === "") {
            alert("Date not selected");
            return false;
        };
        getReport("foster_care");
        setFosterCareReportsIsOpen(true);
    };

    const RepatriationReports = () => {
        if (Qmonth === "" || Qyear === "") {
            alert("Date not selected");
            return false;
        };
        getReport("repatriation");
        setRepatriationReportsIsOpen(true);
    };
    const NcpcrReports = () => {
        if (Qmonth === "" || Qyear === "") {
            alert("Date not selected");
            return false;
        };
        getReport("ncpcr");
        setNcpcrReportsIsOpen(true);
    };
    const QprscReports = () => {
        if (Qmonth === "" || Qyear === "") {
            alert("Date not selected");
            return false;
        };
        getReport("qprsc");
        setQprscReportsIsOpen(true);
    };
    const QprhcReports = () => {
        if (Qmonth === "" || Qyear === "") {
            alert("Date not selected");
            return false;
        };
        getReport("qprhc");
        setQprhcReportsIsOpen(true);
    };

    const ParentingClinicReport = () => {
        if (month === "" || year === "") {
            alert("Date not selected");
            return false;
        };
        getReport("parenting_clinic");
        setParentingClinicReportIsOpen(true);
    };
    const ParentingOutreachReport = () => {
        if (month === "" || year === "") {
            alert("Date not selected");
            return false;
        };
        getReport("parenting_outreach");
        setParentingOutreachReportIsOpen(true);
    };
    const handleChange = (e) => {
        setMonth(e.target.value);
    };
    const handleChangeYear = (e) => {
        setYear(e.target.value);
    };
    const QuarterlyhandleChange = (e) => {
        QuarterlysetMonth(e.target.value);
    };
    const QuarterlyhandleChangeYear = (e) => {
        QuarterlysetYear(e.target.value);
    };
    const SelectAnnually = (e) => {
        setAnnually(e.target.value);
    };
    const date = new Date();
    const currentYear = Number(date.getFullYear());

    return (
        <div>
            <div className="page-wrapper" id="pageWrapper">
                {/* Header  */}
                <Header />

                {/* <!-- Page Body Start--> */}
                <div className="page-body-wrapper horizontal-menu">
                    {/* Sidebar  */}
                    <Sidenav />

                    <div className="page-body">
                        <div className="container-fluid">
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h3> Reports </h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Container-fluid starts--> */}
                        <div className="container-fluid general-widget">
                            <div className="row">
                                {/* Monthly */}
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header pb-0 d-flex justify-content-between">
                                            <h5> Monthly </h5>
                                            <div className="w-50 mt-15 d-flex justify-content-end">
                                                <select
                                                    class="form-select w-25"
                                                    aria-label="Default select example"
                                                    // onChange={(e) => setMonth(e.target.value)}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select Month</option>
                                                    <option value="01">January</option>
                                                    <option value="02">February</option>
                                                    <option value="03">March</option>
                                                    <option value="04">April</option>
                                                    <option value="05">May</option>
                                                    <option value="06">June</option>
                                                    <option value="07">July</option>
                                                    <option value="08">August</option>
                                                    <option value="09">September</option>
                                                    <option value="10">October</option>
                                                    <option value="11">November</option>
                                                    <option value="12">December</option>
                                                </select>
                                                <select
                                                    class="form-select w-25"
                                                    aria-label="Default select example"
                                                    // onChange={(e) => setMonth(e.target.value)}
                                                    onChange={handleChangeYear}
                                                >
                                                    <option value="">Select Year</option>
                                                    <option value={currentYear - 5}>{currentYear - 5}</option>
                                                    <option value={currentYear - 4}>{currentYear - 4}</option>
                                                    <option value={currentYear - 3}>{currentYear - 3}</option>
                                                    <option value={currentYear - 2}>{currentYear - 2}</option>
                                                    <option value={currentYear - 1}>{currentYear - 1}</option>
                                                    <option value={currentYear}>{currentYear}</option>
                                                    <option value={currentYear + 1}>{currentYear + 1}</option>
                                                    <option value={currentYear + 2}>{currentYear + 2}</option>
                                                    <option value={currentYear + 3}>{currentYear + 3}</option>
                                                    <option value={currentYear + 4}>{currentYear + 4}</option>
                                                    <option value={currentYear + 5}>{currentYear + 5}</option>
                                                    <option value={currentYear + 6}>{currentYear + 6}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="card-body btn-showcase">
                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={openReportForm1}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                Child marriage{" "}
                                            </button>

                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={openReportForm2}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong1"
                                            >
                                                {" "}
                                                Child labour{" "}
                                            </button>

                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={saranabalyamReport}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong1"
                                            >
                                                {" "}
                                                Saranabalyam{" "}
                                            </button>

                                            {/* <!-- Scrolling long content--> */}


                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={ParentingClinicReport}
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong1"
                                            >
                                                {" "}
                                                Parenting Clinic{" "}
                                            </button>
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={ParentingOutreachReport}
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong1"
                                            >
                                                {" "}Parenting Outreach{" "}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* Quarterly */}
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header pb-0 pb-0 d-flex justify-content-between">
                                            <h5> Quarterly </h5>
                                            <div className="w-50 mt-15 d-flex justify-content-end">
                                                <select
                                                    class="form-select w-25"
                                                    aria-label="Default select example"
                                                    onChange={QuarterlyhandleChange}
                                                >
                                                    <option value="">Select - Quarterly</option>
                                                    <option value="1">January - March</option>
                                                    <option value="2">April - June</option>
                                                    <option value="3">July - September</option>
                                                    <option value="4">October - December</option>
                                                </select>
                                                <select
                                                    class="form-select w-25"
                                                    aria-label="Default select example"
                                                    // onChange={(e) => setMonth(e.target.value)}
                                                    onChange={QuarterlyhandleChangeYear}
                                                >
                                                    <option value="">Select Year</option>
                                                    <option value={currentYear - 5}>{currentYear - 5}</option>
                                                    <option value={currentYear - 4}>{currentYear - 4}</option>
                                                    <option value={currentYear - 3}>{currentYear - 3}</option>
                                                    <option value={currentYear - 2}>{currentYear - 2}</option>
                                                    <option value={currentYear - 1}>{currentYear - 1}</option>
                                                    <option value={currentYear}>{currentYear}</option>
                                                    <option value={currentYear + 1}>{currentYear + 1}</option>
                                                    <option value={currentYear + 2}>{currentYear + 2}</option>
                                                    <option value={currentYear + 3}>{currentYear + 3}</option>
                                                    <option value={currentYear + 4}>{currentYear + 4}</option>
                                                    <option value={currentYear + 5}>{currentYear + 5}</option>
                                                    <option value={currentYear + 6}>{currentYear + 6}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="card-body btn-showcase">
                                            {/* <!-- Scrolling long content--> */}
                                            {/* <button
                        className="btn btn-primary"
                        onClick={stateSponsorshipReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        State Sponsorship{" "}
                      </button> */}

                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={centralSponsorshipReports}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                Details of children availing sponsorship schemes{" "}
                                            </button>

                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={afterCareReports}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                Aftercare Services{" "}
                                            </button>

                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={afterCareCCIReports}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                Aftercare offered to children in CCIs (age 18-21){" "}
                                            </button>

                                            {/* <!-- Scrolling long content--> */}
                                            {/* <button
                        className="btn btn-primary"
                        onClick={DeinstitutionalisedReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        De-institutionalised children{" "}
                      </button> */}

                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={crime_committed}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                Details of crimes committed by children{" "}
                                            </button>

                                            {/* <!-- Scrolling long content--> */}
                                            {/* <button
                        className="btn btn-primary"
                        onClick={juvenileJusticeReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Juvenile Justice{" "}
                      </button> */}

                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={CWC}
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong1"
                                            >
                                                Details of cases produced before CWC
                                            </button>

                                            {/* <!-- Scrolling long content--> */}
                                            {/* <button
                        className="btn btn-primary"
                        onClick={JJAReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        JJA{" "}
                      </button> */}

                                            {/* <!-- Scrolling long content--> */}
                                            {/* <button
                        className="btn btn-primary"
                        onClick={POCSOReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        POCSO{" "}
                      </button> */}

                                            {/* <!-- Scrolling long content--> */}
                                            {/* <button
                        className="btn btn-primary"
                        onClick={JuvenileJusticeBoardsReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Juvenile Justice Boards{" "}
                      </button> */}

                                            {/* <!-- Scrolling long content--> */}
                                            {/* <button
                        className="btn btn-primary"
                        onClick={InstitutionalCareReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Institutional Care of Children in Conflict with Law{" "}
                      </button> */}

                                            {/* <!-- Scrolling long content--> */}
                                            {/* <button
                        className="btn btn-primary"
                        onClick={ChildWelfareCommitteesReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Child Welfare Committees{" "}
                      </button> */}

                                            {/* <!-- Scrolling long content--> */}
                                            {/* <button
                        className="btn btn-primary"
                        onClick={ChildrenCareReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        Institutional Care of Children in Need of Care and
                        Protection
                      </button> */}

                                            {/* <!-- Scrolling long content--> */}
                                            {/* <button
                        className="btn btn-primary"
                        onClick={AdoptionReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Adoption{" "}
                      </button> */}

                                            {/* <!-- Scrolling long content--> */}
                                            {/* <button
                        className="btn btn-primary"
                        onClick={ProtectionofChildrenReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Protection of Children{" "}
                      </button> */}

                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={FosterCareReports}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                Number of children in foster care{" "}
                                            </button>

                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={RepatriationReports}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                Repatriation{" "}
                                            </button>
                                            {/* Ncpcr */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={NcpcrReports}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                NCPCR{" "}
                                            </button>
                                            {/* Qprsc */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={QprscReports}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                QPR- SC{" "}
                                            </button>
                                            {/* Qprhc */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={QprhcReports}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                QPR- HC{" "}
                                            </button>
                                            <button
                                                className="btn btn-primary"
                                                onClick={violenceReport}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong1"
                                            >
                                                {" "}
                                                Violence against Children{" "}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* Bi-annually */}
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header pb-0 d-flex justify-content-between">
                                            <h5> Bi-annually </h5>
                                            <div className="w-50 mt-15 d-flex justify-content-end">
                                                <select
                                                    class="form-select w-25"
                                                    aria-label="Default select example"
                                                    // onChange={(e) => setMonth(e.target.value)}
                                                    onChange={SelectAnnually}
                                                >
                                                    <option value="">Select Year</option>
                                                    <option value={currentYear - 5}>{currentYear - 5}</option>
                                                    <option value={currentYear - 4}>{currentYear - 4}</option>
                                                    <option value={currentYear - 3}>{currentYear - 3}</option>
                                                    <option value={currentYear - 2}>{currentYear - 2}</option>
                                                    <option value={currentYear - 1}>{currentYear - 1}</option>
                                                    <option value={currentYear}>{currentYear}</option>
                                                    <option value={currentYear + 1}>{currentYear + 1}</option>
                                                    <option value={currentYear + 2}>{currentYear + 2}</option>
                                                    <option value={currentYear + 3}>{currentYear + 3}</option>
                                                    <option value={currentYear + 4}>{currentYear + 4}</option>
                                                    <option value={currentYear + 5}>{currentYear + 5}</option>
                                                    <option value={currentYear + 6}>{currentYear + 6}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="card-body btn-showcase">
                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={resourceReports}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                Details of resource persons/experts{" "}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {modalIsOpen && (
                            <Report1 close={() => setmodalIsOpen(false)} report={report} month={month}
                                year={year} />
                        )}
                        {modalIsOpen2 && (
                            <Report2 close={() => setmodalIsOpen2(false)} report={report} month={month}
                                year={year} />
                        )}
                        {saranabalyamReportIsOpen && (
                            <Report10
                                close={() => setsaranabalyamReportIsOpen(false)}
                                report={report} month={month}
                                year={year}
                            />
                        )}
                        {violenceReportIsOpen && (
                            <Report11
                                close={() => setviolenceReportIsOpen(false)}
                                report={report} Qmonth={Qmonth}
                                Qyear={Qyear}
                            />
                        )}
                        {ParentingClinicReportIsOpen && (
                            <Report25
                                close={() => setParentingClinicReportIsOpen(false)}
                                report={report} month={month}
                                year={year}
                            />
                        )}

                        {ParentingOutreachReportIsOpen && (
                            <Report30
                                close={() => setParentingOutreachReportIsOpen(false)}
                                report={report} month={month}
                                year={year}
                            />
                        )}

                        {stateSponsorshipReportsIsOpen && (
                            <Report3
                                close={() => setstateSponsorshipReportsIsOpen(false)}
                                report={report}
                            />
                        )}
                        {centralSponsorshipReportsIsOpen && (
                            <Report4
                                close={() => setcentralSponsorshipReportsIsOpen(false)}
                                report={report} Qmonth={Qmonth}
                                Qyear={Qyear}
                            />
                        )}
                        {afterCareReportsIsOpen && (
                            <Report5
                                close={() => setafterCareReportsIsOpen(false)}
                                report={report} Qmonth={Qmonth}
                                Qyear={Qyear}
                            />
                        )}
                        {afterCareCCIReportsIsOpen && (
                            <Report6
                                close={() => setafterCareCCIReportsIsOpen(false)}
                                report={report} Qmonth={Qmonth}
                                Qyear={Qyear}
                            />
                        )}
                        {DeinstitutionalisedReportsIsOpen && (
                            <Report14
                                close={() => setDeinstitutionalisedReportsIsOpen(false)}
                                report={report}
                            />
                        )}
                        {crimesReportsIsOpen && (
                            <Report7
                                close={() => setcrimesReportsIsOpen(false)}
                                report={report} Qmonth={Qmonth}
                                Qyear={Qyear}
                            />
                        )}
                        {juvenileJusticeReportsIsOpen && (
                            <Report9
                                close={() => setjuvenileJusticeReportsIsOpen(false)}
                                report={report}
                            />
                        )}

                        {CWCIsOpen && (
                            <Report12 close={() => setCWCIsOpen(false)} report={report} Qmonth={Qmonth}
                                Qyear={Qyear} />
                        )}

                        {/* {JJAReportsIsOpen && (
              <Report12
                close={() => setJJAReportsIsOpen(false)}
                report={report}
              />
            )} */}
                        {/* {POCSOReportsIsOpen && (
              <Report13
                close={() => setPOCSOReportsIsOpen(false)}
                report={report}
              />
            )} */}
                        {JuvenileJusticeBoardsReportsIsOpen && (
                            <Report15
                                close={() => setJuvenileJusticeBoardsReportsIsOpen(false)}
                                report={report}
                            />
                        )}
                        {InstitutionalCareReportsIsOpen && (
                            <Report16
                                close={() => setInstitutionalCareReportsIsOpen(false)}
                                report={report}
                            />
                        )}
                        {ChildWelfareCommitteesReportsIsOpen && (
                            <Report17
                                close={() => setChildWelfareCommitteesReportsIsOpen(false)}
                                report={report}
                            />
                        )}
                        {ChildrenCareReportsIsOpen && (
                            <Report18
                                close={() => setChildrenCareReportsIsOpen(false)}
                                report={report}
                            />
                        )}
                        {AdoptionReportsIsOpen && (
                            <Report19
                                close={() => setAdoptionReportsIsOpen(false)}
                                report={report}
                            />
                        )}
                        {ProtectionofChildrenReportsIsOpen && (
                            <Report20
                                close={() => setProtectionofChildrenReportsIsOpen(false)}
                                report={report}
                            />
                        )}
                        {FosterCareReportsIsOpen && (
                            <Report21
                                close={() => setFosterCareReportsIsOpen(false)}
                                report={report} Qmonth={Qmonth}
                                Qyear={Qyear}
                            />
                        )}
                        {RepatriationReportsIsOpen && (
                            <Report22
                                close={() => setRepatriationReportsIsOpen(false)}
                                report={report} Qmonth={Qmonth}
                                Qyear={Qyear}
                            />
                        )}

                        {resourceReportsIsOpen && (
                            <Report8
                                close={() => setresourceReportsIsOpen(false)}
                                report={report}
                                annually={annually}
                            />
                        )}
                        {NcpcrReportsIsOpen && (
                            <Report26
                                close={() => setNcpcrReportsIsOpen(false)}
                                report={report} Qmonth={Qmonth}
                                Qyear={Qyear}
                            />
                        )}
                        {QprscReportsIsOpen && (
                            <Report27
                                close={() => setQprscReportsIsOpen(false)}
                                report={report} Qmonth={Qmonth}
                                Qyear={Qyear}
                            />
                        )}
                        {QprhcReportsIsOpen && (
                            <Report28
                                close={() => setQprhcReportsIsOpen(false)}
                                report={report} Qmonth={Qmonth}
                                Qyear={Qyear}
                            />
                        )}

                        {/* <!-- Container-fluid Ends--> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reports;
