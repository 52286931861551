import React from 'react';
import { HashRouter } from "react-router-dom";
import Routes from "./Components/Routes";
import { createBrowserHistory } from 'history';
// import Stats from './Components/Stats';

// import ModalR from "./Components/ModalR";


import './App.css';

const browserHistory = createBrowserHistory();

function App() {
  return (
    <div>
      <HashRouter history={browserHistory}>
        <Routes />
        {/* <ModalR /> */}
      </HashRouter>
    </div>
  );
}

export default App;
