import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";

// FORM 13 POCSO (Q)

function Report13(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{}, { downloadExcel }] = useFormStore();
  const Qyear = props.Qyear
  const Qmonth = props.Qmonth
  const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
  const DisplayQuarter = Quarter[Qmonth]

  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };

  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {" "}
            POCSO{" "}
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <h4>{DisplayQuarter} {Qyear}</h4>
        <div className="modal-body">
          <div class="table-responsive">
            <table class="table" id="pocso">
              <thead>
                <tr>
                  <th scope="col" rowspan="3">
                    #
                  </th>
                  <th scope="col" rowspan="3">
                    POCSO
                  </th>
                  <th scope="col" rowspan="2" colspan="2">
                    Total no:of cases{" "}
                  </th>
                  <th scope="col" rowspan="2" colspan="2">
                    FIR Registered
                  </th>
                  <th scope="col" rowspan="2" colspan="2">
                    Produced before CWC
                  </th>
                  <th scope="col" colspan="12">
                    Number of children who availed the services
                  </th>
                </tr>

                <tr>
                  <th scope="col" colspan="2">
                    Support person{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Expert service{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Counselling{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Free legal aid{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Victim compensation
                  </th>
                  <th scope="col" colspan="2">
                    Institutional support le
                  </th>
                </tr>

                <tr>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="row">0</th>
                  <td>district</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
                {/* <tr>
                  <th scope="row">1</th>
                  <td>Penetrative Sexual Assualt (Sec 3)</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">2</th>
                  <td>Aggravated Penetrative Sexual Assault (Sec 5)</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">3</th>
                  <td>Sexual Assault (Sec 7)</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">4</th>
                  <td>Aggravated Sexual Assualt (Sec 9)</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">5</th>
                  <td>Sexual Harassment (Sec 11)</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">6</th>
                  <td>Using child for pornographic purposes (Sec 13)</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">7</th>
                  <td>Total</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("pocso")}
          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default Report13;
