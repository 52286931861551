import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";
import { useEffect } from "react";
import { UPDATE_AFTER_CARE_CHILDREN_CCI } from "../utils/constant";
import { Toaster, toast } from "react-hot-toast";

// FORM 6 Aftercare offered to children in CCIs (age 18-21)

function Form5(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { onSubmit }] = useFormStore();
    const [{ }, { updateReport }] = useFormStore();
    const [{ }, { formUpdated }] = useFormStore();
    const Qmonth = props.Qmonth;
    const Qyear = props.Qyear;
    const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
    const DisplayQuarter = Quarter[Qmonth];

    const [data, setdata] = useState({
        male_cumulative: 0,
        female_cumulative: 0,
        male_current_quarter: 0,
        female_current_quarter: 0,
        home_employed_or_other_income_male_cumulative: 0,
        home_employed_or_other_income_female_cumulative: 0,
        home_employed_or_other_income_male_current_quarter: 0,
        home_employed_or_other_income_female_current_quarter: 0,
        vocational_or_placement_training_male_cummulative: 0,
        vocational_or_placement_training_female_cummulative: 0,
        vocational_or_placement_training_male_current_quarter: 0,
        vocational_or_placement_training_female_current_quarter: 0,
        youth_exitted_from_home_for_employment_male_cumulative: 0,
        youth_exitted_from_home_for_employment_female_cumulative: 0,
        youth_exitted_from_home_for_employment_male_current_quarter: 0,
        youth_exitted_from_home_for_employment_female_current_quarter: 0,
        month: Qmonth,
        year: Qyear,
    });

    useEffect(() => {
        if ((props || {}).isEdit) {
            const { id, created_at, updated_at, username, user, report_type, ...reportSorted } = props.report.find((res) => { return res.month === Qmonth && res.year === Qyear; }) || {};
            setdata({
                ...data,
                ...reportSorted
            });
        }
    }, []);

    const openForm1 = () => {
        if (props.isEdit) {
            return toast.promise(
                updateReport(data, UPDATE_AFTER_CARE_CHILDREN_CCI),
                {
                    loading: 'Updating Record',
                    success: 'Record Updated',
                    error: 'Error while saving'
                }
            ).then(() => formUpdated(true));
        } else {
            onSubmit(data, "after_care_children_cci");
            setmodalIsOpen(false);
        }
    };

    const closeButton = () => {
        props.close();
        setmodalIsOpen(false);
    };

    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <Toaster position="bottom-right" />
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {" "}
                            Aftercare offered to children in CCIs (age 18-21){" "}
                        </h5>
                        <button
                            className="btn-close"
                            onClick={closeButton}
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <h4>{DisplayQuarter} {Qyear}</h4>
                    <div className="modal-body">
                        <form>
                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    <b> No. of children </b>{" "}
                                </label>
                                {/* <label> Male (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, male_cumulative: e.target.value })
                  }
                /> */}
                                <label> Male (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.male_current_quarter}
                                    onChange={(e) =>
                                        setdata({ ...data, male_current_quarter: e.target.value })
                                    }
                                />
                                {/* <label> Female (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.female_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, female_cumulative: e.target.value })
                  }
                /> */}
                                <label> Female (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.female_current_quarter}
                                    onChange={(e) =>
                                        setdata({ ...data, female_current_quarter: e.target.value })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    Number of children currently at home employed/involved in
                                    income generating options{" "}
                                </label>
                                {/* <label> Male (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.home_employed_or_other_income_male_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      home_employed_or_other_income_male_cumulative:
                        e.target.value,
                    })
                  }
                /> */}
                                <label> Male (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={
                                        data.home_employed_or_other_income_male_current_quarter
                                    }
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            home_employed_or_other_income_male_current_quarter:
                                                e.target.value,
                                        })
                                    }
                                />
                                {/* <label> Female (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.home_employed_or_other_income_female_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      home_employed_or_other_income_female_cumulative:
                        e.target.value,
                    })
                  }
                /> */}
                                <label> Female (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={
                                        data.home_employed_or_other_income_female_current_quarter
                                    }
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            home_employed_or_other_income_female_current_quarter:
                                                e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    Number of children who completed vocational/placement
                                    training?{" "}
                                </label>
                                {/* <label> Male (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.vocational_or_placement_training_male_cummulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      vocational_or_placement_training_male_cummulative:
                        e.target.value,
                    })
                  }
                /> */}
                                <label> Male (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={
                                        data.vocational_or_placement_training_male_current_quarter
                                    }
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            vocational_or_placement_training_male_current_quarter:
                                                e.target.value,
                                        })
                                    }
                                />
                                {/* <label> Female (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.vocational_or_placement_training_female_cummulative
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      vocational_or_placement_training_female_cummulative:
                        e.target.value,
                    })
                  }
                /> */}
                                <label> Female (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={
                                        data.vocational_or_placement_training_female_current_quarter
                                    }
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            vocational_or_placement_training_female_current_quarter:
                                                e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    Number of youth who exited from the homes got gainful
                                    employment?{" "}
                                </label>
                                {/* <label> Male (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.youth_exitted_from_home_for_employment_male_cumulative
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      youth_exitted_from_home_for_employment_male_cumulative:
                        e.target.value,
                    })
                  }
                /> */}
                                <label> Male (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={
                                        data.youth_exitted_from_home_for_employment_male_current_quarter
                                    }
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            youth_exitted_from_home_for_employment_male_current_quarter:
                                                e.target.value,
                                        })
                                    }
                                />
                                {/* <label> Female (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.youth_exitted_from_home_for_employment_female_cumulative
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      youth_exitted_from_home_for_employment_female_cumulative:
                        e.target.value,
                    })
                  }
                /> */}
                                <label> Female (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={
                                        data.youth_exitted_from_home_for_employment_female_current_quarter
                                    }
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            youth_exitted_from_home_for_employment_female_current_quarter:
                                                e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={closeButton}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={openForm1}
                            type="button"
                        >
                            Save & Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Form5;
