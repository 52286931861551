import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";

// FORM 14  Details of de-institutionalised children (Govt)

function Form14(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { onSubmit }] = useFormStore();

  const [data, setdata] = useState({
    male_cumulative: 0,
    female_cumulative: 0,
    male_current_quarter: 0,
    female_current_quarter: 0,
    adoption_male_cumulative: 0,
    adoption_female_cumulative: 0,
    adoption_male_current_quarter: 0,
    adoption_female_current_quarter: 0,
    foster_care_male_cumulative: 0,
    foster_care_female_cumulative: 0,
    foster_care_male_current_quarter: 0,
    foster_care_female_current_quarter: 0,
    restoration_male_cumulative: 0,
    restoration_female_cumulative: 0,
    restoration_male_current_quarter: 0,
    restoration_female_current_quarter: 0,
    sponsership_male_cumulative: 0,
    sponsership_female_cumulative: 0,
    sponsership_male_current_quarter: 0,
    sponsership_female_current_quarter: 0,
  });

  const openForm1 = () => {
    props.close();
    onSubmit(data, "deinstitutional");
    setmodalIsOpen(false);
  };
  const closeButton = () => {
    props.close();
    setmodalIsOpen(false);
  };
  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {" "}
              Details of de-institutionalised children (Govt){" "}
            </h5>
            <button
              className="btn-close"
              onClick={openForm1}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <div className="new-expense__control">
                <label>
                  {" "}
                  <b> No. of children </b>{" "}
                </label>
                <label> Male (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, male_cumulative: e.target.value })
                  }
                />
                <label> Male (Current quarter) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male_current_quarter}
                  onChange={(e) =>
                    setdata({ ...data, male_current_quarter: e.target.value })
                  }
                />
                <label> Female (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.female_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, female_cumulative: e.target.value })
                  }
                />
                <label> Female (Current quarter) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.female_current_quarter}
                  onChange={(e) =>
                    setdata({ ...data, female_current_quarter: e.target.value })
                  }
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  <b> De-institutionalised to </b>{" "}
                </label>
                <label> Adoption </label>
                <label> Male (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.adoption_male_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      adoption_male_cumulative: e.target.value,
                    })
                  }
                />
                <label> Male (Current quarter) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.adoption_male_current_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      adoption_male_current_quarter: e.target.value,
                    })
                  }
                />
                <label> Female (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.adoption_female_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      adoption_female_cumulative: e.target.value,
                    })
                  }
                />
                <label> Female (Current quarter) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.adoption_female_current_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      adoption_female_current_quarter: e.target.value,
                    })
                  }
                />
              </div>

              <div className="new-expense__control">
                <label> Foster care </label>
                <label> Male (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.foster_care_male_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      foster_care_male_cumulative: e.target.value,
                    })
                  }
                />
                <label> Male (Current quarter) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.foster_care_male_current_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      foster_care_male_current_quarter: e.target.value,
                    })
                  }
                />
                <label> Female (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.foster_care_female_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      foster_care_female_cumulative: e.target.value,
                    })
                  }
                />
                <label> Female (Current quarter) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.foster_care_female_current_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      foster_care_female_current_quarter: e.target.value,
                    })
                  }
                />
              </div>

              <div className="new-expense__control">
                <label> Restoration to parents/family </label>
                <label> Male (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.restoration_male_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      restoration_male_cumulative: e.target.value,
                    })
                  }
                />
                <label> Male (Current quarter) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.restoration_male_current_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      restoration_male_current_quarter: e.target.value,
                    })
                  }
                />
                <label> Female (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.restoration_female_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      restoration_female_cumulative: e.target.value,
                    })
                  }
                />
                <label> Female (Current quarter) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.restoration_female_current_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      restoration_female_current_quarter: e.target.value,
                    })
                  }
                />
              </div>

              <div className="new-expense__control">
                <label> No of children availed sponsorship </label>
                <label> Male (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.sponsership_male_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      sponsership_male_cumulative: e.target.value,
                    })
                  }
                />
                <label> Male (Current quarter) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.sponsership_male_current_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      sponsership_male_current_quarter: e.target.value,
                    })
                  }
                />
                <label> Female (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.sponsership_female_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      sponsership_female_cumulative: e.target.value,
                    })
                  }
                />
                <label> Female (Current quarter) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.sponsership_female_current_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      sponsership_female_current_quarter: e.target.value,
                    })
                  }
                />
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              onClick={closeButton}
              type="button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              onClick={openForm1}
              type="button"
            >
              Save & Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Form14;
