import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";

// FORM 8 Details of resource persons/experts

function Report8(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { downloadExcel }] = useFormStore();
  const year = props.annually


  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };
  // object assigned to a variable to obtain total
  const values = Array.isArray(props.report) ? props.report : []
  // sum_array = total sum data of district
  const sum_array = (values || []).reduce((sum_array, value) => {
    for (const [key, count] of Object.entries(value)) {
      if (!sum_array[key]) {
        sum_array[key] = 0;
      }
      sum_array[key] += count;
    }
    return sum_array;
  }, {});
  function createPDF() {
    var sTable = document.getElementById('table').innerHTML;
    var style = "<style>";
    style = style + "table {width: 100%;font: 10px Calibri;}";
    style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "</style>";
    var win = window.open('', '', 'height=700,width=700');
    win.document.write('<html><head>');
    win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
    win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
    win.document.write('</head>');
    win.document.write('<body>');
    win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
    win.document.write('</body></html>');
    win.document.close(); 	// CLOSE THE CURRENT WINDOW.
    win.print();    // PRINT THE CONTENTS.
  }
  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {" "}
            Details of resource persons/experts{" "}
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <h4>{year}</h4>
        <div className="modal-body">
          <div class="table-responsive" id="table" height="100%" width="100%" media="print">
            <table class="table" id="resource_person">
              <thead>
                <tr>
                  <th scope="col" rowspan="2">
                    #
                  </th>
                  <th scope="col" rowspan="2">
                    District
                  </th>
                  <th scope="col" colspan="5">
                    Total number of resource persons in district{" "}
                  </th>
                  <th scope="col" colspan="5">
                    Number of sessions demanded{" "}
                  </th>
                  <th scope="col" colspan="5">
                    Number of sessions offered{" "}
                  </th>
                </tr>

                <tr>
                  <th scope="col">Interpretor</th>
                  <th scope="col">Special educator</th>
                  <th scope="col">Support person</th>
                  <th scope="col">Translator</th>
                  <th scope="col">Expert</th>

                  <th scope="col">Interpretor</th>
                  <th scope="col">Special educator</th>
                  <th scope="col">Support person</th>
                  <th scope="col">Translator</th>
                  <th scope="col">Expert</th>

                  <th scope="col">Interpretor</th>
                  <th scope="col">Special educator</th>
                  <th scope="col">Support person</th>
                  <th scope="col">Translator</th>
                  <th scope="col">Expert</th>
                </tr>
              </thead>
              <tbody>
                {(Array.isArray(props.report) ? props.report : []).map((r) => (
                  <tr key={r?.id}>
                    <th scope="row"></th>
                    <td>{r?.username}</td>
                    <td>{r?.no_of_resource_person_interpretor}</td>
                    <td>{r?.no_of_resource_person_special_educator}</td>
                    <td>{r?.no_of_resource_person_support_person}</td>
                    <td>{r?.no_of_resource_person_translator}</td>
                    <td>{r?.no_of_resource_person_expert}</td>
                    <td>{r?.no_of_sesson_demanded_interpretor}</td>
                    <td>{r?.no_of_sesson_demanded_special_educator}</td>
                    <td>{r?.no_of_sesson_demanded_support_person}</td>
                    <td>{r?.no_of_sesson_demanded_translator}</td>
                    <td>{r?.no_of_sesson_demanded_expert}</td>
                    <td>{r?.no_of_sesson_offered_person_interpretor}</td>
                    <td>{r?.no_of_sesson_offered_person_special_educator}</td>
                    <td>{r?.no_of_sesson_offered_person_support_person}</td>
                    <td>{r?.no_of_sesson_offered_person_translator}</td>
                    <td>{r?.no_of_sesson_offered_person_expert}</td>
                  </tr>
                ))}
                <tr>
                  <th scope="row"></th>
                  <td>Total</td>
                  <td>{sum_array.no_of_resource_person_interpretor}</td>
                  <td>{sum_array.no_of_resource_person_special_educator}</td>
                  <td>{sum_array.no_of_resource_person_support_person}</td>
                  <td>{sum_array.no_of_resource_person_translator}</td>
                  <td>{sum_array.no_of_resource_person_expert}</td>
                  <td>{sum_array.no_of_sesson_demanded_interpretor}</td>
                  <td>{sum_array.no_of_sesson_demanded_special_educator}</td>
                  <td>{sum_array.no_of_sesson_demanded_support_person}</td>
                  <td>{sum_array.no_of_sesson_demanded_translator}</td>
                  <td>{sum_array.no_of_sesson_demanded_expert}</td>
                  <td>{sum_array.no_of_sesson_offered_person_interpretor}</td>
                  <td>{sum_array.no_of_sesson_offered_person_special_educator}</td>
                  <td>{sum_array.no_of_sesson_offered_person_support_person}</td>
                  <td>{sum_array.no_of_sesson_offered_person_translator}</td>
                  <td>{sum_array.no_of_sesson_offered_person_expert}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("resource_person")}
          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-primary"
            onClick={createPDF}
            type="button"
            data-bs-dismiss="modal"
          >
            Download as Pdf
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default Report8;
