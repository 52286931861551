import { getUsersList, setPasswordReset } from "../../Api/Admin";

const actions = {
  setLoading:
    (loading) =>
      ({ setState }) => {
        setState({ loading });
      },
  onSubmit:
    (data, url) =>
      async ({ getState, setState }) => {
        try {
          setState({ loading: true });
          if (url === "reset-password") {
            await setPasswordReset(data);
          }
        } catch (error) {
          console.log(error?.data, "error");
          // message.error(error?.data?.message);
        } finally {
          setState({ loading: false });
        }
      },

  getUsersList:
    (url) =>
      async ({ getState, setState }) => {
        try {
          setState({ loading: true });
          const id = getState().currentFormData;
          if (url === "users") {
            const res = await getUsersList();
            setState({ userList: res });
          }

        } catch (error) {
          console.log(error?.data, "error");
          // message.error(error?.data?.message);
        } finally {
          setState({ loading: false });
        }
      },
};

export default actions;
