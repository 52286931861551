import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
// import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";
// FORM 25 PARENTING CLINIC REPORTING FORMAT

function AReport30(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { downloadExcel }] = useFormStore();
    const year = props.year;
    const month = props.month;
    const Month = ["Month not selected", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const DisplayMonth = Month[month];

    const openForm1 = () => {
        setmodalIsOpen(false);
        props.close();
    };
    // object assigned to a variable to obtain total

    let newArray = props.report.filter(function (d) {
        return d.year == year && d.month == month;
    });

    // sum_array = total sum data of district
    const sum_array = newArray.reduce((sum_array, value) => {
        for (const [key, count] of Object.entries(value)) {
            if (!sum_array[key]) {
                sum_array[key] = 0;
            }
            sum_array[key] += count;
        }
        return sum_array;
    }, {});
    // ----------------------------------------------
    let newArrayCumulative = props.report.filter(function (d) {
        return d.year == year && d.month <= month;
    });

    let result = [];

    newArrayCumulative.forEach(function (a) {
        if (!this[a.username]) {
            this[a.username] = {
                username: a.username, outreach_camps_held_during_the_month: 0, panchayaths_covered_during_the_month: 0,
                clients_attended_during_the_month: 0,
                parents_attended_during_the_month: 0,
                children_attended_during_the_month: 0,
                issues_identified_behavioural_during_the_month: 0,
                issues_identified_emotional_during_the_month: 0,
                issues_identified_somatic_during_the_month: 0,
                issues_identified_interpersonal_during_the_month: 0,
                issues_identified_cognitive_during_the_month: 0,
                issues_identified_family_discord_during_the_month: 0,
                referrals_done_during_the_month: 0,
                awareness_classes_during_the_month: 0,
                people_attended_awareness_class_during_the_month: 0,
            };
            result.push(this[a.username]);
        }
        this[a.username].outreach_camps_held_during_the_month += a.outreach_camps_held_during_the_month;
        this[a.username].panchayaths_covered_during_the_month += a.panchayaths_covered_during_the_month;
        this[a.username].clients_attended_during_the_month += a.clients_attended_during_the_month;
        this[a.username].parents_attended_during_the_month += a.parents_attended_during_the_month;
        //
        this[a.username].children_attended_during_the_month += a.children_attended_during_the_month;
        this[a.username].issues_identified_behavioural_during_the_month += a.issues_identified_behavioural_during_the_month;
        this[a.username].issues_identified_emotional_during_the_month += a.issues_identified_emotional_during_the_month;
        this[a.username].issues_identified_somatic_during_the_month += a.issues_identified_somatic_during_the_month;
        this[a.username].issues_identified_interpersonal_during_the_month += a.issues_identified_interpersonal_during_the_month;
        this[a.username].issues_identified_cognitive_during_the_month += a.issues_identified_cognitive_during_the_month;
        this[a.username].issues_identified_family_discord_during_the_month += a.issues_identified_family_discord_during_the_month;
        this[a.username].referrals_done_during_the_month += a.referrals_done_during_the_month;
        this[a.username].awareness_classes_during_the_month += a.awareness_classes_during_the_month;
        this[a.username].people_attended_awareness_class_during_the_month += a.people_attended_awareness_class_during_the_month;
    }, Object.create(null));



    for (let object of newArray) {
        for (let i of result) {
            if (object.username === i.username) {

                object.outreach_camps_held_up_to_last_month = i.outreach_camps_held_during_the_month;
                object.panchayaths_covered_up_to_last_month = i.panchayaths_covered_during_the_month;
                object.clients_attended_up_to_last_month = i.clients_attended_during_the_month;
                object.parents_attended_up_to_last_month = i.parents_attended_during_the_month;
                //
                object.children_attended_up_to_last_month = i.children_attended_during_the_month;
                object.issues_identified_behavioural_up_to_last_month = i.issues_identified_behavioural_during_the_month;
                object.issues_identified_emotional_up_to_last_month = i.issues_identified_emotional_during_the_month;
                object.issues_identified_somatic_up_to_last_month = i.issues_identified_somatic_during_the_month;
                object.issues_identified_interpersonal_up_to_last_month = i.issues_identified_interpersonal_during_the_month;
                object.issues_identified_cognitive_up_to_last_month = i.issues_identified_cognitive_during_the_month;
                object.issues_identified_family_discord_up_to_last_month = i.issues_identified_family_discord_during_the_month;
                object.referrals_done_up_to_last_month = i.referrals_done_during_the_month;
                object.awareness_classes_up_to_last_month = i.awareness_classes_during_the_month;
                object.people_attended_awareness_up_to_last_month = i.people_attended_awareness_class_during_the_month;
            }
        }

    }
    function createPDF() {
        var sTable = document.getElementById('table').innerHTML;
        var style = "<style>";
        style = style + "table {width: 100%;font: 10px Calibri;}";
        style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "</style>";
        var win = window.open('', '', 'height=700,width=700');
        win.document.write('<html><head>');
        win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
        win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
        win.document.write('</body></html>');
        win.document.close(); 	// CLOSE THE CURRENT WINDOW.
        win.print();    // PRINT THE CONTENTS.
    }
    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                        {" "}
                        Parenting Outreach{" "}
                    </h5>
                    <button
                        className="btn-close"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <h4>{DisplayMonth} {year}</h4>
                <div className="modal-body">
                    <div class="table-responsive" id="table" height="100%" width="100%" media="print">

                        <table class="table" id="parenting_outreach">
                            <thead>
                                <tr>
                                    <th scope="col" rowSpan="4">District</th>
                                    <th scope="col" colSpan="2">1</th>
                                    <th scope="col" colSpan="2">2</th>
                                    <th scope="col" colSpan="2">3</th>
                                    <th scope="col" colSpan="2">4</th>
                                    <th scope="col" colSpan="2">5</th>
                                    <th scope="col" colSpan="2">6. I</th>
                                    <th scope="col" colSpan="2">6. II</th>
                                    <th scope="col" colSpan="2">6. III</th>
                                    <th scope="col" colSpan="2">6. IV</th>
                                    <th scope="col" colSpan="2">6. V</th>
                                    <th scope="col" colSpan="2">6. VI</th>
                                    <th scope="col" colSpan="2">7</th>
                                    <th scope="col" colSpan="2">8</th>
                                    <th scope="col" colSpan="2">9</th>
                                </tr>
                                <tr>
                                    <th scope="col" rowspan="2" colspan="2">
                                        No of outreach camps held
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        {" "}
                                        No of Panchayaths covered
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        {" "}
                                        Number of people attended the camps
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        {" "}
                                        No of parents attended{" "}
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        {" "}
                                        No of children attended{" "}
                                    </th>
                                    <th scope="col" colspan="12">
                                        Issues identified{" "}
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        No of referrals done by the counsellor to Parenting
                                        clinics/DRC or others</th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        No of awareness camps held
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">No of people attended in the awareness class</th>
                                </tr>
                                <tr>
                                    <th scope="col" colspan="2">
                                        Behavioural{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Emotional{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Somatic (physical problems){" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Interpersonal (love, relationships etc){" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Cognitive (thinking learning related){" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Family discord{" "}
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative</th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative</th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative</th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative</th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative</th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative</th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative</th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative</th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative</th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative</th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative</th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative</th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative</th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative</th>
                                </tr>
                            </thead>
                            <tbody>
                                {newArray.map((r) => (
                                    <tr>
                                        <td>{r?.username}</td>
                                        <td>{r?.outreach_camps_held_during_the_month}</td>
                                        <td>{r?.outreach_camps_held_up_to_last_month}</td>
                                        <td>{r?.panchayaths_covered_during_the_month}</td>
                                        <td>{r?.panchayaths_covered_up_to_last_month}</td>
                                        <td>{r?.clients_attended_during_the_month}</td>
                                        <td>{r?.clients_attended_up_to_last_month}</td>
                                        <td>{r?.parents_attended_during_the_month}</td>
                                        <td>{r?.parents_attended_up_to_last_month}</td>
                                        <td>{r?.children_attended_during_the_month}</td>
                                        <td>{r?.children_attended_up_to_last_month}</td>
                                        <td>{r?.issues_identified_behavioural_during_the_month}</td>
                                        <td>{r?.issues_identified_behavioural_up_to_last_month}</td>
                                        <td>{r?.issues_identified_emotional_during_the_month}</td>
                                        <td>{r?.issues_identified_emotional_up_to_last_month}</td>
                                        <td>{r?.issues_identified_somatic_during_the_month}</td>
                                        <td>{r?.issues_identified_somatic_up_to_last_month}</td>
                                        <td>{r?.issues_identified_interpersonal_during_the_month}</td>
                                        <td>{r?.issues_identified_interpersonal_up_to_last_month}</td>
                                        <td>{r?.issues_identified_cognitive_during_the_month}</td>
                                        <td>{r?.issues_identified_cognitive_up_to_last_month}</td>
                                        <td>{r?.issues_identified_family_discord_during_the_month}</td>
                                        <td>{r?.issues_identified_family_discord_up_to_last_month}</td>
                                        <td>{r?.referrals_done_during_the_month}</td>
                                        <td>{r?.referrals_done_up_to_last_month}</td>
                                        <td>{r?.awareness_classes_during_the_month}</td>
                                        <td>{r?.awareness_classes_up_to_last_month}</td>
                                        <td>{r?.people_attended_awareness_class_during_the_month}</td>
                                        <td>{r?.people_attended_awareness_up_to_last_month}</td>
                                    </tr>

                                ))}
                                <tr>
                                    <td>Total</td>
                                    <td>{sum_array.outreach_camps_held_during_the_month}</td>
                                    <td>{sum_array.outreach_camps_held_up_to_last_month}</td>
                                    <td>{sum_array.panchayaths_covered_during_the_month}</td>
                                    <td>{sum_array.panchayaths_covered_up_to_last_month}</td>
                                    <td>{sum_array.clients_attended_during_the_month}</td>
                                    <td>{sum_array.clients_attended_up_to_last_month}</td>
                                    <td>{sum_array.parents_attended_during_the_month}</td>
                                    <td>{sum_array.parents_attended_up_to_last_month}</td>
                                    <td>{sum_array.children_attended_during_the_month}</td>
                                    <td>{sum_array.children_attended_up_to_last_month}</td>
                                    <td>{sum_array.issues_identified_behavioural_during_the_month}</td>
                                    <td>{sum_array.issues_identified_behavioural_up_to_last_month}</td>
                                    <td>{sum_array.issues_identified_emotional_during_the_month}</td>
                                    <td>{sum_array.issues_identified_emotional_up_to_last_month}</td>
                                    <td>{sum_array.issues_identified_somatic_during_the_month}</td>
                                    <td>{sum_array.issues_identified_somatic_up_to_last_month}</td>
                                    <td>{sum_array.issues_identified_interpersonal_during_the_month}</td>
                                    <td>{sum_array.issues_identified_interpersonal_up_to_last_month}</td>
                                    <td>{sum_array.issues_identified_cognitive_during_the_month}</td>
                                    <td>{sum_array.issues_identified_cognitive_up_to_last_month}</td>
                                    <td>{sum_array.issues_identified_family_discord_during_the_month}</td>
                                    <td>{sum_array.issues_identified_family_discord_up_to_last_month}</td>
                                    <td>{sum_array.referrals_done_during_the_month}</td>
                                    <td>{sum_array.referrals_done_up_to_last_month}</td>
                                    <td>{sum_array.awareness_classes_during_the_month}</td>
                                    <td>{sum_array.awareness_classes_up_to_last_month}</td>
                                    <td>{sum_array.people_attended_awareness_class_during_the_month}</td>
                                    <td>{sum_array.people_attended_awareness_up_to_last_month}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="modal-footer">
                        <button
                            className="btn btn-primary"
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={() => downloadExcel("parenting_outreach")}
                        >
                            {" "}
                            Download <i class="icofont icofont-arrow-down"></i>{" "}
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={createPDF}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Download as Pdf
                        </button>
                        <button
                            className="btn btn-secondary"
                            onClick={openForm1}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default AReport30;
