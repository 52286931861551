import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";
import Form2 from "./Form2";

// FORM 2  CHILD LABOUR (M)

function Report2(props) {
    const [modalIsOpen2, setmodalIsOpen2] = useState(true);
    const [{ }, { downloadExcel }] = useFormStore();

    const openForm1 = () => {
        setmodalIsOpen2(false);
        props.close();
    };
    const [isEditChildLabour, setIsEditChildLabour] = useState(false);
    const year = props.year;
    const month = props.month;
    const Month = ["Month not selected", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const DisplayMonth = Month[month];
    let newArray = props.report.filter(function (d) {
        return d.year == year && d.month == month;
    });
    function createPDF() {
        var sTable = document.getElementById('table').innerHTML;
        var style = "<style>";
        style = style + "table {width: 100%;font: 10px Calibri;}";
        style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "</style>";
        var win = window.open('', '', 'height=700,width=700');
        win.document.write('<html><head>');
        win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
        win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
        win.document.write('</body></html>');
        win.document.close(); 	// CLOSE THE CURRENT WINDOW.
        win.print();    // PRINT THE CONTENTS.
    }
    return (
        <div>
            <Modal isOpen={modalIsOpen2}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                        {" "}
                        DETAILS OF CHILD LABOUR{" "}
                    </h5>
                    <button
                        className="btn-close"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <h4>{DisplayMonth} {year}</h4>
                <div className="modal-body">
                    <h6>{props.month}/{props.year}</h6>

                    <div class="table-responsive" id="table" height="100%" width="100%" media="print">
                        <table class="table" id="child_labour">
                            <thead>
                                <tr>
                                    <th scope="col" rowSpan={2}>#</th>
                                    <th scope="col" rowSpan={2}>District</th>
                                    <th scope="col" rowSpan={2}>No. of children</th>
                                    <th scope="col" colspan="4">
                                        Age group{" "}
                                    </th>
                                    {/* <th scope="col">Age group 12-15</th>
                  <th scope="col">Age group 16-18</th> */}
                                    <th scope="col" colspan="3">
                                        Gender
                                    </th>
                                    {/* <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Transgender</th> */}
                                    <th scope="col" colspan="2">
                                        Nature of the job
                                    </th>
                                    {/* <th scope="col">Non-hazardous jobs</th> */}
                                    <th scope="col" rowSpan={2}>No. of attending school</th>
                                    <th scope="col" rowSpan={2}>No. of school dropouts</th>
                                    <th scope="col" rowSpan={2}>No. of availing child relief fund</th>
                                </tr>

                                <tr>

                                    <th scope="col">5-7</th>
                                    <th scope="col">8-11</th>
                                    <th scope="col">12-15</th>
                                    <th scope="col">16-18</th>
                                    <th scope="col">Male</th>
                                    <th scope="col">Female</th>
                                    <th scope="col">Transgender</th>
                                    <th scope="col">Hazardous</th>
                                    <th scope="col">Non-hazardous</th>

                                </tr>
                            </thead>
                            <tbody>
                                {newArray.map((r) => (
                                    <tr key={r?.id}>
                                        <th scope="row">{r?.id}</th>
                                        <td>{JSON.parse(getStorage("user")).username}</td>
                                        <td>{r?.children}</td>
                                        <td>{r?.children_5_7}</td>
                                        <td>{r?.children_8_11}</td>
                                        <td>{r?.children_12_15}</td>
                                        <td>{r?.children_16_18}</td>
                                        <td>{r?.male}</td>
                                        <td>{r?.female}</td>
                                        <td>{r?.transgender}</td>
                                        <td>{r?.hazardous_jobs}</td>
                                        <td>{r?.non_hazardous_jobs}</td>
                                        <td>{r?.children_in_school}</td>
                                        <td>{r?.school_dropouts}</td>
                                        <td>{r?.availing_child_refund}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="modal-footer">
                    <button
                        className="btn btn-primary"
                        onClick={() => setIsEditChildLabour(true)}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                        type="button"
                    >
                        Edit
                    </button>
                    <button
                        className="btn btn-primary"
                        type="button"
                        data-bs-dismiss="modal"
                        onClick={() => downloadExcel("child_labour")}
                    >
                        {" "}
                        Download <i class="icofont icofont-arrow-down"></i>{" "}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={createPDF}
                        type="button"
                        data-bs-dismiss="modal"
                    >
                        Download as Pdf
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
                </div>
            </Modal>
            {isEditChildLabour && (
                <Form2 isEdit close={() => setIsEditChildLabour(false)} report={props.report} month={month}
                    year={year} />
            )}
        </div>
    );
}

export default Report2;
