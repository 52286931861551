import React from "react";

import Sidenav from './Sidenav';

import '../assets/css/feather-icon.css';
import '../assets/css/bootstrap.css';
import '../assets/css/responsive.css';
import '../assets/css/themify.css';
import '../assets/css/style.css';
import '../assets/css/fontawesome.css';
import '../assets/css/icofont.css';
import '../assets/css/flag-icon.css';

import Header from './Header';

function Stats () {
  return (
    // <!-- page-wrapper Start-->
    <div className="page-wrapper" id="pageWrapper">
    
      {/* <!-- Page Header Start--> */}
      <Header />   
      {/* <!-- Page Header Ends --> */}
    
      {/* <!-- Page Body Start--> */}
      <div className="page-body-wrapper horizontal-menu">
        
        {/* <!-- Page Sidebar Start--> */}
        <Sidenav />
        {/* <!-- Page Sidebar Ends--> */}

        <div className="page-body">
          <div className="container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-6">
                  <h3> Statistics </h3>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Container-fluid starts--> */}
          <div className="container-fluid general-widget">
            <div className="row">
              
              <div className="col-sm-6 col-xl-3 col-lg-6">
                <div className="card o-hidden border-0">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="media-body"><span className="m-0">
                        (title)
                      </span>
                        <h4 className="mb-0 counter">6659</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3 col-lg-6">
                <div className="card o-hidden border-0">
                  <div className="bg-secondary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="media-body"><span className="m-0">
                        (title)
                      </span>
                        <h4 className="mb-0 counter">9856</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3 col-lg-6">
                <div className="card o-hidden border-0">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="media-body"><span className="m-0">
                        (title)
                      </span>
                        <h4 className="mb-0 counter">893</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3 col-lg-6">
                <div className="card o-hidden border-0">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="media-body"><span className="m-0">
                        (title)
                      </span>
                        <h4 className="mb-0 counter">4531</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3 col-lg-6">
                <div className="card o-hidden border-0">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="media-body"><span className="m-0">
                        (title)
                      </span>
                        <h4 className="mb-0 counter">893</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3 col-lg-6">
                <div className="card o-hidden border-0">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="media-body"><span className="m-0">
                        (title)
                      </span>
                        <h4 className="mb-0 counter">4531</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3 col-lg-6">
                <div className="card o-hidden border-0">
                  <div className="bg-secondary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="media-body"><span className="m-0">
                        (title)
                      </span>
                        <h4 className="mb-0 counter">9856</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3 col-lg-6">
                <div className="card o-hidden border-0">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="media-body"><span className="m-0">
                        (title)
                      </span>
                        <h4 className="mb-0 counter">4531</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3 col-lg-6">
                <div className="card o-hidden border-0">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="media-body"><span className="m-0">
                        (title)
                      </span>
                        <h4 className="mb-0 counter">4531</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3 col-lg-6">
                <div className="card o-hidden border-0">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="media-body"><span className="m-0">
                        (title)
                      </span>
                        <h4 className="mb-0 counter">893</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3 col-lg-6">
                <div className="card o-hidden border-0">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="media-body"><span className="m-0">
                        (title)
                      </span>
                        <h4 className="mb-0 counter">4531</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-xl-3 col-lg-6">
                <div className="card o-hidden border-0">
                  <div className="bg-secondary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="media-body"><span className="m-0">
                        (title)
                      </span>
                        <h4 className="mb-0 counter">9856</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          {/* <!-- Container-fluid Ends--> */}
        </div>
      </div>
    </div>
  )
}

export default Stats;