import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";

// FORM cwc (Q)

function AReport12(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { downloadExcel }] = useFormStore();
  const Qyear = props.Qyear
  const Qmonth = props.Qmonth
  const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
  const DisplayQuarter = Quarter[Qmonth]


  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };

  // object assigned to a variable to obtain total

  let newArray = props.report.filter(function (d) {
    return d.year == Qyear && d.month == Qmonth;
  })

  // sum_array = total sum data of district
  const sum_array = newArray.reduce((sum_array, value) => {
    for (const [key, count] of Object.entries(value)) {
      if (!sum_array[key]) {
        sum_array[key] = 0;
      }
      sum_array[key] += count;
    }
    return sum_array;
  }, {});

  let newArrayCumulative = props.report.filter(function (d) {
    return d.year == Qyear && d.month <= Qmonth;
  })

  let result = [];

  newArrayCumulative.forEach(function (a) {
    if (!this[a.username]) {
      this[a.username] = {
        username: a.username, jja_total_cases_current_quarter: 0,
        jja_fir_registered_current_quarter: 0,
        jja_produced_before_cwc_current_quarter: 0,
        jja_who_avail_service_support_person_current_quarter: 0,
        jja_expert_service_current_quarter: 0,
        jja_counselling_current_quarter: 0,
        jja_free_legal_aid_current_quarter: 0,
        jja_victim_compensation_current_quarter: 0,
        jja_institutional_support_current_quarter: 0,
        pocso_total_cases_current_quarter: 0,
        pocso_fir_registered_current_quarter: 0,
        pocso_produced_before_cwc_current_quarter: 0,
        pocso_who_avail_service_support_person_current_quarter: 0,
        pocso_expert_service_current_quarter: 0,
        pocso_counselling_current_quarter: 0,
        pocso_free_legal_aid_current_quarter: 0,
        pocso_victim_compensation_current_quarter: 0,
        pocso_institutional_support_current_quarter: 0,

      };


      result.push(this[a.username]);
    }
    this[a.username].jja_total_cases_current_quarter += a.jja_total_cases_current_quarter;
    this[a.username].jja_fir_registered_current_quarter += a.jja_fir_registered_current_quarter;
    this[a.username].jja_produced_before_cwc_current_quarter += a.jja_produced_before_cwc_current_quarter;
    this[a.username].jja_who_avail_service_support_person_current_quarter += a.jja_who_avail_service_support_person_current_quarter;
    this[a.username].jja_expert_service_current_quarter += a.jja_expert_service_current_quarter;
    this[a.username].jja_counselling_current_quarter += a.jja_counselling_current_quarter;
    this[a.username].jja_free_legal_aid_current_quarter += a.jja_free_legal_aid_current_quarter;
    this[a.username].jja_victim_compensation_current_quarter += a.jja_victim_compensation_current_quarter;
    this[a.username].jja_institutional_support_current_quarter += a.jja_institutional_support_current_quarter;
    this[a.username].pocso_total_cases_current_quarter += a.pocso_total_cases_current_quarter;
    this[a.username].pocso_fir_registered_current_quarter += a.pocso_fir_registered_current_quarter;
    this[a.username].pocso_produced_before_cwc_current_quarter += a.pocso_produced_before_cwc_current_quarter;
    this[a.username].pocso_who_avail_service_support_person_current_quarter += a.pocso_who_avail_service_support_person_current_quarter;
    this[a.username].pocso_expert_service_current_quarter += a.pocso_expert_service_current_quarter;
    this[a.username].pocso_counselling_current_quarter += a.pocso_counselling_current_quarter;
    this[a.username].pocso_free_legal_aid_current_quarter += a.pocso_free_legal_aid_current_quarter;
    this[a.username].pocso_victim_compensation_current_quarter += a.pocso_victim_compensation_current_quarter;
    this[a.username].pocso_institutional_support_current_quarter += a.pocso_institutional_support_current_quarter;
  }, Object.create(null));

  // console.log(result);
  for (let object of newArray) {
    for (let i of result) {
      if (object.username === i.username) {
        object.jja_total_cases_cumulative = i.jja_total_cases_current_quarter
        object.jja_fir_registered_cumulative = i.jja_fir_registered_current_quarter
        object.jja_produced_before_cwc_cumulative = i.jja_produced_before_cwc_current_quarter
        object.jja_who_avail_service_support_person_cumulative = i.jja_who_avail_service_support_person_current_quarter
        object.jja_expert_service_cumulative = i.jja_expert_service_current_quarter
        object.jja_counselling_cumulative = i.jja_counselling_current_quarter
        object.jja_free_legal_aid_cumulative = i.jja_free_legal_aid_current_quarter
        object.jja_victim_compensation_cumulative = i.jja_victim_compensation_current_quarter
        object.jja_institutional_support_cumulative = i.jja_institutional_support_current_quarter
        object.pocso_total_cases_cumulative = i.pocso_total_cases_current_quarter
        object.pocso_fir_registered_cumulative = i.pocso_fir_registered_current_quarter
        object.pocso_produced_before_cwc_cumulative = i.pocso_produced_before_cwc_current_quarter
        object.pocso_who_avail_service_support_person_cumulative = i.pocso_who_avail_service_support_person_current_quarter
        object.pocso_expert_service_cumulative = i.pocso_expert_service_current_quarter
        object.pocso_counselling_cumulative = i.pocso_counselling_current_quarter
        object.pocso_free_legal_aid_cumulative = i.pocso_free_legal_aid_current_quarter
        object.pocso_victim_compensation_cumulative = i.pocso_victim_compensation_current_quarter
        object.pocso_institutional_support_cumulative = i.pocso_institutional_support_current_quarter
      }
    }
  }
  function createPDF() {
    var sTable = document.getElementById('table').innerHTML;
    var style = "<style>";
    style = style + "table {width: 100%;font: 10px Calibri;}";
    style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "</style>";
    var win = window.open('', '', 'height=700,width=700');
    win.document.write('<html><head>');
    win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
    win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
    win.document.write('</head>');
    win.document.write('<body>');
    win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
    win.document.write('</body></html>');
    win.document.close(); 	// CLOSE THE CURRENT WINDOW.
    win.print();    // PRINT THE CONTENTS.
  }

  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            Details of cases produced before CWC
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <h4>{DisplayQuarter} {Qyear}</h4>
        <div className="modal-body">
          <div class="table-responsive" id="table" height="100%" width="100%" media="print">
            <table class="table" id="cwc">
              <thead>
                <tr>
                  <th scope="col" rowspan="3">
                    #
                  </th>
                  <th scope="col" rowspan="3">
                    JJA
                  </th>
                  <th scope="col" rowspan="2" colspan="2">
                    Total no:of cases{" "}
                  </th>
                  <th scope="col" rowspan="2" colspan="2">
                    FIR Registered
                  </th>
                  <th scope="col" rowspan="2" colspan="2">
                    Produced before CWC
                  </th>
                  <th scope="col" colspan="12">
                    Number of children who availed the services
                  </th>
                </tr>

                <tr>
                  <th scope="col" colspan="2">
                    Support person{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Expert service{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Counselling{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Free legal aid{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Victim compensation
                  </th>
                  <th scope="col" colspan="2">
                    Institutional support le
                  </th>
                </tr>

                <tr>

                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                </tr>
              </thead>

              <tbody>
                {newArray.map((r) => (
                  <tr key={r?.id}>
                    <th scope="row"></th>
                    <td>{r?.username}</td>
                    <td>{r?.jja_total_cases_current_quarter}</td>
                    <td>{r?.jja_total_cases_cumulative}</td>

                    <td>{r?.jja_fir_registered_current_quarter}</td>
                    <td>{r?.jja_fir_registered_cumulative}</td>

                    <td>{r?.jja_produced_before_cwc_current_quarter}</td>
                    <td>{r?.jja_produced_before_cwc_cumulative}</td>

                    <td>
                      {r?.jja_who_avail_service_support_person_current_quarter}
                    </td>
                    <td>{r?.jja_who_avail_service_support_person_cumulative}</td>

                    <td>{r?.jja_expert_service_current_quarter}</td>
                    <td>{r?.jja_expert_service_cumulative}</td>

                    <td>{r?.jja_counselling_current_quarter}</td>
                    <td>{r?.jja_counselling_cumulative}</td>

                    <td>{r?.jja_free_legal_aid_current_quarter}</td>
                    <td>{r?.jja_free_legal_aid_cumulative}</td>

                    <td>{r?.jja_victim_compensation_current_quarter}</td>
                    <td>{r?.jja_victim_compensation_cumulative}</td>

                    <td>{r?.jja_institutional_support_current_quarter}</td>
                    <td>{r?.jja_institutional_support_cumulative}</td>

                  </tr>
                ))}
                <tr >
                  <th scope="row"></th>
                  <td>Total</td>
                  <td>{sum_array.jja_total_cases_current_quarter}</td>
                  <td>{sum_array.jja_total_cases_cumulative}</td>

                  <td>{sum_array.jja_fir_registered_current_quarter}</td>
                  <td>{sum_array.jja_fir_registered_cumulative}</td>

                  <td>{sum_array.jja_produced_before_cwc_current_quarter}</td>
                  <td>{sum_array.jja_produced_before_cwc_cumulative}</td>

                  <td>
                    {sum_array.jja_who_avail_service_support_person_current_quarter}
                  </td>
                  <td>{sum_array.jja_who_avail_service_support_person_cumulative}</td>

                  <td>{sum_array.jja_expert_service_current_quarter}</td>
                  <td>{sum_array.jja_expert_service_cumulative}</td>

                  <td>{sum_array.jja_counselling_current_quarter}</td>
                  <td>{sum_array.jja_counselling_cumulative}</td>

                  <td>{sum_array.jja_free_legal_aid_current_quarter}</td>
                  <td>{sum_array.jja_free_legal_aid_cumulative}</td>

                  <td>{sum_array.jja_victim_compensation_current_quarter}</td>
                  <td>{sum_array.jja_victim_compensation_cumulative}</td>

                  <td>{sum_array.jja_institutional_support_current_quarter}</td>
                  <td>{sum_array.jja_institutional_support_cumulative}</td>

                </tr>
              </tbody>
              {/* </table> */}
              {/* <table class="table" id="pocso"> */}
              <br></br>
              <thead>
                <tr>
                  <th scope="col" rowspan="3">
                    #
                  </th>
                  <th scope="col" rowspan="3">
                    POCSO
                  </th>
                  <th scope="col" rowspan="2" colspan="2">
                    Total no:of cases{" "}
                  </th>
                  <th scope="col" rowspan="2" colspan="2">
                    FIR Registered
                  </th>
                  <th scope="col" rowspan="2" colspan="2">
                    Produced before CWC
                  </th>
                  <th scope="col" colspan="12">
                    Number of children who availed the services
                  </th>
                </tr>

                <tr>
                  <th scope="col" colspan="2">
                    Support person{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Expert service{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Counselling{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Free legal aid{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Victim compensation
                  </th>
                  <th scope="col" colspan="2">
                    Institutional support le
                  </th>
                </tr>

                <tr>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                </tr>
              </thead>

              <tbody>
                {newArray.map((r) => (
                  <tr key={r?.id}>
                    <th scope="row"></th>
                    <td>{r?.username}</td>
                    <td>{r?.pocso_total_cases_current_quarter}</td>
                    <td>{r?.pocso_total_cases_cumulative}</td>

                    <td>{r?.pocso_fir_registered_current_quarter}</td>
                    <td>{r?.pocso_fir_registered_cumulative}</td>

                    <td>{r?.pocso_produced_before_cwc_current_quarter}</td>
                    <td>{r?.pocso_produced_before_cwc_cumulative}</td>

                    <td>
                      {r?.pocso_who_avail_service_support_person_current_quarter}
                    </td>
                    <td>{r?.pocso_who_avail_service_support_person_cumulative}</td>

                    <td>{r?.pocso_expert_service_current_quarter}</td>
                    <td>{r?.pocso_expert_service_cumulative}</td>

                    <td>{r?.pocso_counselling_current_quarter}</td>
                    <td>{r?.pocso_counselling_cumulative}</td>

                    <td>{r?.pocso_free_legal_aid_current_quarter}</td>
                    <td>{r?.pocso_free_legal_aid_cumulative}</td>

                    <td>{r?.pocso_victim_compensation_current_quarter}</td>
                    <td>{r?.pocso_victim_compensation_cumulative}</td>

                    <td>{r?.pocso_institutional_support_current_quarter}</td>
                    <td>{r?.pocso_institutional_support_cumulative}</td>

                  </tr>
                ))}
                <tr>
                  <th scope="row"></th>
                  <td>Total</td>
                  <td>{sum_array.pocso_total_cases_current_quarter}</td>
                  <td>{sum_array.pocso_total_cases_cumulative}</td>

                  <td>{sum_array.pocso_fir_registered_current_quarter}</td>
                  <td>{sum_array.pocso_fir_registered_cumulative}</td>

                  <td>{sum_array.pocso_produced_before_cwc_current_quarter}</td>
                  <td>{sum_array.pocso_produced_before_cwc_cumulative}</td>

                  <td>
                    {sum_array.pocso_who_avail_service_support_person_current_quarter}
                  </td>
                  <td>{sum_array.pocso_who_avail_service_support_person_cumulative}</td>

                  <td>{sum_array.pocso_expert_service_current_quarter}</td>
                  <td>{sum_array.pocso_expert_service_cumulative}</td>
                  <td>{sum_array.pocso_counselling_current_quarter}</td>
                  <td>{sum_array.pocso_counselling_cumulative}</td>
                  <td>{sum_array.pocso_free_legal_aid_current_quarter}</td>
                  <td>{sum_array.pocso_free_legal_aid_cumulative}</td>
                  <td>{sum_array.pocso_victim_compensation_current_quarter}</td>
                  <td>{sum_array.pocso_victim_compensation_cumulative}</td>
                  <td>{sum_array.pocso_institutional_support_current_quarter}</td>
                  <td>{sum_array.pocso_institutional_support_cumulative}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("cwc")}
          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-primary"
            onClick={createPDF}
            type="button"
            data-bs-dismiss="modal"
          >
            Download as Pdf
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}
export default AReport12;
