import React from 'react';

import '../assets/css/feather-icon.css';
import '../assets/css/bootstrap.css';
import '../assets/css/responsive.css';
import '../assets/css/themify.css';
import '../assets/css/style.css';
import '../assets/css/fontawesome.css';
import '../assets/css/icofont.css';
import '../assets/css/flag-icon.css';

import { Link } from "react-router-dom";

import './Sidenav.css';



function Sidenav() {
    return(
        <div>
          <header className="main-nav">
            <nav>
              <div className="main-navbar">
                <div className="left-arrow" id="left-arrow"><i data-feather="arrow-left"></i></div>
                <div id="mainnav">           
                  <ul className="nav-menu custom-scrollbar">
                    
                    <li><Link className="btn btn-primary" to="/home"><i data-feather="home"></i><span> HOME </span></Link> </li>
                    {/* <li><Link className="btn btn-primary" to="/stats"><i data-feather="airplay"></i><span> STATISTICS </span></Link> </li> */}
                    <li><Link className="btn btn-primary" to="/reports"><i data-feather="airplay"></i><span> REPORTS </span></Link> </li>
                  </ul>
                </div>
                <div className="right-arrow" id="right-arrow"><i data-feather="arrow-right"></i></div>
              </div>
            </nav>
          </header>
        </div>
    )
}

export default Sidenav;