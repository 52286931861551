import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";
import { UPDATE_REPATRIATION } from "../utils/constant";
import { Toaster, toast } from "react-hot-toast";
import { useEffect } from "react";

// FORM 22  Repatriation (Q)

function Form21(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { onSubmit }] = useFormStore();
    const [{ }, { updateReport }] = useFormStore();
    const [{ }, { formUpdated }] = useFormStore();
    const Qyear = props.Qyear;
    const Qmonth = props.Qmonth;
    const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
    const DisplayQuarter = Quarter[Qmonth];

    const [data, setdata] = useState({
        with_in_district: 0,
        to_other_state: 0,
        month: Qmonth,
        year: Qyear,
    });

    useEffect(() => {
        if ((props || {}).isEdit) {
            const { id, created_at, updated_at, username, user, report_type, ...reportSorted } = props.report.find((res) => { return res.month === Qmonth && res.year === Qyear; }) || {};
            setdata({
                ...data,
                ...reportSorted
            });
        }
    }, []);

    const openForm1 = () => {
        if (props.isEdit) {
            return toast.promise(
                updateReport(data, UPDATE_REPATRIATION),
                {
                    loading: 'Updating Record',
                    success: 'Record Updated',
                    error: 'Error while saving'
                }
            ).then(() => formUpdated(true));
        } else {
            props.close();
            onSubmit(data, "repatriation");
            setmodalIsOpen(false);
        }
    };
    const closeButton = () => {
        props.close();
        setmodalIsOpen(false);
    };
    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <Toaster position="bottom-right" />
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {" "}
                            Repatriation{" "}
                        </h5>
                        <button
                            className="btn-close"
                            onClick={closeButton}
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <h4>{DisplayQuarter} {Qyear}</h4>
                    <div className="modal-body">
                        <form>
                            <div className="new-expense__control">
                                <label> Within District </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.with_in_district}
                                    onChange={(e) =>
                                        setdata({ ...data, with_in_district: e.target.value })
                                    }
                                />{" "}
                                <br />
                                <label> To other state </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.to_other_state}
                                    onChange={(e) =>
                                        setdata({ ...data, to_other_state: e.target.value })
                                    }
                                />{" "}
                                <br />
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={closeButton}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={openForm1}
                            type="button"
                        >
                            Save & Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Form21;
