import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";

// FORM 19 3. Adoption under section 56 of the JJ Act, 2015	(Q)

function Form20(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { onSubmit }] = useFormStore();

  const [data, setdata] = useState({
    full_time_sanctioned_strength_dcpu: 0,
    full_time_working_strength_dcpu: 0,
    full_time_vacancies_dcpu: 0,
    part_time_sanctioned_strength_dcpu: 0,
    part_time_working_strength_dcpu: 0,
    part_time_vacancies_dcpu: 0,
    additional_charge_of_child_home: 0,
    sjpu_in_state: 0,
    police_in_state_designated_sjpu: 0,
    member_of_sjpu_provided_induction_trainng: 0,
  });
  const openForm1 = () => {
    props.close();
    onSubmit(data, "child_protection");
    setmodalIsOpen(false);
  };
  const closeButton = () => {
    props.close();
    setmodalIsOpen(false);
  };
  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {" "}
              Institutional Mechanisms for protection of children{" "}
            </h5>
            <button
              className="btn-close"
              onClick={closeButton}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <h5>
                {" "}
                a) Has a Child Protection Society been constituted for the
                district as directed by section 106 of the JJ Act, 2015?{" "}
              </h5>
              <div className="new-expense__control">
                <label> </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>
              <br /> <br /> <br />
              <h5> b) Details of officers/staffs of the DCPU's </h5>
              <div className="new-expense__control">
                <label> Full-time staff in the DCPU </label>
                <label> Sanctioned strength </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.full_time_sanctioned_strength_dcpu}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      full_time_sanctioned_strength_dcpu: e.target.value,
                    })
                  }
                />
                <label> Working strength </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.full_time_working_strength_dcpu}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      full_time_working_strength_dcpu: e.target.value,
                    })
                  }
                />
                <label> Vacancies </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.full_time_vacancies_dcpu}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      full_time_vacancies_dcpu: e.target.value,
                    })
                  }
                />
                <label> Part- time staff in the DCPU</label>
                <label> Sanctioned strength </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.part_time_sanctioned_strength_dcpu}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      part_time_sanctioned_strength_dcpu: e.target.value,
                    })
                  }
                />
                <label> Working strength </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.part_time_working_strength_dcpu}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      part_time_working_strength_dcpu: e.target.value,
                    })
                  }
                />
                <label> Vacancies </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.part_time_vacancies_dcpu}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      part_time_vacancies_dcpu: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of staff given Additional Charge of Children's Homes{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.additional_charge_of_child_home}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      additional_charge_of_child_home: e.target.value,
                    })
                  }
                />
              </div>
              <br /> <br /> <br />
              <h5>
                {" "}
                c) Number of Child Welfare Police Officers in the districts
                (designated under section 107(1) of the JJ Act, 2015){" "}
              </h5>
              <div className="new-expense__control">
                <label> </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>
              <br /> <br /> <br />
              <h5>
                {" "}
                e) Special Juvenile Police Units (Constituted under section 107
                (2) of the JJ Act, 2015){" "}
              </h5>
              <div className="new-expense__control">
                <label> No. of SJPUs in the State </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.sjpu_in_state}
                  onChange={(e) =>
                    setdata({ ...data, sjpu_in_state: e.target.value })
                  }
                />
                <label>
                  {" "}
                  No. of Police Officers in the state designated as members of
                  SJPUs{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.police_in_state_designated_sjpu}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      police_in_state_designated_sjpu: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of members of the SJPUs who have been provided induction
                  training{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.member_of_sjpu_provided_induction_trainng}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      member_of_sjpu_provided_induction_trainng: e.target.value,
                    })
                  }
                />
              </div>
              <br /> <br /> <br />
            </form>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              onClick={closeButton}
              type="button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              onClick={openForm1}
              type="button"
            >
              Save & Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Form20;
