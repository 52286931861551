import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";
import Modal from "react-modal";

import { useFormStore } from "../Store/Forms";
import "./dashboard.css";
import PasswordReset from "./PasswordReset";


function Users(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [PasswordResetIsOpen, setPasswordResetIsOpen] = React.useState(false);
    const [name, setName] = React.useState("");
    const openForm1 = () => {
        setmodalIsOpen(false);
        props.close();
    };
    const handleChange = (e) => {
        setPasswordResetIsOpen(true);
        setName(e.target.value);

    };
    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                        {" "}
                        Users{" "}
                    </h5>
                    <button
                        className="btn-close"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <h4></h4>
                <div className="modal-body">
                    <div class="password_reset-modal">
                        <div>
                            {props.userList.map(item => {
                                return <div className="username-reset">
                                    <p >{item}</p>
                                    <button className="btn btn-danger" value={item} onClick={handleChange}>Change Password</button>
                                </div>;

                            })}
                        </div>
                    </div>
                </div>

                {PasswordResetIsOpen && (
                    <PasswordReset close={() => setPasswordResetIsOpen(false)} name={name} />
                )}
            </Modal>
        </div>

    )
}

export default Users;
