import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";

// FORM 21  Foster Care	(Q)

function Report21(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { downloadExcel }] = useFormStore();
  const Qyear = props.Qyear
  const Qmonth = props.Qmonth
  const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
  const DisplayQuarter = Quarter[Qmonth]


  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };
  // object assigned to a variable to obtain total


  let newArray = props.report.filter(function (d) {
    return d.year == Qyear && d.month == Qmonth;
  })

  // sum_array = total sum data of district
  const sum_array = newArray.reduce((sum_array, value) => {
    for (const [key, count] of Object.entries(value)) {
      if (!sum_array[key]) {
        sum_array[key] = 0;
      }
      sum_array[key] += count;
    }
    return sum_array;
  }, {});
  // ----------------------------------------------
  let newArrayCumulative = props.report.filter(function (d) {
    return d.year == Qyear && d.month <= Qmonth;
  })

  let result = [];

  newArrayCumulative.forEach(function (a) {
    if (!this[a.username]) {
      this[a.username] = { username: a.username, lt_st_current_quarter: 0, vacation_current_quarter: 0, group_current_quarter: 0, kinship_current_quarter: 0 };
      result.push(this[a.username]);
    }
    this[a.username].lt_st_current_quarter += a.lt_st_current_quarter;
    this[a.username].vacation_current_quarter += a.vacation_current_quarter;
    this[a.username].group_current_quarter += a.group_current_quarter;
    this[a.username].kinship_current_quarter += a.kinship_current_quarter;
  }, Object.create(null));


  for (let object of newArray) {
    for (let i of result) {
      if (object.username === i.username) {
        console.log(i.username, object.username,)
        console.log(i.vacation_current_quarter, i.group_current_quarter, i.kinship_current_quarter)
        object.lt_st_cumulative = i.lt_st_current_quarter
        object.vacation_cumulative = i.vacation_current_quarter
        object.group_cumulative = i.group_current_quarter
        object.kinship_cumulative = i.kinship_current_quarter
      }
    }

  }


  function createPDF() {
    var sTable = document.getElementById('table').innerHTML;
    var style = "<style>";
    style = style + "table {width: 100%;font: 10px Calibri;}";
    style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "</style>";
    var win = window.open('', '', 'height=700,width=700');
    win.document.write('<html><head>');
    win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
    win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
    win.document.write('</head>');
    win.document.write('<body>');
    win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
    win.document.write('</body></html>');
    win.document.close(); 	// CLOSE THE CURRENT WINDOW.
    win.print();    // PRINT THE CONTENTS.
  }

  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {" "}
            Foster care{" "}
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <h4>{DisplayQuarter} {Qyear}</h4>
        <div className="modal-body">
          <div class="table-responsive" id="table" height="100%" width="100%" media="print">
            <table class="table" id="foster_care">
              <thead>
                <tr>
                  <th scope="col" rowspan="2">
                    #
                  </th>
                  <th scope="col" rowspan="2">
                    District
                  </th>
                  <th scope="col" colspan="2">
                    Fostercare (Long-term/short-term){" "}
                  </th>
                  <th scope="col" colspan="2">
                    Vacation foster care{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Group foster care{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Kinship foster care{" "}
                  </th>
                </tr>

                <tr>

                  <th scope="col">Current Quarter </th>
                  <th scope="col">Cumulative </th>
                  <th scope="col">Current Quarter </th>
                  <th scope="col">Cumulative </th>
                  <th scope="col">Current Quarter </th>
                  <th scope="col">Cumulative </th>
                  <th scope="col">Current Quarter </th>
                  <th scope="col">Cumulative </th>
                </tr>
              </thead>

              <tbody>
                {newArray.map((r) => (
                  <tr key={r?.id}>
                    <th scope="row"></th>
                    <td>{r?.username}</td>
                    <td>{r?.lt_st_current_quarter}</td>
                    <td>{r?.lt_st_cumulative}</td>
                    <td>{r?.vacation_current_quarter}</td>
                    <td>{r?.vacation_cumulative}</td>
                    <td>{r?.group_current_quarter}</td>
                    <td>{r?.group_cumulative}</td>
                    <td>{r?.kinship_current_quarter}</td>
                    <td>{r?.group_cumulative}</td>
                  </tr>
                ))}
                <tr>
                  <th scope="row"></th>
                  <td>Total</td>
                  <td>{sum_array.lt_st_current_quarter}</td>
                  <td>{sum_array.lt_st_cumulative}</td>
                  <td>{sum_array.vacation_current_quarter}</td>
                  <td>{sum_array.vacation_cumulative}</td>
                  <td>{sum_array.group_current_quarter}</td>
                  <td>{sum_array.group_cumulative}</td>
                  <td>{sum_array.kinship_current_quarter}</td>
                  <td>{sum_array.group_cumulative}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("foster_care")}
          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-primary"
            onClick={createPDF}
            type="button"
            data-bs-dismiss="modal"
          >
            Download as Pdf
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default Report21;
