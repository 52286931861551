import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";
import { useCallback } from "react";

// FORM 25 PARENTING CLINIC REPORTING FORMAT

function AReport27(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { downloadExcel }] = useFormStore();
    const Qyear = props.Qyear;
    const Qmonth = props.Qmonth;
    const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
    const DisplayQuarter = Quarter[Qmonth];
    const convertArrayToObject = useCallback((array, key) => {
        const initialValue = {};
        if (Array.isArray(array)) {
            return array.reduce((obj, item) => {
                return {
                    ...item,
                };
            }, initialValue);
        }
        else return {};
    }, [props.report]);

    const convertedReport = convertArrayToObject(props.report || []);


    const openForm1 = () => {
        setmodalIsOpen(false);
        props.close();
    };
    function createPDF() {
        var sTable = document.getElementById('table').innerHTML;
        var style = "<style>";
        style = style + "table {width: 100%;font: 10px Calibri;}";
        style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "</style>";
        var win = window.open('', '', 'height=700,width=700');
        win.document.write('<html><head>');
        win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
        win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
        win.document.write('</body></html>');
        win.document.close(); 	// CLOSE THE CURRENT WINDOW.
        win.print();    // PRINT THE CONTENTS.
    }
    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                        {" "}
                        QPR - Supreme Court{" "}
                    </h5>
                    <button
                        className="btn-close"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <h4>{DisplayQuarter} {Qyear}</h4>
                <div className="modal-body">
                    <div class="table-responsive" id="table" height="100%" width="100%" media="print" style={{ position: 'absolute' }}>
                        <table class="table" id="qprsc">
                            <tr>
                                <th>Sl. No.</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>No. of JJBs in the state</td>


                                <td>{convertedReport.sum_jjbs_in_the_state}</td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>No. of Districts in the state where no JJB is operating</td>

                                <td>{convertedReport.sum_districts_in_state_where_no_jjb_is_operating}</td>

                            </tr>
                            <tr>
                                <td>3</td>
                                <td>
                                    No. of Districts in the state where more than one JJB is
                                    operating
                                </td>

                                <td>
                                    {convertedReport.sum_districts_in_state_where_more_than_one_jjb_is_operating}
                                </td>

                            </tr>

                            {/* Pendency of cases before the JJBs*/}
                            <tr><th colspan={3}><h6> b) Pendency of cases before the JJBs:- </h6></th></tr>


                            <tr>
                                <th>Sl. No.</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Opening Balance at the start of the quarter</td>

                                <td>{convertedReport.sum_jjbs_opening_balance_at_the_start_of_the_quarter}</td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>No of cases instituted during the quarter</td>

                                <td>{convertedReport.sum_cases_instituted_during_the_quarter}</td>

                            </tr>
                            <tr>
                                <td>3</td>
                                <td>No of cases disposed of during the quarter</td>

                                <td>
                                    {
                                        convertedReport.sum_cases_before_the_jjbs_cases_disposed_of_during_the_quarter
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>4</td>
                                <td>No. of cases pending at the end of the quarter</td>

                                <td>
                                    {
                                        convertedReport.sum_cases_before_the_jjbs_cases_pending_at_the_end_of_the_quarter
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>5</td>
                                <td>
                                    No of cases pending for a period of less than 4 months at the
                                    end of the quarter
                                </td>

                                <td>{convertedReport.sum_cases_pending_for_a_period_of_less_than_4_months}</td>

                            </tr>
                            <tr>
                                <td>6</td>
                                <td>
                                    No of cases pending for a period of more than 4 months but less
                                    than 6 months at the end of the quarter
                                </td>

                                <td>{convertedReport.sum_cases_pending_for_a_period_of_more_than_4_months}</td>

                            </tr>
                            <tr>
                                <td>7</td>
                                <td>
                                    No of cases pending for a period of more than 6 months but less
                                    than 1 year at the end of the quarter
                                </td>


                                <td>{convertedReport.sum_cases_pending_for_a_period_of_more_than_6_months}</td>

                            </tr>
                            <tr>
                                <td>8</td>
                                <td>
                                    No of cases pending for a period of more than 1 year at the end
                                    of the quarter
                                </td>

                                <td>{convertedReport.sum_cases_pending_for_a_period_of_more_than_1_year}</td>

                            </tr>
                            {/* </table> */}
                            {/* Details with regards to staff in JJBs */}
                            <tr><th colspan={3}><h6> c) Details with regards to staff in JJBs:- </h6></th></tr>
                            {/* <table> */}
                            <tr>
                                <th>Sl. No.</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Total Sanctioned strength of JJB staff in the State</td>

                                <td>{convertedReport.sum_sanctioned_strength_of_jjb_staff_in_the_state}</td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Total Working strength of JJB staff in the State</td>

                                <td >{convertedReport.sum_working_strength_of_jjb_staff_in_the_state}</td>

                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Total Vacancies in JJB staff in the State</td>

                                <td>{convertedReport.sum_vacancies_in_jjb_staff_in_the_state}</td>

                            </tr>
                            {/* </table> */}
                            {/*d) 1. Have any effort been made to fill the existing vacancies in the cadre of staff of the JJB’s?  */}
                            <tr><th colspan={3}> <h6>
                                {" "}
                                d) 1. Have any effort been made to fill the existing vacancies in
                                the cadre of staff of the JJB’s?{" "}
                            </h6></th></tr>
                            <tr>
                                <td colspan={3}>--</td>
                            </tr>
                            <tr><th colspan={3}>
                                <h6>
                                    {" "}
                                    2. If Yes, please provide details (Kindly answer in 150 words){" "}
                                </h6></th></tr>
                            <tr>
                                <td colspan={3}>
                                    --
                                </td>
                            </tr>

                            {/* 2. Institutional Care of Children in Conflict with Law */}
                            <tr><th colspan={3}><h5> 2. Institutional Care of Children in Conflict with Law </h5></th></tr>
                            <tr><th colspan={3}><h6>
                                {" "}
                                a) Observation Homes (established under section 42(1) of the JJ
                                (Care and Protection of Children) Act, 2015){" "}
                            </h6></th></tr>

                            {/* <table> */}
                            <tr>
                                <th>Sl.No</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>
                                    No. of Observation Homes in the state maintained by the state
                                    govt itself
                                </td>

                                <td>
                                    {
                                        convertedReport.sum_observation_homes_in_state_maintained_by_state_govt_itself
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>
                                    No. of Observation Homes in the state maintained by the state
                                    govt through voluntary or non – governmental organization
                                </td>

                                <td>
                                    {convertedReport.sum_observation_homes_in_state_maintained_by_state_govt}
                                </td>

                            </tr>
                            <tr>
                                <td>3</td>
                                <td>No. of children placed in Observation Home in the state</td>

                                <td>{convertedReport.sum_children_placed_in_observation_home_in_the_state}</td>

                            </tr>
                            <tr>
                                <td>4</td>
                                <td>
                                    No. of districts in the state without any Observation Homes
                                </td>

                                <td>
                                    {convertedReport.sum_districts_in_the_state_without_any_observation_homes}
                                </td>

                            </tr>
                            {/* </table> */}

                            <tr><th colspan={3}><h6>
                                {" "}
                                b) Special Homes (established under section 48(1) of the JJ Act,
                                2015){" "}
                            </h6></th></tr>

                            {/* <table> */}
                            <tr>
                                <th>Sl.No</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>
                                    No. of Special Homes in the state maintained by the state govt
                                    itself
                                </td>

                                <td>
                                    {convertedReport.sum_special_homes_in_state_maintained_by_state_govt_itself}
                                </td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>
                                    No. of Special Homes in the state maintained by the state govt
                                    through voluntary or non – governmental organization
                                </td>

                                <td>
                                    {convertedReport.sum_special_homes_in_the_state_maintained_by_the_state}
                                </td>

                            </tr>
                            <tr>
                                <td>3</td>
                                <td>No. of children placed in Special Homes in the state</td>

                                <td>{convertedReport.sum_children_placed_in_special_home_in_the_state}</td>

                            </tr>
                            <tr>
                                <td>4</td>
                                <td>No. of districts in the state without any Special Homes</td>

                                <td>{convertedReport.sum_districts_in_the_state_without_any_special_homes}</td>

                            </tr>
                            {/* </table> */}

                            <tr><th colspan={3}><h6>
                                {" "}
                                c) Place of Safety (established under section 49 of the JJ Act,
                                2015){" "}
                            </h6></th></tr>

                            {/* <table> */}
                            <tr>
                                <th>Sl.No</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>No. of Place of Safety in the state</td>

                                <td>{convertedReport.sum_place_of_safety_in_the_state}</td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>No. of children placed in Place of Safety</td>

                                <td>{convertedReport.sum_children_placed_in_place_of_safety}</td>

                            </tr>
                            {/* </table> */}

                            <tr><th colspan={3}><h4> B. Children in Need of Care and Protection </h4></th></tr>
                            <tr><th colspan={3}><h6>
                                {" "}
                                1. Child Welfare Committees (Constituted under section 27(1) of the
                                JJ Act, 2015):-{" "}
                            </h6> </th></tr>

                            {/* <table> */}
                            <tr>
                                <th>Sl.No</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>No. of CWCs in the state</td>

                                <td>{convertedReport.sum_cwcs_in_the_state}</td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>No. of Districts in the state where no CWC is operating</td>

                                <td>{convertedReport.sum_districts_in_state_where_no_cwc_is_operating}</td>

                            </tr>
                            <tr>
                                <td>3</td>
                                <td>
                                    No. of Districts in the state where more than one CWC are
                                    operating
                                </td>

                                <td>
                                    {convertedReport.sum_districts_state_where_more_than_one_cwc_is_operating}
                                </td>

                            </tr>
                            {/* </table> */}

                            <tr><th colspan={3}><h5>
                                {" "}
                                2. Institutional Care of Children in Need of Care and Protection{" "}
                            </h5></th></tr>
                            <tr><th colspan={3}><h6>
                                {" "}
                                a) Children’s Homes (established under section 50 of the JJ Act,
                                2015):-{" "}
                            </h6></th></tr>

                            {/* <table> */}
                            <tr>
                                <th>Sl.No</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>
                                    No. of Children’s Homes in the state maintained by the state
                                    govt itself
                                </td>

                                <td>
                                    {convertedReport.sum_children_homes_in_state_maintained_by_state_govt_itself}
                                </td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>
                                    No. of Children’s Homes in the state maintained by the state
                                    govt through voluntary or non – governmental organization
                                </td>

                                <td>
                                    {
                                        convertedReport.sum_children_homes_in_the_state_maintained_by_the_state_govt
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>3</td>
                                <td>No. of children placed in Children’s Homes in the state</td>

                                <td>{convertedReport.sum_children_placed_in_children_home_in_the_state}</td>

                            </tr>
                            <tr>
                                <td>4</td>
                                <td>
                                    No. of districts in the state without any Children’s Homes
                                </td>

                                <td>
                                    {convertedReport.sum_districts_in_the_state_without_any_children_homes}
                                </td>

                            </tr>
                            {/* </table> */}

                            {/* b) Staffs in Children’s Home */}
                            <tr><th colspan={4}><h6> b) Staffs in Children’s Home </h6></th></tr>

                            {/* <table> */}
                            <tr>
                                <th>Sl.No</th>
                                <th>Particulars</th>
                                <th>Strength of staff</th>
                                <th>Remarks</th>
                            </tr>
                            <tr>
                                <td rowSpan={3}>1</td>
                                <td rowSpan={3}>
                                    Total no. of full time staff in all the children’s home in the
                                    state
                                </td>
                                <td>Sanctioned strength</td>

                                <td>
                                    {
                                        convertedReport.sum_full_time_staff_in_children_home_in_state_sanctioned_strength
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>Working strength</td>

                                <td>
                                    {
                                        convertedReport.sum_full_time_staff_in_children_home_in_state_working_strength
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>Vacancies</td>

                                <td>
                                    {convertedReport.sum_full_time_staff_in_children_home_in_state_vacancies}
                                </td>

                            </tr>
                            <tr>
                                <td rowSpan={3}>2</td>
                                <td rowSpan={3}>
                                    Total no. of part time staff in all the children’s home in the
                                    state
                                </td>
                                <td>Sanctioned strength</td>

                                <td>
                                    {
                                        convertedReport.sum_part_time_staff_in_children_home_in_state_sanctioned_strength
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>Working strength</td>

                                <td>
                                    {
                                        convertedReport.sum_part_time_staff_in_children_home_in_state_working_strength
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>Vacancies</td>

                                <td>
                                    {convertedReport.sum_part_time_staff_in_children_home_in_state_vacancies}
                                </td>

                            </tr>
                            <tr>
                                <td>3</td>
                                <td>
                                    No. of staff given Additional charge of children’s home in the
                                    state
                                </td>

                                <td>
                                    {
                                        convertedReport.sum_staff_given_additional_charge_of_children_home_in_the_state
                                    }
                                </td>

                                <td></td>
                            </tr>
                            {/* </table> */}

                            {/*  */}
                            <tr><th colspan={3}><h6>
                                {" "}
                                c) Fit Facility (Recognised under section 51 of the JJ Act, 2015):-{" "}
                            </h6></th></tr>

                            {/* <table> */}
                            <tr>
                                <th>Sl.No</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>
                                    No. of Governmental Organization recognised as Fit Facility
                                </td>


                                <td>
                                    {convertedReport.sum_governmental_oganization_recognised_as_fit_facility}
                                </td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>
                                    No. of Non-Governmental Organization recognised as Fit
                                    Facility
                                </td>

                                <td>
                                    {convertedReport.sum_non_governmental_oganization_recognised_as_fit_facility}
                                </td>

                            </tr>
                            <tr>
                                <td>3</td>
                                <td>No. of children placed in Fit Facility in the state</td>

                                <td>{convertedReport.sum_children_placed_in_fit_facility_in_the_state}</td>

                            </tr>
                            {/* </table> */}

                            <tr><th colspan={3}><h6>
                                {" "}
                                d) Fit Persons (Recognised under section 52 of the JJ Act, 2015):-{" "}
                            </h6></th></tr>

                            {/* <table> */}
                            <tr>
                                <th>Sl.No</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>No. of persons in the state recognised as Fit Facility</td>

                                <td>{convertedReport.sum_persons_in_the_state_recognised_as_fit_facility}</td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>No. of children placed with Fit persons in the state</td>

                                <td>{convertedReport.sum_children_placed_with_fit_persons_in_the_state}</td>

                            </tr>
                            {/* </table> */}

                            <tr><th colspan={3}><h6>
                                {" "}
                                e) Open Shelter (established under section 43 of the JJ Act, 2015):-{" "}
                            </h6></th></tr>

                            {/* <table> */}
                            <tr>
                                <th>Sl.No</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>
                                    No. of Open Shelters in the state maintained by the state govt
                                    itself
                                </td>

                                <td>
                                    {convertedReport.sum_open_shelters_in_state_maintained_by_state_govt_itself}
                                </td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>
                                    No. of Open Shelters in the state maintained by the state govt
                                    through voluntary or non – governmental organization
                                </td>

                                <td>
                                    {convertedReport.sum_open_shelters_in_the_state_maintained_by_the_state_govt}
                                </td>

                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Total no. of Open Shelters in the state</td>

                                <td>{convertedReport.sum_total_open_shelters_in_the_state}</td>

                            </tr>
                            <tr>
                                <td>4</td>
                                <td>No. of children placed in Open Shelters in the state</td>

                                <td>{convertedReport.sum_children_placed_in_open_shelters_in_the_state}</td>

                            </tr>
                            {/* </table> */}
                            <tr><th colspan={3}><h5> 3. Adoption under section 56 of the JJ Act, 2015 </h5></th></tr>
                            <tr><th colspan={3}><h6>
                                {" "}
                                a) Specialised Adoption Agencies (Recognised under section 65 of
                                the JJ Act, 2015){" "}
                            </h6></th></tr>

                            {/* <table> */}
                            <tr>
                                <th>Sl.No</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>No. of SAA in the state</td>

                                <td>{convertedReport.sum_ssa_in_the_state}</td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>
                                    No. of districts in the state where no recognised SAA is
                                    operating
                                </td>

                                <td>
                                    {
                                        convertedReport.sum_districts_in_the_state_where_no_recognised_ssa_is_operating
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>3</td>
                                <td>
                                    No. of districts in the state where more than one SAA is
                                    operating
                                </td>

                                <td>
                                    {
                                        convertedReport.sum_districts_in_the_state_where_more_than_one_ssa_is_operating
                                    }
                                </td>

                            </tr>
                            {/* </table> */}

                            <tr><th colspan={3}><h6>
                                {" "}
                                b) Pendency of cases before the CWC for declaring a child free for
                                Adoption{" "}
                            </h6></th></tr>
                            {/* <table> */}
                            <tr>
                                <th>Sl.No</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Opening balance at the start of the quarter</td>

                                <td>
                                    {convertedReport.sum_cws_opening_balance_at_the_start_of_the_quarter}
                                </td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>No. of cases filed during the quarter</td>

                                <td>{convertedReport.sum_cases_filed_during_the_quarter}</td>

                            </tr>
                            <tr>
                                <td>3</td>
                                <td>No. of cases disposed of during the quarter</td>

                                <td>
                                    {
                                        convertedReport.sum_cases_before_the_cws_cases_disposed_of_during_the_quarter
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Total no. of cases pending at the end of the quarter</td>

                                <td>
                                    {
                                        convertedReport.sum_cases_before_the_cws_cases_pending_at_the_end_of_the_quarter
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>5</td>
                                <td>
                                    No. of pending cases pertaining to children below the age of
                                    2 years at the end of the quarter
                                </td>

                                <td>
                                    {
                                        convertedReport.sum_pending_cases_pertaining_to_children_below_the_age_of_2_years
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>6</td>
                                <td>
                                    No. of the cases pertaining to children below the age of 2
                                    years that have been pending for more than 2 months at the
                                    end of the quarter (from the date of production of the child
                                    before the CWC)
                                </td>

                                <td>
                                    {convertedReport.sum_cases_pertaining_to_children_below_the_age_of_2_years}
                                </td>

                            </tr>
                            <tr>
                                <td>7</td>
                                <td>
                                    No. of pending cases pertaining to children above the age of
                                    2 years at the end of the quarter
                                </td>

                                <td>
                                    {
                                        convertedReport.sum_pending_cases_pertaining_to_children_above_the_age_of_2_years
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>8</td>
                                <td>
                                    No. of the cases pertaining to children above the age of 2
                                    years that have been pending for more than 4 months at the
                                    end of the quarter (from the date of production of the child
                                    before the CWC)
                                </td>

                                <td>
                                    {convertedReport.sum_cases_pertaining_to_children_above_the_age_of_2_years}
                                </td>

                            </tr>
                            {/* </table> */}

                            <tr><th colspan={3}><h6>
                                {" "}
                                c) Number of children in the Child Care Institution in the
                                districts whose information has been uploaded to the Child
                                Adoption Resource Information and Guidance System (CARIGS):-
                            </h6></th></tr>
                            <tr><th colspan={3}>{convertedReport.sum_children_in_the_child_care_institution_in_the_districts}</th></tr>

                            <tr><th colspan={3}><h6> d) Adoption Orders (as per section 61, JJ Act, 2015) </h6></th></tr>

                            {/* <table> */}
                            <tr>
                                <th>Sl.No</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Opening balance at the start of the quarter</td>

                                <td>{convertedReport.sum_opening_balance_at_the_start_of_the_quarter_d}</td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>No. of cases filed during the quarter</td>

                                <td>{convertedReport.sum_cases_filed_during_the_quarter_d}</td>

                            </tr>
                            <tr>
                                <td>3</td>
                                <td>No. of cases disposed of during the quarter</td>

                                <td>{convertedReport.sum_cases_disposed_of_during_the_quarter_d}</td>

                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Total no. of cases pending at the end of the quarter</td>

                                <td>{convertedReport.sum_cases_pending_at_the_end_of_the_quarter_d}</td>

                            </tr>
                            <tr>
                                <td>5</td>
                                <td>
                                    No. of cases that have been pending for more than 2 months
                                    at the end of the quarter
                                </td>

                                <td>
                                    {convertedReport.sum_cases_that_have_been_pending_for_more_than_2_months}
                                </td>

                            </tr>
                            {/* </table> */}

                            <tr><th colspan={3}><h5> 4. Institutional Mechanisms for protection of children </h5></th></tr>
                            <tr><th colspan={3}><h6>
                                {" "}
                                a) Has a Child Protection Society been constituted for the State
                                as directed by section 106 of the JJ Act, 2015? :-{" "}
                            </h6></th></tr>
                            <tr><th colspan={3}><h6>
                                {" "}
                                --{" "}
                            </h6></th></tr>

                            <tr><th colspan={3}><h6>
                                {" "}
                                b) Number of Districts Child Protection Units in the State
                                established under section 106 of the JJ Act,2015:-{" "}
                            </h6></th></tr>
                            <tr><th colspan={3}>{convertedReport.sum_districts_child_protection_units_in_the_state}</th></tr>

                            <tr><th colspan={3}><h6> c) Details of officers/staffs of the DCPU's </h6></th></tr>

                            {/* <table> */}
                            <tr>
                                <th>Sl.No</th>
                                <th>Particulars</th>
                                <th>Strength of staff</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td rowSpan={3}>1</td>
                                <td rowSpan={3}>Full – time staff in the DCPU</td>
                                <td>Sanctioned strength</td>

                                <td>
                                    {convertedReport.sum_full_time_staff_in_the_dcpu_sanctioned_strength}
                                </td>

                            </tr>
                            <tr>
                                <td>Working strength</td>

                                <td>{convertedReport.sum_full_time_staff_in_the_dcpu_working_strength}</td>

                            </tr>
                            <tr>
                                <td>Vacancies</td>

                                <td>{convertedReport.sum_full_time_staff_in_the_dcpu_vacancies}</td>

                            </tr>
                            <tr>
                                <td rowSpan={3}>2</td>
                                <td rowSpan={3}>Part – time staff in the DCPU</td>
                                <td>Sanctioned strength</td>

                                <td>
                                    {convertedReport.sum_part_time_staff_in_the_dcpu_sanctioned_strength}
                                </td>

                            </tr>
                            <tr>
                                <td>Working strength</td>

                                <td>{convertedReport.sum_part_time_staff_in_the_dcpu_working_strength}</td>

                            </tr>
                            <tr>
                                <td>Vacancies</td>

                                <td>{convertedReport.sum_part_time_staff_in_the_dcpu_vacancies}</td>

                            </tr>
                            <tr>
                                <td rowSpan={3}>3</td>
                                <td rowSpan={3}>Total staff of all DCPU’s in the State</td>
                                <td>Sanctioned strength</td>

                                <td>
                                    {
                                        convertedReport.sum_total_staff_of_all_dcpus_in_the_state_sanctioned_strength
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>Working strength</td>

                                <td>
                                    {
                                        convertedReport.sum_total_staff_of_all_dcpus_in_the_state_working_strength
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>Vacancies</td>

                                <td>
                                    {convertedReport.sum_total_staff_of_all_dcpus_in_the_state_vacancies}
                                </td>

                            </tr>
                            <tr>
                                <td>4</td>
                                <td colSpan={2}>
                                    No. of staff given Additional Charge of Children’s Homes
                                </td>

                                <td>
                                    {convertedReport.sum_staff_given_additional_charge_of_childrens_home}
                                </td>

                            </tr>
                            {/* </table> */}

                            <tr><th colspan={3}><h6>
                                {" "}
                                d) Number of Child Welfare Police Officers in the districts
                                (designated under section 107(1) of the JJ Act, 2015):-{" "}
                            </h6></th></tr>
                            <tr><th colspan={3}>{convertedReport.sum_staff_given_additional_charge_of_childrens_home}</th></tr>

                            <tr><th colspan={3}><h6>
                                {" "}
                                e) Special Juvenile Police Units (Constituted under section 107
                                (2) of the JJ Act, 2015):-{" "}
                            </h6></th></tr>

                            {/* <table> */}
                            <tr>
                                <th>Sl.No</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>No. of SJPUs in the State</td>

                                <td>{convertedReport.sum_sjpus_in_the_state}</td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>
                                    No. of Police Officers in the state designated as members of
                                    SJPUs
                                </td>

                                <td>
                                    {
                                        convertedReport.sum_police_officers_in_the_state_designated_as_members_of_sjpus
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>3</td>
                                <td>
                                    No. of members of the SJPUs who have been provided induction
                                    training
                                </td>

                                <td>
                                    {
                                        convertedReport.sum_members_of_the_sjpus_who_have_been_provided_induction_training
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>4</td>
                                <td>
                                    No. of districts in the state where SJPUs have not been
                                    constituted
                                </td>

                                <td>
                                    {
                                        convertedReport.sum_districts_in_the_state_where_sjpus_have_not_been_constituted
                                    }
                                </td>

                            </tr>
                            <tr>
                                <td>5</td>
                                <td>
                                    No. of cities in the state where SJPUs have not been
                                    constituted
                                </td>

                                <td>
                                    {
                                        convertedReport.sum_cities_in_the_state_where_sjpus_have_not_been_constituted
                                    }
                                </td>

                            </tr>

                        </table>

                    </div>
                    <div className="modal-footer" style={{ position: 'fixed', "bottom": "0", "right": "0" }}>
                        <button
                            className="btn btn-primary"
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={() => downloadExcel("qprsc")}
                        >
                            {" "}
                            Download <i class="icofont icofont-arrow-down"></i>{" "}
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={createPDF}
                            type="button"
                            data-bs-dismiss="modal"

                        >
                            Download as Pdf
                        </button>
                        <button
                            className="btn btn-secondary"
                            onClick={openForm1}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default AReport27;
