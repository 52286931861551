import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";
import Modal from "react-modal";

import "./Form.css";
import { useUserStore } from "../Store/Admin";

function PasswordReset(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { onSubmit }] = useUserStore();
    const openForm1 = () => {
        onSubmit(data, "reset-password");
        alert("Password changed successfully")
        props.close();
        setmodalIsOpen(false);
    };
    const openForm = () => {
        props.close();
        setmodalIsOpen(false);
    };

    console.log(props.message)
    const [data, setdata] = useState({
        username: props.name,
        newpassword: ""
    });
    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <div>
                    <div className="modal-header">
                        <span>
                            {" "}
                            <h5 className="modal-title" id="exampleModalLongTitle">
                                Reset Password
                            </h5>{" "}
                        </span>
                        <button
                            className="btn-close"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    <div className="modal-body">
                        <form>
                            <div className="new-expense__control">
                                <label> Username</label> :{" "}
                                {props.name}
                            </div>
                            <div><label> New password </label> :{" "}
                                <input
                                    type="password"
                                    onChange={(e) => setdata({ ...data, newpassword: e.target.value })}
                                /></div>

                        </form>
                    </div>

                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={openForm1}
                            type="button"
                        >
                            Reset
                        </button>
                    </div>
                </div>
            </Modal>
        </div>


    )
}

export default PasswordReset;
