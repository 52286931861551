import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";

// FORM 9  Information regarding Juvenile Justice (Care and Protection of Children) Act

function Form9(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { onSubmit }] = useFormStore();

  const [data, setdata] = useState({
    state_child_protection_on_society_setup_u_s_106_jj_act_2015: 0,
    district_child_protection_unit_u_s_106_jj_act_2015: 0,
    sjpu_child_welfare_police_in_district_u_s_107_jj_act_2015: 0,
    children_courts_u_s_25_cpcr_act_2005: 0,
    reg_ccis_u_s_41_jj_act_2015_run_by_govt_18_a: 0,
    reg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_b: 0,
    unreg_ccis_u_s_41_jj_act_2015_run_by_govt_18_c: 0,
    unreg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_d: 0,
  });

  const openForm1 = () => {
    props.close();
    onSubmit(data, "juvenile_justice");
    setmodalIsOpen(false);
  };
  const closeButton = () => {
    props.close();
    setmodalIsOpen(false);
  };
  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {" "}
              Information regarding Juvenile Justice (Care and Protection of
              Children) Act{" "}
            </h5>
            <button
              className="btn-close"
              onClick={closeButton}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether State Child Protection on Society set up u/s 106 of JJ
                  Act, 2015. If yes, Copy of notification{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.state_child_protection_on_society_setup_u_s_106_jj_act_2015
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      state_child_protection_on_society_setup_u_s_106_jj_act_2015:
                        e.target.value,
                    })
                  }
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  District Child Protection Unit u/s 106 of JJ Act, 2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.district_child_protection_unit_u_s_106_jj_act_2015
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      district_child_protection_unit_u_s_106_jj_act_2015:
                        e.target.value,
                    })
                  }
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of SJPU & No. of Child Welfare Police Officers in District
                  u/s 107 of JJ Act, 2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.sjpu_child_welfare_police_in_district_u_s_107_jj_act_2015
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      sjpu_child_welfare_police_in_district_u_s_107_jj_act_2015:
                        e.target.value,
                    })
                  }
                />
              </div>

              {/* <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of JJBs in the District (u/s 4 of the JJ Act 2015){" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div> */}

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of Children Courts u/s 25 of the CPCR Act, 2005{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.children_courts_u_s_25_cpcr_act_2005}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      children_courts_u_s_25_cpcr_act_2005: e.target.value,
                    })
                  }
                />
              </div>

              {/* <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of CWCs in the District (u/s 27 of the JJ Act 2015){" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div> */}

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of Reg. CCIs u/s 41 of JJ Act, 2015 (Run by Govt.)
                  18(a){" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.reg_ccis_u_s_41_jj_act_2015_run_by_govt_18_a}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      reg_ccis_u_s_41_jj_act_2015_run_by_govt_18_a:
                        e.target.value,
                    })
                  }
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of Reg. CCIs u/s 41 of JJ Act, 2015 (Run by NGOs)
                  18(b){" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.reg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_b}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      reg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_b:
                        e.target.value,
                    })
                  }
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of Unreg. CCIs u/s 41 of JJ Act, 2015 (Run by Govt.)
                  18(c){" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.unreg_ccis_u_s_41_jj_act_2015_run_by_govt_18_c}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      unreg_ccis_u_s_41_jj_act_2015_run_by_govt_18_c:
                        e.target.value,
                    })
                  }
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of Unreg. CCIs u/s 41 of JJ Act, 2015 (Run by NGOs)
                  18(d){" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.unreg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_d}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      unreg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_d:
                        e.target.value,
                    })
                  }
                />
              </div>

              {/* <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of persons penalized for non-registration of CCI u/s
                  42{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of open shelter Shelter set up U/s 43 of the JJ Act,
                  2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total capacity of open shelter Shelter U/s 43 of the JJ Act,
                  2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of children in Open Shelter U/s 43 of the JJ Act,
                  2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total no. of Observation Homes set up U/s 47 of the JJ Act,
                  2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total capacity of Observation Homes U/s 43 of the JJ Act, 2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total Children in Home who are staying for more than 3 months{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of Special Home set up u/s 48 of the JJ Act, 2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total capacity of Special Home u/s 48 of the JJ Act, 2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of children in Special Home u/s 48 of the JJ Act,
                  2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of place of safety u/s 49 of the JJ Act, 2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label> Total Capacity of Place of Safety </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label> Total No. of children in Place of Safety </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of children home set up u/s 50 of the JJ Act, 2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of Foster Care recognized u/s 44 of the JJ Act 2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of Fit facility recognized u/s 51 of the Act 2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of Fit Person recognized u / s 52 of JJ Act 2015 {" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of Specialised Adoption Agency Organised u/s 65 of
                  JJ Act, 2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Appointment of Inspection Committee by State u / s 54 of the
                  JJ Act 2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether Juvenile Justice Fund has been created u / s 105 of
                  the JJ Act 2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of inspections conducted by inspection committee to all
                  housing facilities of children u / s 54(2) of the JJ Act 15?{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether the State Govt evaluated the functioning of JJB CWC
                  SJPU regd. Or recognized institutions u / s 55(1) of JJ Act
                  2015?{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of JJBs in the District u/s 4 of JJ Act, 2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether induction training & sensitisation to JJB has been
                  provided (within 60 days from the dates of appointment u/s
                  4(5) of JJ Act, 2015 Yes/No if no reason thereof{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether pendency for JJB is quarterly reviewed by CJM/CMM
                  yes/No if no reason thereof u/s 16(1) of JJ Act, 2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether pendency of the board is reviewed by the High Level
                  Committee as per sec. 16(2) of JJ Act, 2015 if no reason
                  thereof{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of children in Observation Home in case of petty offences{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of Children staying in Observation Home for more than 3
                  months in case of petty offence{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of run away children (Home-wise) U / s 26(2){" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of Homes where (CNCP) Child in need of care and protection
                  & Child in conflict with Law(CCL) are kept together{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of cases presented before the Session Court where
                  the Court felt or the persons claims himself / herself to be a
                  minor?{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of children residing in the Special Home for more
                  than 3 yrs{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of & Child in conflict with Law (CCL) placed in
                  place of safety{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether Reformative services like education skill development
                  vocational training counselling behaviour modification therapy
                  and psychiatric support have been provided to each and every
                  child during their stay in the Special Home{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label> No. of sittings in a week by JJB </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether sittings of JJB are held in the premises of Home Yes /
                  No if not reasons thereof{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label> Date of appointment of the Board (Dist-wise) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>Date of Superannuation of the Board (Dist-wise) </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether Principal Magistrate of JJB is exclusive or given
                  additional charge of the Board Yes / No & No. accordingly{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether JJB conducting monthly visit/inspection of residential
                  facilities off CCL u/s 8(3)(j) of JJ Act, 2015 Yes/No if no
                  reason thereof{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of cases where JJB has ordered registration of FIR for
                  offences committed against any where (CNCP) Child in need of
                  care and protection & Child in conflict with Law(CCL) u/s
                  8(3)(K)(l) of JJ Act, 2015 *{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether regular inspections of adult jails is being conducted
                  by JJB u/s 8(3)(m) of JJ Act, 2015 yes/no if no reason
                  thereof {" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether any Child below 18 yrs was found to be confined in the
                  Adult jail during inspection Yes / No if yes details thereof{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether such children were transferred to Observation Home
                  yes/no, if no reason thereof u/s 8(3)(m) of JJ Act, 2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  No. of cases where legal aid provided to CCL (Child Conflict
                  with Law){" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of cases where there is no Advocate to represent CCL{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether facility of interpreter or translators available is
                  with the JJB U / s (3)(d) Yes / No{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label> Total No. of cases with JJB </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label> No. of pending cases </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label> Reason of pendency, please specify </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>No. of cases disposed off </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether pendency is being reported to DM u/s 16(3) of JJ Act,
                  2015 Yes/no, if no reason thereof{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of cases where bail is granted to CCL u/s 12 of JJ Act,
                  2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether inquiry in case by board is completed within time u/s
                  14(2) of JJ Act, 2015 yes/no if no, reason thereof{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether any case of ill treatment by the police towards the
                  child has been reported before the JJB? If yes, action taken
                  against the police official{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of cases which are pending due to want of bail bond U/s 12{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Whether preliminary assessment in heinous offences is
                  completed within time u/s 14(3) i.e 3 months{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  No. of Cases of Heinous Offences pending with Board{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of Cases of Serious Offences pending with Board{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  No. of Cases of Petty Offences pending with Board{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>No. of cases where CCL are tried with Adults </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of children produced before JJB after recovery. u / s
                  26(2) of JJ Act, 2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of cases where child is not found to be in conflict with
                  law u / s 17 of JJ Act, 2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of children who are found to be in CCL u/s 18 of JJ Act,
                  2015{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of cases where CCL was found CNCP & transferred to CWC{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of bail applications received in the JJB{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label> Total no. of bails been granted by JJB </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of children been transferred from JJB to the Court{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of cases in which inquiry completed within 4 months{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of cases of petty offences disposed of due to time{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  Total No. of children released on probation of good conduct{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male}
                  onChange={(e) => setdata({ ...data, male: e.target.value })}
                />
              </div> */}
            </form>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              onClick={closeButton}
              type="button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              onClick={openForm1}
              type="button"
            >
              Save & Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Form9;
