import {
    adoption,
    after_care_children_cci,
    after_care_service,
    central_sponsership,
    child_labour,
    child_marriage,
    child_protection,
    crime_committed,
    parenting_outreach,
    parenting_clinic,
    cwc,
    deinstitutional,
    foster_care,
    get_qprsc,
    get_qprhc,
    get_parenting_outreach,
    get_parenting_clinic,
    get_ncpcr,
    get_adoption,
    get_after_care_children_cci,
    get_after_care_service,
    get_central_sponsership,
    get_child_labour,
    get_child_marriage,
    get_child_protection,
    get_crime_committed,
    get_cwc,
    get_deinstitutional,
    get_foster_care,
    get_institutional_care,
    get_institutional_care_protection,
    get_jja,
    get_jjb,
    get_juvenile_justice,
    get_pocso,
    get_repatriation,
    get_resource_person,
    get_saranabalyam,
    get_state_sponsership,
    get_violence_against_children,
    institutional_care,
    institutional_care_protection,
    jja,
    jjb,
    juvenile_justice,
    pocso,
    repatriation,
    resource_person,
    saranabalyam,
    state_sponsership,
    violence_against_children,
    ncpcr,
    qprsc,
    qprhc,
    admin_get_saranabalyam,
    admin_get_ncpcr,
    admin_get_qprsc,
    admin_get_qprhc,
    admin_get_child_marriage,
    admin_get_child_labour,
    admin_get_cwc,
    admin_get_fostercare,
    admin_get_repatriation,
    admin_get_centralsponsorship,
    admin_get_aftercareservices,
    admin_get_aftercarecci,
    admin_get_crimecommitted,
    admin_get_resource_person,
    admin_violence_against_children,
    admin_get_parenting_outreach,
    admin_get_parenting_clinic,
    update_child_marriage,
    update_child_labour,
    update_saranabalyam,
    update_parenting_clinic,
    update_parenting_outreach,
    update_central_sponsership,
    update_data,
    update_data_yearly
} from "../../Api/Forms";
import TableToExcel from "@linways/table-to-excel";
import { UPDATE_AFTER_CARE_CHILDREN_CCI, UPDATE_CASESCWC, UPDATE_CRIME_COMMITTED, UPDATE_FOSTER_CARE, UPDATE_NCPCR, UPDATE_QPRHC, UPDATE_QPRSC, UPDATE_REPATRIATION, UPDATE_RESOURCE_PERSON, UPDATE_VIOLENCE_AGAINST_CHILDREN } from "../../utils/constant";

const actions = {
    setLoading:
        (loading) =>
            ({ setState }) => {
                setState({ loading });
            },
    setCurrentFormData:
        (currentFormData) =>
            ({ setState }) => {
                setState({ currentFormData });
            },

    downloadExcel: (url) => () => {
        TableToExcel.convert(document.getElementById(url), {
            name: `${url}.xlsx`,
            sheet: {
                name: "Sheet 1",
            },
        });
    },

    onSubmit:
        (data, url) =>
            async ({ getState, setState }) => {
                try {
                    setState({ loading: true });
                    const id = getState().currentFormData;
                    if (url === "child_marriage") {
                        await child_marriage(data);
                    } else if (url === "child_labour") {
                        await child_labour(data);
                    } else if (url === "saranabalyam") {
                        await saranabalyam(data);
                    } else if (url === "violence_against_children") {
                        await violence_against_children(data, id);
                    } else if (url === "state_sponsership") {
                        await state_sponsership(data);
                    } else if (url === "central_sponsership") {
                        await central_sponsership(data);
                    } else if (url === "after_care_service") {
                        await after_care_service(data);
                    } else if (url === "after_care_children_cci") {
                        await after_care_children_cci(data);
                    } else if (url === "crime_committed") {
                        await crime_committed(data);
                    } else if (url === "juvenile_justice") {
                        await juvenile_justice(data);
                    } else if (url === "jja") {
                        await jja(data);
                    } else if (url === "pocso") {
                        await pocso(data);
                    } else if (url === "deinstitutional") {
                        await deinstitutional(data);
                    } else if (url === "jjb") {
                        await jjb(data);
                    } else if (url === "institutional_care") {
                        await institutional_care(data);
                    } else if (url === "cwc") {
                        await cwc(data);
                    } else if (url === "institutional_care_protection") {
                        await institutional_care_protection(data);
                    } else if (url === "adoption") {
                        await adoption(data);
                    } else if (url === "child_protection") {
                        await child_protection(data);
                    } else if (url === "foster_care") {
                        await foster_care(data);
                    } else if (url === "repatriation") {
                        await repatriation(data);
                    } else if (url === "resource_person") {
                        await resource_person(data);
                    } else if (url === "ncpcr") {
                        await ncpcr(data);
                    } else if (url === "qprsc") {
                        await qprsc(data);
                    } else if (url === "qprhc") {
                        await qprhc(data);
                    } else if (url === "parenting_outreach") {
                        await parenting_outreach(data);
                    } else if (url === "parenting_clinic") {
                        await parenting_clinic(data);
                    }
                } catch (error) {
                    console.log(error?.data, "error");
                    // message.error(error?.data?.message);
                } finally {
                    setState({ loading: false });
                }
            },
    getReport:
        (url, month = "", year = "", Qmonth = "", Qyear = "", annually = "") =>
            async ({ getState, setState }) => {
                try {
                    setState({ loading: true });
                    const id = getState().currentFormData;
                    if (url === "child_marriage") {
                        const res = await get_child_marriage();
                        setState({ report: res });
                    } else if (url === "child_labour") {
                        const res = await get_child_labour();
                        setState({ report: res });
                    } else if (url === "saranabalyam") {
                        const res = await get_saranabalyam();
                        setState({ report: res });
                    } else if (url === "violence_against_children") {
                        const res = await get_violence_against_children();
                        setState({ report: res });
                    } else if (url === "parenting_outreach") {
                        const res = await get_parenting_outreach();
                        setState({ report: res });
                    } else if (url === "parenting_clinic") {
                        const res = await get_parenting_clinic();
                        setState({ report: res });
                    } else if (url === "state_sponsership") {
                        const res = await get_state_sponsership();
                        setState({ report: res });
                    } else if (url === "central_sponsership") {
                        const res = await get_central_sponsership();
                        setState({ report: res });
                    } else if (url === "after_care_service") {
                        const res = await get_after_care_service();
                        setState({ report: res });
                    } else if (url === "after_care_children_cci") {
                        const res = await get_after_care_children_cci();
                        setState({ report: res });
                    } else if (url === "crime_committed") {
                        const res = await get_crime_committed();
                        setState({ report: res });
                    } else if (url === "juvenile_justice") {
                        const res = await get_juvenile_justice();
                        setState({ report: res });
                    } else if (url === "jja") {
                        const res = await get_jja();
                        setState({ report: res });
                    } else if (url === "pocso") {
                        const res = await get_pocso();
                        setState({ report: res });
                    } else if (url === "deinstitutional") {
                        const res = await get_deinstitutional();
                        setState({ report: res });
                    } else if (url === "jjb") {
                        const res = await get_jjb();
                        setState({ report: res });
                    } else if (url === "institutional_care") {
                        const res = await get_institutional_care();
                        setState({ report: res });
                    } else if (url === "cwc") {
                        const res = await get_cwc();
                        setState({ report: res });
                    } else if (url === "institutional_care_protection") {
                        const res = await get_institutional_care_protection();
                        setState({ report: res });
                    } else if (url === "adoption") {
                        const res = await get_adoption();
                        setState({ report: res });
                    } else if (url === "child_protection") {
                        const res = await get_child_protection();
                        setState({ report: res });
                    } else if (url === "foster_care") {
                        const res = await get_foster_care();
                        setState({ report: res });
                    } else if (url === "repatriation") {
                        const res = await get_repatriation();
                        setState({ report: res });
                    } else if (url === "resource_person") {
                        const res = await get_resource_person();
                        setState({ report: res });
                    } else if (url === "ncpcr") {
                        const res = await get_ncpcr();
                        setState({ report: res });
                    } else if (url === "qprsc") {
                        const res = await get_qprsc();
                        setState({ report: res });
                    } else if (url === "qprhc") {
                        const res = await get_qprhc();
                        setState({ report: res });
                    } else if (url === "admin_ncpcr") {
                        const res = await admin_get_ncpcr(Qmonth, Qyear);
                        setState({ report: res });
                    } else if (url === "admin_saranabalyam") {
                        const res = await admin_get_saranabalyam(month, year);
                        setState({ report: res });
                    } else if (url === "admin_qprsc") {
                        const res = await admin_get_qprsc(Qmonth, Qyear);
                        setState({ report: res });
                    } else if (url === "admin_qprhc") {
                        const res = await admin_get_qprhc(Qmonth, Qyear);
                        setState({ report: res });
                    } else if (url === "admin_cm") {
                        const res = await admin_get_child_marriage(month, year);
                        setState({ report: res });
                    } else if (url === "admin_childlabour") {
                        const res = await admin_get_child_labour(month, year);
                        setState({ report: res });
                    } else if (url === "admin_cwc") {
                        const res = await admin_get_cwc(Qmonth, Qyear);
                        setState({ report: res });
                    } else if (url === "admin_fostercare") {
                        const res = await admin_get_fostercare(Qmonth, Qyear);
                        setState({ report: res });
                    } else if (url === "admin_repatriation") {
                        const res = await admin_get_repatriation(Qmonth, Qyear);
                        setState({ report: res });
                    } else if (url === "admin_centralsponsorship") {
                        const res = await admin_get_centralsponsorship(Qmonth, Qyear);
                        setState({ report: res });
                    } else if (url === "admin_aftercareservices") {
                        const res = await admin_get_aftercareservices(Qmonth, Qyear);
                        setState({ report: res });
                    } else if (url === "admin_aftercarecci") {
                        const res = await admin_get_aftercarecci(Qmonth, Qyear);
                        setState({ report: res });
                    } else if (url === "admin_crimecommitted") {
                        const res = await admin_get_crimecommitted(Qmonth, Qyear);
                        setState({ report: res });
                    } else if (url === "admin_resource_person") {
                        const res = await admin_get_resource_person(annually);
                        setState({ report: res });
                    } else if (url === "admin_violence_against_children") {
                        const res = await admin_violence_against_children(Qmonth, Qyear);
                        setState({ report: res });
                    } else if (url === "admin_parenting_outreach") {
                        const res = await admin_get_parenting_outreach(month, year);
                        setState({ report: res });
                    } else if (url === "admin_parenting_clinic") {
                        const res = await admin_get_parenting_clinic(month, year);
                        setState({ report: res });
                    }


                } catch (error) {
                    console.log(error?.data, "error");
                    // message.error(error?.data?.message);
                } finally {
                    setState({ loading: false });
                }
            },
    updateReport:
        (data, url) =>
            async ({ setState }) => {
                try {
                    setState({ loading: true });
                    switch (url) {
                        case "update_child_marriage": {
                            const res = await update_child_marriage(data);
                            setState({ updatedReport: res });
                            return res;
                        }
                        case "update_child_labour": {
                            const res = await update_child_labour(data);
                            setState({ updateReport: res });
                            return res;
                        }
                        case "update_saranabalyam": {
                            const res = await update_saranabalyam(data);
                            setState({ updateReport: res });
                            return res;
                        }
                        case "update_parenting_clinic": {
                            const res = await update_parenting_clinic(data);
                            setState({ updateReport: res });
                            return res;
                        }
                        case "update_parenting_outreach": {
                            const res = await update_parenting_outreach(data);
                            setState({ updateReport: res });
                            return res;
                        }
                        case "update_central_sponsership": {
                            const res = await update_central_sponsership(data);
                            setState({ updateReport: res });
                            return res;
                        }
                        case "update_after_care_service": {
                            const res = await update_data(data, url);
                            setState({ updateReport: res });
                            return res;
                        }
                        case UPDATE_AFTER_CARE_CHILDREN_CCI: {
                            const res = await update_data(data, url);
                            setState({ updateReport: res });
                            return res;
                        }
                        case UPDATE_CRIME_COMMITTED: {
                            const res = await update_data(data, url);
                            setState({ updateReport: res });
                            return res;
                        }
                        case UPDATE_CASESCWC: {
                            const res = await update_data(data, url);
                            setState({ updateReport: res });
                            return res;
                        }
                        case UPDATE_FOSTER_CARE: {
                            const res = await update_data(data, url);
                            setState({ updateReport: res });
                            return res;
                        }
                        case UPDATE_REPATRIATION: {
                            const res = await update_data(data, url);
                            setState({ updateReport: res });
                            return res;
                        }
                        case UPDATE_NCPCR: {
                            const res = await update_data(data, url);
                            setState({ updateReport: res });
                            return res;
                        }
                        case UPDATE_QPRSC: {
                            const res = await update_data(data, url);
                            setState({ updateReport: res });
                            return res;
                        }
                        case UPDATE_QPRHC: {
                            const res = await update_data(data, url);
                            setState({ updateReport: res });
                            return res;
                        }
                        case UPDATE_VIOLENCE_AGAINST_CHILDREN: {
                            const res = await update_data(data, url);
                            setState({ updateReport: res });
                            return res;
                        }
                        case UPDATE_RESOURCE_PERSON: {
                            const res = await update_data_yearly(data, url);
                            setState({ updateReport: res });
                            return res;
                        }
                    }
                }
                catch (e) {
                    console.log(e);
                }
            },
    formUpdated:
        (isUpdated) =>
            ({ setState }) => {
                setState({ isUpdated });
            },
};

export default actions;
