import { post, get } from "../common/base_api";

//post
export const child_marriage = (data) => {
    return post("/reports/monthly/child_marriage", data);
};
export const update_child_marriage = (data) => {
    return post("/reports/monthly/update_child_marriage", data);
};
export const update_child_labour = (data) => {
    return post("/reports/monthly/update_child_labour", data);
};
export const update_saranabalyam = (data) => {
    return post("/reports/monthly/update_saranabalyam", data);
};
export const update_parenting_clinic = (data) => {
    return post("/reports/monthly/update_parenting_clinic", data);
};
export const update_parenting_outreach = (data) => {
    return post("/reports/monthly/update_parenting_outreach", data);
};
export const child_labour = (data) => {
    return post("/reports/monthly/child_labour", data);
};
export const saranabalyam = (data) => {
    return post("/reports/monthly/saranabalyam", data);
};
export const violence_against_children = (data) => {
    return post(`/reports/monthly/violence_against_children`, data);
};
export const parenting_outreach = (data) => {
    return post(`/reports/monthly/parenting_outreach`, data);
};
export const parenting_clinic = (data) => {
    return post(`/reports/monthly/parenting_clinic`, data);
};
export const state_sponsership = (data) => {
    return post("/reports/quarterly/state_sponsership", data);
};
export const central_sponsership = (data) => {
    return post("/reports/quarterly/central_sponsership", data);
};
export const update_central_sponsership = (data) => {
    return post("/reports/quarterly/update_central_sponsership", data);
};
export const update_data = (data, url) => {
    return post(`/reports/quarterly/${url}`, data);
};
export const update_data_yearly = (data, url) => {
    return post(`/reports/yearly/${url}`, data);
};
export const after_care_service = (data) => {
    return post("/reports/quarterly/after_care_service", data);
};
export const after_care_children_cci = (data) => {
    return post("/reports/quarterly/after_care_children_cci", data);
};
export const crime_committed = (data) => {
    return post("/reports/quarterly/crime_committed", data);
};
export const juvenile_justice = (data) => {
    return post("/reports/quarterly/juvenile_justice", data);
};
export const jja = (data) => {
    return post("/reports/quarterly/jja", data);
};
export const pocso = (data) => {
    return post("/reports/quarterly/pocso", data);
};
export const deinstitutional = (data) => {
    return post("/reports/quarterly/deinstitutional", data);
};
export const jjb = (data) => {
    return post("/reports/quarterly/jjb", data);
};
export const institutional_care = (data) => {
    return post("/reports/quarterly/institutional_care", data);
};
export const cwc = (data) => {
    return post("/reports/quarterly/cwc", data);
};
export const institutional_care_protection = (data) => {
    return post("/reports/quarterly/institutional_care_protection", data);
};
export const adoption = (data) => {
    return post("/reports/quarterly/adoption", data);
};
export const child_protection = (data) => {
    return post("/reports/quarterly/child_protection", data);
};
export const foster_care = (data) => {
    return post("/reports/quarterly/foster_care", data);
};
export const repatriation = (data) => {
    return post("/reports/quarterly/repatriation", data);
};
export const resource_person = (data) => {
    return post("/reports/yearly/resource_person", data);
};
export const ncpcr = (data) => {
    return post("/reports/quarterly/ncpcr", data);
};
export const qprsc = (data) => {
    return post("/reports/quarterly/qprsc", data);
};
export const qprhc = (data) => {
    return post("/reports/quarterly/qprhc", data);
};

//get
export const get_child_marriage = () => {
    return get("/reports/monthly/child_marriage");
};
export const get_child_labour = () => {
    return get("/reports/monthly/child_labour");
};
export const get_saranabalyam = () => {
    return get(`/reports/monthly/saranabalyam`);
};
export const get_violence_against_children = () => {
    return get(`/reports/monthly/violence_against_children`);
};
export const get_parenting_outreach = () => {
    return get(`/reports/monthly/parenting_outreach`);
};
export const get_parenting_clinic = () => {
    return get(`/reports/monthly/parenting_clinic`);
};
export const get_state_sponsership = () => {
    return get("/reports/quarterly/state_sponsership");
};
export const get_central_sponsership = () => {
    return get("/reports/quarterly/central_sponsership");
};
export const get_after_care_service = () => {
    return get("/reports/quarterly/after_care_service");
};
export const get_after_care_children_cci = () => {
    return get("/reports/quarterly/after_care_children_cci");
};
export const get_crime_committed = () => {
    return get("/reports/quarterly/crime_committed");
};
export const get_juvenile_justice = () => {
    return get("/reports/quarterly/juvenile_justice");
};
export const get_jja = () => {
    return get("/reports/quarterly/jja");
};
export const get_pocso = () => {
    return get("/reports/quarterly/pocso");
};
export const get_deinstitutional = () => {
    return get("/reports/quarterly/deinstitutional");
};
export const get_jjb = () => {
    return get("/reports/quarterly/jjb");
};
export const get_institutional_care = () => {
    return get("/reports/quarterly/institutional_care");
};
export const get_cwc = () => {
    return get("/reports/quarterly/cwc");
};
export const get_institutional_care_protection = () => {
    return get("/reports/quarterly/institutional_care_protection");
};
export const get_adoption = () => {
    return get("/reports/quarterly/adoption");
};
export const get_child_protection = () => {
    return get("/reports/quarterly/child_protection");
};
export const get_foster_care = () => {
    return get("/reports/quarterly/foster_care");
};
export const get_repatriation = () => {
    return get("/reports/quarterly/repatriation");
};
export const get_resource_person = () => {
    return get("/reports/yearly/resource_person");
};
export const get_ncpcr = () => {
    return get("/reports/quarterly/ncpcr");
};
export const get_qprsc = () => {
    return get("/reports/quarterly/qprsc");
};
export const get_qprhc = () => {
    return get("/reports/quarterly/qprhc");
};
export const admin_get_ncpcr = (Qmonth, Qyear) => {
    return get("/reports/quarterly/" + Qmonth + "/" + Qyear + "/admin_ncpcr");
};
export const admin_get_qprsc = (Qmonth, Qyear) => {
    return get("/reports/quarterly/" + Qmonth + "/" + Qyear + "/admin_qprsc");
};
export const admin_get_qprhc = (Qmonth, Qyear) => {
    return get("/reports/quarterly/" + Qmonth + "/" + Qyear + "/admin_qprhc");
};
export const admin_get_child_marriage = (month, year) => {
    return get("/reports/monthly/" + month + "/" + year + "/admin_cm");
};
export const admin_get_saranabalyam = (month, year) => {
    return get("/reports/monthly/" + month + "/" + year + "/admin_saranabalyam");
};
export const admin_get_child_labour = (month, year) => {
    return get("/reports/monthly/" + month + "/" + year + "/admin_childlabour");
};
export const admin_violence_against_children = (Qmonth, Qyear) => {
    return get("/reports/quarterly/" + Qmonth + "/" + Qyear + "/admin_violence_against_children");
};
export const admin_get_cwc = (Qmonth, Qyear) => {
    return get("/reports/quarterly/" + Qmonth + "/" + Qyear + "/admin_cwc");
};
export const admin_get_fostercare = (Qmonth, Qyear) => {
    return get("/reports/quarterly/" + Qmonth + "/" + Qyear + "/admin_fostercare");
};
export const admin_get_repatriation = (Qmonth, Qyear) => {
    return get("/reports/quarterly/" + Qmonth + "/" + Qyear + "/admin_repatriation");
};
export const admin_get_centralsponsorship = (Qmonth, Qyear) => {
    return get("/reports/quarterly/" + Qmonth + "/" + Qyear + "/admin_centralsponsorship");

};
export const admin_get_aftercareservices = (Qmonth, Qyear) => {
    return get("/reports/quarterly/" + Qmonth + "/" + Qyear + "/admin_aftercareservices");
};
export const admin_get_aftercarecci = (Qmonth, Qyear) => {
    return get("/reports/quarterly/" + Qmonth + "/" + Qyear + "/admin_aftercarecci");
};
export const admin_get_crimecommitted = (Qmonth, Qyear) => {
    return get("/reports/quarterly/" + Qmonth + "/" + Qyear + "/admin_crimecommitted");
};

export const admin_get_resource_person = (annually) => {
    return get("/reports/yearly/" + annually + "/admin_resource_person");
};
export const admin_get_parenting_outreach = (month, year) => {
    return get("/reports/monthly/" + month + "/" + year + "/admin_parenting_outreach");
};
export const admin_get_parenting_clinic = (month, year) => {
    return get("/reports/monthly/" + month + "/" + year + "/admin_parenting_clinic");
};
