import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";
// FORM 22  Repatriation (Q)

function Report22(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{}, { downloadExcel }] = useFormStore();
  const Qyear = props.Qyear
  const Qmonth = props.Qmonth
  const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
  const DisplayQuarter = Quarter[Qmonth]

  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };
  // object assigned to a variable to obtain total
  const values = props.report
  // sum_array = total sum data of district
  const sum_array = values.reduce((sum_array, value) => {
    for (const [key, count] of Object.entries(value)) {
      if (!sum_array[key]) {
        sum_array[key] = 0;
      }
      sum_array[key] += count;
    }
    return sum_array;
  }, {});
  function createPDF() {
    var sTable = document.getElementById('table').innerHTML;
    var style = "<style>";
    style = style + "table {width: 100%;font: 10px Calibri;}";
    style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "</style>";
    var win = window.open('', '', 'height=700,width=700');
    win.document.write('<html><head>');
    win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
    win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
    win.document.write('</head>');
    win.document.write('<body>');
    win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
    win.document.write('</body></html>');
    win.document.close(); 	// CLOSE THE CURRENT WINDOW.
    win.print();    // PRINT THE CONTENTS.
  }

  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {" "}
            Repatriation{" "}
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <h4>{DisplayQuarter} {Qyear}</h4>
        <div className="modal-body">
        <div class="table-responsive" id="table" height="100%" width="100%" media="print">
            <table class="table" id="repatriation">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">District</th>
                  <th scope="col">Within District </th>
                  <th scope="col">To other state </th>
                </tr>
              </thead>

              <tbody>
              {props.report.map((r) => (
                <tr key={r?.id}>
                  <th scope="row">{r?.id}</th>
                  <td>{r?.username}</td>
                  
                  <td>{r?.with_in_district}</td>
                  <td>{r?.to_other_state}</td>
                </tr>
              ))}
                <tr>
                  <th scope="row"></th>
                  <td>Total</td>
                  
                  <td>{sum_array.with_in_district}</td>
                  <td>{sum_array.to_other_state}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("repatriation")}

          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-primary"
            onClick={createPDF}
            type="button"
            data-bs-dismiss="modal"
          >
            Download as Pdf
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default Report22;
