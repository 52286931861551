import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";

// FORM 15  Juvenile Justice Boards

function Form15(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { onSubmit }] = useFormStore();

  const [data, setdata] = useState({
    jjb_in_district: 0,
    balance_start_quarter: 0,
    cases_instituted_during_quarter: 0,
    cases_disposed_during_quarter: 0,
    pending_at_end_of_quarter: 0,
    pending_for_period_less_4_month_end_of_quarter: 0,
    pending_for_period_more_4_month_less_6_month_end_of_quarter: 0,
    pending_for_period_more_6_month_less_1_year_end_of_quarter: 0,
    pending_for_period_more_1_year_end_of_quarter: 0,
    sanctioned_staff_district: 0,
    working_staff_district: 0,
    vaccancies_in_district: 0,
    effort_made: "",
  });
  const openForm1 = () => {
    props.close();
    onSubmit(data, "jjb");
    setmodalIsOpen(false);
  };
  const closeButton = () => {
    props.close();
    setmodalIsOpen(false);
  };
  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {" "}
              Juvenile Justice Boards{" "}
            </h5>
            <button
              className="btn-close"
              onClick={closeButton}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <h5>
                {" "}
                a) Children’s Homes (established under section 50 of the JJ Act,
                2015):-{" "}
              </h5>
              <div className="new-expense__control">
                <label> No. of JJBs in the district </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.jjb_in_district}
                  onChange={(e) =>
                    setdata({ ...data, jjb_in_district: e.target.value })
                  }
                />
              </div>
              <br /> <br /> <br />
              <h5> b) Pendency of cases before the JJBs </h5>
              <div className="new-expense__control">
                <label> Opening balance at the start of the quarter </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.balance_start_quarter}
                  onChange={(e) =>
                    setdata({ ...data, balance_start_quarter: e.target.value })
                  }
                />
                <label> No of cases instituted during the quarter </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.cases_instituted_during_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      cases_instituted_during_quarter: e.target.value,
                    })
                  }
                />
                <label> No of cases disposed of during the quarter </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.cases_disposed_during_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      cases_disposed_during_quarter: e.target.value,
                    })
                  }
                />
                <label> No. of cases pending at the end of the quarter </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.pending_at_end_of_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pending_at_end_of_quarter: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No of cases pending for a period of less than 4 months at the
                  end of the quarter{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.pending_for_period_less_4_month_end_of_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pending_for_period_less_4_month_end_of_quarter:
                        e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No of cases pending for a period of more than 4 months but
                  less than 6 months at the end of the quarter{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.pending_for_period_more_4_month_less_6_month_end_of_quarter
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pending_for_period_more_4_month_less_6_month_end_of_quarter:
                        e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No of cases pending for a period of more than 6 months but
                  less than 1 year at the end of the quarter{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.pending_for_period_more_6_month_less_1_year_end_of_quarter
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pending_for_period_more_6_month_less_1_year_end_of_quarter:
                        e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No of cases pending for a period of more than 1 year at the
                  end of the quarter{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.pending_for_period_more_1_year_end_of_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pending_for_period_more_1_year_end_of_quarter:
                        e.target.value,
                    })
                  }
                />
              </div>
              <br /> <br /> <br />
              <h5> c) Details with regards to staff in JJBs </h5>
              <div className="new-expense__control">
                <label>
                  {" "}
                  Total Sanctioned strength of JJB staff in the district{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.sanctioned_staff_district}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      sanctioned_staff_district: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  Total Working strength of JJB staff in the district{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.working_staff_district}
                  onChange={(e) =>
                    setdata({ ...data, working_staff_district: e.target.value })
                  }
                />
                <label> Total Vacancies of JJB staff in the district </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.vaccancies_in_district}
                  onChange={(e) =>
                    setdata({ ...data, vaccancies_in_district: e.target.value })
                  }
                />
              </div>
              <br /> <br /> <br />
              <h5>
                {" "}
                d) 1. Have any effort been made to fill the existing vacancies
                in the cadre of staff of the JJB's?{" "}
              </h5>
              <div className="new-expense__control">
                <textarea
                  value={data.effort_made}
                  onChange={(e) =>
                    setdata({ ...data, effort_made: e.target.value })
                  }
                >
                  {" "}
                </textarea>
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              onClick={closeButton}
              type="button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              onClick={openForm1}
              type="button"
            >
              Save & Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Form15;
