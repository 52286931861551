import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";

// FORM 16 Institutional Care of Children in Conflict with Law	(Q)

function Form16(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { onSubmit }] = useFormStore();

  const [data, setdata] = useState({
    observation_home_district_by_state: 0,
    bservation_home_district_by_state_trough_ngo: 0,
    children_in_observation_home_in_district: 0,
    special_home_district_by_state: 0,
    special_home_district_by_state_trough_ngo: 0,
    special_in_observation_home_in_district: 0,
    place_of_saftey_in_district: 0,
    children_placed_in_place_of_saftey: 0,
  });
  const openForm1 = () => {
    props.close();
    onSubmit(data, "institutional_care");
    setmodalIsOpen(false);
  };
  const closeButton = () => {
    props.close();
    setmodalIsOpen(false);
  };
  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {" "}
              Institutional Care of Children in Conflict with Law{" "}
            </h5>
            <button
              className="btn-close"
              onClick={closeButton}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <h5>
                {" "}
                a) Observation Homes (established under section 42(1) of the JJ
                (Care and Protection of Children) Act, 2015){" "}
              </h5>
              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of Observation Homes in the district maintained by the
                  state govt itself{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.observation_home_district_by_state}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      observation_home_district_by_state: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of Observation Homes in the district maintained by the
                  state govt through voluntary or non-governmental organization{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.bservation_home_district_by_state_trough_ngo}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      bservation_home_district_by_state_trough_ngo:
                        e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of children placed in Observation Home in the district{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.children_in_observation_home_in_district}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      children_in_observation_home_in_district: e.target.value,
                    })
                  }
                />
              </div>
              <br /> <br /> <br />
              <h5>
                {" "}
                b) Special Homes (established under section 48(1) of the JJ Act,
                2015){" "}
              </h5>
              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of Special Homes in the district maintained by the state
                  govt itself{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.special_home_district_by_state}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      special_home_district_by_state: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of Special Homes in the district maintained by the state
                  govt through voluntary or non-governmental organization{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.special_home_district_by_state_trough_ngo}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      special_home_district_by_state_trough_ngo: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of children placed in Special Homes in the district{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.special_in_observation_home_in_district}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      special_in_observation_home_in_district: e.target.value,
                    })
                  }
                />
              </div>
              <br /> <br /> <br />
              <h5>
                {" "}
                c) Place of Safety (established under section 49 of the JJ Act,
                2015){" "}
              </h5>
              <div className="new-expense__control">
                <label> No. of Place of Safety in the district </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.place_of_saftey_in_district}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      place_of_saftey_in_district: e.target.value,
                    })
                  }
                />
                <label> No. of children placed in Place of Safety </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.children_placed_in_place_of_saftey}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      children_placed_in_place_of_saftey: e.target.value,
                    })
                  }
                />
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              onClick={closeButton}
              type="button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              onClick={openForm1}
              type="button"
            >
              Save & Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Form16;
