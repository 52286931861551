import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { useFormStore } from "../Store/Forms";

// FORM 15  Juvenile Justice Boards

function Report15(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{}, { downloadExcel }] = useFormStore();
  const Qyear = props.Qyear
  const Qmonth = props.Qmonth
  const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
  const DisplayQuarter = Quarter[Qmonth]


  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };
  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {" "}
            Juvenile Justice Boards{" "}
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <h4>{DisplayQuarter} {Qyear}</h4>
        <div className="modal-body">
          <div class="table-responsive">
            <h5>
              {" "}
              a) Children’s Homes (established under section 50 of the JJ Act,
              2015):-{" "}
            </h5>
            <table class="table" id="jjb">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" rowspan="2">
                    Particulars
                  </th>
                  <th scope="col">As on 31.12.21 </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col">1</th>
                  <th scope="col">No. of JJBs in the district</th>
                  <th scope="col">0</th>
                </tr>
              </tbody>
            </table>{" "}
            <br /> <br />
            <h5> b) Pendency of cases before the JJBs </h5>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" rowspan="2">
                    Particulars
                  </th>
                  <th scope="col">As on 31.12.21 </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col">1</th>
                  <th scope="col">
                    Opening balance at the start of the quarter
                  </th>
                  <th scope="col">
                    {/* {" "}
                    {
                      props.report[props.report.length - 1]
                        ?.balance_start_quarter
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">2</th>
                  <th scope="col">No of cases instituted during the quarter</th>
                  <th scope="col">
                    {/* {" "}
                    {
                      props.report[props.report.length - 1]
                        ?.cases_instituted_during_quarter
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">3</th>
                  <th scope="col">
                    No of cases disposed of during the quarter
                  </th>
                  <th scope="col">
                    {/* {" "}
                    {
                      props.report[props.report.length - 1]
                        ?.cases_disposed_during_quarter
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">4</th>
                  <th scope="col">
                    No. of cases pending at the end of the quarter
                  </th>
                  <th scope="col">
                    {/* {" "}
                    {
                      props.report[props.report.length - 1]
                        ?.pending_at_end_of_quarter
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">5</th>
                  <th scope="col">
                    No of cases pending for a period of less than 4 months at
                    the end of the quarter
                  </th>
                  <th scope="col">
                    {/* {" "}
                    {
                      props.report[props.report.length - 1]
                        ?.pending_for_period_less_4_month_end_of_quarter
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">6</th>
                  <th scope="col">
                    No of cases pending for a period of more than 4 months but
                    less than 6 months at the end of the quarter
                  </th>
                  <th scope="col">
                    {/* {" "}
                    {
                      props.report[props.report.length - 1]
                        ?.pending_for_period_more_4_month_less_6_month_end_of_quarter
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">7</th>
                  <th scope="col">
                    No of cases pending for a period of more than 6 months but
                    less than 1 year at the end of the quarter
                  </th>
                  <th scope="col">
                    {/* {" "}
                    {
                      props.report[props.report.length - 1]
                        ?.pending_for_period_more_6_month_less_1_year_end_of_quarter
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">8</th>
                  <th scope="col">
                    No of cases pending for a period of more than 1 year at the
                    end of the quarter
                  </th>
                  <th scope="col">
                    {/* {" "}
                    {
                      props.report[props.report.length - 1]
                        ?.pending_for_period_more_1_year_end_of_quarter
                    } */}
                  </th>
                </tr>
              </tbody>
            </table>{" "}
            <br /> <br />
            <h5> c) Details with regards to staff in JJBs </h5>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" rowspan="2">
                    Particulars
                  </th>
                  <th scope="col">As on 31.12.21 </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col">1</th>
                  <th scope="col">
                    Total Sanctioned strength of JJB staff in the district
                  </th>
                  <th scope="col">
                    {/* {" "}
                    {
                      props.report[props.report.length - 1]
                        ?.sanctioned_staff_district
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">2</th>
                  <th scope="col">
                    Total Working strength of JJB staff in the district
                  </th>
                  <th scope="col">
                    {/* {" "}
                    {
                      props.report[props.report.length - 1]
                        ?.working_staff_district
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">3</th>
                  <th scope="col">
                    Total Vacancies of JJB staff in the district
                  </th>
                  <th scope="col">
                    {/* {" "}
                    {
                      props.report[props.report.length - 1]
                        ?.vaccancies_in_district
                    } */}
                  </th>
                </tr>
              </tbody>
            </table>{" "}
            <br /> <br />
            <h5>
              {" "}
              d) 1. Have any effort been made to fill the existing vacancies in
              the cadre of staff of the JJB's?{" "}
            </h5>
            <h5>
              {/* {props.report[props.report.length - 1]?.effort_made} */}
            </h5>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("jjb")}
          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default Report15;
