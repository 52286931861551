import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { useFormStore } from "../Store/Forms";

// FORM 19 3. Adoption under section 56 of the JJ Act, 2015	(Q)

function Report18(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{}, { downloadExcel }] = useFormStore();

  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };

  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            Institutional Care of Children in Need of Care and Protection
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <div class="table-responsive">
            <h5>
              a) Specialised Adoption Agencies (Recognised under section 65 of
              the JJ Act, 2015)
            </h5>
            <table class="table" id="adoption">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Particulars</th>
                  <th scope="col">As on 31.12.21 </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col">1</th>
                  <th scope="col">No. of SAA in the district</th>
                  <th scope="col">
                    {props.report[props.report.length - 1]?.ssa_in_district}
                  </th>
                </tr>
              </tbody>
            </table>
            <br /> <br />
            <h5>
              b) Pendency of cases before the CWC for declaring a child free for
              Adoption
            </h5>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Particulars</th>
                  <th scope="col">As on 31.12.21 </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col">1</th>
                  <th scope="col">
                    Opening balance at the start of the quartert
                  </th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.opening_balance_in_quarter
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">2</th>
                  <th scope="col">No. of cases filed during the quarter</th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.cases_filled_during_quarter
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">1</th>
                  <th scope="col">
                    Opening balance at the start of the quartert
                  </th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.cases_disposed_during_quarter
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">3</th>
                  <th scope="col">
                    No. of cases disposed of during the quarter
                  </th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.cases_pending_end_of_quarter
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">4</th>
                  <th scope="col">
                    Total no. of cases pending at the end of the quarter
                  </th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.pending_case_age_less_2_year_end_of_quarter
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">5</th>
                  <th scope="col">
                    No. of pending cases pertaining to children below the age of
                    2 years at the end of the quarter
                  </th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.pending_case_more_2_month_age_less_2_year_end_of_quarter
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">6</th>
                  <th scope="col">
                    No. of the cases pertaining to children below the age of 2
                    years that have been pending for more than 2 months at the
                    end of the quarter (from the date of production of the child
                    before the CWC)
                  </th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.pending_case_age_above_2_year_end_of_quarter
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">7</th>
                  <th scope="col">
                    No. of pending cases pertaining to children above the age of
                    2 years at the end of the quarter
                  </th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.pending_case_more_4_month_age_more_2_year_end_of_quarter
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">8</th>
                  <th scope="col">
                    No. of the cases pertaining to children above the age of 2
                    years that have been pending for more than 4 months at the
                    end of the quarter (from the date of production of the child
                    before the CWC)
                  </th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.child_info_uploaded_to_carigs
                    }
                  </th>
                </tr>
              </tbody>
            </table>
            <br /> <br />
            <h5>
              c) Number of children in the Child Care Institution in the
              districts whose information has been uploaded to the Child
              Adoption Resource Information and Guidance System (CARIGS):-
            </h5>
            <br /> <br />
            <h5> d) Adoption Orders (as per section 61, JJ Act, 2015) </h5>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Particulars</th>
                  <th scope="col">As on 31.12.21 </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col">1</th>
                  <th scope="col">
                    Opening balance at the start of the quarter
                  </th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.adoption_order_opening_balance_start_of_quarter
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">2</th>
                  <th scope="col">No. of cases filed during the quarter</th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.adoption_order_filed_during_quarter
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">3</th>
                  <th scope="col">
                    No. of cases disposed of during the quarter
                  </th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.adoption_order_disposed_during_quarter
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">4</th>
                  <th scope="col">
                    Total no. of cases pending at the end of the quarter
                  </th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.adoption_order_pending_end_quarter
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">5</th>
                  <th scope="col">
                    No. of cases that have been pending for more than 2 months
                    at the end of the quarter
                  </th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.adoption_order_pending_more_2_month_end_quarter
                    }
                  </th>
                </tr>
              </tbody>
            </table>
            <br /> <br />
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("adoption")}
          >
            Download <i class="icofont icofont-arrow-down"></i>
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default Report18;
