import React, { useState } from 'react';
import Modal from 'react-modal';

import '../assets/css/feather-icon.css';
import '../assets/css/bootstrap.css';
import '../assets/css/responsive.css';
import '../assets/css/themify.css';
import '../assets/css/style.css';
import '../assets/css/fontawesome.css';
import '../assets/css/icofont.css';
import '../assets/css/flag-icon.css';

import './Form.css';


// FORM 3 : State sponsorship	

function Pending (props) {

  const [modalIsOpen, setmodalIsOpen] = useState(true)

  const openForm1 = () => {
    props.close();
    setmodalIsOpen(false)
  }

  return (
    <div>

      <Modal isOpen={modalIsOpen}>

        <div>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle"> State Sponsorship	</h5>
            <button className="btn-close" onClick={openForm1} type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div className="modal-body">
            <form>
              <div classNameName='new-expense__control'>
                <label> Number of children availing sponsorship	(cumulative)  </label> : <input type='number' min='0.01'  />
                
              </div>
      
              <div classNameName='new-expense__control'>
              <label> Number of children availing sponsorship (current quarter)  </label> : <input type='number' min='0.01'  />
              </div>
      
              <div classNameName='new-expense__control'>
                <label> Number of children whose scholarship was exempted  </label> : <input type='number' min='0.01' step='0.01'  />
              </div>
      
              <div classNameName='new-expense__control'>
                <label> Number of children whose scholarship was added  </label> : <input type='number' min='0.01' step='0.01'  />
              </div>
            </form>
          </div>
          
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={openForm1} type="button" data-bs-dismiss="modal">Close</button>
            <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button>
          </div>
        </div>        
        
      </Modal>
    </div>
    )
}

export default Pending;