import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";
import { Toaster, toast } from "react-hot-toast";

// FORM 2  CHILD LABOUR

function Form2(props) {

    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { onSubmit }] = useFormStore();
    const [{ }, { updateReport }] = useFormStore();
    const [{ }, { formUpdated }] = useFormStore();
    const month = props.month;
    const year = props.year;
    const Month = ["Month not selected", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const DisplayMonth = Month[month];

    const [data, setdata] = useState({
        children: 0,
        children_5_7: 0,
        children_8_11: 0,
        children_12_15: 0,
        children_16_18: 0,
        male: 0,
        female: 0,
        transgender: 0,
        hazardous_jobs: 0,
        non_hazardous_jobs: 0,
        children_in_school: 0,
        school_dropouts: 0,
        availing_child_refund: 0,
        month: month,
        year: year,
    });

    let reportSorted = {};

    useEffect(() => {
        if ((props || {}).isEdit) {
            // getReport('child_labour', month, year);
            reportSorted = props.report.find((res) => { return res.month === month && res.year === year; }) || {};
            setdata({
                ...data,
                children: reportSorted.children,
                children_5_7: reportSorted.children_5_7,
                children_8_11: reportSorted.children_8_11,
                children_12_15: reportSorted.children_12_15,
                children_16_18: reportSorted.children_16_18,
                male: reportSorted.male,
                female: reportSorted.female,
                transgender: reportSorted.transgender,
                hazardous_jobs: reportSorted.hazardous_jobs,
                children_in_school: reportSorted.children_in_school,
                school_dropouts: reportSorted.school_dropouts,
                availing_child_refund: reportSorted.availing_child_refund,
            });
        }
    }, []);

    const openForm = () => {
        props.close();
        setmodalIsOpen(true);
    };

    const openForm1 = () => {
        // props.close();
        if (props.isEdit) {
            return toast.promise(
                updateReport(data, "update_child_labour"),
                {
                    loading: 'Updating Record',
                    success: 'Record Updated',
                    error: 'Error while saving'
                }
            ).then(() => formUpdated(true));
        } else {
            onSubmit(data, "child_labour");
            setmodalIsOpen(false);
        };
    };

    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <Toaster position="bottom-right" />
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle1">
                            Child Labour

                        </h5>
                        <button
                            className="btn-close"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    <h4>{DisplayMonth} {year}</h4>
                    <div className="modal-body">
                        <form>
                            <div classNameName="new-expense__control">
                                <label> Total number of children </label> :{" "}
                                <input
                                    type="number"
                                    value={data.children}

                                    onChange={(e) =>
                                        setdata({ ...data, children: e.target.value })

                                    }
                                />

                            </div>

                            <div classNameName="new-expense__control">
                                <label> Number of children between 5-7 age group </label> :{" "}
                                <input
                                    type="number"
                                    value={data.children_5_7}
                                    onChange={(e) =>
                                        setdata({ ...data, children_5_7: e.target.value })
                                    }
                                />
                            </div>

                            <div classNameName="new-expense__control">
                                <label> Number of children between 8-11 age group </label> :{" "}
                                <input
                                    type="number"
                                    value={data.children_8_11}
                                    onChange={(e) =>
                                        setdata({ ...data, children_8_11: e.target.value })
                                    }
                                />
                            </div>

                            <div classNameName="new-expense__control">
                                <label> Number of children between 12-15 age group </label> :{" "}
                                <input
                                    type="number"
                                    value={data.children_12_15}
                                    onChange={(e) =>
                                        setdata({ ...data, children_12_15: e.target.value })
                                    }
                                />
                            </div>

                            <div classNameName="new-expense__control">
                                <label> Number of children between 16-18 age group </label> :{" "}
                                <input
                                    type="number"
                                    value={data.children_16_18}
                                    onChange={(e) =>
                                        setdata({ ...data, children_16_18: e.target.value })
                                    }
                                />
                            </div>

                            <div classNameName="new-expense__control">
                                <label> Number of males </label> :{" "}
                                <input
                                    type="number"
                                    value={data.male}
                                    onChange={(e) => setdata({ ...data, male: e.target.value })}
                                />
                            </div>

                            <div classNameName="new-expense__control">
                                <label> Number of females </label> :{" "}
                                <input
                                    type="number"
                                    value={data.female}
                                    onChange={(e) => setdata({ ...data, female: e.target.value })}
                                />
                            </div>

                            <div classNameName="new-expense__control">
                                <label> Number of transgenders </label> :{" "}
                                <input
                                    type="number"
                                    value={data.transgender}
                                    onChange={(e) =>
                                        setdata({ ...data, transgender: e.target.value })
                                    }
                                />
                            </div>

                            <div classNameName="new-expense__control">
                                <label> Number of hazardous jobs </label> :{" "}
                                <input
                                    type="number"
                                    value={data.hazardous_jobs}
                                    onChange={(e) =>
                                        setdata({ ...data, hazardous_jobs: e.target.value })
                                    }
                                />
                            </div>

                            <div classNameName="new-expense__control">
                                <label> Number of non- hazardous jobs </label> :{" "}
                                <input
                                    type="number"
                                    value={data.non_hazardous_jobs}
                                    onChange={(e) =>
                                        setdata({ ...data, non_hazardous_jobs: e.target.value })
                                    }
                                />
                            </div>

                            <div classNameName="new-expense__control">
                                <label> Number of children attending school </label> :{" "}
                                <input
                                    type="number"
                                    value={data.children_in_school}
                                    onChange={(e) =>
                                        setdata({ ...data, children_in_school: e.target.value })
                                    }
                                />
                            </div>

                            <div classNameName="new-expense__control">
                                <label> Number of school dropouts </label> :{" "}
                                <input
                                    type="number"
                                    value={data.school_dropouts}
                                    onChange={(e) =>
                                        setdata({ ...data, school_dropouts: e.target.value })
                                    }
                                />
                            </div>

                            <div classNameName="new-expense__control">
                                <label> Number of children availing child relief fund </label> :{" "}
                                <input
                                    type="number"
                                    value={data.availing_child_refund}
                                    onChange={(e) =>
                                        setdata({ ...data, availing_child_refund: e.target.value })
                                    }
                                />
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={openForm1}
                            type="button"
                        >
                            Save & Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Form2;