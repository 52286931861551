import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";

// FORM 25 PARENTING CLINIC REPORTING FORMAT

function AReport25(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { downloadExcel }] = useFormStore();
  const year = props.year
  const month = props.month
  const Month = ["Month not selected", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const DisplayMonth = Month[month]


  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };
  // object assigned to a variable to obtain total
  let newArray = props.report.filter(function (d) {
    return d.year == year && d.month == month;
  })

  // sum_array = total sum data of district
  const sum_array = newArray.reduce((sum_array, value) => {
    for (const [key, count] of Object.entries(value)) {
      if (!sum_array[key]) {
        sum_array[key] = 0;
      }
      sum_array[key] += count;
    }
    return sum_array;
  }, {});

  let newArrayCumulative = props.report.filter(function (d) {
    return d.year == year && d.month <= month;
  })

  let result = [];

  newArrayCumulative.forEach(function (a) {
    if (!this[a.username]) {
      this[a.username] = {
        username: a.username, clients_attended_the_clinic_during_the_month: 0,
        new_cases_during_the_month: 0,
        follow_up_cases_during_the_month: 0,
        parents_attended_on_the_day: 0,
        children_attended_on_the_day: 0,
        behavioural_on_the_day: 0,
        emotional_on_the_day: 0,
        somatic_on_the_day: 0,
        interpersonal_on_the_day: 0,
        cognitive_on_the_day: 0,
        family_discord_on_the_day: 0,
        referrals_done_counsellor_on_the_day: 0,
        walk_ins_on_the_day: 0,
        referral_cases_on_the_day: 0,
        awws_on_the_day: 0,
        teachers_on_the_day: 0,
        police_on_the_day: 0,
        asha_workers_on_the_day: 0,
        local_leaders_on_the_day: 0,
        others_on_the_day: 0,
      };
      result.push(this[a.username]);
    }
    this[a.username].clients_attended_the_clinic_during_the_month += a.clients_attended_the_clinic_during_the_month;
    this[a.username].new_cases_during_the_month += a.new_cases_during_the_month;
    this[a.username].follow_up_cases_during_the_month += a.follow_up_cases_during_the_month;
    this[a.username].parents_attended_on_the_day += a.parents_attended_on_the_day;
    this[a.username].children_attended_on_the_day += a.children_attended_on_the_day;
    this[a.username].behavioural_on_the_day += a.behavioural_on_the_day;
    this[a.username].emotional_on_the_day += a.emotional_on_the_day;
    this[a.username].somatic_on_the_day += a.somatic_on_the_day;
    this[a.username].interpersonal_on_the_day += a.interpersonal_on_the_day;
    this[a.username].cognitive_on_the_day += a.cognitive_on_the_day;
    this[a.username].family_discord_on_the_day += a.family_discord_on_the_day;
    this[a.username].referrals_done_counsellor_on_the_day += a.referrals_done_counsellor_on_the_day;
    this[a.username].walk_ins_on_the_day += a.walk_ins_on_the_day;
    this[a.username].referral_cases_on_the_day += a.referral_cases_on_the_day;
    this[a.username].awws_on_the_day += a.awws_on_the_day;
    this[a.username].teachers_on_the_day += a.teachers_on_the_day;
    this[a.username].police_on_the_day += a.police_on_the_day;
    this[a.username].asha_workers_on_the_day += a.asha_workers_on_the_day;
    this[a.username].local_leaders_on_the_day += a.local_leaders_on_the_day;
    this[a.username].others_on_the_day += a.others_on_the_day;
  }, Object.create(null));

  // console.log(result);
  for (let object of newArray) {
    for (let i of result) {
      if (object.username === i.username) {
        object.clients_attended_the_clinic_cumulative = i.clients_attended_the_clinic_during_the_month
        object.new_cases_cumulative = i.new_cases_during_the_month
        object.follow_up_cases_cumulative = i.follow_up_cases_during_the_month
        object.parents_attended_cumulative = i.parents_attended_on_the_day
        object.children_attended_cumulative = i.children_attended_on_the_day
        object.behavioural_cumulative = i.behavioural_on_the_day
        object.emotional_cumulative = i.emotional_on_the_day
        object.somatic_cumulative = i.somatic_on_the_day
        object.interpersonal_cumulative = i.interpersonal_on_the_day
        object.cognitive_cumulative = i.cognitive_on_the_day
        object.family_discord_cumulative = i.family_discord_on_the_day
        object.referrals_done_counsellor_cumulative = i.referrals_done_counsellor_on_the_day
        object.walk_ins_cumulative = i.walk_ins_on_the_day
        object.referral_cases_cumulative = i.referral_cases_on_the_day
        object.awws_cumulative = i.awws_on_the_day
        object.teachers_cumulative = i.teachers_on_the_day
        object.police_cumulative = i.police_on_the_day
        object.asha_workers_cumulative = i.asha_workers_on_the_day
        object.local_leaders_cumulative = i.local_leaders_on_the_day
        object.others_cumulative = i.others_on_the_day
      }
    }
  }
  function createPDF() {
    var sTable = document.getElementById('table').innerHTML;
    var style = "<style>";
    style = style + "table {width: 100%;font: 10px Calibri;}";
    style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "</style>";
    var win = window.open('', '', 'height=700,width=700');
    win.document.write('<html><head>');
    win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
    win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
    win.document.write('</head>');
    win.document.write('<body>');
    win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
    win.document.write('</body></html>');
    win.document.close(); 	// CLOSE THE CURRENT WINDOW.
    win.print();    // PRINT THE CONTENTS.
  }
  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {" "}
            Information on Parenting Clinic{" "}
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <h4>{DisplayMonth} {year}</h4>
        <div className="modal-body">
          <div class="table-responsive" id="table" height="100%" width="100%" media="print">
            <table class="table" id="parenting_clinic">
              <thead>
                <tr>
                  <th scope="col" rowspan="3">
                    #
                  </th>
                  <th scope="col" rowspan="3">
                    District
                  </th>
                  <th scope="col" rowspan="2" colspan="2">
                    No. of Clients attended the clinic
                  </th>
                  <th scope="col" rowspan="2" colspan="2">
                    {" "}
                    New cases
                  </th>
                  <th scope="col" rowspan="2" colspan="2">
                    {" "}
                    Follow up cases
                  </th>
                  <th scope="col" rowspan="2" colspan="2">
                    {" "}
                    No of parents attended{" "}
                  </th>
                  <th scope="col" rowspan="2" colspan="2">
                    {" "}
                    No of children attended{" "}
                  </th>
                  <th scope="col" colspan="12">
                    Issues identified{" "}
                  </th>
                  <th scope="col" rowspan="2" colspan="2">
                    No of referrals done by the counsellor to DRC or others{" "}
                  </th>
                  <th scope="col" rowspan="2" colspan="2">
                    {" "}
                    No of walk ins (cases who came without referral){" "}
                  </th>
                  <th scope="col" rowspan="2" colspan="2">
                    No of referral cases{" "}
                  </th>
                  <th scope="col" colspan="12">
                    Referred by{" "}
                  </th>
                </tr>

                <tr>
                  <th scope="col" colspan="2">
                    Behavioural{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Emotional{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Somatic (physical problems){" "}
                  </th>
                  <th scope="col" colspan="2">
                    Interpersonal (love, relationships etc){" "}
                  </th>
                  <th scope="col" colspan="2">
                    Cognitive (thinking learning related){" "}
                  </th>
                  <th scope="col" colspan="2">
                    Family discord{" "}
                  </th>

                  <th scope="col" colspan="2">
                    AWWs{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Teachers{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Police{" "}
                  </th>
                  <th scope="col" colspan="2">
                    ASHA workers{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Local leaders/elected representatives{" "}
                  </th>
                  <th scope="col" colspan="2">
                    Others (please specify){" "}
                  </th>
                </tr>

                <tr>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                  <th scope="col"> During the month </th>
                  <th scope="col"> Cumulative </th>
                </tr>
              </thead>

              <tbody>
                {newArray.map((r) => (
                  <tr key={r?.id}>
                    <th scope="row"></th>
                    <td>{r?.username}</td>
                    <td>{r?.clients_attended_the_clinic_during_the_month}</td>
                    <td>{r?.clients_attended_the_clinic_cumulative}</td>
                    <td>{r?.new_cases_during_the_month}</td>
                    <td>{r?.new_cases_cumulative}</td>
                    <td>{r?.follow_up_cases_during_the_month}</td>
                    <td>{r?.follow_up_cases_cumulative}</td>
                    <td>{r?.parents_attended_on_the_day}</td>
                    <td>{r?.parents_attended_cumulative}</td>
                    <td>{r?.children_attended_on_the_day}</td>
                    <td>{r?.children_attended_cumulative}</td>
                    <td>{r?.behavioural_on_the_day}</td>
                    <td>{r?.behavioural_cumulative}</td>
                    <td>{r?.emotional_on_the_day}</td>
                    <td>{r?.emotional_cumulative}</td>
                    <td>{r?.somatic_on_the_day}</td>
                    <td>{r?.somatic_cumulative}</td>
                    <td>{r?.interpersonal_on_the_day}</td>
                    <td>{r?.interpersonal_cumulative}</td>
                    <td>{r?.cognitive_on_the_day}</td>
                    <td>{r?.cognitive_cumulative}</td>
                    <td>{r?.family_discord_on_the_day}</td>
                    <td>{r?.family_discord_cumulative}</td>
                    <td>{r?.referrals_done_counsellor_on_the_day}</td>
                    <td>{r?.referrals_done_counsellor_cumulative}</td>
                    <td>{r?.walk_ins_on_the_day}</td>
                    <td>{r?.walk_ins_cumulative}</td>
                    <td>{r?.referral_cases_on_the_day}</td>
                    <td>{r?.referral_cases_cumulative}</td>
                    <td>{r?.awws_on_the_day}</td>
                    <td>{r?.awws_cumulative}</td>
                    <td>{r?.teachers_on_the_day}</td>
                    <td>{r?.teachers_cumulative}</td>
                    <td>{r?.police_on_the_day}</td>
                    <td>{r?.police_cumulative}</td>
                    <td>{r?.asha_workers_on_the_day}</td>
                    <td>{r?.asha_workers_cumulative}</td>
                    <td>{r?.local_leaders_on_the_day}</td>
                    <td>{r?.local_leaders_cumulative}</td>
                    <td>{r?.others_on_the_day}</td>
                    <td>{r?.others_cumulative}</td>
                  </tr>
                ))}
                <tr>
                  <th scope="row"></th>
                  <td>Total</td>
                  <td>{sum_array.clients_attended_the_clinic_during_the_month}</td>
                  <td>{sum_array.clients_attended_the_clinic_cumulative}</td>
                  <td>{sum_array.new_cases_during_the_month}</td>
                  <td>{sum_array.new_cases_cumulative}</td>
                  <td>{sum_array.follow_up_cases_during_the_month}</td>
                  <td>{sum_array.follow_up_cases_cumulative}</td>
                  <td>{sum_array.parents_attended_on_the_day}</td>
                  <td>{sum_array.parents_attended_cumulative}</td>
                  <td>{sum_array.children_attended_on_the_day}</td>
                  <td>{sum_array.children_attended_cumulative}</td>
                  <td>{sum_array.behavioural_on_the_day}</td>
                  <td>{sum_array.behavioural_cumulative}</td>
                  <td>{sum_array.emotional_on_the_day}</td>
                  <td>{sum_array.emotional_cumulative}</td>
                  <td>{sum_array.somatic_on_the_day}</td>
                  <td>{sum_array.somatic_cumulative}</td>
                  <td>{sum_array.interpersonal_on_the_day}</td>
                  <td>{sum_array.interpersonal_cumulative}</td>
                  <td>{sum_array.cognitive_on_the_day}</td>
                  <td>{sum_array.cognitive_cumulative}</td>
                  <td>{sum_array.family_discord_on_the_day}</td>
                  <td>{sum_array.family_discord_cumulative}</td>
                  <td>{sum_array.referrals_done_counsellor_on_the_day}</td>
                  <td>{sum_array.referrals_done_counsellor_cumulative}</td>
                  <td>{sum_array.walk_ins_on_the_day}</td>
                  <td>{sum_array.walk_ins_cumulative}</td>
                  <td>{sum_array.referral_cases_on_the_day}</td>
                  <td>{sum_array.referral_cases_cumulative}</td>
                  <td>{sum_array.awws_on_the_day}</td>
                  <td>{sum_array.awws_cumulative}</td>
                  <td>{sum_array.teachers_on_the_day}</td>
                  <td>{sum_array.teachers_cumulative}</td>
                  <td>{sum_array.police_on_the_day}</td>
                  <td>{sum_array.police_cumulative}</td>
                  <td>{sum_array.asha_workers_on_the_day}</td>
                  <td>{sum_array.asha_workers_cumulative}</td>
                  <td>{sum_array.local_leaders_on_the_day}</td>
                  <td>{sum_array.local_leaders_cumulative}</td>
                  <td>{sum_array.others_on_the_day}</td>
                  <td>{sum_array.others_cumulative}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("parenting_clinic")}
          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-primary"
            onClick={createPDF}
            type="button"
            data-bs-dismiss="modal"
          >
            Download as Pdf
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default AReport25;
