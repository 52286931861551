import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";

// FORM 17 1. Child Welfare Committees (Constituted under section 27(1) of the JJ Act, 2015):-

function Form17(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { onSubmit }] = useFormStore();

  const [data, setdata] = useState({
    cwc_in_district: 0,
    no_cwc_in_district: 0,
  });

  const openForm1 = () => {
    props.close();
    onSubmit(data, "cwc");
    setmodalIsOpen(false);
  };
  const closeButton = () => {
    props.close();
    setmodalIsOpen(false);
  };
  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {" "}
              Child Welfare Committees{" "}
            </h5>
            <button
              className="btn-close"
              onClick={closeButton}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <div className="new-expense__control">
                <label> No. of CWCs in the district </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.cwc_in_district}
                  onChange={(e) =>
                    setdata({ ...data, cwc_in_district: e.target.value })
                  }
                />
                <label>
                  {" "}
                  No. of Districts in the district where no CWC is operating{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.no_cwc_in_district}
                  onChange={(e) =>
                    setdata({ ...data, no_cwc_in_district: e.target.value })
                  }
                />
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              onClick={closeButton}
              type="button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              onClick={openForm1}
              type="button"
            >
              Save & Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Form17;
