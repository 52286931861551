import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";
import Form25 from "./Form25";

// FORM 25 PARENTING CLINIC REPORTING FORMAT

function Report25(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [isParentalClinic, setIsParentalClinic] = useState(false);
    const [{ }, { downloadExcel }] = useFormStore();

    const openForm1 = () => {
        setmodalIsOpen(false);
        props.close();
    };

    const year = props.year;
    const month = props.month;
    const Month = ["Month not selected", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const DisplayMonth = Month[month];
    let newArray = props.report.filter(function (d) {
        return d.year == year && d.month == month;
    });

    // cumulative function
    let Cmonth = props.report.filter(function (d) {
        return d.year == year && d.month <= month;
    });

    const cumulative = Cmonth.reduce((cumulative, value) => {
        for (const [key, count] of Object.entries(value)) {
            if (!cumulative[key]) {
                cumulative[key] = 0;
            }
            cumulative[key] += count;
        }
        return cumulative;
    }, {});
    //---------------

    function createPDF() {
        var sTable = document.getElementById('table').innerHTML;
        var style = "<style>";
        style = style + "table {width: 100%;font: 10px Calibri;}";
        style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "</style>";
        var win = window.open('', '', 'height=700,width=700');
        win.document.write('<html><head>');
        win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
        win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
        win.document.write('</body></html>');
        win.document.close(); 	// CLOSE THE CURRENT WINDOW.
        win.print();    // PRINT THE CONTENTS.
    }

    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                        {" "}
                        Information on Parenting Clinic{" "}
                    </h5>
                    <button
                        className="btn-close"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <h4>{DisplayMonth} {year}</h4>
                <div className="modal-body">
                    <div class="table-responsive" id="table" height="100%" width="100%" media="print">
                        <table class="table" id="parenting_clinic">
                            <thead>
                                <tr>
                                    <th scope="col" rowspan="3">
                                        #
                                    </th>
                                    <th scope="col" rowspan="3">
                                        District
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        No. of Clients attended the clinic
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        {" "}
                                        New cases
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        {" "}
                                        Follow up cases
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        {" "}
                                        No of parents attended{" "}
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        {" "}
                                        No of children attended{" "}
                                    </th>
                                    <th scope="col" colspan="12">
                                        Issues identified{" "}
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        No of referrals done by the counsellor to DRC or others{" "}
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        {" "}
                                        No of walk ins (cases who came without referral){" "}
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        No of referral cases{" "}
                                    </th>
                                    <th scope="col" colspan="12">
                                        Referred by{" "}
                                    </th>
                                </tr>

                                <tr>
                                    <th scope="col" colspan="2">
                                        Behavioural{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Emotional{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Somatic (physical problems){" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Interpersonal (love, relationships etc){" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Cognitive (thinking learning related){" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Family discord{" "}
                                    </th>

                                    <th scope="col" colspan="2">
                                        AWWs{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Teachers{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Police{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        ASHA workers{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Local leaders/elected representatives{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Others (please specify){" "}
                                    </th>
                                </tr>

                                <tr>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                    <th scope="col"> During the month </th>
                                    <th scope="col"> Cumulative </th>
                                </tr>
                            </thead>

                            <tbody>
                                {newArray.map((r) => (
                                    <tr key={r?.id}>
                                        <th scope="row">{r?.id}</th>
                                        <td>{JSON.parse(getStorage("user")).username}</td>
                                        <td>{r?.clients_attended_the_clinic_during_the_month}</td>
                                        <td>{cumulative.clients_attended_the_clinic_during_the_month}</td>
                                        <td>{r?.new_cases_during_the_month}</td>
                                        <td>{cumulative.new_cases_during_the_month}</td>
                                        <td>{r?.follow_up_cases_during_the_month}</td>
                                        <td>{cumulative.follow_up_cases_during_the_month}</td>
                                        <td>{r?.parents_attended_on_the_day}</td>
                                        <td>{cumulative.parents_attended_on_the_day}</td>
                                        <td>{r?.children_attended_on_the_day}</td>
                                        <td>{cumulative.children_attended_on_the_day}</td>
                                        <td>{r?.behavioural_on_the_day}</td>
                                        <td>{cumulative.behavioural_on_the_day}</td>
                                        <td>{r?.emotional_on_the_day}</td>
                                        <td>{cumulative.emotional_on_the_day}</td>
                                        <td>{r?.somatic_on_the_day}</td>
                                        <td>{cumulative.somatic_on_the_day}</td>
                                        <td>{r?.interpersonal_on_the_day}</td>
                                        <td>{cumulative.interpersonal_on_the_day}</td>
                                        <td>{r?.cognitive_on_the_day}</td>
                                        <td>{cumulative.cognitive_on_the_day}</td>
                                        <td>{r?.family_discord_on_the_day}</td>
                                        <td>{cumulative.family_discord_on_the_day}</td>
                                        <td>{r?.referrals_done_counsellor_on_the_day}</td>
                                        <td>{cumulative.referrals_done_counsellor_on_the_day}</td>
                                        <td>{r?.walk_ins_on_the_day}</td>
                                        <td>{cumulative.walk_ins_on_the_day}</td>
                                        <td>{r?.referral_cases_on_the_day}</td>
                                        <td>{cumulative.referral_cases_on_the_day}</td>
                                        <td>{r?.awws_on_the_day}</td>
                                        <td>{cumulative.awws_on_the_day}</td>
                                        <td>{r?.teachers_on_the_day}</td>
                                        <td>{cumulative.teachers_on_the_day}</td>
                                        <td>{r?.police_on_the_day}</td>
                                        <td>{cumulative.police_on_the_day}</td>
                                        <td>{r?.asha_workers_on_the_day}</td>
                                        <td>{cumulative.asha_workers_on_the_day}</td>
                                        <td>{r?.local_leaders_on_the_day}</td>
                                        <td>{cumulative.local_leaders_on_the_day}</td>
                                        <td>{r?.others_on_the_day}</td>
                                        <td>{cumulative.others_on_the_day}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="modal-footer">
                    <button
                        className="btn btn-primary"
                        onClick={() => setIsParentalClinic(true)}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                        type="button"
                    >
                        Edit
                    </button>
                    <button
                        className="btn btn-primary"
                        type="button"
                        data-bs-dismiss="modal"
                        onClick={() => downloadExcel("parenting_clinic")}
                    >
                        {" "}
                        Download <i class="icofont icofont-arrow-down"></i>{" "}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={createPDF}
                        type="button"
                        data-bs-dismiss="modal"
                    >
                        Download as Pdf
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
                </div>
            </Modal>
            {isParentalClinic && (
                <Form25
                    isEdit
                    close={() => setIsParentalClinic(false)}
                    report={props.report} month={month}
                    year={year}
                />
            )}
        </div>
    );
}

export default Report25;
