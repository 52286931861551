import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";
import { useEffect } from "react";
import { UPDATE_CASESCWC } from "../utils/constant";
import { Toaster, toast } from "react-hot-toast";

// FORM 12 JJA

function Form12(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { onSubmit }] = useFormStore();
    const [{ }, { updateReport }] = useFormStore();
    const [{ }, { formUpdated }] = useFormStore();
    const Qyear = props.Qyear;
    const Qmonth = props.Qmonth;
    const Quarter = ["Date not selected", "January-March", "April-June", "July-September", "October-December"];
    const DisplayQuarter = Quarter[Qmonth];

    const [data, setdata] = useState({
        //jja data
        jja_total_cases_cumulative: 0,
        jja_total_cases_current_quarter: 0,
        jja_fir_registered_cumulative: 0,
        jja_fir_registered_current_quarter: 0,
        jja_produced_before_cwc_cumulative: 0,
        jja_produced_before_cwc_current_quarter: 0,
        jja_who_avail_service_support_person_cumulative: 0,
        jja_who_avail_service_support_person_current_quarter: 0,
        jja_expert_service_cumulative: 0,
        jja_expert_service_current_quarter: 0,
        jja_counselling_cumulative: 0,
        jja_counselling_current_quarter: 0,
        jja_free_legal_aid_cumulative: 0,
        jja_free_legal_aid_current_quarter: 0,
        jja_victim_compensation_cumulative: 0,
        jja_victim_compensation_current_quarter: 0,
        jja_institutional_support_cumulative: 0,
        jja_institutional_support_current_quarter: 0,
        // posco data
        pocso_total_cases_cumulative: 0,
        pocso_total_cases_current_quarter: 0,
        pocso_fir_registered_cumulative: 0,
        pocso_fir_registered_current_quarter: 0,
        pocso_produced_before_cwc_cumulative: 0,
        pocso_produced_before_cwc_current_quarter: 0,
        pocso_who_avail_service_support_person_cumulative: 0,
        pocso_who_avail_service_support_person_current_quarter: 0,
        pocso_expert_service_cumulative: 0,
        pocso_expert_service_current_quarter: 0,
        pocso_counselling_cumulative: 0,
        pocso_counselling_current_quarter: 0,
        pocso_free_legal_aid_cumulative: 0,
        pocso_free_legal_aid_current_quarter: 0,
        pocso_victim_compensation_cumulative: 0,
        pocso_victim_compensation_current_quarter: 0,
        pocso_institutional_support_cumulative: 0,
        pocso_institutional_support_current_quarter: 0,
        month: Qmonth,
        year: Qyear,
    });

    useEffect(() => {
        if ((props || {}).isEdit) {
            const { id, created_at, updated_at, username, user, report_type, ...reportSorted } = props.report.find((res) => { return res.month === Qmonth && res.year === Qyear; }) || {};
            setdata({
                ...data,
                ...reportSorted
            });
        }
    }, []);

    const openForm1 = () => {
        if (props.isEdit) {
            return toast.promise(
                updateReport(data, UPDATE_CASESCWC),
                {
                    loading: 'Updating Record',
                    success: 'Record Updated',
                    error: 'Error while saving'
                }
            ).then(() => formUpdated(true));
        } else {
            props.close();
            onSubmit(data, "cwc");
            setmodalIsOpen(false);
        }
    };

    const closeButton = () => {
        props.close();
        setmodalIsOpen(false);
    };
    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <Toaster position="bottom-right" />
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            Details of cases produced before CWC
                        </h5>
                        <button
                            className="btn-close"
                            onClick={closeButton}
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <h4>{DisplayQuarter} {Qyear}</h4>
                    <div className="modal-body">
                        <form>
                            <h4 className="text-secondary">JJA</h4>

                            <div className="new-expense__control">
                                <label> Total no:of cases </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.jja_total_cases_cumulative
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      jja_total_cases_cumulative:
                        e.target.value,
                    })
                  }
                /> */}
                                {/* <input
                  type="number"
                  min="1"
                  value={data.jja_total_cases_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, total_cases_cumulative: e.target.value })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={
                                        data.jja_total_cases_current_quarter
                                    }
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            jja_total_cases_current_quarter:
                                                e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> FIR Registered </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.jja_fir_registered_cumulative
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      jja_fir_registered_cumulative:
                        e.target.value,
                    })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={
                                        data.jja_fir_registered_current_quarter
                                    }
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            jja_fir_registered_current_quarter:
                                                e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Produced before CWC </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.jja_produced_before_cwc_cumulative
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      jja_produced_before_cwc_cumulative:
                        e.target.value,
                    })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={
                                        data.jja_produced_before_cwc_current_quarter
                                    }
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            jja_produced_before_cwc_current_quarter:
                                                e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> No. of children who availed the services </label>
                                <label> Support person </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.jja_who_avail_service_support_person_cumulative
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      jja_who_avail_service_support_person_cumulative:
                        e.target.value,
                    })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={
                                        data.jja_who_avail_service_support_person_current_quarter
                                    }
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            jja_who_avail_service_support_person_current_quarter:
                                                e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Expert service </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.jja_expert_service_cumulative
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      jja_expert_service_cumulative:
                        e.target.value,
                    })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={
                                        data.jja_expert_service_current_quarter
                                    }
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            jja_expert_service_current_quarter:
                                                e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Counselling </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.jja_counselling_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      jja_counselling_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.jja_counselling_current_quarter}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            jja_counselling_current_quarter: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Free legal aid </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.jja_free_legal_aid_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      jja_free_legal_aid_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.jja_free_legal_aid_current_quarter}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            jja_free_legal_aid_current_quarter: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Victim compensation </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.jja_victim_compensation_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      jja_victim_compensation_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.jja_victim_compensation_current_quarter}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            jja_victim_compensation_current_quarter: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Institutional support </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.jja_institutional_support_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      jja_institutional_support_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.jja_institutional_support_current_quarter}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            jja_institutional_support_current_quarter: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            {/* posco */}
                            <h4 className="text-secondary">POCSO</h4>

                            <div className="new-expense__control">
                                <label> Total no:of cases </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.pocso_total_cases_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pocso_total_cases_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.pocso_total_cases_current_quarter}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            pocso_total_cases_current_quarter: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> FIR Registered </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.pocso_fir_registered_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pocso_fir_registered_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.pocso_fir_registered_current_quarter}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            pocso_fir_registered_current_quarter: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Produced before CWC </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.pocso_produced_before_cwc_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pocso_produced_before_cwc_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.pocso_produced_before_cwc_current_quarter}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            pocso_produced_before_cwc_current_quarter: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> No. of children who availed the services </label>
                                <label> Support person </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.pocso_who_avail_service_support_person_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pocso_who_avail_service_support_person_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.pocso_who_avail_service_support_person_current_quarter}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            pocso_who_avail_service_support_person_current_quarter: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Expert service </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.pocso_expert_service_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pocso_expert_service_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.pocso_expert_service_current_quarter}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            pocso_expert_service_current_quarter: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Counselling </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.pocso_counselling_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pocso_counselling_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.pocso_counselling_current_quarter}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            pocso_counselling_current_quarter: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Free legal aid </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.pocso_free_legal_aid_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pocso_free_legal_aid_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.pocso_free_legal_aid_current_quarter}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            pocso_free_legal_aid_current_quarter: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Victim compensation </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.pocso_victim_compensation_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pocso_victim_compensation_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.pocso_victim_compensation_current_quarter}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            pocso_victim_compensation_current_quarter: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Institutional support </label>
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.pocso_institutional_support_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pocso_institutional_support_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.pocso_institutional_support_current_quarter}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            pocso_institutional_support_current_quarter: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={closeButton}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={openForm1}
                            type="button"
                        >
                            Save & Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Form12;
