import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { useFormStore } from "../Store/Forms";

// FORM 20 4. Institutional Mechanisms for protection of children	(Q)

function Report20(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{}, { downloadExcel }] = useFormStore();

  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };

  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            Institutional Mechanisms for protection of children
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <div class="table-responsive">
            <h5>
              a) Has a Child Protection Society been constituted for the
              district as directed by section 106 of the JJ Act, 2015? :-
            </h5>
            <br /> <br />
            <h5>b) Details of officers/staffs of the DCPU’s </h5>
            <table class="table" id="child_protection">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Particulars</th>
                  <th scope="col">As on 31.12.21 </th>
                  <th> </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col" rowspan="3">
                    1
                  </th>
                  <th scope="col" rowspan="3">
                    Full- time staff in the DCPU
                  </th>
                  <th scope="col">Sanctioned strength</th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.full_time_sanctioned_strength_dcpu
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">Working strength</th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.full_time_working_strength_dcpu
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">Vacancies</th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.full_time_vacancies_dcpu
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col" rowspan="3">
                    2
                  </th>
                  <th scope="col" rowspan="3">
                    Part-time staff in the DCPU
                  </th>
                  <th scope="col">Sanctioned strength</th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.part_time_sanctioned_strength_dcpu
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">Working strength</th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.part_time_working_strength_dcpu
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">Vacancies</th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.part_time_vacancies_dcpu
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">3</th>
                  <th scope="col">
                    No. of staff given Additional Charge of Children's Homes
                  </th>
                  <th> </th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.additional_charge_of_child_home
                    }
                  </th>
                </tr>
              </tbody>
            </table>
            <br /> <br />
            <h5>
              c) Number of Child Welfare Police Officers in the districts
              (designated under section 107(1) of the JJ Act, 2015):-
            </h5>
            <br /> <br />
            <h5>
              d) Special Juvenile Police Units (Constituted under section 107
              (2) of the JJ Act, 2015)
            </h5>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Particulars</th>
                  <th scope="col">As on 31.12.21 </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col">1</th>
                  <th scope="col">No. of SJPUs in the district</th>
                  <th scope="col">
                    {props.report[props.report.length - 1]?.sjpu_in_state}
                  </th>
                </tr>

                <tr>
                  <th scope="col">2</th>
                  <th scope="col">
                    No. of Police Officers in the district designated as members
                    of SJPUs
                  </th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.police_in_state_designated_sjpu
                    }
                  </th>
                </tr>

                <tr>
                  <th scope="col">3</th>
                  <th scope="col">
                    No. of members of the SJPUs who have been provided induction
                    training
                  </th>
                  <th scope="col">
                    {
                      props.report[props.report.length - 1]
                        ?.member_of_sjpu_provided_induction_trainng
                    }
                  </th>
                </tr>
              </tbody>
            </table>
            <br /> <br />
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("child_protection")}
          >
            Download <i class="icofont icofont-arrow-down"></i>
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default Report20;
