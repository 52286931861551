import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";
import { Toaster, toast } from "react-hot-toast";

// FORM 25 PARENTING CLINIC REPORTING FORMAT

function Form25(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { onSubmit }] = useFormStore();
    const [{ }, { updateReport }] = useFormStore();
    const [{ }, { formUpdated }] = useFormStore();
    const month = props.month;
    const year = props.year;
    const Month = ["Month not selected", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const DisplayMonth = Month[month];

    const [data, setdata] = useState({
        clients_attended_the_clinic_during_the_month: 0,
        clients_attended_the_clinic_cumulative: 0,
        new_cases_during_the_month: 0,
        new_cases_cumulative: 0,
        follow_up_cases_during_the_month: 0,
        follow_up_cases_cumulative: 0,
        parents_attended_cumulative: 0,
        parents_attended_on_the_day: 0,
        children_attended_cumulative: 0,
        children_attended_on_the_day: 0,
        behavioural_cumulative: 0,
        behavioural_on_the_day: 0,
        emotional_cumulative: 0,
        emotional_on_the_day: 0,
        somatic_cumulative: 0,
        somatic_on_the_day: 0,
        interpersonal_cumulative: 0,
        interpersonal_on_the_day: 0,
        cognitive_cumulative: 0,
        cognitive_on_the_day: 0,
        family_discord_cumulative: 0,
        family_discord_on_the_day: 0,
        referrals_done_counsellor_cumulative: 0,
        referrals_done_counsellor_on_the_day: 0,
        walk_ins_cumulative: 0,
        walk_ins_on_the_day: 0,
        referral_cases_cumulative: 0,
        referral_cases_on_the_day: 0,
        awws_cumulative: 0,
        awws_on_the_day: 0,
        teachers_cumulative: 0,
        teachers_on_the_day: 0,
        police_cumulative: 0,
        police_on_the_day: 0,
        asha_workers_cumulative: 0,
        asha_workers_on_the_day: 0,
        local_leaders_cumulative: 0,
        local_leaders_on_the_day: 0,
        others_cumulative: 0,
        others_on_the_day: 0,
        month: month,
        year: year,
    });

    useEffect(() => {
        if ((props || {}).isEdit) {
            const { id, created_at, updated_at, username, user, report_type, ...reportSorted } = props.report.find((res) => { return res.month === month && res.year === year; }) || {};
            setdata({
                ...data,
                ...reportSorted
            });
        }
    }, []);

    const openForm1 = () => {
        if (props.isEdit) {
            return toast.promise(
                updateReport(data, "update_parenting_clinic"),
                {
                    loading: 'Updating Record',
                    success: 'Record Updated',
                    error: 'Error while saving'
                }
            ).then(() => formUpdated(true));
        } else {
            onSubmit(data, "parenting_clinic");
            setmodalIsOpen(false);
        }
    };

    const openForm = () => {
        props.close();
        setmodalIsOpen(false);
    };

    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <Toaster position="bottom-right" />
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {" "}
                            Information on Parenting Clinic{" "}
                        </h5>
                        <button
                            className="btn-close"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    <h4>{DisplayMonth} {year}</h4>
                    <div className="modal-body">
                        <form>
                            <label>Number of clients attended the clinic</label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.clients_attended_the_clinic_during_the_month}
                                    onChange={(e) =>
                                        setdata({ ...data, clients_attended_the_clinic_during_the_month: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.clients_attended_the_clinic_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, clients_attended_the_clinic_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label>New Cases</label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.new_cases_during_the_month}
                                    onChange={(e) =>
                                        setdata({ ...data, new_cases_during_the_month: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.new_cases_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, new_cases_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label>Follow up Cases</label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.follow_up_cases_during_the_month}
                                    onChange={(e) =>
                                        setdata({ ...data, follow_up_cases_during_the_month: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.follow_up_cases_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, follow_up_cases_cumulative: e.target.value })
                  }
                /> */}
                            </div>
                            <label> No of parents attended </label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.parents_attended_on_the_day}
                                    onChange={(e) =>
                                        setdata({ ...data, parents_attended_on_the_day: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.parents_attended_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, parents_attended_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label> No of children attended </label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.children_attended_on_the_day}
                                    onChange={(e) =>
                                        setdata({ ...data, children_attended_on_the_day: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.children_attended_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, children_attended_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label>
                                {" "}
                                <b> Issues identified </b>{" "}
                            </label>
                            <label> Behavioural </label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.behavioural_on_the_day}
                                    onChange={(e) =>
                                        setdata({ ...data, behavioural_on_the_day: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.behavioural_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, behavioural_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label> Emotional </label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.emotional_on_the_day}
                                    onChange={(e) =>
                                        setdata({ ...data, emotional_on_the_day: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.emotional_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, emotional_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label> Somatic (physical problems) </label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.somatic_on_the_day}
                                    onChange={(e) =>
                                        setdata({ ...data, somatic_on_the_day: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.somatic_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, somatic_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label> Interpersonal (love, relationships etc) </label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.interpersonal_on_the_day}
                                    onChange={(e) =>
                                        setdata({ ...data, interpersonal_on_the_day: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.interpersonal_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, interpersonal_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label> Cognitive (thinking learning related) </label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.cognitive_on_the_day}
                                    onChange={(e) =>
                                        setdata({ ...data, cognitive_on_the_day: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.cognitive_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, cognitive_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label> Family discord </label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.family_discord_on_the_day}
                                    onChange={(e) =>
                                        setdata({ ...data, family_discord_on_the_day: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.family_discord_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, family_discord_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label>
                                {" "}
                                No of referrals done by the counsellor to DRC or others{" "}
                            </label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.referrals_done_counsellor_on_the_day}
                                    onChange={(e) =>
                                        setdata({ ...data, referrals_done_counsellor_on_the_day: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.referrals_done_counsellor_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, referrals_done_counsellor_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label> No of walk ins (cases who came without referral) </label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.walk_ins_on_the_day}
                                    onChange={(e) =>
                                        setdata({ ...data, walk_ins_on_the_day: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.walk_ins_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, walk_ins_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label> No of referral cases </label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.referral_cases_on_the_day}
                                    onChange={(e) =>
                                        setdata({ ...data, referral_cases_on_the_day: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.referral_cases_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, referral_cases_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label>
                                {" "}
                                <b>Referred by </b>{" "}
                            </label>
                            <label> AWWs </label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.awws_on_the_day}
                                    onChange={(e) =>
                                        setdata({ ...data, awws_on_the_day: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.awws_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, awws_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label> Teachers </label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.teachers_on_the_day}
                                    onChange={(e) =>
                                        setdata({ ...data, teachers_on_the_day: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.teachers_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, teachers_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label> Police </label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.police_on_the_day}
                                    onChange={(e) =>
                                        setdata({ ...data, police_on_the_day: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.police_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, police_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label> ASHA workers </label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.asha_workers_on_the_day}
                                    onChange={(e) =>
                                        setdata({ ...data, asha_workers_on_the_day: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.asha_workers_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, asha_workers_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label> Local leaders/elected representatives </label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.local_leaders_on_the_day}
                                    onChange={(e) =>
                                        setdata({ ...data, local_leaders_on_the_day: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.local_leaders_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, local_leaders_cumulative: e.target.value })
                  }
                /> */}
                            </div>

                            <label> Others (please specify) </label>
                            <div className="new-expense__control">
                                <label>During the month </label> : <input
                                    type="number"
                                    min="1"
                                    value={data.others_on_the_day}
                                    onChange={(e) =>
                                        setdata({ ...data, others_on_the_day: e.target.value })
                                    }
                                />
                                {/* <label>Cumulative </label> : <input
                  type="number"
                  min="1"
                  value={data.others_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, others_cumulative: e.target.value })
                  }
                /> */}
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={openForm1}
                            type="button"
                        >
                            Save & Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Form25;
