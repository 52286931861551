import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";
import { Toaster, toast } from "react-hot-toast";

// FORM 5 After Care services  (Govt)

function Form5(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { onSubmit }] = useFormStore();
    const [{ }, { updateReport }] = useFormStore();
    const [{ }, { formUpdated }] = useFormStore();
    const Qmonth = props.Qmonth;
    const Qyear = props.Qyear;
    const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
    const DisplayQuarter = Quarter[Qmonth];

    const [data, setdata] = useState({
        children_need_after_care_male_15_18_cumulative: 0,
        children_need_after_care_female_15_18_cumulative: 0,
        children_need_after_care_male_15_18_quarterly: 0,
        children_need_after_care_female_15_18_quarterly: 0,
        psychosocial_assessment_male_cumulative: 0,
        psychosocial_assessment_female_cumulative: 0,
        psychosocial_assessment_male_quarterly: 0,
        psychosocial_assessment_female_quarterly: 0,
        after_care_plan_created_male_cumulative: 0,
        after_care_plan_created_female_cumulative: 0,
        after_care_plan_created_male_quarterly: 0,
        after_care_plan_created_female_quarterly: 0,
        send_to_after_home_male_cumulative: 0,
        send_to_after_home_female_cumulative: 0,
        send_to_after_home_male_quarterly: 0,
        send_to_after_home_female_quarterly: 0,
        children_18_year_placed_for_employment_male_cumulative: 0,
        children_18_year_placed_for_employment_female_cumulative: 0,
        children_18_year_placed_for_employment_male_quarterly: 0,
        children_18_year_placed_for_employment_female_quarterly: 0,
        compleated_vocational_training_male_cumulative: 0,
        compleated_vocational_training_female_cumulative: 0,
        compleated_vocational_training_male_quarterly: 0,
        compleated_vocational_training_female_quarterly: 0,
        month: Qmonth,
        year: Qyear,
    });

    useEffect(() => {
        if ((props || {}).isEdit) {
            const { id, created_at, updated_at, username, user, report_type, ...reportSorted } = props.report.find((res) => { return res.month === Qmonth && res.year === Qyear; }) || {};
            setdata({
                ...data,
                ...reportSorted
            });
        }
    }, []);
    const openForm1 = () => {
        if (props.isEdit) {
            return toast.promise(
                updateReport(data, "update_after_care_service"),
                {
                    loading: 'Updating Record',
                    success: 'Record Updated',
                    error: 'Error while saving'
                }
            ).then(() => formUpdated(true));
        } else {
            onSubmit(data, "after_care_service");
            setmodalIsOpen(false);
        }
    };
    const closeButton = () => {
        props.close();
        setmodalIsOpen(false);
    };
    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <Toaster position="bottom-right" />
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {" "}
                            After Care Services{" "}
                        </h5>
                        <button
                            className="btn-close"
                            onClick={closeButton}
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <h4>{DisplayQuarter} {Qyear}</h4>
                    <div className="modal-body">
                        <form>
                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    No. of children who need aftercare services (15-18){" "}
                                </label>
                                {/* <label> Male (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.children_need_after_care_male_15_18_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      children_need_after_care_male_15_18_cumulative:
                        e.target.value,
                    })
                  }
                /> */}
                                <label> Male (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.children_need_after_care_male_15_18_quarterly}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            children_need_after_care_male_15_18_quarterly:
                                                e.target.value,
                                        })
                                    }
                                />
                                {/* <label> Female (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.children_need_after_care_female_15_18_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      children_need_after_care_female_15_18_cumulative:
                        e.target.value,
                    })
                  }
                /> */}
                                <label> Female (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.children_need_after_care_female_15_18_quarterly}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            children_need_after_care_female_15_18_quarterly:
                                                e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    No. of children for whom psychosocial assessment is completed{" "}
                                </label>
                                {/* <label> Male (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.psychosocial_assessment_male_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      psychosocial_assessment_male_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Male (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.psychosocial_assessment_male_quarterly}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            psychosocial_assessment_male_quarterly: e.target.value,
                                        })
                                    }
                                />
                                {/* <label> Female (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.psychosocial_assessment_female_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      psychosocial_assessment_female_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Female (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.psychosocial_assessment_female_quarterly}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            psychosocial_assessment_female_quarterly: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> No. of children for whom aftercare plan created </label>
                                {/* <label> Male (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.after_care_plan_created_male_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      after_care_plan_created_male_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Male (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.after_care_plan_created_male_quarterly}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            after_care_plan_created_male_quarterly: e.target.value,
                                        })
                                    }
                                />
                                {/* <label> Female (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.after_care_plan_created_female_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      after_care_plan_created_female_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Female (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.after_care_plan_created_female_quarterly}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            after_care_plan_created_female_quarterly: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> No. of children sent to aftercare homes </label>
                                {/* <label> Male (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.send_to_after_home_male_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      send_to_after_home_male_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Male (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.send_to_after_home_male_quarterly}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            send_to_after_home_male_quarterly: e.target.value,
                                        })
                                    }
                                />
                                {/* <label> Female (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.send_to_after_home_female_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      send_to_after_home_female_cumulative: e.target.value,
                    })
                  }
                /> */}
                                <label> Female (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.send_to_after_home_female_quarterly}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            send_to_after_home_female_quarterly: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    No. of children who have completed 18 years who were placed
                                    for gainful employment{" "}
                                </label>
                                {/* <label> Male (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.children_18_year_placed_for_employment_male_cumulative
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      children_18_year_placed_for_employment_male_cumulative:
                        e.target.value,
                    })
                  }
                /> */}
                                <label> Male (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={
                                        data.children_18_year_placed_for_employment_male_quarterly
                                    }
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            children_18_year_placed_for_employment_male_quarterly:
                                                e.target.value,
                                        })
                                    }
                                />
                                {/* <label> Female (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.children_18_year_placed_for_employment_female_cumulative
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      children_18_year_placed_for_employment_female_cumulative:
                        e.target.value,
                    })
                  }
                /> */}
                                <label> Female (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={
                                        data.children_18_year_placed_for_employment_female_quarterly
                                    }
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            children_18_year_placed_for_employment_female_quarterly:
                                                e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    No. of children who completed vocational training{" "}
                                </label>
                                {/* <label> Male (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.compleated_vocational_training_male_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      compleated_vocational_training_male_cumulative:
                        e.target.value,
                    })
                  }
                /> */}
                                <label> Male (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.compleated_vocational_training_male_quarterly}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            compleated_vocational_training_male_quarterly:
                                                e.target.value,
                                        })
                                    }
                                />
                                {/* <label> Female (Cumulative) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.compleated_vocational_training_female_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      compleated_vocational_training_female_cumulative:
                        e.target.value,
                    })
                  }
                /> */}
                                <label> Female (Current quarter) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.compleated_vocational_training_female_quarterly}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            compleated_vocational_training_female_quarterly:
                                                e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={closeButton}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={openForm1}
                            type="button"
                        >
                            Save & Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Form5;
