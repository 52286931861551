import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";

// FORM 14 Details of de-institutionalised children (Govt)	(Q)

function Report14(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{}, { downloadExcel }] = useFormStore();

  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };

  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {" "}
            Details of de-institutionalised children{" "}
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <div class="table-responsive">
            <table class="table" id="deinstitutional">
              <thead>
                <tr>
                  <th scope="col" rowspan="4">
                    #
                  </th>
                  <th scope="col" rowspan="4">
                    District
                  </th>
                  <th scope="col" rowspan="2" colspan="4">
                    Total No. of children{" "}
                  </th>
                  <th scope="col" colspan="12">
                    {" "}
                    De-institutionalised to{" "}
                  </th>
                  <th scope="col" rowspan="2" colspan="4">
                    {" "}
                    No of children availed sponsorship{" "}
                  </th>
                </tr>

                <tr>
                  <th scope="col" colspan="4">
                    {" "}
                    Adoption{" "}
                  </th>
                  <th scope="col" colspan="4">
                    Foster care{" "}
                  </th>
                  <th scope="col" colspan="4">
                    Restoration to parents/family{" "}
                  </th>
                </tr>

                <tr>
                  <th scope="col" colspan="2">
                    Male
                  </th>
                  <th scope="col" colspan="2">
                    Female
                  </th>
                  <th scope="col" colspan="2">
                    Male
                  </th>
                  <th scope="col" colspan="2">
                    Female
                  </th>
                  <th scope="col" colspan="2">
                    Male
                  </th>
                  <th scope="col" colspan="2">
                    Female
                  </th>
                  <th scope="col" colspan="2">
                    Male
                  </th>
                  <th scope="col" colspan="2">
                    Female
                  </th>
                  <th scope="col" colspan="2">
                    Male
                  </th>
                  <th scope="col" colspan="2">
                    Female
                  </th>
                </tr>

                <tr>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                </tr>
              </thead>

              <tbody>
                {props.report.map((r) => (
                  <tr key={r?.id}>
                    <th scope="row">{r?.id}</th>
                    <td>{JSON.parse(getStorage("user")).username}</td>
                    <td>{r?.male_cumulative}</td>
                    <td>{r?.male_current_quarter}</td>
                    <td>{r?.female_cumulative}</td>
                    <td>{r?.female_current_quarter}</td>
                    <td>{r?.adoption_male_cumulative}</td>
                    <td>{r?.adoption_male_current_quarter}</td>
                    <td>{r?.adoption_female_cumulative}</td>
                    <td>{r?.adoption_female_current_quarter}</td>
                    <td>{r?.foster_care_male_cumulative}</td>
                    <td>{r?.foster_care_male_current_quarter}</td>
                    <td>{r?.foster_care_female_cumulative}</td>
                    <td>{r?.foster_care_female_current_quarter}</td>
                    <td>{r?.restoration_male_cumulative}</td>
                    <td>{r?.restoration_male_current_quarter}</td>
                    <td>{r?.restoration_female_cumulative}</td>
                    <td>{r?.restoration_female_current_quarter}</td>
                    <td>{r?.sponsership_male_cumulative}</td>
                    <td>{r?.sponsership_male_current_quarter}</td>
                    <td>{r?.sponsership_female_cumulative}</td>
                    <td>{r?.sponsership_female_current_quarter}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("deinstitutional")}
          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default Report14;
