import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./Login";
import Home from "./Home";
import Stats from "./Stats";
import Reports from "./Reports";
import Admin from "./Admin";
import PageNotFound from "./PageNotFound";
import Dashboard from "./dashboard";
import Users from "./Users";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/sign-in" />
      <Route exact path="/sign-in" component={Login} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/sign-out" component={Login} />
      <Route exact path="/stats" component={Stats} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/reports" component={Reports} />
      <Route exact path="/admin" component={Admin} />
      <Route exact path="/users" component={Users} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
