import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";

// FORM 18 Institutional Care of Children in Need of Care and Protection

function Form18(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { onSubmit }] = useFormStore();

  const [data, setdata] = useState({
    childrens_home_district_by_state: 0,
    childrens_home_district_by_state_by_ngo: 0,
    placed_in_children_home_in_district: 0,
    full_time_staff_in_child_home_in_district_sanctioned: 0,
    full_time_staff_in_child_home_in_district_working_strength: 0,
    full_time_staff_in_child_home_in_district_vacancies: 0,
    part_time_staff_sanctioned: 0,
    part_time_staff_working_strength: 0,
    part_time_staff_vacancies: 0,
    give_additional_charge: 0,
    gov_org_rec_fit_facility: 0,
    non_gov_org_rec_fit_facility: 0,
    no_of_children_fit_facility: 0,
    person_in_district_recog_as_fit_facility: 0,
    children_placed_with_fit_person: 0,
    open_shelter_in_district_by_state_govt_itself: 0,
    open_shelter_in_district_by_state_govt_trough_ngo: 0,
    open_shelter_in_district: 0,
    children_in_open_shelter_in_district: 0,
  });
  const openForm1 = () => {
    props.close();
    onSubmit(data, "institutional_care_protection");
    setmodalIsOpen(false);
  };
  const closeButton = () => {
    props.close();
    setmodalIsOpen(false);
  };
  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {" "}
              Institutional Care of Children in Need of Care and Protection{" "}
            </h5>
            <button
              className="btn-close"
              onClick={closeButton}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <h5>
                {" "}
                a) Children's Homes (established under section 50 of the JJ Act,
                2015{" "}
              </h5>
              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of Children's Homes in the district maintained by the
                  state govt itself{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.childrens_home_district_by_state}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      childrens_home_district_by_state: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of Children's Homes in the district maintained by the
                  state govt through voluntary or non – governmental
                  organization{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.childrens_home_district_by_state_by_ngo}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      childrens_home_district_by_state_by_ngo: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of children placed in Children's Homes in the district{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.placed_in_children_home_in_district}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      placed_in_children_home_in_district: e.target.value,
                    })
                  }
                />
              </div>
              <br /> <br /> <br />
              <h5> b) Staffs in Children's Home </h5>
              <div className="new-expense__control">
                <label>
                  {" "}
                  Total no. of full time staff in all the children's home in the
                  district{" "}
                </label>
                <label> Sanctioned strength </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.full_time_staff_in_child_home_in_district_sanctioned
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      full_time_staff_in_child_home_in_district_sanctioned:
                        e.target.value,
                    })
                  }
                />
                <label> Working strength </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.full_time_staff_in_child_home_in_district_working_strength
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      full_time_staff_in_child_home_in_district_working_strength:
                        e.target.value,
                    })
                  }
                />
                <label> Vacancies </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.full_time_staff_in_child_home_in_district_vacancies
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      full_time_staff_in_child_home_in_district_vacancies:
                        e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  Total no. of part time staff in all the children's home in the
                  district{" "}
                </label>
                <label> Sanctioned strength </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.part_time_staff_sanctioned}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      part_time_staff_sanctioned: e.target.value,
                    })
                  }
                />
                <label> Working strength </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.part_time_staff_working_strength}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      part_time_staff_working_strength: e.target.value,
                    })
                  }
                />
                <label> Vacancies </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.part_time_staff_vacancies}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      part_time_staff_vacancies: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of staff given Additional charge of children's home in the
                  district{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.give_additional_charge}
                  onChange={(e) =>
                    setdata({ ...data, give_additional_charge: e.target.value })
                  }
                />
              </div>
              <br /> <br /> <br />
              <h5>
                {" "}
                c) Fit Facility (Recognised under section 51 of the JJ Act,
                2015){" "}
              </h5>
              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of Governmental Organization recognised as Fit Facility{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.gov_org_rec_fit_facility}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      gov_org_rec_fit_facility: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of Non-Governmental Organization recognised as Fit
                  Facility{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.non_gov_org_rec_fit_facility}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      non_gov_org_rec_fit_facility: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of children placed in Fit Facility in the district{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.no_of_children_fit_facility}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      no_of_children_fit_facility: e.target.value,
                    })
                  }
                />
              </div>
              <br /> <br /> <br />
              <h5>
                {" "}
                d) Fit Persons (Recognised under section 52 of the JJ Act, 2015){" "}
              </h5>
              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of persons in the district recognised as Fit Facility{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.person_in_district_recog_as_fit_facility}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      person_in_district_recog_as_fit_facility: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of children placed with Fit persons in the district
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.children_placed_with_fit_person}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      children_placed_with_fit_person: e.target.value,
                    })
                  }
                />
              </div>
              <br /> <br /> <br />
              <h5>
                {" "}
                e) Open Shelter (established under section 43 of the JJ Act,
                2015):-{" "}
              </h5>
              <div className="new-expense__control">
                <label>
                  {" "}
                  No. of Open Shelters in the district maintained by the state
                  govt itself{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.open_shelter_in_district_by_state_govt_itself}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      open_shelter_in_district_by_state_govt_itself:
                        e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of Open Shelters in the district maintained by the state
                  govt through voluntary or non – governmental organization{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.open_shelter_in_district_by_state_govt_trough_ngo}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      open_shelter_in_district_by_state_govt_trough_ngo:
                        e.target.value,
                    })
                  }
                />
                <label> Total no. of Open Shelters in the district </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.open_shelter_in_district}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      open_shelter_in_district: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of children placed in Open Shelters in the district{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.children_in_open_shelter_in_district}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      children_in_open_shelter_in_district: e.target.value,
                    })
                  }
                />
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              onClick={closeButton}
              type="button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              onClick={openForm1}
              type="button"
            >
              Save & Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Form18;
