import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";
import { Toaster, toast } from "react-hot-toast";
import { UPDATE_NCPCR } from "../utils/constant";

// FORM 28 NCPCR

function Form28(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { onSubmit }] = useFormStore();
    const [{ }, { updateReport }] = useFormStore();
    const [{ }, { formUpdated }] = useFormStore();
    const Qyear = props.Qyear;
    const Qmonth = props.Qmonth;
    const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
    const DisplayQuarter = Quarter[Qmonth];

    const [data, setdata] = useState({
        state_child_protection_on_society_setup_u_s_106_jj_act_2015: 0,
        district_child_protection_unit_u_s_106_jj_act_2015: 0,
        sjpu_child_welfare_police_in_district_u_s_107_jj_act_2015: 0,
        jjbs_in_the_district_u_s_4_of_the_jj_act_2015: 0,
        cwcs_in_the_district_u_s_27_of_the_jj_act_2015: 0,
        children_courts_u_s_25_cpcr_act_2005: 0,
        reg_ccis_u_s_41_jj_act_2015_run_by_govt_18_a: 0,
        reg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_b: 0,
        unreg_ccis_u_s_41_jj_act_2015_run_by_govt_18_c: 0,
        unreg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_d: 0,
        persons_penalized_for_non_registration_of_cci_u_s_42: 0,
        open_shelter_shelter_set_up_u_s_43_of_the_jj_act_2015: 0,
        open_shelter_shelter_u_s_43_of_the_jj_act_2015: 0,
        children_in_open_shelter_u_s_43_of_the_jj_act_2015: 0,
        observation_homes_set_up_u_s_47_of_the_jj_Act_2015: 0,
        observation_homes_u_s_43_of_the_jj_act_2015: 0,
        children_in_home_who_are_staying_more_than_3_months: 0,
        special_home_set_up_u_s_48_of_the_jj_act_2015: 0,
        special_home_u_s_48_of_the_jj_act_2015: 0,
        children_in_special_home_u_s_48_of_the_jj_act_2015: 0,
        place_of_safety_u_s_49_of_the_jj_act_2015: 0,
        children_in_place_of_safety: 0,
        capacity_of_place_of_safety: 0,
        children_home_set_up_u_s_50_of_the_jj_act_2015: 0,
        fit_facility_recognized_u_s_51_of_the_act_2015: 0,
        foster_care_recognized_u_s_44_of_the_jj_act_2015: 0,
        fit_person_recognized_u_s_52_of_jj_act_2015: 0,
        specialised_adoption_agency_organised_u_s_65_of_jj_act_2015: 0,
        inspection_committee_by_state_u_s_54_of_jj_act_2015: 0,
        juvenile_justice_fund_has_been_created_u_s_105_of_jj_act_2015: 0,
        all_housing_facilities_of_children_u_s_54_2_of_jj_act_2015: 0,
        jjb_cwc_sjpu_regd_institutions_u_s_55_1_of_jj_act_2015: 0,
        //ssj5wcd
        jjb_in_the_district_u_s_4_of_jj_act_2015: 0,
        induction_training_sensitisation_to_jjb_has_been_provided: 0,
        cjm_cmm_yes_no_if_no_reason_thereof_u_s_16_1_of_jj_act_2015: 0,
        the_high_level_committee_as_per_sec_16_2_of_jj_act_2015: 0,
        children_in_observation_home_in_case_of_petty_offences: 0,
        children_staying_in_observation_home_for_more_than_3_months: 0,
        run_away_children_home_wise_u_s_26_2: 0,
        homes_where_cncp_child_in_need_of_care: 0,
        cases_presented_before_the_session_court_where_the_court_felt: 0,
        children_residing_in_the_special_home_for_more_than_3_yrs: 0,
        child_in_conflict_with_law_ccl_placed_in_place_of_safety: 0,
        reformative_services_like_education_skill_development_training: 0,
        // sjj6wcd
        sittings_in_a_week_by_jjb: 0,
        sittings_of_jjb_are_held_in_the_premises_of_home: 0,
        date_of_appointment_of_the_board_dist_wise: 0,
        date_of_superannuation_of_the_board_dist_wise: 0,
        magistrate_of_jjb_is_exclusive_or_additional_charge_board: 0,
        monthly_visit_of_residential_facilities_of_ccl: 0,
        jjb_has_ordered_registration_for_offences_committed_child: 0,
        regular_inspections_of_adult_jails_is_being_conducted: 0,
        child_below_18_yrs_was_found_to_be_confined_in_the_adult_jail: 0,
        children_were_transferred_to_observation_home: 0,
        cases_where_legal_aid_provided_to_ccl: 0,
        cases_where_there_is_no_dvocate_to_represent_ccl: 0,
        facility_of_interpreter_or_translators_available: 0,
        // sjj7wcd
        cases_with_jjb: 0,
        no_of_pending_cases: 0,
        reason_of_pendency: 0,
        cases_disposed_off: 0,
        pendency_is_being_reported_to_dm_u_s_16_3_of_jj_act_2015: 0,
        cases_where_bail_is_granted_to_ccl_u_s_12_of_jj_act_2015: 0,
        inquiry_in_case_by_board_is_completed_within_time_u_s_14_2: 0,
        case_of_ill_treatment_by_the_police_towards_the_child: 0,
        cases_which_are_pending_due_to_want_of_bail_bond_u_s_12: 0,
        preliminary_assessment_in_heinous_offences_is_completed: 0,
        Cases_of_heinous_offences_pending_with_board: 0,
        Cases_of_Serious_offences_pending_with_board: 0,
        Cases_of_Petty_Offences_pending_with_board: 0,
        cases_where_ccl_are_tried_with_adults: 0,
        //ssj8wcd
        children_produced_before_jjb_after_recovery: 0,
        cases_where_child_is_not_found_to_be_in_conflict_with_law: 0,
        children_who_are_found_to_be_in_ccl_u_s_18_of_jjb_act_2015: 0,
        cases_where_ccl_was_found_cncp_transferred_to_cwc: 0,
        bail_applications_received_in_the_jjb: 0,
        bails_been_granted_by_jjb: 0,
        children_been_transferred_from_jjb_to_the_court: 0,
        cases_in_which_inquiry_completed_within_4_months: 0,
        cases_of_petty_offences_disposed_of_due_to_time: 0,
        children_released_on_probation_of_good_conduct: 0,
        //SPCMA1WCD
        child_marriages_reported_to_police: 0,
        child_marriages_reported_to_child_marriage_prohibition_officers: 0,
        child_marriages_reported_to_first_class_judicial_magistrate: 0,
        child_marriages_reported_to_child_welfare_committee: 0,
        child_marriages_reported_to_district_magistrate: 0,
        cmpos_appointed_u_s_6_of_the_act: 0,
        child_marriages_prevented_by_dm_u_s_13_5_of_the: 0,
        child_marriages_prevented_by_cmpos_under_section: 0,
        mass_child_marriages_prevented_by_under_section: 0,
        awareness_programmes_conducted_under_section_16_3_d: 0,
        sensitization_programmes_conducted_under_section_16_3_e: 0,
        // SPOCSO1WCD
        no_of_districts: 0,
        special_courts_established_u_s_28_of_pocso_act: 0,
        special_courts_dealing_exclusively_with_pocso_cases: 0,
        special_courts_designated_with_additional_charge: 0,
        special_courts_equipped_for_following_provisions: 0,
        special_courts_having_availability_of_interpreter: 0,
        special_public_prosecutors_appointed_u_s_32_of_pocso_act: 0,
        special_public_prosecutors_conducting_exclusively: 0,
        special_public_prosecutors_conducting_pocso_cases_in_addition: 0,
        programs_campaigns_have_been_conducted_for_provisions: 0,
        training_imparted_on_pocso_act_to_state_govt_officer: 0,
        trainings_imparted_on_pocso_act_to_police_officers: 0,
        professionals_to_assist_the_child_at_pre_trial_and_trial_stag: 0,
        training_modules_prepared_for_effective_discharge_of_functions: 0,
        any_victim_compensation_fund_scheme_has_been_created: 0,
        cases_where_compensation_has_been_provided_to_victim: 0,
        compensation_paid_within_30_days_of_order_of_Special_Court: 0,
        month: Qmonth,
        year: Qyear,
    });

    useEffect(() => {
        if ((props || {}).isEdit) {
            const { id, created_at, updated_at, username, user, report_type, ...reportSorted } = props.report.find((res) => { return res.month === Qmonth && res.year === Qyear; }) || {};
            setdata({
                ...data,
                ...reportSorted
            });
        }
    }, []);

    const openForm1 = () => {
        if (props.isEdit) {
            return toast.promise(
                updateReport(data, UPDATE_NCPCR),
                {
                    loading: 'Updating Record',
                    success: 'Record Updated',
                    error: 'Error while saving'
                }
            ).then(() => formUpdated(true));
        } else {
            props.close();
            onSubmit(data, "ncpcr");
            setmodalIsOpen(false);
        }
    };
    const openForm = () => {
        props.close();
        setmodalIsOpen(false);
    };
    console.log(Qmonth, Qyear);
    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <Toaster position="bottom-right" />
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            NCPCR- Management Information system Online Reporting (MONITOR)
                        </h5>

                        <button
                            className="btn-close"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <h4>{DisplayQuarter} {Qyear}</h4>
                    <div className="modal-body">
                        <div className="new-expense__control">
                            <table>
                                <tr>
                                    <th>Category Code</th>
                                    <th>S. No.</th>
                                    <th colSpan={2}>Details</th>
                                    <th></th>
                                    <th>Remarks</th>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="extra">
                                        {" "}
                                        Information regarding Juvenile Justice (Care and Protection
                                        of Children) Act
                                    </td>
                                    <td className="extra"></td>
                                    <td className="extra"></td>
                                </tr>
                                <tr>
                                    <td rowSpan={10}>SJJ1WCD</td>
                                    <td>1</td>
                                    <td colSpan={2}>
                                        Whether State Child Protection on Society set up u/s 106 of
                                        JJ Act, 2015. If yes, Copy of notification
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={
                                                data.state_child_protection_on_society_setup_u_s_106_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    state_child_protection_on_society_setup_u_s_106_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td colSpan={2}>
                                        District Child Protection Unit u/s 106 of JJ Act, 2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.district_child_protection_unit_u_s_106_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    district_child_protection_unit_u_s_106_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td colSpan={2}>
                                        No. of SJPU & No. of Child Welfare Police Officers in
                                        District u/s 107 of JJ Act, 2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.sjpu_child_welfare_police_in_district_u_s_107_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    sjpu_child_welfare_police_in_district_u_s_107_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td colSpan={2}>
                                        Total No. of JJBs in the District (u/s 4 of the JJ Act 2015)
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.jjbs_in_the_district_u_s_4_of_the_jj_act_2015}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    jjbs_in_the_district_u_s_4_of_the_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td colSpan={2}>
                                        Total No. of Children Courts u/s 25 of the CPCR Act, 2005
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.children_courts_u_s_25_cpcr_act_2005}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_courts_u_s_25_cpcr_act_2005: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td colSpan={2}>
                                        Total No. of CWCs in the District (u/s 27 of the JJ Act
                                        2015)
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.cwcs_in_the_district_u_s_27_of_the_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    cwcs_in_the_district_u_s_27_of_the_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td colSpan={2}>
                                        Total No. of Reg. CCIs u/s 41 of JJ Act, 2015 (Run by Govt.)
                                        18(a)
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.reg_ccis_u_s_41_jj_act_2015_run_by_govt_18_a}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    reg_ccis_u_s_41_jj_act_2015_run_by_govt_18_a:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td colSpan={2}>
                                        Total No. of Reg. CCIs u/s 41 of JJ Act, 2015 (Run by NGOs)
                                        18(b)
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.reg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_b}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    reg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_b:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td colSpan={2}>
                                        Total No. of Unreg. CCIs u/s 41 of JJ Act, 2015 (Run by
                                        Govt.) 18(c )
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.unreg_ccis_u_s_41_jj_act_2015_run_by_govt_18_c
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    unreg_ccis_u_s_41_jj_act_2015_run_by_govt_18_c:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td colSpan={2}>
                                        Total No. of Unreg. CCIs u/s 41 of JJ Act, 2015 (Run by
                                        NGOs) 18(d)
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.unreg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_d
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    unreg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_d:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="extra"></td>
                                    <td className="extra"></td>
                                    <td className="extra"></td>
                                </tr>
                                <tr>
                                    <td rowSpan={7}>SJJ2WCD</td>
                                    <td>1</td>
                                    <td colSpan={2}>
                                        Total No. of persons penalized for non-registration of CCI
                                        u/s 42
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.persons_penalized_for_non_registration_of_cci_u_s_42
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    persons_penalized_for_non_registration_of_cci_u_s_42:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td colSpan={2}>
                                        Total No. of open shelter Shelter set up U/s 43 of the JJ
                                        Act, 2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.open_shelter_shelter_set_up_u_s_43_of_the_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    open_shelter_shelter_set_up_u_s_43_of_the_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td colSpan={2}>
                                        Total capacity of open shelter Shelter U/s 43 of the JJ Act,
                                        2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.open_shelter_shelter_u_s_43_of_the_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    open_shelter_shelter_u_s_43_of_the_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td colSpan={2}>
                                        Total No. of children in Open Shelter U/s 43 of the JJ Act,
                                        2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.children_in_open_shelter_u_s_43_of_the_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_in_open_shelter_u_s_43_of_the_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td colSpan={2}>
                                        Total no. of Observation Homes set up U/s 47 of the JJ Act,
                                        2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.observation_homes_set_up_u_s_47_of_the_jj_Act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    observation_homes_set_up_u_s_47_of_the_jj_Act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td colSpan={2}>
                                        Total capacity of Observation Homes U/s 43 of the JJ Act,
                                        2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.observation_homes_u_s_43_of_the_jj_act_2015}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    observation_homes_u_s_43_of_the_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td colSpan={2}>
                                        Total Children in Home who are staying for more than 3
                                        months
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.children_in_home_who_are_staying_more_than_3_months
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_in_home_who_are_staying_more_than_3_months:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="extra"></td>
                                    <td className="extra"></td>
                                    <td className="extra"></td>
                                </tr>
                                <tr>
                                    <td rowSpan={9}>SJJ3WCD</td>
                                    <td>1</td>
                                    <td colSpan={2}>
                                        Total No. of Special Home set up u/s 48 of the JJ Act, 2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.special_home_set_up_u_s_48_of_the_jj_act_2015}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    special_home_set_up_u_s_48_of_the_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td colSpan={2}>
                                        Total capacity of Special Home u/s 48 of the JJ Act, 2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.special_home_u_s_48_of_the_jj_act_2015}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    special_home_u_s_48_of_the_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td colSpan={2}>
                                        Total No. of children in Special Home u/s 48 of the JJ Act,
                                        2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.children_in_special_home_u_s_48_of_the_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_in_special_home_u_s_48_of_the_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td colSpan={2}>
                                        Total No. of place of safety u/s 49 of the JJ Act, 2015{" "}
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.place_of_safety_u_s_49_of_the_jj_act_2015}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    place_of_safety_u_s_49_of_the_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td colSpan={2}>Total Capacity of Place of Safety</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.capacity_of_place_of_safety}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    capacity_of_place_of_safety: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td colSpan={2}>Total No. of children in Place of Safety</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.children_in_place_of_safety}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_in_place_of_safety: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td colSpan={2}>
                                        Total No. of children home set up u/s 50 of the JJ Act, 2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.children_home_set_up_u_s_50_of_the_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_home_set_up_u_s_50_of_the_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td colSpan={2}>
                                        Total No. of Foster Care recognized u/s 44 of the JJ Act
                                        2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.foster_care_recognized_u_s_44_of_the_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    foster_care_recognized_u_s_44_of_the_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>

                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td colSpan={2}>
                                        Total No. of Fit facility recognized u/s 51 of the Act 2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.fit_facility_recognized_u_s_51_of_the_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    fit_facility_recognized_u_s_51_of_the_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="extra"></td>
                                    <td className="extra"></td>
                                    <td className="extra"></td>
                                </tr>
                                <tr>
                                    <td rowSpan={6}>SJJ4WCD</td>
                                    <td>1</td>
                                    <td colSpan={2}>
                                        Total No. of Fit Person recognized u / s 52 of JJ Act 2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.fit_person_recognized_u_s_52_of_jj_act_2015}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    fit_person_recognized_u_s_52_of_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td colSpan={2}>
                                        Total No. of Specialised Adoption Agency Organised u/s 65 of
                                        JJ Act, 2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.specialised_adoption_agency_organised_u_s_65_of_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    specialised_adoption_agency_organised_u_s_65_of_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td colSpan={2}>
                                        Appointment of Inspection Committee by State u / s 54 of the
                                        JJ Act 2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.inspection_committee_by_state_u_s_54_of_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    inspection_committee_by_state_u_s_54_of_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td colSpan={2}>
                                        Whether Juvenile Justice Fund has been created u / s 105 of
                                        the JJ Act 2015
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.juvenile_justice_fund_has_been_created_u_s_105_of_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    juvenile_justice_fund_has_been_created_u_s_105_of_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td colSpan={2}>
                                        No. of inspections conducted by inspection committee to all
                                        housing facilities of children u / s 54(2) of the JJ Act 15?
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.all_housing_facilities_of_children_u_s_54_2_of_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    all_housing_facilities_of_children_u_s_54_2_of_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td colSpan={2}>
                                        Whether the State Govt evaluated the functioning of JJB CWC
                                        SJPU regd. Or recognized institutions u / s 55(1) of JJ Act
                                        2015?{" "}
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.jjb_cwc_sjpu_regd_institutions_u_s_55_1_of_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    jjb_cwc_sjpu_regd_institutions_u_s_55_1_of_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="extra">
                                        MIS on JJB
                                    </td>
                                    <td className="extra"></td>
                                    <td className="extra"></td>
                                </tr>
                                <tr>
                                    <td rowSpan={12}>SJJ5WCD</td>
                                    <td>1</td>
                                    <td colSpan={2}>
                                        Total No. of JJBs in the District u/s 4 of JJ Act, 2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.jjb_in_the_district_u_s_4_of_jj_act_2015}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    jjb_in_the_district_u_s_4_of_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td colSpan={2}>
                                        Whether induction training & sensitisation to JJB has been
                                        provided within 60 days from the dates of appointment u/s
                                        4(5) of JJ Act, 2015 Yes/No if no reason thereof
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.induction_training_sensitisation_to_jjb_has_been_provided
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    induction_training_sensitisation_to_jjb_has_been_provided:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td colSpan={2}>
                                        Whether pendency for JJB is quarterly reviewed by CJM/CMM
                                        yes/No if no reason thereof u/s 16(1) of JJ Act, 2015
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.cjm_cmm_yes_no_if_no_reason_thereof_u_s_16_1_of_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    cjm_cmm_yes_no_if_no_reason_thereof_u_s_16_1_of_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td colSpan={2}>
                                        Whether pendency of the board is reviewed by the High Level
                                        Committee as per sec. 16(2) of JJ Act, 2015 if no reason
                                        thereof
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.the_high_level_committee_as_per_sec_16_2_of_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    the_high_level_committee_as_per_sec_16_2_of_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td colSpan={2}>
                                        No. of children in Observation Home in case of petty
                                        offences
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.children_in_observation_home_in_case_of_petty_offences
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_in_observation_home_in_case_of_petty_offences:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td colSpan={2}>
                                        No. of Children staying in Observation Home for more than 3
                                        months in case of petty offence{" "}
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.children_staying_in_observation_home_for_more_than_3_months
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_staying_in_observation_home_for_more_than_3_months:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td colSpan={2}>
                                        No. of run away children (Home-wise) U / s 26(2)
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.run_away_children_home_wise_u_s_26_2}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    run_away_children_home_wise_u_s_26_2: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td colSpan={2}>
                                        No. of Homes where (CNCP) Child in need of care and
                                        protection & Child in conflict with Law(CCL) are kept
                                        together
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.homes_where_cncp_child_in_need_of_care}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    homes_where_cncp_child_in_need_of_care:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td colSpan={2}>
                                        Total No. of cases presented before the Session Court where
                                        the Court felt or the persons claims himself / herself to be
                                        a minor?
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.cases_presented_before_the_session_court_where_the_court_felt
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    cases_presented_before_the_session_court_where_the_court_felt:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td colSpan={2}>
                                        Total No. of children residing in the Special Home for more
                                        than 3 yrs
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.children_residing_in_the_special_home_for_more_than_3_yrs
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_residing_in_the_special_home_for_more_than_3_yrs:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td colSpan={2}>
                                        Total No. of & Child in conflict with Law (CCL) placed in
                                        place of safety
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.child_in_conflict_with_law_ccl_placed_in_place_of_safety
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    child_in_conflict_with_law_ccl_placed_in_place_of_safety:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td colSpan={2}>
                                        Whether Reformative services like education skill
                                        development vocational training counselling behaviour
                                        modification therapy and psychiatric support have been
                                        provided to each and every child during their stay in the
                                        Special Home{" "}
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.reformative_services_like_education_skill_development_training
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    reformative_services_like_education_skill_development_training:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="extra">
                                        MIS on JJB
                                    </td>
                                    <td className="extra"></td>
                                    <td className="extra"></td>
                                </tr>
                                <tr>
                                    <td rowSpan={13}>SJJ6WCD</td>
                                    <td>1</td>
                                    <td colSpan={2}>No. of sittings in a week by JJB</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.sittings_in_a_week_by_jjb}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    sittings_in_a_week_by_jjb: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td colSpan={2}>
                                        Whether sittings of JJB are held in the premises of Home Yes
                                        / No if not reasons thereof
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.sittings_of_jjb_are_held_in_the_premises_of_home
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    sittings_of_jjb_are_held_in_the_premises_of_home:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td colSpan={2}>
                                        Date of appointment of the Board (Dist-wise)
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.date_of_appointment_of_the_board_dist_wise}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    date_of_appointment_of_the_board_dist_wise:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td colSpan={2}>
                                        Date of Superannuation of the Board (Dist-wise)
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.date_of_superannuation_of_the_board_dist_wise}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    date_of_superannuation_of_the_board_dist_wise:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td colSpan={2}>
                                        Whether Principal Magistrate of JJB is exclusive or given
                                        additional charge of the Board Yes / No & No. accordingly
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.magistrate_of_jjb_is_exclusive_or_additional_charge_board
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    magistrate_of_jjb_is_exclusive_or_additional_charge_board:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td colSpan={2}>
                                        Whether JJB conducting monthly visit/inspection of
                                        residential facilities off CCL u/s 8(3)(j) of JJ Act, 2015
                                        Yes/No if no reason thereof
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.monthly_visit_of_residential_facilities_of_ccl
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    monthly_visit_of_residential_facilities_of_ccl:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td colSpan={2}>
                                        No. of cases where JJB has ordered registration of FIR for
                                        offences committed against any where (CNCP) Child in need of
                                        care and protection & Child in conflict with Law(CCL) u/s
                                        8(3)(K)(l) of JJ Act, 2015 *
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.jjb_has_ordered_registration_for_offences_committed_child
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    jjb_has_ordered_registration_for_offences_committed_child:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>

                                <tr>
                                    <td>8</td>
                                    <td colSpan={2}>
                                        Whether regular inspections of adult jails is being
                                        conducted by JJB u/s 8(3)(m) of JJ Act, 2015 yes/no if no
                                        reason thereof
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.regular_inspections_of_adult_jails_is_being_conducted
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    regular_inspections_of_adult_jails_is_being_conducted:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td colSpan={2}>
                                        Whether any Child below 18 yrs was found to be confined in
                                        the Adult jail during inspection Yes / No if yes details
                                        thereof
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.child_below_18_yrs_was_found_to_be_confined_in_the_adult_jail
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    child_below_18_yrs_was_found_to_be_confined_in_the_adult_jail:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td colSpan={2}>
                                        Whether such children were transferred to Observation Home
                                        yes/no, if no reason thereof u/s 8(3)(m) of JJ Act, 2015
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={data.children_were_transferred_to_observation_home}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_were_transferred_to_observation_home:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td colSpan={2}>
                                        No. of cases where legal aid provided to CCL (Child Conflict
                                        with Law)
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.cases_where_legal_aid_provided_to_ccl}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    cases_where_legal_aid_provided_to_ccl: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td colSpan={2}>
                                        No. of cases where there is no Advocate to represent CCL
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.cases_where_there_is_no_dvocate_to_represent_ccl
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    cases_where_there_is_no_dvocate_to_represent_ccl:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td colSpan={2}>
                                        Whether facility of interpreter or translators available is
                                        with the JJB U / s (3)(d) Yes / No
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.facility_of_interpreter_or_translators_available
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    facility_of_interpreter_or_translators_available:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="extra">
                                        MIS on JJB
                                    </td>
                                    <td className="extra"></td>
                                    <td className="extra"></td>
                                </tr>
                                <tr>
                                    <td rowSpan={14}>SJJ7WCD</td>
                                    <td>1</td>
                                    <td colSpan={2}>Total No. of cases with JJB</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.cases_with_jjb}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    cases_with_jjb: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td colSpan={2}>No. of pending cases</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.no_of_pending_cases}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    no_of_pending_cases: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td colSpan={2}>Reason of pendency, please specify</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.reason_of_pendency}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    reason_of_pendency: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td colSpan={2}>No. of cases disposed off</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.cases_disposed_off}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    cases_disposed_off: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td colSpan={2}>
                                        Whether pendency is being reported to DM u/s 16(3) of JJ
                                        Act, 2015 Yes/no, if no reason thereof
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.pendency_is_being_reported_to_dm_u_s_16_3_of_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    pendency_is_being_reported_to_dm_u_s_16_3_of_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td colSpan={2}>
                                        No. of cases where bail is granted to CCL u/s 12 of JJ Act,
                                        2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.cases_where_bail_is_granted_to_ccl_u_s_12_of_jj_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    cases_where_bail_is_granted_to_ccl_u_s_12_of_jj_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td colSpan={2}>
                                        Whether inquiry in case by board is completed within time
                                        u/s 14(2) of JJ Act, 2015 yes/no if no, reason thereof
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.inquiry_in_case_by_board_is_completed_within_time_u_s_14_2
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    inquiry_in_case_by_board_is_completed_within_time_u_s_14_2:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td colSpan={2}>
                                        Whether any case of ill treatment by the police towards the
                                        child has been reported before the JJB? If yes, action taken
                                        against the police official
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.case_of_ill_treatment_by_the_police_towards_the_child
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    case_of_ill_treatment_by_the_police_towards_the_child:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td colSpan={2}>
                                        No. of cases which are pending due to want of bail bond U/s
                                        12
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.cases_which_are_pending_due_to_want_of_bail_bond_u_s_12
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    cases_which_are_pending_due_to_want_of_bail_bond_u_s_12:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td colSpan={2}>
                                        Whether preliminary assessment in heinous offences is
                                        completed within time u/s 14(3) i.e 3 months
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.preliminary_assessment_in_heinous_offences_is_completed
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    preliminary_assessment_in_heinous_offences_is_completed:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td colSpan={2}>
                                        No. of Cases of Heinous Offences pending with Board
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.Cases_of_heinous_offences_pending_with_board}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    Cases_of_heinous_offences_pending_with_board:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td colSpan={2}>
                                        No. of Cases of Serious Offences pending with Board
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.Cases_of_Serious_offences_pending_with_board}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    Cases_of_Serious_offences_pending_with_board:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td colSpan={2}>
                                        No. of Cases of Petty Offences pending with Board
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.Cases_of_Petty_Offences_pending_with_board}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    Cases_of_Petty_Offences_pending_with_board:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>14</td>
                                    <td colSpan={2}>
                                        No. of cases where CCL are tried with Adults
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.cases_where_ccl_are_tried_with_adults}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    cases_where_ccl_are_tried_with_adults: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="extra">
                                        MIS on JJB
                                    </td>
                                    <td className="extra"></td>
                                    <td className="extra"></td>
                                </tr>
                                <tr>
                                    <td rowSpan={10}>SJJ8WCD</td>
                                    <td>1</td>
                                    <td colSpan={2}>
                                        No. of children produced before JJB after recovery. u / s
                                        26(2) of JJ Act, 2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.children_produced_before_jjb_after_recovery}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_produced_before_jjb_after_recovery:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td colSpan={2}>
                                        No. of cases where child is not found to be in conflict with
                                        law u / s 17 of JJ Act, 2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.cases_where_child_is_not_found_to_be_in_conflict_with_law
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    cases_where_child_is_not_found_to_be_in_conflict_with_law:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td colSpan={2}>
                                        No. of children who are found to be in CCL u/s 18 of JJ Act,
                                        2015
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.children_who_are_found_to_be_in_ccl_u_s_18_of_jjb_act_2015
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_who_are_found_to_be_in_ccl_u_s_18_of_jjb_act_2015:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td colSpan={2}>
                                        No. of cases where CCL was found CNCP & transferred to CWC
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.cases_where_ccl_was_found_cncp_transferred_to_cwc
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    cases_where_ccl_was_found_cncp_transferred_to_cwc:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td colSpan={2}>
                                        Total No. of bail applications received in the JJB
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.bail_applications_received_in_the_jjb}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    bail_applications_received_in_the_jjb: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td colSpan={2}>Total no. of bails been granted by JJB</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.bails_been_granted_by_jjb}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    bails_been_granted_by_jjb: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td colSpan={2}>
                                        Total No. of children been transferred from JJB to the Court
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.children_been_transferred_from_jjb_to_the_court
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_been_transferred_from_jjb_to_the_court:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td colSpan={2}>
                                        No. of cases in which inquiry completed within 4 months
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.cases_in_which_inquiry_completed_within_4_months
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    cases_in_which_inquiry_completed_within_4_months:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td colSpan={2}>
                                        Total No. of cases of petty offences disposed of due to time
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.cases_of_petty_offences_disposed_of_due_to_time
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    cases_of_petty_offences_disposed_of_due_to_time:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td colSpan={2}>
                                        Total No. of children released on probation of good conduct
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.children_released_on_probation_of_good_conduct
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_released_on_probation_of_good_conduct:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="extra">
                                        Information regarding The Prohibition of Child Marriage Act
                                    </td>
                                    <td className="extra"></td>
                                    <td className="extra"></td>
                                </tr>
                                <tr>
                                    <td rowSpan={11}>SPCMA1WCD</td>
                                    <td>1</td>
                                    <td colSpan={2}>No. of Child Marriages reported to Police</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.child_marriages_reported_to_police}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    child_marriages_reported_to_police: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td colSpan={2}>
                                        No. of Child Marriages reported to Child Marriage
                                        Prohibition Officers{" "}
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.child_marriages_reported_to_child_marriage_prohibition_officers
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    child_marriages_reported_to_child_marriage_prohibition_officers:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td colSpan={2}>
                                        No. of Child Marriages reported to First Class Judicial
                                        Magistrate or Metropolitan Magistrate
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.child_marriages_reported_to_first_class_judicial_magistrate
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    child_marriages_reported_to_first_class_judicial_magistrate:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td colSpan={2}>
                                        No. of Child Marriages reported to Child Welfare Committee
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.child_marriages_reported_to_child_welfare_committee
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    child_marriages_reported_to_child_welfare_committee:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td colSpan={2}>
                                        No. of Child Marriages reported to District Magistrate
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.child_marriages_reported_to_district_magistrate
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    child_marriages_reported_to_district_magistrate:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td colSpan={2}>No. of CMPOs appointed U / s 6 of the Act</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.cmpos_appointed_u_s_6_of_the_act}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    cmpos_appointed_u_s_6_of_the_act: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td colSpan={2}>
                                        No. of Child Marriages prevented by DM u / s 13(5) of the
                                        Act
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.child_marriages_prevented_by_dm_u_s_13_5_of_the
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    child_marriages_prevented_by_dm_u_s_13_5_of_the:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td colSpan={2}>
                                        No. of Child Marriages prevented by CMPOs Under section 16
                                        (3) (a) of the Act
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.child_marriages_prevented_by_cmpos_under_section
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    child_marriages_prevented_by_cmpos_under_section:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td colSpan={2}>
                                        No. of Mass Child Marriages prevented by Under section 16
                                        (3) (a) of the Act
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.mass_child_marriages_prevented_by_under_section
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    mass_child_marriages_prevented_by_under_section:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td colSpan={2}>
                                        No. of Awareness Programmes conducted Under section 16(3)(d)
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.awareness_programmes_conducted_under_section_16_3_d
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    awareness_programmes_conducted_under_section_16_3_d:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td colSpan={2}>
                                        No. of Sensitization Programmes conducted Under section
                                        16(3)e
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.sensitization_programmes_conducted_under_section_16_3_e
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    sensitization_programmes_conducted_under_section_16_3_e:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="extra">
                                        Information regarding Protection of Children from Sexual
                                        Offences (POCSO) Act{" "}
                                    </td>
                                    <td className="extra"></td>
                                    <td className="extra"></td>
                                </tr>
                                <tr>
                                    <td rowSpan={17}>SPOCSO1WCD</td>
                                    <td rowSpan={1}>1</td>
                                    <td colSpan={1} rowSpan={6}>
                                        Infrastructure facilities in Special Courts u/s 28 POCSO Act
                                        2012
                                    </td>
                                    <td>No of Districts</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.no_of_districts}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    no_of_districts: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={1}>2</td>
                                    <td>No of Special Courts established u/s 28 of POCSO Act</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.special_courts_established_u_s_28_of_pocso_act
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    special_courts_established_u_s_28_of_pocso_act:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={1}>3</td>
                                    <td>
                                        No of Special Courts dealing exclusively with POCSO cases
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.special_courts_dealing_exclusively_with_pocso_cases
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    special_courts_dealing_exclusively_with_pocso_cases:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={1}>4</td>
                                    <td>
                                        No of Special Courts designated with additional charge other
                                        than POCSO cases
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.special_courts_designated_with_additional_charge
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    special_courts_designated_with_additional_charge:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={1}>5</td>
                                    <td>
                                        No of Special courts equipped for following provisions u/s
                                        36 POCSO Act 2012 (for video conferencing)
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.special_courts_equipped_for_following_provisions
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    special_courts_equipped_for_following_provisions:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={1}>6</td>
                                    <td>
                                        No of Special courts having availability of interpreter or
                                        expert u/s 38 POCSO Act 2012
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.special_courts_having_availability_of_interpreter
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    special_courts_having_availability_of_interpreter:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={1}>7</td>
                                    <td colSpan={1} rowSpan={3}>
                                        Special Public Prosecutors u/s 32 POCSO Act 2012
                                    </td>
                                    <td>
                                        No of Special Public Prosecutors appointed u/s 32 of POCSO
                                        Act
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.special_public_prosecutors_appointed_u_s_32_of_pocso_act
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    special_public_prosecutors_appointed_u_s_32_of_pocso_act:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={1}>8</td>
                                    <td>
                                        Special Public Prosecutors conducting exclusively with POCSO
                                        cases
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.special_public_prosecutors_conducting_exclusively
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    special_public_prosecutors_conducting_exclusively:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={1}>9</td>
                                    <td>
                                        Special Public Prosecutors conducting POCSO cases in
                                        addition to other cases
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.special_public_prosecutors_conducting_pocso_cases_in_addition
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    special_public_prosecutors_conducting_pocso_cases_in_addition:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={1}>10</td>
                                    <td colSpan={1} rowSpan={3}>
                                        Public Awareness about the Act u/s 43 POCSO Act
                                    </td>
                                    <td>
                                        Whether any awareness programs/campaigns have been conducted
                                        for provisions of POCSO Act
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.programs_campaigns_have_been_conducted_for_provisions
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    programs_campaigns_have_been_conducted_for_provisions:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={1}>11</td>
                                    <td>
                                        No of trainings specifically imparted on POCSO Act to State
                                        Government officers (Yes or No) if yes, how many? If no,
                                        reasons thereof
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.training_imparted_on_pocso_act_to_state_govt_officer
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    training_imparted_on_pocso_act_to_state_govt_officer:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={1}>12</td>
                                    <td>
                                        No of trainings specifically imparted on POCSO Act to Police
                                        officers
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.trainings_imparted_on_pocso_act_to_police_officers
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    trainings_imparted_on_pocso_act_to_police_officers:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={1}>13</td>
                                    <td colSpan={1} rowSpan={2}>
                                        Implementation of Rule 6 of POCSO Rules
                                    </td>
                                    <td>
                                        Whether Guidelines for professionals to assist the child at
                                        pre trial and trial stage have been prepared u/s 39 of POCSO
                                        Act, 2012
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.professionals_to_assist_the_child_at_pre_trial_and_trial_stag
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    professionals_to_assist_the_child_at_pre_trial_and_trial_stag:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={1}>14</td>
                                    <td>
                                        Whether Training modules have been prepared for effective
                                        discharge of functions under the Act. [Rule 6 (d) POCSO
                                        Rules 2012]
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.training_modules_prepared_for_effective_discharge_of_functions
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    training_modules_prepared_for_effective_discharge_of_functions:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={1}>15</td>
                                    <td colSpan={1} rowSpan={3}>
                                        Implementation of Rule 7 of POCSO Rules 2012
                                    </td>
                                    <td>
                                        Whether any victim compensation fund/scheme has been
                                        created?
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={
                                                data.any_victim_compensation_fund_scheme_has_been_created
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    any_victim_compensation_fund_scheme_has_been_created:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={1}>16</td>
                                    <td>
                                        No of cases where compensation has been provided to victim
                                        (interim and final)
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.cases_where_compensation_has_been_provided_to_victim
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    cases_where_compensation_has_been_provided_to_victim:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={1}>17</td>
                                    <td>
                                        No of cases where compensation has been paid within 30 days
                                        of order of Special Court u/r 7 (5) of POCSO Rules 2012)
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.compensation_paid_within_30_days_of_order_of_Special_Court
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    compensation_paid_within_30_days_of_order_of_Special_Court:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text"></input>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={openForm1}
                            type="button"
                        >
                            Save & Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Form28;
