import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";
import { toast, Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { UPDATE_QPRHC, UPDATE_QPRSC } from "../utils/constant";

// FORM 27 QPR supreme court

function Form27(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { onSubmit }] = useFormStore();
    const [{ }, { updateReport }] = useFormStore();
    const [{ }, { formUpdated }] = useFormStore();
    const Qyear = props.Qyear;
    const Qmonth = props.Qmonth;
    const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
    const DisplayQuarter = Quarter[Qmonth];

    const [data, setdata] = useState({
        jjbs_in_the_state: 0,
        districts_in_state_where_no_jjb_is_operating: 0,
        districts_in_state_where_more_than_one_jjb_is_operating: 0,
        //b
        jjbs_opening_balance_at_the_start_of_the_quarter: 0,
        cases_instituted_during_the_quarter: 0,
        cases_before_the_jjbs_cases_disposed_of_during_the_quarter: 0,
        cases_before_the_jjbs_cases_pending_at_the_end_of_the_quarter: 0,
        cases_pending_for_a_period_of_less_than_4_months: 0,
        cases_pending_for_a_period_of_more_than_4_months: 0,
        cases_pending_for_a_period_of_more_than_6_months: 0,
        cases_pending_for_a_period_of_more_than_1_year: 0,
        //c
        sanctioned_strength_of_jjb_staff_in_the_state: 0,
        working_strength_of_jjb_staff_in_the_state: 0,
        vacancies_in_jjb_staff_in_the_state: 0, //14
        //d
        any_effort_been_made_to_fill_the_existing_vacancies: "Null",
        any_effort_been_made_to_fill_the_existing_vacancies_details: "Null",
        //a) Observation Homes
        observation_homes_in_state_maintained_by_state_govt_itself: 0,
        observation_homes_in_state_maintained_by_state_govt: 0,
        children_placed_in_observation_home_in_the_state: 0,
        districts_in_the_state_without_any_observation_homes: 0, //20
        //b spec
        special_homes_in_state_maintained_by_state_govt_itself: 0,
        special_homes_in_the_state_maintained_by_the_state: 0,
        children_placed_in_special_home_in_the_state: 0,
        districts_in_the_state_without_any_special_homes: 0,
        //c) Place of Safety
        place_of_safety_in_the_state: 0,
        children_placed_in_place_of_safety: 0,
        //cwcs
        cwcs_in_the_state: 0,
        districts_in_state_where_no_cwc_is_operating: 0,
        districts_state_where_more_than_one_cwc_is_operating: 0,
        //a) Children's Homes
        children_homes_in_state_maintained_by_state_govt_itself: 0, //30
        children_homes_in_the_state_maintained_by_the_state_govt: 0,
        children_placed_in_children_home_in_the_state: 0,
        districts_in_the_state_without_any_children_homes: 0,
        //c) Fit Facility
        full_time_staff_in_children_home_in_state_sanctioned_strength: 0,
        full_time_staff_in_children_home_in_state_working_strength: 0,
        full_time_staff_in_children_home_in_state_vacancies: 0,
        part_time_staff_in_children_home_in_state_sanctioned_strength: 0,
        part_time_staff_in_children_home_in_state_working_strength: 0,
        part_time_staff_in_children_home_in_state_vacancies: 0,
        staff_given_additional_charge_of_children_home_in_the_state: 0, //40
        //go
        governmental_oganization_recognised_as_fit_facility: 0,
        non_governmental_oganization_recognised_as_fit_facility: 0,
        children_placed_in_fit_facility_in_the_state: 0,
        //d) Fit Persons
        persons_in_the_state_recognised_as_fit_facility: 0,
        children_placed_with_fit_persons_in_the_state: 0,
        //e) Open Shelter
        open_shelters_in_state_maintained_by_state_govt_itself: 0,
        open_shelters_in_the_state_maintained_by_the_state_govt: 0,
        total_open_shelters_in_the_state: 0,
        children_placed_in_open_shelters_in_the_state: 0,
        //a) Specialised Adoption Agencies
        ssa_in_the_state: 0, //50
        districts_in_the_state_where_no_recognised_ssa_is_operating: 0,
        districts_in_the_state_where_more_than_one_ssa_is_operating: 0,
        //b) Pendency of cases before the CWC for declaring a child free for Adoption
        cws_opening_balance_at_the_start_of_the_quarter: 0,
        cases_filed_during_the_quarter: 0,
        cases_before_the_cws_cases_disposed_of_during_the_quarter: 0,
        cases_before_the_cws_cases_pending_at_the_end_of_the_quarter: 0,
        pending_cases_pertaining_to_children_below_the_age_of_2_years: 0,
        cases_pertaining_to_children_below_the_age_of_2_years: 0,
        pending_cases_pertaining_to_children_above_the_age_of_2_years: 0,
        cases_pertaining_to_children_above_the_age_of_2_years: 0, //60
        //c) Number of children in the Child Care Institution in the districts
        children_in_the_child_care_institution_in_the_districts: 0,
        //Adoption Orders
        opening_balance_at_the_start_of_the_quarter_d: 0,
        cases_filed_during_the_quarter_d: 0,
        cases_disposed_of_during_the_quarter_d: 0,
        cases_pending_at_the_end_of_the_quarter_d: 0,
        cases_that_have_been_pending_for_more_than_2_months: 0,
        //4. Institutional Mechanisms for protection of children
        has_a_child_protection_society_been_constituted_for_the_state: "Null",
        districts_child_protection_units_in_the_state: 0,
        //c) Details of officers/staffs of the DCPU's
        full_time_staff_in_the_dcpu_sanctioned_strength: 0,
        full_time_staff_in_the_dcpu_working_strength: 0, //70
        full_time_staff_in_the_dcpu_vacancies: 0,
        part_time_staff_in_the_dcpu_sanctioned_strength: 0,
        part_time_staff_in_the_dcpu_working_strength: 0,
        part_time_staff_in_the_dcpu_vacancies: 0,
        total_staff_of_all_dcpus_in_the_state_sanctioned_strength: 0,
        total_staff_of_all_dcpus_in_the_state_working_strength: 0,
        total_staff_of_all_dcpus_in_the_state_vacancies: 0,
        staff_given_additional_charge_of_childrens_home: 0,
        staff_given_additional_charge_of_childrens_home_as_on_31_12_21: 0,
        //d) Number of Child Welfare Police Officers in the districts
        child_welfare_police_officers_in_the_districts: 0,
        //e) Special Juvenile Police Units
        sjpus_in_the_state: 0, //80
        police_officers_in_the_state_designated_as_members_of_sjpus: 0,
        members_of_the_sjpus_who_have_been_provided_induction_training: 0,
        districts_in_the_state_where_sjpus_have_not_been_constituted: 0,
        cities_in_the_state_where_sjpus_have_not_been_constituted: 0,
        month: Qmonth,
        year: Qyear,
    });

    useEffect(() => {
        if ((props || {}).isEdit) {
            const { id, created_at, updated_at, username, user, report_type, ...reportSorted } = props.report.find((res) => { return res.month === Qmonth && res.year === Qyear; }) || {};
            setdata({
                ...data,
                ...reportSorted
            });
        }
    }, []);

    const openForm1 = () => {
        if (props.isEdit) {
            return toast.promise(
                updateReport(data, UPDATE_QPRSC),
                {
                    loading: 'Updating Record',
                    success: 'Record Updated',
                    error: 'Error while saving'
                }
            ).then(() => formUpdated(true));
        } else {
            props.close();
            onSubmit(data, "qprsc");
            setmodalIsOpen(false);
        }
    };
    const openForm = () => {
        props.close();
        setmodalIsOpen(false);
    };
    return (
        <div>
            <Modal isOpen={modalIsOpen}>
            <Toaster position="bottom-right" />
            <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            QPR supreme court
                        </h5>

                        <button
                            className="btn-close"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <h4>{DisplayQuarter} {Qyear}</h4>
                    <div className="modal-body">
                        <h3> STRENGTHENING OF JUVENILE JUSTICE SYSTEM </h3>
                        <h3>
                            {" "}
                            Quarterly Report for the time period 1.10.2021 - 31.12.2021{" "}
                        </h3>
                        <h4> A. Children in Conflict with Law </h4>
                        <h5> 1. Juvenile Justice Boards </h5>
                        <h6>
                            {" "}
                            a) Number of JJBs in the district(Established under section 4 of
                            the JJ Act, 2015):-{" "}
                        </h6>
                        <table>
                            <tr>
                                <th>Sl. No.</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>No. of JJBs in the district</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.jjbs_in_the_state}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                jjbs_in_the_state: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        {/* Pendency of cases before the JJBs*/}
                        <h6> b) Pendency of cases before the JJBs:- </h6>

                        <table>
                            <tr>
                                <th>Sl. No.</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Opening Balance at the start of the quarter</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.jjbs_opening_balance_at_the_start_of_the_quarter
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                jjbs_opening_balance_at_the_start_of_the_quarter:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>No of cases instituted during the quarter</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_instituted_during_the_quarter}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_instituted_during_the_quarter: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>No of cases disposed of during the quarter</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.cases_before_the_jjbs_cases_disposed_of_during_the_quarter
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_before_the_jjbs_cases_disposed_of_during_the_quarter:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>No. of cases pending at the end of the quarter</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.cases_before_the_jjbs_cases_pending_at_the_end_of_the_quarter
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_before_the_jjbs_cases_pending_at_the_end_of_the_quarter:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>
                                    No of cases pending for a period of less than 4 months at the
                                    end of the quarter
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.cases_pending_for_a_period_of_less_than_4_months
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_for_a_period_of_less_than_4_months:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>
                                    No of cases pending for a period of more than 4 months but
                                    less than 6 months at the end of the quarter
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.cases_pending_for_a_period_of_more_than_4_months
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_for_a_period_of_more_than_4_months:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>
                                    No of cases pending for a period of more than 6 months but
                                    less than 1 year at the end of the quarter
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.cases_pending_for_a_period_of_more_than_6_months
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_for_a_period_of_more_than_6_months:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>
                                    No of cases pending for a period of more than 1 year at the
                                    end of the quarter
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_pending_for_a_period_of_more_than_1_year}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_for_a_period_of_more_than_1_year:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        {/* Details with regards to staff in JJBs */}
                        <h6> c) Details with regards to staff in JJBs:- </h6>
                        <table>
                            <tr>
                                <th>Sl. No.</th>
                                <th>Particulars</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Total Sanctioned strength of JJB staff in the district</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sanctioned_strength_of_jjb_staff_in_the_state}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sanctioned_strength_of_jjb_staff_in_the_state:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Total Working strength of JJB staff in the district</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.working_strength_of_jjb_staff_in_the_state}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                working_strength_of_jjb_staff_in_the_state:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Total Vacancies of JJB staff in the district</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.vacancies_in_jjb_staff_in_the_state}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                vacancies_in_jjb_staff_in_the_state: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        {/*d) 1. Have any effort been made to fill the existing vacancies in the cadre of staff of the JJB's?  */}
                        <h6>
                            {" "}
                            d) 1. Have any effort been made to fill the existing vacancies in
                            the cadre of staff of the JJB's?{" "}
                        </h6>
                        <input
                            type="text"
                            min="1"
                            value={data.any_effort_been_made_to_fill_the_existing_vacancies}
                            onChange={(e) =>
                                setdata({
                                    ...data,
                                    any_effort_been_made_to_fill_the_existing_vacancies:
                                        e.target.value,
                                })
                            }
                        />
                        <h6>
                            {" "}
                            2. If Yes, please provide details (Kindly answer in 150 words){" "}
                        </h6>
                        <div className="new-expense__control">
                            <textarea
                                value={
                                    data.any_effort_been_made_to_fill_the_existing_vacancies_details
                                }
                                onChange={(e) =>
                                    setdata({
                                        ...data,
                                        any_effort_been_made_to_fill_the_existing_vacancies_details:
                                            e.target.value,
                                    })
                                }
                            >
                                {" "}
                            </textarea>
                        </div>

                        {/* 2. Institutional Care of Children in Conflict with Law */}
                        <h5> 2. Institutional Care of Children in Conflict with Law </h5>
                        <h6>
                            {" "}
                            a) Observation Homes (established under section 42(1) of the JJ
                            (Care and Protection of Children) Act, 2015){" "}
                        </h6>
                        <div className="new-expense__control">
                            <table>
                                <tr>
                                    <th>Sl.No</th>
                                    <th>Particulars</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>
                                        No. of Observation Homes in the district maintained by the
                                        state govt itself
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.observation_homes_in_state_maintained_by_state_govt_itself
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    observation_homes_in_state_maintained_by_state_govt_itself:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>
                                        No. of Observation Homes in the district maintained by the
                                        state govt through voluntary or non – governmental
                                        organization
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.observation_homes_in_state_maintained_by_state_govt
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    observation_homes_in_state_maintained_by_state_govt:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>
                                        No. of children placed in Observation Home in the district
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.children_placed_in_observation_home_in_the_state
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_placed_in_observation_home_in_the_state:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <h6>
                            {" "}
                            b) Special Homes (established under section 48(1) of the JJ Act,
                            2015){" "}
                        </h6>
                        <div className="new-expense__control">
                            <table>
                                <tr>
                                    <th>Sl.No</th>
                                    <th>Particulars</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>
                                        No. of Special Homes in the district maintained by the state
                                        govt itself
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.special_homes_in_state_maintained_by_state_govt_itself
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    special_homes_in_state_maintained_by_state_govt_itself:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>
                                        No. of Special Homes in the district maintained by the state
                                        govt through voluntary or non – governmental organization
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.special_homes_in_the_state_maintained_by_the_state
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    special_homes_in_the_state_maintained_by_the_state:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>
                                        No. of children placed in Special Homes in the district
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.children_placed_in_special_home_in_the_state}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_placed_in_special_home_in_the_state:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <h6>
                            {" "}
                            c) Place of Safety (established under section 49 of the JJ Act,
                            2015){" "}
                        </h6>
                        <div className="new-expense__control">
                            <table>
                                <tr>
                                    <th>Sl.No</th>
                                    <th>Particulars</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>No. of Place of Safety in the district</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.place_of_safety_in_the_state}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    place_of_safety_in_the_state: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>No. of children placed in Place of Safety</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.children_placed_in_place_of_safety}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_placed_in_place_of_safety: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <h4> B. Children in Need of Care and Protection </h4>
                        <h6>
                            {" "}
                            1. Child Welfare Committees (Constituted under section 27(1) of
                            the JJ Act, 2015):-{" "}
                        </h6>
                        <div className="new-expense__control">
                            <table>
                                <tr>
                                    <th>Sl.No</th>
                                    <th>Particulars</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>No. of CWCs in the district</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.cwcs_in_the_state}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    cwcs_in_the_state: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>
                                        No. of Districts in the district where no CWC is operating
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.districts_in_state_where_no_cwc_is_operating}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    districts_in_state_where_no_cwc_is_operating:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <h5>
                            {" "}
                            2. Institutional Care of Children in Need of Care and Protection{" "}
                        </h5>
                        <h6>
                            {" "}
                            a) Children's Homes (established under section 50 of the JJ Act,
                            2015):-{" "}
                        </h6>
                        <div className="new-expense__control">
                            <table>
                                <tr>
                                    <th>Sl.No</th>
                                    <th>Particulars</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>
                                        No. of Children's Homes in the district maintained by the
                                        state govt itself
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.children_homes_in_state_maintained_by_state_govt_itself
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_homes_in_state_maintained_by_state_govt_itself:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>
                                        No. of Children's Homes in the district maintained by the
                                        state govt through voluntary or non – governmental
                                        organization
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.children_homes_in_the_state_maintained_by_the_state_govt
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_homes_in_the_state_maintained_by_the_state_govt:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>
                                        No. of children placed in Children's Homes in the district
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.children_placed_in_children_home_in_the_state}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_placed_in_children_home_in_the_state:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/* b) Staffs in Children's Home */}
                        <h6> b) Staffs in Children's Home </h6>
                        <div className="new-expense__control">
                            <table>
                                <tr>
                                    <th>Sl.No</th>
                                    <th>Particulars</th>
                                    <th>Strength of staff</th>
                                    <th>Remarks</th>
                                </tr>
                                <tr>
                                    <td rowSpan={3}>1</td>
                                    <td rowSpan={3}>
                                        Total no. of full time staff in all the children's home in
                                        the district
                                    </td>
                                    <td>Sanctioned strength</td>
                                    <td>Only Government run home</td>
                                    {/* <input
                      type="number"
                      min="1"
                      value={
                        data.full_time_staff_in_children_home_in_state_sanctioned_strength
                      }
                      onChange={(e) =>
                        setdata({
                          ...data,
                          full_time_staff_in_children_home_in_state_sanctioned_strength:
                            e.target.value,
                        })
                      }
                    /> */}
                                </tr>
                                <tr>
                                    <td>Working strength</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.full_time_staff_in_children_home_in_state_working_strength
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    full_time_staff_in_children_home_in_state_working_strength:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Vacancies</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.full_time_staff_in_children_home_in_state_vacancies
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    full_time_staff_in_children_home_in_state_vacancies:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={3}>2</td>
                                    <td rowSpan={3}>
                                        Total no. of part time staff in all the children's home in
                                        the district
                                    </td>
                                    <td>Sanctioned strength</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.part_time_staff_in_children_home_in_state_sanctioned_strength
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    part_time_staff_in_children_home_in_state_sanctioned_strength:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Working strength</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.part_time_staff_in_children_home_in_state_working_strength
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    part_time_staff_in_children_home_in_state_working_strength:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Vacancies</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.part_time_staff_in_children_home_in_state_vacancies
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    part_time_staff_in_children_home_in_state_vacancies:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>
                                        No. of staff given Additional charge of children's home in
                                        the district
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.staff_given_additional_charge_of_children_home_in_the_state
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    staff_given_additional_charge_of_children_home_in_the_state:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input type="text" />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {/*  */}
                        <h6>
                            {" "}
                            c) Fit Facility (Recognised under section 51 of the JJ Act,
                            2015):-{" "}
                        </h6>
                        <div className="new-expense__control">
                            <table>
                                <tr>
                                    <th>Sl.No</th>
                                    <th>Particulars</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>
                                        No. of Governmental Organization recognised as Fit Facility
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.governmental_oganization_recognised_as_fit_facility
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    governmental_oganization_recognised_as_fit_facility:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>
                                        No. of Non-Governmental Organization recognised as Fit
                                        Facility
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.non_governmental_oganization_recognised_as_fit_facility
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    non_governmental_oganization_recognised_as_fit_facility:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>
                                        No. of children placed in Fit Facility in the district
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.children_placed_in_fit_facility_in_the_state}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_placed_in_fit_facility_in_the_state:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <h6>
                            {" "}
                            d) Fit Persons (Recognised under section 52 of the JJ Act, 2015):-{" "}
                        </h6>
                        <div className="new-expense__control">
                            <table>
                                <tr>
                                    <th>Sl.No</th>
                                    <th>Particulars</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>
                                        No. of persons in the district recognised as Fit Facility
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.persons_in_the_state_recognised_as_fit_facility
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    persons_in_the_state_recognised_as_fit_facility:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>
                                        No. of children placed with Fit persons in the district
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.children_placed_with_fit_persons_in_the_state}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_placed_with_fit_persons_in_the_state:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <h6>
                            {" "}
                            e) Open Shelter (established under section 43 of the JJ Act,
                            2015):-{" "}
                        </h6>
                        <div className="new-expense__control">
                            <table>
                                <tr>
                                    <th>Sl.No</th>
                                    <th>Particulars</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>
                                        No. of Open Shelters in the district maintained by the state
                                        govt itself
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.open_shelters_in_state_maintained_by_state_govt_itself
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    open_shelters_in_state_maintained_by_state_govt_itself:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>
                                        No. of Open Shelters in the district maintained by the state
                                        govt through voluntary or non – governmental organization
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={
                                                data.open_shelters_in_the_state_maintained_by_the_state_govt
                                            }
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    open_shelters_in_the_state_maintained_by_the_state_govt:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Total no. of Open Shelters in the district</td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.total_open_shelters_in_the_state}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    total_open_shelters_in_the_state: e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>
                                        No. of children placed in Open Shelters in the district
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.children_placed_in_open_shelters_in_the_state}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_placed_in_open_shelters_in_the_state:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                            </table>
                            <h5> 3. Adoption under section 56 of the JJ Act, 2015 </h5>
                            <h6>
                                {" "}
                                a) Specialised Adoption Agencies (Recognised under section 65 of
                                the JJ Act, 2015){" "}
                            </h6>
                            <div className="new-expense__control">
                                <table>
                                    <tr>
                                        <th>Sl.No</th>
                                        <th>Particulars</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>No. of SAA in the district</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={data.ssa_in_the_state}
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        ssa_in_the_state: e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <h6>
                                {" "}
                                b) Pendency of cases before the CWC for declaring a child free
                                for Adoption{" "}
                            </h6>
                            <div className="new-expense__control">
                                <table>
                                    <tr>
                                        <th>Sl.No</th>
                                        <th>Particulars</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Opening balance at the start of the quarter</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.cws_opening_balance_at_the_start_of_the_quarter
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        cws_opening_balance_at_the_start_of_the_quarter:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>No. of cases filed during the quarter</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={data.cases_filed_during_the_quarter}
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        cases_filed_during_the_quarter: e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>No. of cases disposed of during the quarter</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.cases_before_the_cws_cases_disposed_of_during_the_quarter
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        cases_before_the_cws_cases_disposed_of_during_the_quarter:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>
                                            Total no. of cases pending at the end of the quarter
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.cases_before_the_cws_cases_pending_at_the_end_of_the_quarter
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        cases_before_the_cws_cases_pending_at_the_end_of_the_quarter:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>
                                            No. of pending cases pertaining to children below the age
                                            of 2 years at the end of the quarter
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.pending_cases_pertaining_to_children_below_the_age_of_2_years
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        pending_cases_pertaining_to_children_below_the_age_of_2_years:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>
                                            No. of the cases pertaining to children below the age of 2
                                            years that have been pending for more than 2 months at the
                                            end of the quarter (from the date of production of the
                                            child before the CWC)
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.cases_pertaining_to_children_below_the_age_of_2_years
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        cases_pertaining_to_children_below_the_age_of_2_years:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>
                                            No. of pending cases pertaining to children above the age
                                            of 2 years at the end of the quarter
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.pending_cases_pertaining_to_children_above_the_age_of_2_years
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        pending_cases_pertaining_to_children_above_the_age_of_2_years:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>
                                            No. of the cases pertaining to children above the age of 2
                                            years that have been pending for more than 4 months at the
                                            end of the quarter (from the date of production of the
                                            child before the CWC)
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.cases_pertaining_to_children_above_the_age_of_2_years
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        cases_pertaining_to_children_above_the_age_of_2_years:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <h6>
                                {" "}
                                c) Number of children in the Child Care Institution in the
                                districts whose information has been uploaded to the Child
                                Adoption Resource Information and Guidance System (CARIGS):-
                            </h6>
                            <input
                                type="number"
                                min="1"
                                value={
                                    data.children_in_the_child_care_institution_in_the_districts
                                }
                                onChange={(e) =>
                                    setdata({
                                        ...data,
                                        children_in_the_child_care_institution_in_the_districts:
                                            e.target.value,
                                    })
                                }
                            />
                            <h6> d) Adoption Orders (as per section 61, JJ Act, 2015) </h6>
                            <div className="new-expense__control">
                                <table>
                                    <tr>
                                        <th>Sl.No</th>
                                        <th>Particulars</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Opening balance at the start of the quarter</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.opening_balance_at_the_start_of_the_quarter_d
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        opening_balance_at_the_start_of_the_quarter_d:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>No. of cases filed during the quarter</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={data.cases_filed_during_the_quarter_d}
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        cases_filed_during_the_quarter_d: e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>No. of cases disposed of during the quarter</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={data.cases_disposed_of_during_the_quarter_d}
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        cases_disposed_of_during_the_quarter_d:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>
                                            Total no. of cases pending at the end of the quarter
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={data.cases_pending_at_the_end_of_the_quarter_d}
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        cases_pending_at_the_end_of_the_quarter_d:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>
                                            No. of cases that have been pending for more than 2 months
                                            at the end of the quarter
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.cases_that_have_been_pending_for_more_than_2_months
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        cases_that_have_been_pending_for_more_than_2_months:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <h5> 4. Institutional Mechanisms for protection of children </h5>
                            <h6>
                                {" "}
                                a) Has a Child Protection Society been constituted for the
                                district as directed by section 106 of the JJ Act, 2015? :-{" "}
                            </h6>
                            <input
                                type="text"
                                min="1"
                                value={
                                    data.has_a_child_protection_society_been_constituted_for_the_state
                                }
                                onChange={(e) =>
                                    setdata({
                                        ...data,
                                        has_a_child_protection_society_been_constituted_for_the_state:
                                            e.target.value,
                                    })
                                }
                            />
                            <h6> b)Number of Districts Child Protection Units in the State established under section 106 of the JJ Act,2015:- </h6>
                            <input
                                type="text"
                                min="1"
                                value={
                                    data.districts_child_protection_units_in_the_state
                                }
                                onChange={(e) =>
                                    setdata({
                                        ...data,
                                        districts_child_protection_units_in_the_state:
                                            e.target.value,
                                    })
                                }
                            />

                            <h6> c) Details of officers/staffs of the DCPU's </h6>
                            <div className="new-expense__control">
                                <table>
                                    <tr>
                                        <th>Sl.No</th>
                                        <th>Particulars</th>
                                        <th>Strength of staff</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <td rowSpan={3}>1</td>
                                        <td rowSpan={3}>Full – time staff in the DCPU</td>
                                        <td>Sanctioned strength</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.full_time_staff_in_the_dcpu_sanctioned_strength
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        full_time_staff_in_the_dcpu_sanctioned_strength:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Working strength</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.full_time_staff_in_the_dcpu_working_strength
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        full_time_staff_in_the_dcpu_working_strength:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Vacancies</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={data.full_time_staff_in_the_dcpu_vacancies}
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        full_time_staff_in_the_dcpu_vacancies:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={3}>2</td>
                                        <td rowSpan={3}>Part – time staff in the DCPU</td>
                                        <td>Sanctioned strength</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.part_time_staff_in_the_dcpu_sanctioned_strength
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        part_time_staff_in_the_dcpu_sanctioned_strength:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Working strength</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.part_time_staff_in_the_dcpu_working_strength
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        part_time_staff_in_the_dcpu_working_strength:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Vacancies</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={data.part_time_staff_in_the_dcpu_vacancies}
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        part_time_staff_in_the_dcpu_vacancies:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={3}>3</td>
                                        <td rowSpan={3}>Total staff of all DCPU's in the State</td>
                                        <td>Sanctioned strength</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.total_staff_of_all_dcpus_in_the_state_sanctioned_strength
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        total_staff_of_all_dcpus_in_the_state_sanctioned_strength:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Working strength</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.total_staff_of_all_dcpus_in_the_state_working_strength
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        total_staff_of_all_dcpus_in_the_state_working_strength:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Vacancies</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.total_staff_of_all_dcpus_in_the_state_vacancies
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        total_staff_of_all_dcpus_in_the_state_vacancies:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={3}>4</td>
                                        <td rowSpan={3}>
                                            No. of staff given Additional Charge of Children's Homes
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.staff_given_additional_charge_of_childrens_home
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        staff_given_additional_charge_of_childrens_home:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.staff_given_additional_charge_of_childrens_home_as_on_31_12_21
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        staff_given_additional_charge_of_childrens_home_as_on_31_12_21:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <h6>
                                {" "}
                                d) Number of Child Welfare Police Officers in the districts
                                (designated under section 107(1) of the JJ Act, 2015):-{" "}
                            </h6>
                            <input
                                type="number"
                                min="1"
                                value={data.child_welfare_police_officers_in_the_districts}
                                onChange={(e) =>
                                    setdata({
                                        ...data,
                                        child_welfare_police_officers_in_the_districts:
                                            e.target.value,
                                    })
                                }
                            />
                            <h6>
                                {" "}
                                e) Special Juvenile Police Units (Constituted under section 107
                                (2) of the JJ Act, 2015):-{" "}
                            </h6>
                            <div className="new-expense__control">
                                <table>
                                    <tr>
                                        <th>Sl.No</th>
                                        <th>Particulars</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>No. of SJPUs in the district</td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={data.sjpus_in_the_state}
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        sjpus_in_the_state: e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>
                                            No. of Police Officers in the district designated as
                                            members of SJPUs
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.police_officers_in_the_state_designated_as_members_of_sjpus
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        police_officers_in_the_state_designated_as_members_of_sjpus:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>
                                            No. of members of the SJPUs who have been provided
                                            induction training
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.members_of_the_sjpus_who_have_been_provided_induction_training
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        members_of_the_sjpus_who_have_been_provided_induction_training:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>
                                            No. of districts in the state where SJPUs have not been constituted
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.districts_in_the_state_where_sjpus_have_not_been_constituted
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        districts_in_the_state_where_sjpus_have_not_been_constituted:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>
                                            No. of cities in the state where SJPUs have not been constituted
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                min="1"
                                                value={
                                                    data.cities_in_the_state_where_sjpus_have_not_been_constituted
                                                }
                                                onChange={(e) =>
                                                    setdata({
                                                        ...data,
                                                        cities_in_the_state_where_sjpus_have_not_been_constituted:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={openForm1}
                            type="button"
                        >
                            Save & Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Form27;
