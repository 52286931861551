import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";
import { getStorage } from "../common/functions/Storage";
import "./Form.css";
import { useFormStore } from "../Store/Forms";
import { UPDATE_QPRHC } from "../utils/constant";
import { Toaster, toast } from "react-hot-toast";
// FORM 29  HIGHCOURT

function Form29(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { onSubmit }] = useFormStore();
    const [{ }, { updateReport }] = useFormStore();
    const [{ }, { formUpdated }] = useFormStore();
    const Qyear = props.Qyear;
    const Qmonth = props.Qmonth;
    const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
    const DisplayQuarter = Quarter[Qmonth];

    const [data, setdata] = useState({
        registered_total_cases_1: 0,
        registered_petty_1: 0,
        registered_serious_1: 0,
        registered_heinous_1: 0,
        registered_7_10_1: 0,
        registered_11_13_1: 0,
        registered_14_15_1: 0,
        registered_16_18_1: 0,
        registered_male_1: 0,
        registered_female_1: 0,
        registered_general_1: 0,
        registered_sc_1: 0,
        registered_st_1: 0,
        registered_obc_1: 0,
        registered_not_known_1: 0,
        registered_apl_1: 0,
        registered_bpl_1: 0,
        registered_illiterate_1: 0,
        registered_primary_1: 0,
        registered_secondary_1: 0,
        // indicator : 2
        disposed_total_cases_2: 0,
        disposed_petty_2: 0,
        disposed_serious_2: 0,
        disposed_heinous_2: 0,
        disposed_7_10_2: 0,
        disposed_11_13_2: 0,
        disposed_14_15_2: 0,
        disposed_16_18_2: 0,
        disposed_male_2: 0,
        disposed_female_2: 0,
        disposed_general_2: 0,
        disposed_sc_2: 0,
        disposed_st_2: 0,
        disposed_obc_2: 0,
        disposed_not_known_2: 0,
        disposed_apl_2: 0,
        disposed_bpl_2: 0,
        disposed_illiterate_2: 0,
        disposed_primary_2: 0,
        disposed_secondary_2: 0,
        // indicator : 3
        pending_age_7_10_pending_for_less_than_1_years_3: 0,
        pending_age_7_10_pending_for_1_2_years_3: 0,
        pending_age_7_10_pending_for_3_5_years_3: 0,
        pending_age_7_10_pending_for_5_plus_years_3: 0,
        pending_age_11_13_pending_for_less_than_1_years_3: 0,
        pending_age_11_13_pending_for_1_2_years_3: 0,
        pending_age_11_13_pending_for_3_5_years_3: 0,
        pending_age_11_13_pending_for_5_plus_years_3: 0,
        pending_age_14_15_pending_for_less_than_1_years_3: 0,
        pending_age_14_15_pending_for_1_2_years_3: 0,
        pending_age_14_15_pending_for_3_5_years_3: 0,
        pending_age_14_15_pending_for_5_plus_years_3: 0,
        pending_age_16_18_pending_for_less_than_1_years_3: 0,
        pending_age_16_18_pending_for_1_2_years_3: 0,
        pending_age_16_18_pending_for_3_5_years_3: 0,
        pending_age_16_18_pending_for_5_plus_years_3: 0,
        // indicator : 4
        pending_cases_total_cases_4: 0,
        pending_cases_7_10_4: 0,
        pending_cases_11_13_4: 0,
        pending_cases_14_15_4: 0,
        pending_cases_16_18_4: 0,
        pending_cases_male_4: 0,
        pending_cases_female_4: 0,
        pending_cases_general_4: 0,
        pending_cases_sc_4: 0,
        pending_cases_st_4: 0,
        pending_cases_obc_4: 0,
        pending_cases_not_known_4: 0,
        pending_cases_apl_4: 0,
        pending_cases_bpl_4: 0,
        pending_cases_illiterate_4: 0,
        pending_cases_primary_4: 0,
        pending_cases_secondary_4: 0,
        // indicator : 5 a
        pocso_total_cases_5_a: 0,
        pocso_0_5_5_a: 0,
        pocso_6_10_5_a: 0,
        pocso_11_18_5_a: 0,
        pocso_male_5_a: 0,
        pocso_female_5_a: 0,
        pocso_general_5_a: 0,
        pocso_sc_5_a: 0,
        pocso_st_5_a: 0,
        pocso_obc_5_a: 0,
        pocso_not_known_5_a: 0,
        pocso_apl_5_a: 0,
        pocso_bpl_5_a: 0,
        pocso_illiterate_5_a: 0,
        pocso_primary_5_a: 0,
        pocso_secondary_5_a: 0,
        // indicator : 5 b
        convictions_total_cases_5_b: 0,
        convictions_0_5_5_b: 0,
        convictions_6_10_5_b: 0,
        convictions_11_18_5_b: 0,
        convictions_male_5_b: 0,
        convictions_female_5_b: 0,
        convictions_general_5_b: 0,
        convictions_sc_5_b: 0,
        convictions_st_5_b: 0,
        convictions_obc_5_b: 0,
        convictions_not_known_5_b: 0,
        convictions_apl_5_b: 0,
        convictions_bpl_5_b: 0,
        convictions_illiterate_5_b: 0,
        convictions_primary_5_b: 0,
        convictions_secondary_5_b: 0,
        // indicator : 5 c
        pocso_pending_total_cases_5_c: 0,
        pocso_pending_0_5_5_c: 0,
        pocso_pending_6_10_5_c: 0,
        pocso_pending_11_18_5_c: 0,
        pocso_pending_male_5_c: 0,
        pocso_pending_female_5_c: 0,
        pocso_pending_general_5_c: 0,
        pocso_pending_sc_5_c: 0,
        pocso_pending_st_5_c: 0,
        pocso_pending_obc_5_c: 0,
        pocso_pending_not_known_5_c: 0,
        pocso_pending_apl_5_c: 0,
        pocso_pending_bpl_5_c: 0,
        pocso_pending_illiterate_5_c: 0,
        pocso_pending_primary_5_c: 0,
        pocso_pending_secondary_5_c: 0,
        // indicator : 6 a: 0,
        heinous_total_cases_6_a: 0,
        heinous_7_10_6_a: 0,
        heinous_11_13_6_a: 0,
        heinous_14_15_6_a: 0,
        heinous_16_18_6_a: 0,
        heinous_male_6_a: 0,
        heinous_female_6_a: 0,
        heinous_general_6_a: 0,
        heinous_sc_6_a: 0,
        heinous_st_6_a: 0,
        heinous_obc_6_a: 0,
        heinous_not_known_6_a: 0,
        heinous_apl_6_a: 0,
        heinous_bpl_6_a: 0,
        heinous_illiterate_6_a: 0,
        heinous_primary_6_a: 0,
        heinous_secondary_6_a: 0,
        // indicator : 6 b
        heinous_nature_total_cases_6_b: 0,
        heinous_nature_7_10_6_b: 0,
        heinous_nature_11_13_6_b: 0,
        heinous_nature_14_15_6_b: 0,
        heinous_nature_16_18_6_b: 0,
        heinous_nature_male_6_b: 0,
        heinous_nature_female_6_b: 0,
        heinous_nature_general_6_b: 0,
        heinous_nature_sc_6_b: 0,
        heinous_nature_st_6_b: 0,
        heinous_nature_obc_6_b: 0,
        heinous_nature_not_known_6_b: 0,
        heinous_nature_apl_6_b: 0,
        heinous_nature_bpl_6_b: 0,
        heinous_nature_illiterate_6_b: 0,
        heinous_nature_primary_6_b: 0,
        heinous_nature_secondary_6_b: 0,
        // indicator : 7 a
        registered_under_pocso_total_cases_7_a: 0,
        registered_under_pocso_7_10_7_a: 0,
        registered_under_pocso_11_13_7_a: 0,
        registered_under_pocso_14_15_7_a: 0,
        registered_under_pocso_16_18_7_a: 0,
        registered_under_pocso_male_7_a: 0,
        registered_under_pocso_female_7_a: 0,
        registered_under_pocso_general_7_a: 0,
        registered_under_pocso_sc_7_a: 0,
        registered_under_pocso_st_7_a: 0,
        registered_under_pocso_obc_7_a: 0,
        registered_under_pocso_not_known_7_a: 0,
        registered_under_pocso_apl_7_a: 0,
        registered_under_pocso_bpl_7_a: 0,
        registered_under_pocso_illiterate_7_a: 0,
        registered_under_pocso_primary_7_a: 0,
        registered_under_pocso_secondary_7_a: 0,
        // indicator : 7 b
        special_court_total_cases_7_b: 0,
        special_court_7_10_7_b: 0,
        special_court_11_13_7_b: 0,
        special_court_14_15_7_b: 0,
        special_court_16_18_7_b: 0,
        special_court_male_7_b: 0,
        special_court_female_7_b: 0,
        special_court_general_7_b: 0,
        special_court_sc_7_b: 0,
        special_court_st_7_b: 0,
        special_court_obc_7_b: 0,
        special_court_not_known_7_b: 0,
        special_court_apl_7_b: 0,
        special_court_bpl_7_b: 0,
        special_court_illiterate_7_b: 0,
        special_court_primary_7_b: 0,
        special_court_secondary_7_b: 0,
        // indicator : 8 1
        pocso_victims_total_cases_8: 0,
        pocso_victims_0_5_8: 0,
        pocso_victims_6_10_8: 0,
        pocso_victims_11_18_8: 0,
        pocso_victims_male_8: 0,
        pocso_victims_female_8: 0,
        pocso_victims_general_8: 0,
        pocso_victims_sc_8: 0,
        pocso_victims_st_8: 0,
        pocso_victims_obc_8: 0,
        pocso_victims_not_known_8: 0,
        pocso_victims_apl_8: 0,
        pocso_victims_bpl_8: 0,
        pocso_victims_illiterate_8: 0,
        pocso_victims_primary_8: 0,
        pocso_victims_secondary_8: 0,
        // indicator : 8 2
        compensation_total_cases_8: 0,
        compensation_0_5_8: 0,
        compensation_6_10_8: 0,
        compensation_11_18_8: 0,
        compensation_male_8: 0,
        compensation_female_8: 0,
        compensation_general_8: 0,
        compensation_sc_8: 0,
        compensation_st_8: 0,
        compensation_obc_8: 0,
        compensation_not_known_8: 0,
        compensation_apl_8: 0,
        compensation_bpl_8: 0,
        compensation_illiterate_8: 0,
        compensation_primary_8: 0,
        compensation_secondary_8: 0,
        // indicator : 9 a
        sjpu_to_jjb_total_cases_9_a: 0,
        sjpu_to_jjb_7_10_9_a: 0,
        sjpu_to_jjb_11_13_9_a: 0,
        sjpu_to_jjb_14_15_9_a: 0,
        sjpu_to_jjb_16_18_9_a: 0,
        sjpu_to_jjb_male_9_a: 0,
        sjpu_to_jjb_female_9_a: 0,
        sjpu_to_jjb_general_9_a: 0,
        sjpu_to_jjb_sc_9_a: 0,
        sjpu_to_jjb_st_9_a: 0,
        sjpu_to_jjb_obc_9_a: 0,
        sjpu_to_jjb_not_known_9_a: 0,
        sjpu_to_jjb_apl_9_a: 0,
        sjpu_to_jjb_bpl_9_a: 0,
        sjpu_to_jjb_illiterate_9_a: 0,
        sjpu_to_jjb_primary_9_a: 0,
        sjpu_to_jjb_secondary_9_a: 0,
        // indicator : 9 b
        sjpu_disposed_total_cases_9_b: 0,
        sjpu_disposed_7_10_9_b: 0,
        sjpu_disposed_11_13_9_b: 0,
        sjpu_disposed_14_15_9_b: 0,
        sjpu_disposed_16_18_9_b: 0,
        sjpu_disposed_male_9_b: 0,
        sjpu_disposed_female_9_b: 0,
        sjpu_disposed_general_9_b: 0,
        sjpu_disposed_sc_9_b: 0,
        sjpu_disposed_st_9_b: 0,
        sjpu_disposed_obc_9_b: 0,
        sjpu_disposed_not_known_9_b: 0,
        sjpu_disposed_apl_9_b: 0,
        sjpu_disposed_bpl_9_b: 0,
        sjpu_disposed_illiterate_9_b: 0,
        sjpu_disposed_primary_9_b: 0,
        sjpu_disposed_secondary_9_b: 0,
        // indicator : 11 a
        juvenility_plea_total_cases_11_a: 0,
        juvenility_plea_7_10_11_a: 0,
        juvenility_plea_11_13_11_a: 0,
        juvenility_plea_14_15_11_a: 0,
        juvenility_plea_16_18_11_a: 0,
        juvenility_plea_male_11_a: 0,
        juvenility_plea_female_11_a: 0,
        juvenility_plea_general_11_a: 0,
        juvenility_plea_sc_11_a: 0,
        juvenility_plea_st_11_a: 0,
        juvenility_plea_obc_11_a: 0,
        juvenility_plea_not_known_11_a: 0,
        juvenility_plea_apl_11_a: 0,
        juvenility_plea_bpl_11_a: 0,
        juvenility_plea_illiterate_11_a: 0,
        juvenility_plea_primary_11_a: 0,
        juvenility_plea_secondary_11_a: 0,
        // indicator : 11 b
        cases_referred_total_cases_11_b: 0,
        cases_referred_7_10_11_b: 0,
        cases_referred_11_13_11_b: 0,
        cases_referred_14_15_11_b: 0,
        cases_referred_16_18_11_b: 0,
        cases_referred_male_11_b: 0,
        cases_referred_female_11_b: 0,
        cases_referred_general_11_b: 0,
        cases_referred_sc_11_b: 0,
        cases_referred_st_11_b: 0,
        cases_referred_obc_11_b: 0,
        cases_referred_not_known_11_b: 0,
        cases_referred_apl_11_b: 0,
        cases_referred_bpl_11_b: 0,
        cases_referred_illiterate_11_b: 0,
        cases_referred_primary_11_b: 0,
        cases_referred_secondary_11_b: 0,
        //indicator 12
        jjb_vac_in_key_statutory_and_al_bo_sanctioned_positions_12: 0,
        jjb_vac_in_key_statutory_and_al_bo_filled_positions_12: 0,
        jjb_vac_in_key_statutory_and_al_bo_vacant_positions_12: 0,
        sjpu_vac_in_key_statutory_and_al_bo_sanctioned_positions_12: 0,
        sjpu_vac_in_key_statutory_and_al_bo_filled_positions_12: 0,
        sjpu_vac_in_key_statutory_and_al_bo_vacant_positions_12: 0,
        jjcs_vac_in_key_statutory_and_al_bo_sanctioned_positions_12: 0,
        jjcs_vac_in_key_statutory_and_al_bo_filled_positions_12: 0,
        jjcs_vac_in_key_statutory_and_al_bo_vacant_positions_12: 0,
        spu_pocso_vac_in_key_st_and_al_bo_sanctioned_positions_12: 0,
        spu_pocso_vac_in_key_statutory_and_al_bo_filled_positions_12: 0,
        spu_pocso_vac_in_key_statutory_and_al_bo_vacant_positions_12: 0,
        //indicator 13
        no_of_child_friendly_courts_13: 0,
        // police
        // indicator 1
        cases_registered_cncp_1: 0,
        cases_registered_0_5_1: 0,
        cases_registered_6_10_1: 0,
        cases_registered_11_14_1: 0,
        cases_registered_15_18_1: 0,
        cases_registered_male_1: 0,
        cases_registered_female_1: 0,
        cases_registered_general_1: 0,
        cases_registered_sc_1: 0,
        cases_registered_st_1: 0,
        cases_registered_obc_1: 0,
        cases_registered_not_known_1: 0,
        cases_registered_apl_1: 0,
        cases_registered_bpl_1: 0,
        cases_registered_illiterate_1: 0,
        cases_registered_primary_1: 0,
        cases_registered_secondary_1: 0,
        // indicator 2
        cases_disposed_cncp_2: 0,
        cases_disposed_0_5_2: 0,
        cases_disposed_6_10_2: 0,
        cases_disposed_11_14_2: 0,
        cases_disposed_15_18_2: 0,
        cases_disposed_male_2: 0,
        cases_disposed_female_2: 0,
        cases_disposed_general_2: 0,
        cases_disposed_sc_2: 0,
        cases_disposed_st_2: 0,
        cases_disposed_obc_2: 0,
        cases_disposed_not_known_2: 0,
        cases_disposed_apl_2: 0,
        cases_disposed_bpl_2: 0,
        cases_disposed_illiterate_2: 0,
        cases_disposed_primary_2: 0,
        cases_disposed_secondary_2: 0,
        // indicator 3
        cases_pending_0_5_for_less_than_6_months_3: 0,
        cases_pending_0_5_for_7_months_1_year_3: 0,
        cases_pending_0_5_for_1_2_years_3: 0,
        cases_pending_0_5_for_2_years_3: 0,
        cases_pending_6_10_for_less_than_6_months_3: 0,

        cases_pending_6_10_for_7_months_1_year_3: 0,
        cases_pending_6_10_for_1_2_years_3: 0,
        cases_pending_6_10_for_2_years_3: 0,
        cases_pending_11_14_for_less_than_6_months_3: 0,
        cases_pending_11_14_for_7_months_1_year_3: 0,
        cases_pending_11_14_for_1_2_years_3: 0,
        cases_pending_11_14_for_2_years_3: 0,
        cases_pending_15_18_for_less_than_6_months_3: 0,
        cases_pending_15_18_for_7_months_1_year_3: 0,
        cases_pending_15_18_for_1_2_years_3: 0,
        cases_pending_15_18_for_2_years_3: 0,
        // indicator 9 a
        referred_by_sjpu_cncp_9_a: 0,
        referred_by_sjpu_0_5_9_a: 0,
        referred_by_sjpu_6_10_9_a: 0,
        referred_by_sjpu_11_14_9_a: 0,
        referred_by_sjpu_15_18_9_a: 0,
        referred_by_sjpu_male_9_a: 0,
        referred_by_sjpu_female_9_a: 0,
        referred_by_sjpu_general_9_a: 0,
        referred_by_sjpu_sc_9_a: 0,
        referred_by_sjpu_st_9_a: 0,
        referred_by_sjpu_obc_9_a: 0,
        referred_by_sjpu_not_known_9_a: 0,
        referred_by_sjpu_apl_9_a: 0,
        referred_by_sjpu_bpl_9_a: 0,
        referred_by_sjpu_illiterate_9_a: 0,
        referred_by_sjpu_primary_9_a: 0,
        referred_by_sjpu_secondary_9_a: 0,
        // indicator 9 b
        cases_disposed_cncp_9_b: 0,
        cases_disposed_0_5_9_b: 0,
        cases_disposed_6_10_9_b: 0,
        cases_disposed_11_14_9_b: 0,
        cases_disposed_15_18_9_b: 0,
        cases_disposed_male_9_b: 0,
        cases_disposed_female_9_b: 0,
        cases_disposed_general_9_b: 0,
        cases_disposed_sc_9_b: 0,
        cases_disposed_st_9_b: 0,
        cases_disposed_obc_9_b: 0,
        cases_disposed_not_known_9_b: 0,
        cases_disposed_apl_9_b: 0,
        cases_disposed_bpl_9_b: 0,
        cases_disposed_illiterate_9_b: 0,
        cases_disposed_primary_9_b: 0,
        cases_disposed_secondary_9_b: 0,
        //indicator 10
        cncp_missing_children_10_a: 0,
        age_group_0_5_missing_children_10_a: 0,
        age_group_6_10_missing_children_10_a: 0,
        age_group_11_14_missing_children_10_a: 0,
        age_group_15_18_missing_children_10_a: 0,
        sex_male_missing_children_10_a: 0,
        sex_female_missing_children_10_a: 0,
        social_class_gen_missing_children_10_a: 0,
        social_class_sc_missing_children_10_a: 0,
        social_class_st_missing_children_10_a: 0,
        social_class_obc_missing_children_10_a: 0,
        social_class_nk_missing_children_10_a: 0,
        economic_class_apl_missing_children_10_a: 0,
        economic_class_bpl_missing_children_10_a: 0,
        edu_bg_illiterate_missing_children_10_a: 0,
        edu_bg_primary_missing_children_10_a: 0,
        edu_bg_secondary_missing_children_10_a: 0,
        //indicator 10 b
        cncp_recovered_children_10_b: 0,
        age_group_0_5_recovered_children_10_b: 0,
        age_group_6_10_recovered_children_10_b: 0,
        age_group_11_14_recovered_children_10_b: 0,
        age_group_15_18_recovered_children_10_b: 0,
        sex_male_recovered_children_10_b: 0,
        sex_female_recovered_children_10_b: 0,
        social_class_gen_recovered_children_10_b: 0,
        social_class_sc_recovered_children_10_b: 0,
        social_class_st_recovered_children_10_b: 0,
        social_class_obc_recovered_children_10_b: 0,
        social_class_nk_recovered_children_10_b: 0,
        economic_class_apl_recovered_children_10_b: 0,
        economic_class_bpl_recovered_children_10_b: 0,
        edu_bg_illiterate_recovered_children_10_b: 0,
        edu_bg_primary_recovered_children_10_b: 0,
        edu_bg_secondary_recovered_children_10_b: 0,
        //indicator 10 c
        cncp_trafficked_children_10_c: 0,
        age_group_0_5_trafficked_children_10_c: 0,
        age_group_6_10_trafficked_children_10_c: 0,
        age_group_11_14_trafficked_children_10_c: 0,
        age_group_15_18_trafficked_children_10_c: 0,
        sex_male_trafficked_children_10_c: 0,
        sex_female_trafficked_children_10_c: 0,
        social_class_gen_trafficked_children_10_c: 0,
        social_class_sc_trafficked_children_10_c: 0,
        social_class_st_trafficked_children_10_c: 0,
        social_class_obc_trafficked_children_10_c: 0,
        social_class_nk_trafficked_children_10_c: 0,
        economic_class_apl_trafficked_children_10_c: 0,
        economic_class_bpl_trafficked_children_10_c: 0,
        edu_bg_illiterate_trafficked_children_10_c: 0,
        edu_bg_primary_trafficked_children_10_c: 0,
        edu_bg_secondary_trafficked_children_10_c: 0,
        //indicator 12
        cwc_vac_in_key_sat_allied_bod_sanctioned_positions_12: 0,
        cwc_vac_in_key_sat_allied_bod_filled_positions_12: 0,
        cwc_vac_in_key_sat_allied_bod_vacant_positions_12: 0,
        dcpu_vac_in_key_sat_allied_bod_sanctioned_positions_12: 0,
        dcpu_vac_in_key_sat_allied_bod_filled_positions_12: 0,
        dcpu_vac_in_key_sat_allied_bod_vacant_positions_12: 0,
        sjpu_vac_in_key_sat_allied_bod_sanctioned_positions_12: 0,
        sjpu_vac_in_key_sat_allied_bod_filled_positions_12: 0,
        sjpu_vac_in_key_sat_allied_bod_vacant_positions_12: 0,
        ssaa_vac_in_key_sat_allied_bod_sanctioned_positions_12: 0,
        ssaa_vac_in_key_sat_allied_bod_filled_positions_12: 0,
        ssaa_vac_in_key_sat_allied_bod_vacant_positions_12: 0,
        //indicator 15
        rci_child_friendly_police_stations_one_stop_centers_15: 0,
        rci_observation_home_15: 0,
        rci_childrens_home_15: 0,
        rci_saa_15: 0,
        rci_open_shelter_15: 0,
        //16
        total_cases_childern_in_rci_16_a: 0,
        age_group_0_5_childern_in_rci_16_a: 0,
        age_group_6_10_childern_in_rci_16_a: 0,
        age_group_11_14_childern_in_rci_16_a: 0,
        sex_male_childern_in_rci_16_a: 0,
        sex_female_childern_in_rci_16_a: 0,
        social_class_gen_childern_in_rci_16_a: 0,
        social_class_sc_childern_in_rci_16_a: 0,
        social_class_st_childern_in_rci_16_a: 0,
        social_class_obc_childern_in_rci_16_a: 0,
        social_class_nk_childern_in_rci_16_a: 0,
        economic_class_apl_childern_in_rci_16_a: 0,
        economic_class_bpl_childern_in_rci_16_a: 0,
        edu_bg_illiterate_childern_in_rci_16_a: 0,
        edu_bg_primary_childern_in_rci_16_a: 0,
        edu_bg_secondary_childern_in_rci_16_a: 0,

        total_cases_childern_staying_rci_16_b: 0,
        age_group_0_5_childern_staying_rci_16_b: 0,
        age_group_6_10_childern_staying_rci_16_b: 0,
        age_group_11_14_childern_staying_rci_16_b: 0,
        sex_male_childern_staying_rci_16_b: 0,
        sex_female_childern_staying_rci_16_b: 0,
        social_class_gen_childern_staying_rci_16_b: 0,
        social_class_sc_childern_staying_rci_16_b: 0,
        social_class_st_childern_staying_rci_16_b: 0,
        social_class_obc_childern_staying_rci_16_b: 0,
        social_class_nk_childern_staying_rci_16_b: 0,
        economic_class_apl_childern_staying_rci_16_b: 0,
        economic_class_bpl_childern_staying_rci_16_b: 0,
        edu_bg_illiterate_childern_staying_rci_16_b: 0,
        edu_bg_primary_childern_staying_rci_16_b: 0,
        edu_bg_secondary_childern_staying_rci_16_b: 0,
        //17
        design_inst_no_of_institution: 0,
        design_inst_no_of_inst_monit_as_per_state_rule: 0,
        month: Qmonth,
        year: Qyear,
    });

    useEffect(() => {
        if ((props || {}).isEdit) {
            const { id, created_at, updated_at, username, user, report_type, ...reportSorted } = props.report.find((res) => { return res.month === Qmonth && res.year === Qyear; }) || {};
            setdata({
                ...data,
                ...reportSorted
            });
        }
    }, []);
    const openForm1 = () => {
        if (props.isEdit) {
            return toast.promise(
                updateReport(data, UPDATE_QPRHC),
                {
                    loading: 'Updating Record',
                    success: 'Record Updated',
                    error: 'Error while saving'
                }
            ).then(() => formUpdated(true));
        } else {
            props.close();
            onSubmit(data, "qprhc");
            setmodalIsOpen(false);
        }
    };
    const openForm = () => {
        props.close();
        setmodalIsOpen(false);
    };

    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <Toaster position="bottom-right" />
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            QPR - HighCourt
                        </h5>

                        <br></br>
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            JJB
                        </h5>

                        <button
                            className="btn-close"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <h4>{DisplayQuarter} {Qyear}</h4>
                    <h4 className="modal-title" id="exampleModalLongTitle">
                        Indicator 1: Number of cases registered during 1 Jan 2022 to 31st
                        March 2022, district wise
                    </h4>
                    <h6 className="modal-title" id="exampleModalLongTitle">
                        Juvenile Justice Board (JJB) - CICL
                    </h6>
                    <div className="modal-body">
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Heads/Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>Petty</th>
                                <th>Serious</th>
                                <th>Heinous</th>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_total_cases_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_total_cases_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_petty_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_petty_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_serious_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_serious_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_heinous_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_heinous_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_7_10_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_7_10_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_11_13_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_11_13_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_14_15_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_14_15_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_16_18_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_16_18_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_male_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_male_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_female_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_female_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_general_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_general_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_sc_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_sc_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_st_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_st_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_obc_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_obc_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_not_known_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_not_known_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_apl_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_apl_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_bpl_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_bpl_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_illiterate_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_illiterate_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_primary_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_primary_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_secondary_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_secondary_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h5 className="juvanile">
                            {" "}
                            Indicator 2: Number of cases disposed during 1 Jan 2022 to 31st
                            March 2022, district wise{" "}
                        </h5>
                        <h6 className="jjb"> Juvenile Justice Board (JJB) - CICL</h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Heads/Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>Petty</th>
                                <th>Serious</th>
                                <th>Heinous</th>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_total_cases_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_total_cases_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_petty_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_petty_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_serious_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_serious_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_heinous_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_heinous_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_7_10_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_7_10_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_11_13_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_11_13_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_14_15_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_14_15_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_16_18_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_16_18_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_male_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_male_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_female_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_female_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_general_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_general_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_sc_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_sc_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_st_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_st_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_obc_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_obc_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_not_known_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_not_known_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_apl_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_apl_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_bpl_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_bpl_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_illiterate_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_illiterate_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_primary_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_primary_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.disposed_secondary_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                disposed_secondary_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h5 className="juvanile2">
                            {" "}
                            Indicator 3: Number of cases pending in the state and district
                            before JJBs as on 31st March 2022{" "}
                        </h5>
                        <h6 className="jjb"> Juvenile Justice Board (JJB) - CICL</h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th></th>
                                <th colSpan={4}>Age 7-10</th>
                                <th colSpan={4}>Age 11-13</th>
                                <th colSpan={4}>Age 14-15</th>
                                <th colSpan={4}> Age 16-18</th>
                            </tr>
                            <tr>
                                <th>District</th>
                                <th>Pending for &#60;1 years</th>
                                <th>Pending for 1-2 years</th>
                                <th>Pending for 3-5 years</th>
                                <th>Pending for 5+ years</th>
                                <th>Pending for &#60;1 years</th>
                                <th>Pending for 1-2 years</th>
                                <th>Pending for 3-5 years</th>
                                <th>Pending for 5+ years</th>
                                <th>Pending for &#60;1 years</th>
                                <th>Pending for 1-2 years</th>
                                <th>Pending for 3-5 years</th>
                                <th>Pending for 5+ years</th>
                                <th>Pending for &#60;1 years</th>
                                <th>Pending for 1-2 years</th>
                                <th>Pending for 3-5 years</th>
                                <th>Pending for 5+ years</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.pending_age_7_10_pending_for_less_than_1_years_3
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_age_7_10_pending_for_less_than_1_years_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_age_7_10_pending_for_1_2_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_age_7_10_pending_for_1_2_years_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_age_7_10_pending_for_3_5_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_age_7_10_pending_for_3_5_years_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_age_7_10_pending_for_5_plus_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_age_7_10_pending_for_5_plus_years_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.pending_age_11_13_pending_for_less_than_1_years_3
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_age_11_13_pending_for_less_than_1_years_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_age_11_13_pending_for_1_2_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_age_11_13_pending_for_1_2_years_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_age_11_13_pending_for_3_5_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_age_11_13_pending_for_3_5_years_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_age_11_13_pending_for_5_plus_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_age_11_13_pending_for_5_plus_years_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.pending_age_14_15_pending_for_less_than_1_years_3
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_age_14_15_pending_for_less_than_1_years_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_age_14_15_pending_for_1_2_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_age_14_15_pending_for_1_2_years_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_age_14_15_pending_for_3_5_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_age_14_15_pending_for_3_5_years_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_age_14_15_pending_for_5_plus_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_age_14_15_pending_for_5_plus_years_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.pending_age_16_18_pending_for_less_than_1_years_3
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_age_16_18_pending_for_less_than_1_years_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_age_16_18_pending_for_1_2_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_age_16_18_pending_for_1_2_years_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_age_16_18_pending_for_3_5_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_age_16_18_pending_for_3_5_years_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_age_16_18_pending_for_5_plus_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_age_16_18_pending_for_5_plus_years_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h5 className="juvanile3">
                            {" "}
                            Indicator 4: Number of Pending Cases in relation to Petty Offences
                            as on 31st March 2022, district wise{" "}
                        </h5>
                        <h6 className="jjb1"> Juvenile Justice Board (JJB) - CICL </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_cases_total_cases_4}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_cases_total_cases_4: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_cases_7_10_4}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_cases_7_10_4: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_cases_11_13_4}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_cases_11_13_4: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_cases_14_15_4}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_cases_14_15_4: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_cases_16_18_4}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_cases_16_18_4: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_cases_male_4}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_cases_male_4: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_cases_female_4}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_cases_female_4: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_cases_general_4}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_cases_general_4: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_cases_sc_4}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_cases_sc_4: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_cases_st_4}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_cases_st_4: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_cases_obc_4}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_cases_obc_4: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_cases_not_known_4}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_cases_not_known_4: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_cases_apl_4}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_cases_apl_4: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_cases_bpl_4}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_cases_bpl_4: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_cases_illiterate_4}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_cases_illiterate_4: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_cases_primary_4}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_cases_primary_4: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pending_cases_secondary_4}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pending_cases_secondary_4: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h5 className="juvanile4">
                            {" "}
                            Indicator 5: Number of- a) POCSO cases referred to Special Courts
                            b) convictions in POCSO cases c) POCSO cases pending for more than
                            a year{" "}
                        </h5>
                        <h6 className="jjb2">
                            {" "}
                            5.a) Number of POCSO cases referred to Special Courts during 1 Oct
                            2021 to 31 Dec 2021 - CNCP{" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_total_cases_5_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_total_cases_5_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_0_5_5_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_0_5_5_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_6_10_5_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_6_10_5_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_11_18_5_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_11_18_5_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_male_5_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_male_5_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_female_5_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_female_5_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_general_5_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_general_5_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_sc_5_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_sc_5_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_st_5_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_st_5_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_obc_5_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_obc_5_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_not_known_5_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_not_known_5_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_apl_5_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_apl_5_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_bpl_5_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_bpl_5_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_illiterate_5_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_illiterate_5_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_primary_5_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_primary_5_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_secondary_5_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_secondary_5_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h6 className="jjb3">
                            {" "}
                            5.b) Number of convictions in POCSO cases during 1 Jan 2022 to
                            31st March 2022 - CNCP{" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.convictions_total_cases_5_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                convictions_total_cases_5_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.convictions_0_5_5_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                convictions_0_5_5_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.convictions_6_10_5_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                convictions_6_10_5_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.convictions_11_18_5_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                convictions_11_18_5_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.convictions_male_5_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                convictions_male_5_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.convictions_female_5_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                convictions_female_5_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.convictions_general_5_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                convictions_general_5_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.convictions_sc_5_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                convictions_sc_5_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.convictions_st_5_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                convictions_st_5_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.convictions_obc_5_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                convictions_obc_5_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.convictions_not_known_5_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                convictions_not_known_5_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.convictions_apl_5_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                convictions_apl_5_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.convictions_bpl_5_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                convictions_bpl_5_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.convictions_illiterate_5_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                convictions_illiterate_5_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.convictions_primary_5_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                convictions_primary_5_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.convictions_secondary_5_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                convictions_secondary_5_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h6 className="jjb3">
                            {" "}
                            5.c) Number of POCSO cases pending for more than a year as on 31st
                            March 2022 - CNCP{" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_pending_total_cases_5_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_pending_total_cases_5_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_pending_0_5_5_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_pending_0_5_5_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_pending_6_10_5_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_pending_6_10_5_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_pending_11_18_5_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_pending_11_18_5_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_pending_male_5_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_pending_male_5_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_pending_female_5_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_pending_female_5_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_pending_general_5_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_pending_general_5_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_pending_sc_5_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_pending_sc_5_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_pending_st_5_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_pending_st_5_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_pending_obc_5_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_pending_obc_5_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_pending_not_known_5_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_pending_not_known_5_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_pending_apl_5_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_pending_apl_5_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_pending_bpl_5_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_pending_bpl_5_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_pending_illiterate_5_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_pending_illiterate_5_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_pending_primary_5_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_pending_primary_5_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_pending_secondary_5_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_pending_secondary_5_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h5 className="juvanile5">
                            {" "}
                            Indicator 6: Number of cases registered of heinous nature that are
                            - a) continuing under JJB, b) referred to Special Court - CICL{" "}
                        </h5>
                        <h6 className="jjb4">
                            {" "}
                            6.a) Number of cases registered of heinous nature that are
                            continuing under JJB during 1 Jan 2022 to 31st March 2022 - CICL{" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_total_cases_6_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_total_cases_6_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_7_10_6_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_7_10_6_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_11_13_6_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_11_13_6_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_14_15_6_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_14_15_6_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_16_18_6_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_16_18_6_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_male_6_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_male_6_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_female_6_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_female_6_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_general_6_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_general_6_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_sc_6_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_sc_6_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_st_6_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_st_6_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_obc_6_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_obc_6_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_not_known_6_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_not_known_6_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_apl_6_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_apl_6_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_bpl_6_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_bpl_6_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_illiterate_6_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_illiterate_6_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_primary_6_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_primary_6_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_secondary_6_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_secondary_6_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h6 className="jjb4">
                            {" "}
                            6.b) Number of cases registered of heinous nature that are
                            referred to Special Court during 1 Jan 2022 to 31st March 2022 -
                            CIC{" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_nature_total_cases_6_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_nature_total_cases_6_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_nature_7_10_6_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_nature_7_10_6_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_nature_11_13_6_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_nature_11_13_6_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_nature_14_15_6_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_nature_14_15_6_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_nature_16_18_6_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_nature_16_18_6_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_nature_male_6_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_nature_male_6_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_nature_female_6_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_nature_female_6_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_nature_general_6_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_nature_general_6_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_nature_sc_6_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_nature_sc_6_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_nature_st_6_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_nature_st_6_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_nature_obc_6_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_nature_obc_6_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_nature_not_known_6_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_nature_not_known_6_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_nature_apl_6_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_nature_apl_6_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_nature_bpl_6_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_nature_bpl_6_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_nature_illiterate_6_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_nature_illiterate_6_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_nature_primary_6_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_nature_primary_6_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.heinous_nature_secondary_6_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                heinous_nature_secondary_6_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h5 className="juvanile5">
                            {" "}
                            Indicator 7: Number of cases registered under POCSO that are - a)
                            continuing under JJB, b) referred to Special Court{" "}
                        </h5>
                        <h6 className="jjb4">
                            {" "}
                            7.a) Number of cases registered under POCSO that are continuing
                            under JJB during1 Jan 2022 to 31st March 2022 - CICL{" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_under_pocso_total_cases_7_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_under_pocso_total_cases_7_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_under_pocso_7_10_7_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_under_pocso_7_10_7_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_under_pocso_11_13_7_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_under_pocso_11_13_7_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_under_pocso_14_15_7_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_under_pocso_14_15_7_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_under_pocso_16_18_7_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_under_pocso_16_18_7_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_under_pocso_male_7_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_under_pocso_male_7_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_under_pocso_female_7_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_under_pocso_female_7_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_under_pocso_general_7_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_under_pocso_general_7_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_under_pocso_sc_7_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_under_pocso_sc_7_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_under_pocso_st_7_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_under_pocso_st_7_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_under_pocso_obc_7_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_under_pocso_obc_7_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_under_pocso_not_known_7_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_under_pocso_not_known_7_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_under_pocso_apl_7_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_under_pocso_apl_7_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_under_pocso_bpl_7_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_under_pocso_bpl_7_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_under_pocso_illiterate_7_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_under_pocso_illiterate_7_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_under_pocso_primary_7_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_under_pocso_primary_7_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.registered_under_pocso_secondary_7_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                registered_under_pocso_secondary_7_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h6 className="jjb4">
                            {" "}
                            7.b) Number of cases registered under POCSO that are referred to
                            Special Court during 1 Jan 2022 to 31st March 2022 - CICL{" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.special_court_total_cases_7_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                special_court_total_cases_7_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.special_court_7_10_7_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                special_court_7_10_7_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.special_court_11_13_7_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                special_court_11_13_7_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.special_court_14_15_7_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                special_court_14_15_7_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.special_court_16_18_7_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                special_court_16_18_7_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.special_court_male_7_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                special_court_male_7_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.special_court_female_7_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                special_court_female_7_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.special_court_general_7_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                special_court_general_7_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.special_court_sc_7_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                special_court_sc_7_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.special_court_st_7_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                special_court_st_7_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.special_court_obc_7_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                special_court_obc_7_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.special_court_not_known_7_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                special_court_not_known_7_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.special_court_apl_7_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                special_court_apl_7_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.special_court_bpl_7_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                special_court_bpl_7_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.special_court_illiterate_7_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                special_court_illiterate_7_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.special_court_primary_7_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                special_court_primary_7_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.special_court_secondary_7_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                special_court_secondary_7_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h5 className="juvanile4">
                            {" "}
                            Indicator 8: Number of POCSO victims who have been given victim
                            compensation (interim/final){" "}
                        </h5>
                        <h6 className="jjb3">
                            {" "}
                            Interim Compensation given during 1 Jan 2022 to 31st March 2022 -
                            CNCP{" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_victims_total_cases_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_victims_total_cases_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_victims_0_5_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_victims_0_5_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_victims_6_10_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_victims_6_10_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_victims_11_18_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_victims_11_18_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_victims_male_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_victims_male_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_victims_female_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_victims_female_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_victims_general_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_victims_general_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_victims_sc_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_victims_sc_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_victims_st_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_victims_st_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_victims_obc_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_victims_obc_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_victims_not_known_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_victims_not_known_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_victims_apl_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_victims_apl_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_victims_bpl_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_victims_bpl_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_victims_illiterate_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_victims_illiterate_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_victims_primary_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_victims_primary_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.pocso_victims_secondary_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                pocso_victims_secondary_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h6 className="jjb3">
                            {" "}
                            Final Compensation given during 1 Jan 2022 to 31st March 2022 -
                            CNCP{" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.compensation_total_cases_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                compensation_total_cases_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.compensation_0_5_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                compensation_0_5_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.compensation_6_10_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                compensation_6_10_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.compensation_11_18_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                compensation_11_18_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.compensation_male_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                compensation_male_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.compensation_female_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                compensation_female_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.compensation_general_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                compensation_general_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.compensation_sc_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                compensation_sc_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.compensation_st_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                compensation_st_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.compensation_obc_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                compensation_obc_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.compensation_not_known_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                compensation_not_known_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.compensation_apl_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                compensation_apl_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.compensation_bpl_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                compensation_bpl_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.compensation_illiterate_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                compensation_illiterate_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.compensation_primary_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                compensation_primary_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.compensation_secondary_8}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                compensation_secondary_8: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h5 className="juvanile5">
                            {" "}
                            Indicator 9: Number of cases a) referred by SJPU to JJB in last
                            one year b) disposed JJB in last one year{" "}
                        </h5>
                        <h6 className="jjb4">
                            {" "}
                            9.a) Number of cases referred by SJPU to JJB (CICL) during 1 Jan
                            2022 to 31st March 2022 - CICL{" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_to_jjb_total_cases_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_to_jjb_total_cases_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_to_jjb_7_10_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_to_jjb_7_10_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_to_jjb_11_13_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_to_jjb_11_13_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_to_jjb_14_15_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_to_jjb_14_15_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_to_jjb_16_18_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_to_jjb_16_18_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_to_jjb_male_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_to_jjb_male_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_to_jjb_female_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_to_jjb_female_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_to_jjb_general_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_to_jjb_general_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_to_jjb_sc_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_to_jjb_sc_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_to_jjb_st_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_to_jjb_st_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_to_jjb_obc_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_to_jjb_obc_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_to_jjb_not_known_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_to_jjb_not_known_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_to_jjb_apl_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_to_jjb_apl_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_to_jjb_bpl_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_to_jjb_bpl_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_to_jjb_illiterate_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_to_jjb_illiterate_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_to_jjb_primary_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_to_jjb_primary_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_to_jjb_secondary_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_to_jjb_secondary_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h6 className="jjb4">
                            {" "}
                            9.b) Number of cases disposed - SJPU to JJB (CICL) during 1 Jan
                            2022 to 31st March 2022 CICL{" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_disposed_total_cases_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_disposed_total_cases_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_disposed_7_10_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_disposed_7_10_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_disposed_11_13_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_disposed_11_13_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_disposed_14_15_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_disposed_14_15_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_disposed_16_18_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_disposed_16_18_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_disposed_male_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_disposed_male_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_disposed_female_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_disposed_female_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_disposed_general_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_disposed_general_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_disposed_sc_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_disposed_sc_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_disposed_st_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_disposed_st_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_disposed_obc_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_disposed_obc_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_disposed_not_known_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_disposed_not_known_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_disposed_apl_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_disposed_apl_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_disposed_bpl_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_disposed_bpl_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_disposed_illiterate_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_disposed_illiterate_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_disposed_primary_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_disposed_primary_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sjpu_disposed_secondary_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_disposed_secondary_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h5 className="juvanile5">
                            {" "}
                            Indicator 11: Number of cases of a) juvenility plea administered
                            b) referred to JJB{" "}
                        </h5>
                        <h6 className="jjb4">
                            {" "}
                            11.a) Number of cases of juvenility plea administered during 1 Jan
                            2022 to 31st March 2022 - CICL{" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.juvenility_plea_total_cases_11_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                juvenility_plea_total_cases_11_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.juvenility_plea_7_10_11_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                juvenility_plea_7_10_11_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.juvenility_plea_11_13_11_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                juvenility_plea_11_13_11_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.juvenility_plea_14_15_11_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                juvenility_plea_14_15_11_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.juvenility_plea_16_18_11_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                juvenility_plea_16_18_11_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.juvenility_plea_male_11_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                juvenility_plea_male_11_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.juvenility_plea_female_11_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                juvenility_plea_female_11_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.juvenility_plea_general_11_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                juvenility_plea_general_11_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.juvenility_plea_sc_11_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                juvenility_plea_sc_11_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.juvenility_plea_st_11_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                juvenility_plea_st_11_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.juvenility_plea_obc_11_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                juvenility_plea_obc_11_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.juvenility_plea_not_known_11_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                juvenility_plea_not_known_11_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.juvenility_plea_apl_11_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                juvenility_plea_apl_11_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.juvenility_plea_bpl_11_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                juvenility_plea_bpl_11_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.juvenility_plea_illiterate_11_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                juvenility_plea_illiterate_11_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.juvenility_plea_primary_11_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                juvenility_plea_primary_11_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.juvenility_plea_secondary_11_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                juvenility_plea_secondary_11_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h6 className="jjb4">
                            {" "}
                            11.b) Number of cases referred to JJB during 1 Jan 2022 to 31st
                            March 2022 - CICL{" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_referred_total_cases_11_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_referred_total_cases_11_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_referred_7_10_11_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_referred_7_10_11_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_referred_11_13_11_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_referred_11_13_11_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_referred_14_15_11_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_referred_14_15_11_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_referred_16_18_11_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_referred_16_18_11_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_referred_male_11_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_referred_male_11_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_referred_female_11_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_referred_female_11_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_referred_general_11_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_referred_general_11_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_referred_sc_11_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_referred_sc_11_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_referred_st_11_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_referred_st_11_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_referred_obc_11_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_referred_obc_11_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_referred_not_known_11_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_referred_not_known_11_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_referred_apl_11_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_referred_apl_11_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_referred_bpl_11_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_referred_bpl_11_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_referred_illiterate_11_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_referred_illiterate_11_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_referred_primary_11_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_referred_primary_11_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_referred_secondary_11_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_referred_secondary_11_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h5 className="juvanile6"> Indicator 12 </h5>
                        <h6 className="jjb6">
                            {" "}
                            Juvenile Justice Boards (status as on 31st March 2022){" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th colSpan={3} className="bgclr">
                                    Number of vacancies in key statutory and allied bodies{" "}
                                </th>
                            </tr>
                            <tr>
                                <th>Sanctioned Positions</th>
                                <th>Filled Positions</th>
                                <th>Vacant Positions</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.jjb_vac_in_key_statutory_and_al_bo_sanctioned_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                jjb_vac_in_key_statutory_and_al_bo_sanctioned_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.jjb_vac_in_key_statutory_and_al_bo_filled_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                jjb_vac_in_key_statutory_and_al_bo_filled_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.jjb_vac_in_key_statutory_and_al_bo_vacant_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                jjb_vac_in_key_statutory_and_al_bo_vacant_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h6 className="jjb6">
                            {" "}
                            Special Juvenile Police Units (status as on 31st March 2022){" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th colSpan={3} className="bgclr">
                                    Number of vacancies in key statutory and allied bodies{" "}
                                </th>
                            </tr>
                            <tr>
                                <th>Sanctioned Positions</th>
                                <th>Filled Positions</th>
                                <th>Vacant Positions</th>
                            </tr>
                            <tr>
                                <td>{JSON.parse(getStorage("user")).username}</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.sjpu_vac_in_key_statutory_and_al_bo_sanctioned_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_vac_in_key_statutory_and_al_bo_sanctioned_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.sjpu_vac_in_key_statutory_and_al_bo_filled_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_vac_in_key_statutory_and_al_bo_filled_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.sjpu_vac_in_key_statutory_and_al_bo_vacant_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_vac_in_key_statutory_and_al_bo_vacant_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h6 className="jjb6">
                            {" "}
                            JJC Secretariat (status as on 31st March 2022){" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th colSpan={3} className="bgclr">
                                    Number of vacancies in key statutory and allied bodies{" "}
                                </th>
                            </tr>
                            <tr>
                                <th>Sanctioned Positions</th>
                                <th>Filled Positions</th>
                                <th>Vacant Positions</th>
                            </tr>
                            <tr>
                                <td>{JSON.parse(getStorage("user")).username}</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.jjcs_vac_in_key_statutory_and_al_bo_sanctioned_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                jjcs_vac_in_key_statutory_and_al_bo_sanctioned_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.jjcs_vac_in_key_statutory_and_al_bo_filled_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                jjcs_vac_in_key_statutory_and_al_bo_filled_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.jjcs_vac_in_key_statutory_and_al_bo_vacant_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                jjcs_vac_in_key_statutory_and_al_bo_vacant_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h6 className="jjb6">
                            {" "}
                            Support persons under POCSO (status as on 31st March 2022){" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th colSpan={3} className="bgclr">
                                    Number of vacancies in key statutory and allied bodies{" "}
                                </th>
                            </tr>
                            <tr>
                                <th>Sanctioned Positions</th>
                                <th>Filled Positions</th>
                                <th>Vacant Positions</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.spu_pocso_vac_in_key_st_and_al_bo_sanctioned_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                spu_pocso_vac_in_key_st_and_al_bo_sanctioned_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.spu_pocso_vac_in_key_statutory_and_al_bo_filled_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                spu_pocso_vac_in_key_statutory_and_al_bo_filled_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.spu_pocso_vac_in_key_statutory_and_al_bo_vacant_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                spu_pocso_vac_in_key_statutory_and_al_bo_vacant_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h5 className="juvanile6"> Indicator 13: Number and name of districts having Child Friendly Courts (status as on 31st March 2022) </h5>
                        {/* <h6 className="jjb6">
              {" "}
              Juvenile Justice Boards (status as on 31st March 2022){" "}
            </h6> */}
                        <table className="qprhighcourt">
                            <tr>
                                <th>District</th>
                                <th>
                                    No. of Child Friendly Courts{" "}
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.no_of_child_friendly_courts_13
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                no_of_child_friendly_courts_13:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            {/* <tr>
                <td>TOTAL</td>
                <td><input type="number"></input></td>

              </tr> */}
                        </table>


                        {/* police */}
                        <br></br>
                        <h2>DCPU-CWC_POLICE</h2>
                        <h5 className="dcpu">
                            {" "}
                            Indicator 1: Number of cases registered during 1 Jan 2022 to 31st
                            March 2022, district wise{" "}
                        </h5>
                        <table className="qprhighcourt">
                            <tr>
                                <th></th>
                                <th colSpan={17} className="bgclr">
                                    Child Welfare Committee (CWC)
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>
                                    Total Number of Children in Need of Care and Protection (CNCP)
                                </th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-14</th>
                                <th>15-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_registered_cncp_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_registered_cncp_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_registered_0_5_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_registered_0_5_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_registered_6_10_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_registered_6_10_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_registered_11_14_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_registered_11_14_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_registered_15_18_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_registered_15_18_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_registered_male_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_registered_male_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_registered_female_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_registered_female_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_registered_general_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_registered_general_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_registered_sc_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_registered_sc_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_registered_st_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_registered_st_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_registered_obc_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_registered_obc_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_registered_not_known_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_registered_not_known_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_registered_apl_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_registered_apl_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_registered_bpl_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_registered_bpl_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_registered_illiterate_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_registered_illiterate_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_registered_primary_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_registered_primary_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_registered_secondary_1}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_registered_secondary_1: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>

                        <h5 className="dcpu">
                            {" "}
                            Indicator 2: Number of cases disposed during 1 Jan 2022 to 31st
                            March 2022, district wise{" "}
                        </h5>
                        <table className="qprhighcourt">
                            <tr>
                                <th></th>
                                <th colSpan={17} className="bgclr">
                                    Child Welfare Committee (CWC)
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>
                                    Total Number of Children in Need of Care and Protection (CNCP)
                                </th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-14</th>
                                <th>15-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_cncp_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_cncp_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_0_5_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_0_5_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_6_10_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_6_10_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_11_14_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_11_14_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_15_18_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_15_18_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_male_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_male_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_female_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_female_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_general_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_general_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_sc_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_sc_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_st_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_st_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_obc_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_obc_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_not_known_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_not_known_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_apl_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_apl_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_bpl_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_bpl_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_illiterate_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_illiterate_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_primary_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_primary_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_secondary_2}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_secondary_2: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>

                        <h5 className="dcpu1">
                            {" "}
                            Indicator 3: Number of cases pending in the state and district
                            before CWCs as on 31st March 2022{" "}
                        </h5>
                        <table className="qprhighcourt">
                            <tr>
                                <th></th>
                                <th colSpan={16} className="bgclr">
                                    Child Welfare Committee (CWC)
                                </th>
                            </tr>
                            <tr>
                                <th></th>
                                <th colSpan={4}>Age 0-5</th>
                                <th colSpan={4}>Age 6-10</th>
                                <th colSpan={4}> Age 11-14</th>
                                <th colSpan={4}> Age 15-18</th>
                            </tr>
                            <tr>
                                <th>District</th>
                                <th>Pending for &lt;6 months</th>
                                <th>Pending for 7 monhts-1 year</th>
                                <th>Pending for 1-2 years</th>
                                <th>Pending for 2+ years</th>
                                <th>Pending for &lt;6 months</th>
                                <th>Pending for 7 monhts-1 year</th>
                                <th>Pending for 1-2 years</th>
                                <th>Pending for 2+ years</th>
                                <th>Pending for &lt;6 months</th>
                                <th>Pending for 7 monhts-1 year</th>
                                <th>Pending for 1-2 years</th>
                                <th>Pending for 2+ years</th>
                                <th>Pending for &lt;6 months</th>
                                <th>Pending for 7 monhts-1 year</th>
                                <th>Pending for 1-2 years</th>
                                <th>Pending for 2+ years</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_pending_0_5_for_less_than_6_months_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_0_5_for_less_than_6_months_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_pending_0_5_for_7_months_1_year_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_0_5_for_7_months_1_year_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_pending_0_5_for_1_2_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_0_5_for_1_2_years_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_pending_0_5_for_2_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_0_5_for_2_years_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_pending_6_10_for_less_than_6_months_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_6_10_for_less_than_6_months_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_pending_6_10_for_7_months_1_year_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_6_10_for_7_months_1_year_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_pending_6_10_for_1_2_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_6_10_for_1_2_years_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_pending_6_10_for_2_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_6_10_for_2_years_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_pending_11_14_for_less_than_6_months_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_11_14_for_less_than_6_months_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_pending_11_14_for_7_months_1_year_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_11_14_for_7_months_1_year_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_pending_11_14_for_1_2_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_11_14_for_1_2_years_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_pending_11_14_for_2_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_11_14_for_2_years_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_pending_15_18_for_less_than_6_months_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_15_18_for_less_than_6_months_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_pending_15_18_for_7_months_1_year_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_15_18_for_7_months_1_year_3:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_pending_15_18_for_1_2_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_15_18_for_1_2_years_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_pending_15_18_for_2_years_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_pending_15_18_for_2_years_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>

                        <h5 className="dcpu">
                            {" "}
                            Indicator 9: Number of cases a) referred by SJPU to CWC in last
                            one year b) disposed - CWC in last one year{" "}
                        </h5>
                        <table className="qprhighcourt">
                            <tr>
                                <th></th>
                                <th colSpan={17} className="bgclr">
                                    9.a) Number of cases referred by SJPU to CWC (CNCP) during 1
                                    Jan 2022 to 31st March 2022
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>
                                    Total Number of Children in Need of Care and Protection (CNCP)
                                </th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-14</th>
                                <th>15-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.referred_by_sjpu_cncp_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                referred_by_sjpu_cncp_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.referred_by_sjpu_0_5_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                referred_by_sjpu_0_5_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.referred_by_sjpu_6_10_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                referred_by_sjpu_6_10_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.referred_by_sjpu_11_14_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                referred_by_sjpu_11_14_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.referred_by_sjpu_15_18_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                referred_by_sjpu_15_18_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.referred_by_sjpu_male_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                referred_by_sjpu_male_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.referred_by_sjpu_female_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                referred_by_sjpu_female_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.referred_by_sjpu_general_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                referred_by_sjpu_general_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.referred_by_sjpu_sc_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                referred_by_sjpu_sc_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.referred_by_sjpu_st_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                referred_by_sjpu_st_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.referred_by_sjpu_obc_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                referred_by_sjpu_obc_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.referred_by_sjpu_not_known_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                referred_by_sjpu_not_known_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.referred_by_sjpu_apl_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                referred_by_sjpu_apl_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.referred_by_sjpu_bpl_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                referred_by_sjpu_bpl_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.referred_by_sjpu_illiterate_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                referred_by_sjpu_illiterate_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.referred_by_sjpu_primary_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                referred_by_sjpu_primary_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.referred_by_sjpu_secondary_9_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                referred_by_sjpu_secondary_9_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <table className="qprhighcourt" id="qpr">
                            <tr>
                                <th></th>
                                <th colSpan={17} className="bgclr">
                                    9.b) Number of cases disposed - SJPU to CWC (CNCP) during 1
                                    Jan 2022 to 31st March 2022{" "}
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>
                                    Total Number of Children in Need of Care and Protection (CNCP)
                                </th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-14</th>
                                <th>15-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_cncp_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_cncp_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_0_5_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_0_5_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_6_10_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_6_10_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_11_14_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_11_14_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_15_18_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_15_18_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_male_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_male_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_female_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_female_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_general_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_general_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_sc_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_sc_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_st_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_st_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_obc_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_obc_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_not_known_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_not_known_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_apl_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_apl_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_bpl_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_bpl_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_illiterate_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_illiterate_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_primary_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_primary_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cases_disposed_secondary_9_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cases_disposed_secondary_9_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h5 className="dcpu">
                            {" "}
                            Indicator 10: Number of- a) cases of missing children in last one
                            year b) cases of recovered children in last one year c) cases of
                            trafficked children in last one year{" "}
                        </h5>
                        <table className="qprhighcourt">
                            <tr>
                                <th></th>
                                <th colSpan={17} className="bgclr">
                                    10.a) Number of cases of missing children during 1 Jan 2022 to
                                    31st March 2022
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>
                                    Total Number of Children in Need of Care and Protection (CNCP)
                                </th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-14</th>
                                <th>15-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cncp_missing_children_10_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cncp_missing_children_10_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_0_5_missing_children_10_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_0_5_missing_children_10_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_6_10_missing_children_10_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_6_10_missing_children_10_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_11_14_missing_children_10_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_11_14_missing_children_10_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_15_18_missing_children_10_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_15_18_missing_children_10_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sex_male_missing_children_10_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sex_male_missing_children_10_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sex_female_missing_children_10_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sex_female_missing_children_10_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_gen_missing_children_10_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_gen_missing_children_10_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_sc_missing_children_10_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_sc_missing_children_10_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_st_missing_children_10_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_st_missing_children_10_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_obc_missing_children_10_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_obc_missing_children_10_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_nk_missing_children_10_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_nk_missing_children_10_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.economic_class_apl_missing_children_10_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                economic_class_apl_missing_children_10_a:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.economic_class_bpl_missing_children_10_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                economic_class_bpl_missing_children_10_a:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.edu_bg_illiterate_missing_children_10_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                edu_bg_illiterate_missing_children_10_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.edu_bg_primary_missing_children_10_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                edu_bg_primary_missing_children_10_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.edu_bg_secondary_missing_children_10_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                edu_bg_secondary_missing_children_10_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <table className="qprhighcourt" id="qpr">
                            <tr>
                                <th></th>
                                <th colSpan={17} className="bgclr">
                                    10.b) Number of cases of recovered children during 1 Jan 2022
                                    to 31st March 2022{" "}
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>
                                    Total Number of Children in Need of Care and Protection (CNCP)
                                </th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-14</th>
                                <th>15-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cncp_recovered_children_10_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cncp_recovered_children_10_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_0_5_recovered_children_10_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_0_5_recovered_children_10_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_6_10_recovered_children_10_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_6_10_recovered_children_10_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_11_14_recovered_children_10_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_11_14_recovered_children_10_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_15_18_recovered_children_10_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_15_18_recovered_children_10_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sex_male_recovered_children_10_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sex_male_recovered_children_10_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sex_female_recovered_children_10_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sex_female_recovered_children_10_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_gen_recovered_children_10_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_gen_recovered_children_10_b:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_sc_recovered_children_10_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_sc_recovered_children_10_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_st_recovered_children_10_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_st_recovered_children_10_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_obc_recovered_children_10_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_obc_recovered_children_10_b:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_nk_recovered_children_10_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_nk_recovered_children_10_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.economic_class_apl_recovered_children_10_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                economic_class_apl_recovered_children_10_b:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.economic_class_bpl_recovered_children_10_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                economic_class_bpl_recovered_children_10_b:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.edu_bg_illiterate_recovered_children_10_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                edu_bg_illiterate_recovered_children_10_b:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.edu_bg_primary_recovered_children_10_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                edu_bg_primary_recovered_children_10_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.edu_bg_secondary_recovered_children_10_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                edu_bg_secondary_recovered_children_10_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <table className="qprhighcourt" id="qpr">
                            <tr>
                                <th></th>
                                <th colSpan={17} className="bgclr">
                                    10.c) Number of cases of trafficked children during 1 Jan 2022
                                    to 31st March 2022{" "}
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>
                                    Total Number of Children in Need of Care and Protection (CNCP)
                                </th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-14</th>
                                <th>15-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.cncp_trafficked_children_10_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cncp_trafficked_children_10_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_0_5_trafficked_children_10_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_0_5_trafficked_children_10_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_6_10_trafficked_children_10_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_6_10_trafficked_children_10_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_11_14_trafficked_children_10_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_11_14_trafficked_children_10_c:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_15_18_trafficked_children_10_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_15_18_trafficked_children_10_c:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sex_male_trafficked_children_10_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sex_male_trafficked_children_10_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sex_female_trafficked_children_10_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sex_female_trafficked_children_10_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_gen_trafficked_children_10_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_gen_trafficked_children_10_c:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_sc_trafficked_children_10_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_sc_trafficked_children_10_c:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_st_trafficked_children_10_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_st_trafficked_children_10_c:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_obc_trafficked_children_10_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_obc_trafficked_children_10_c:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_nk_trafficked_children_10_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_nk_trafficked_children_10_c:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.economic_class_apl_trafficked_children_10_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                economic_class_apl_trafficked_children_10_c:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.economic_class_bpl_trafficked_children_10_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                economic_class_bpl_trafficked_children_10_c:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.edu_bg_illiterate_trafficked_children_10_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                edu_bg_illiterate_trafficked_children_10_c:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.edu_bg_primary_trafficked_children_10_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                edu_bg_primary_trafficked_children_10_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.edu_bg_secondary_trafficked_children_10_c}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                edu_bg_secondary_trafficked_children_10_c: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h5 className="juvanile6"> Indicator 12 </h5>
                        <h6 className="jjb6">
                            {" "}
                            Child Welfare Committees (status as on 31st March 2022){" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th colSpan={3} className="bgclr">
                                    Number of vacancies in key statutory and allied bodies{" "}
                                </th>
                            </tr>
                            <tr>
                                <th>Sanctioned Positions</th>
                                <th>Filled Positions</th>
                                <th>Vacant Positions</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.cwc_vac_in_key_sat_allied_bod_sanctioned_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cwc_vac_in_key_sat_allied_bod_sanctioned_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.cwc_vac_in_key_sat_allied_bod_filled_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cwc_vac_in_key_sat_allied_bod_filled_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.cwc_vac_in_key_sat_allied_bod_vacant_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                cwc_vac_in_key_sat_allied_bod_vacant_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h6 className="jjb6">
                            {" "}
                            District Child Protection Units (status as on 31st March 2022){" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th colSpan={3} className="bgclr">
                                    Number of vacancies in key statutory and allied bodies{" "}
                                </th>
                            </tr>
                            <tr>
                                <th>Sanctioned Positions</th>
                                <th>Filled Positions</th>
                                <th>Vacant Positions</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.dcpu_vac_in_key_sat_allied_bod_sanctioned_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                dcpu_vac_in_key_sat_allied_bod_sanctioned_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.dcpu_vac_in_key_sat_allied_bod_filled_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                dcpu_vac_in_key_sat_allied_bod_filled_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.dcpu_vac_in_key_sat_allied_bod_vacant_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                dcpu_vac_in_key_sat_allied_bod_vacant_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h6 className="jjb6">
                            {" "}
                            Special Juvenile Police Units (status as on 31st March 2022){" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th colSpan={3} className="bgclr">
                                    Number of vacancies in key statutory and allied bodies{" "}
                                </th>
                            </tr>
                            <tr>
                                <th>Sanctioned Positions</th>
                                <th>Filled Positions</th>
                                <th>Vacant Positions</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.sjpu_vac_in_key_sat_allied_bod_sanctioned_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_vac_in_key_sat_allied_bod_sanctioned_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.sjpu_vac_in_key_sat_allied_bod_filled_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_vac_in_key_sat_allied_bod_filled_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.sjpu_vac_in_key_sat_allied_bod_vacant_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sjpu_vac_in_key_sat_allied_bod_vacant_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h6 className="jjb6">
                            {" "}
                            State Special Adoption Agencies (status as on 31st March 2022){" "}
                        </h6>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th colSpan={3} className="bgclr">
                                    Number of vacancies in key statutory and allied bodies{" "}
                                </th>
                            </tr>
                            <tr>
                                <th>Sanctioned Positions</th>
                                <th>Filled Positions</th>
                                <th>Vacant Positions</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.ssaa_vac_in_key_sat_allied_bod_sanctioned_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                ssaa_vac_in_key_sat_allied_bod_sanctioned_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.ssaa_vac_in_key_sat_allied_bod_filled_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                ssaa_vac_in_key_sat_allied_bod_filled_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.ssaa_vac_in_key_sat_allied_bod_vacant_positions_12
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                ssaa_vac_in_key_sat_allied_bod_vacant_positions_12:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h5 className="dcpu2">
                            {" "}
                            Indicator 14: Number and name of districts having Child Friendly
                            Police Stations (status as on 31st March 2022){" "}
                        </h5>
                        <h5 className="dcpu2">
                            {" "}
                            Indicator 15: Number of residential care institutions (observation
                            home, children's home, SAA, open shelter) (status as on 31st March
                            2022){" "}
                        </h5>
                        <table className="qprhighcourt">
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2} className="bgclr">
                                    No. of Child Friendly Police Stations/One Stop Centers as per
                                    state guidelines
                                </th>
                                <th colSpan={4} className="bgclr">
                                    Number of residential care institutions
                                </th>
                            </tr>
                            <tr>
                                <th>Observation Home</th>
                                <th>Children's Home</th>
                                <th>SAA</th>
                                <th>Open Shelter</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={
                                            data.rci_child_friendly_police_stations_one_stop_centers_15
                                        }
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                rci_child_friendly_police_stations_one_stop_centers_15:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.rci_observation_home_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                rci_observation_home_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.rci_childrens_home_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                rci_childrens_home_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.rci_saa_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                rci_saa_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.rci_open_shelter_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                rci_open_shelter_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h5 className="dcpu1">
                            {" "}
                            Indicator 16: Number of children a) in residential care
                            institutions b) staying for more than a year in residential care
                            institutions{" "}
                        </h5>
                        <table className="qprhighcourt">
                            <tr>
                                <th></th>
                                <th colSpan={16} className="bgclr">
                                    16.a) Number of children in residential care institutions
                                    (observation home, children’s home, SAA, open shelter) as on
                                    31st March 2022
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-18</th>

                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_cases_childern_in_rci_16_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_cases_childern_in_rci_16_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_0_5_childern_in_rci_16_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_0_5_childern_in_rci_16_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_6_10_childern_in_rci_16_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_6_10_childern_in_rci_16_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_11_14_childern_in_rci_16_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_11_14_childern_in_rci_16_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>

                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sex_male_childern_in_rci_16_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sex_male_childern_in_rci_16_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sex_female_childern_in_rci_16_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sex_female_childern_in_rci_16_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_gen_childern_in_rci_16_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_gen_childern_in_rci_16_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_sc_childern_in_rci_16_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_sc_childern_in_rci_16_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_st_childern_in_rci_16_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_st_childern_in_rci_16_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_obc_childern_in_rci_16_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_obc_childern_in_rci_16_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_nk_childern_in_rci_16_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_nk_childern_in_rci_16_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.economic_class_apl_childern_in_rci_16_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                economic_class_apl_childern_in_rci_16_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.economic_class_bpl_childern_in_rci_16_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                economic_class_bpl_childern_in_rci_16_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.edu_bg_illiterate_childern_in_rci_16_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                edu_bg_illiterate_childern_in_rci_16_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.edu_bg_primary_childern_in_rci_16_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                edu_bg_primary_childern_in_rci_16_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.edu_bg_secondary_childern_in_rci_16_a}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                edu_bg_secondary_childern_in_rci_16_a: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <table className="qprhighcourt" id="qpr">
                            <tr>
                                <th></th>
                                <th colSpan={16} className="bgclr">
                                    16.b) Number of children staying for more than a year in
                                    residential care institutions (observation home, children’s
                                    home, SAA, open shelter) as on 31st March 2022{" "}
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-18</th>

                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_cases_childern_staying_rci_16_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_cases_childern_staying_rci_16_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_0_5_childern_staying_rci_16_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_0_5_childern_staying_rci_16_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_6_10_childern_staying_rci_16_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_6_10_childern_staying_rci_16_b:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.age_group_11_14_childern_staying_rci_16_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                age_group_11_14_childern_staying_rci_16_b:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>

                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sex_male_childern_staying_rci_16_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sex_male_childern_staying_rci_16_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.sex_female_childern_staying_rci_16_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                sex_female_childern_staying_rci_16_b: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_gen_childern_staying_rci_16_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_gen_childern_staying_rci_16_b:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_sc_childern_staying_rci_16_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_sc_childern_staying_rci_16_b:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_st_childern_staying_rci_16_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_st_childern_staying_rci_16_b:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_obc_childern_staying_rci_16_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_obc_childern_staying_rci_16_b:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.social_class_nk_childern_staying_rci_16_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                social_class_nk_childern_staying_rci_16_b:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.economic_class_apl_childern_staying_rci_16_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                economic_class_apl_childern_staying_rci_16_b:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.economic_class_bpl_childern_staying_rci_16_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                economic_class_bpl_childern_staying_rci_16_b:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.edu_bg_illiterate_childern_staying_rci_16_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                edu_bg_illiterate_childern_staying_rci_16_b:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.edu_bg_primary_childern_staying_rci_16_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                edu_bg_primary_childern_staying_rci_16_b:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.edu_bg_secondary_childern_staying_rci_16_b}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                edu_bg_secondary_childern_staying_rci_16_b:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        <h5 className="juvanile8">
                            {" "}
                            Indicator 17: Number of designated institutions that have been
                            monitored at least once per quarter by the inspection committee as
                            per state rules during the period 1 Jan 2022 to 31st March 2022{" "}
                        </h5>

                        <table className="qprhighcourt">
                            <tr>
                                <th>District</th>
                                <th>Number of such institutions</th>
                                <th>
                                    Number of institutions which are monitored as per state rules
                                </th>
                            </tr>

                            <tr>
                                <td>
                                    <p>{JSON.parse(getStorage("user")).username}</p>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.design_inst_no_of_institution}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                design_inst_no_of_institution: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.design_inst_no_of_inst_monit_as_per_state_rule}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                design_inst_no_of_inst_monit_as_per_state_rule:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={openForm1}
                            type="button"
                        >
                            Save & Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Form29;
