import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";

// FORM 9  Information regarding Juvenile Justice (Care and Protection of Children) Act

function Report9(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{}, { downloadExcel }] = useFormStore();
  const Qyear = props.Qyear
  const Qmonth = props.Qmonth
  const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
  const DisplayQuarter = Quarter[Qmonth]


  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };

  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {" "}
            Information regarding Juvenile Justice (Care and Protection of
            Children) Act{" "}
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <h4>{DisplayQuarter} {Qyear}</h4>
        <div className="modal-body">
          <div class="table-responsive">
            <table class="table" id="juvenile_justice">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">District</th>
                  <th scope="col">
                    Whether State Child Protection on Society set up u/s 106 of
                    JJ Act, 2015. If yes, Copy of notification{" "}
                  </th>
                  <th scope="col">
                    District Child Protection Unit u/s 106 of JJ Act, 2015
                  </th>
                  <th scope="col">
                    No. of SJPU & No. of Child Welfare Police Officers in
                    District u/s 107 of JJ Act, 2015{" "}
                  </th>
                  <th scope="col">
                    Total No. of Children Courts u/s 25 of the CPCR Act, 2005{" "}
                  </th>
                  <th scope="col">
                    Total No. of Reg. CCIs u/s 41 of JJ Act, 2015 (Run by Govt.)
                    18(a){" "}
                  </th>
                  <th scope="col">
                    Total No. of Reg. CCIs u/s 41 of JJ Act, 2015 (Run by NGOs)
                    18(b){" "}
                  </th>
                  <th scope="col">
                    Total No. of Unreg. CCIs u/s 41 of JJ Act, 2015 (Run by
                    Govt.) 18(c ){" "}
                  </th>
                  <th scope="col">
                    Total No. of Unreg. CCIs u/s 41 of JJ Act, 2015 (Run by
                    NGOs) 18(d){" "}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="row">0</th>
                  <td>district</td>
                  <td>0 </td>
                  <td>0 </td>
                  <td>0 </td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("juvenile_justice")}
          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default Report9;
