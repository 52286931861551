import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";
import Form5 from "./Form5";

// FORM 5 After Care services  (Govt) (Q)

function Report5(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { downloadExcel }] = useFormStore();
    const [formOpen, setFormOpen] = useState(false);
    const openForm1 = () => {
        setmodalIsOpen(false);
        props.close();
    };
    const Qyear = props.Qyear;
    const Qmonth = props.Qmonth;
    const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
    const DisplayQuarter = Quarter[Qmonth];
    let newArray = props.report.filter(function (d) {
        return d.year == Qyear && d.month == Qmonth;
    });
    // cumulative function
    let Cmonth = props.report.filter(function (d) {
        return d.year == Qyear && d.month <= Qmonth;
    });

    const cumulative = Cmonth.reduce((cumulative, value) => {
        for (const [key, count] of Object.entries(value)) {
            if (!cumulative[key]) {
                cumulative[key] = 0;
            }
            cumulative[key] += count;
        }
        return cumulative;
    }, {});
    //---------------
    function createPDF() {
        var sTable = document.getElementById('table').innerHTML;
        var style = "<style>";
        style = style + "table {width: 100%;font: 10px Calibri;}";
        style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "</style>";
        var win = window.open('', '', 'height=700,width=700');
        win.document.write('<html><head>');
        win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
        win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
        win.document.write('</body></html>');
        win.document.close(); 	// CLOSE THE CURRENT WINDOW.
        win.print();    // PRINT THE CONTENTS.
    }
    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                        {" "}
                        After Care Services{" "}
                    </h5>
                    <button
                        className="btn-close"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <h4>{DisplayQuarter} {Qyear}</h4>
                <div className="modal-body">
                    <div class="table-responsive" id="table" height="100%" width="100%" media="print">
                        <table class="table" id="after_care_service">
                            <thead>
                                <tr>
                                    <th scope="col" rowspan="3">
                                        #
                                    </th>
                                    <th scope="col" rowspan="3">
                                        District
                                    </th>
                                    <th scope="col" colspan="4">
                                        No. of children who need aftercare services (15-18)
                                    </th>
                                    <th scope="col" colspan="4">
                                        No. of children for whom aftercare plan created?
                                    </th>
                                    <th scope="col" colspan="4">
                                        No. of children for whom psychosocial assessment is
                                        completed?{" "}
                                    </th>
                                    <th scope="col" colspan="4">
                                        No. of children sent to aftercare homes?
                                    </th>
                                    <th scope="col" colspan="4">
                                        No. of children who have completed 18 years who were placed
                                        for gainful employment?
                                    </th>
                                    <th scope="col" colspan="4">
                                        No. of children who completed vocational training?{" "}
                                    </th>
                                </tr>

                                <tr>
                                    <th scope="col" colspan="2">
                                        Male
                                    </th>
                                    <th scope="col" colspan="2">
                                        Female
                                    </th>
                                    <th scope="col" colspan="2">
                                        Male
                                    </th>
                                    <th scope="col" colspan="2">
                                        Female
                                    </th>
                                    <th scope="col" colspan="2">
                                        Male
                                    </th>
                                    <th scope="col" colspan="2">
                                        Female
                                    </th>
                                    <th scope="col" colspan="2">
                                        Male
                                    </th>
                                    <th scope="col" colspan="2">
                                        Female
                                    </th>
                                    <th scope="col" colspan="2">
                                        Male
                                    </th>
                                    <th scope="col" colspan="2">
                                        Female
                                    </th>
                                    <th scope="col" colspan="2">
                                        Male
                                    </th>
                                    <th scope="col" colspan="2">
                                        Female
                                    </th>
                                </tr>

                                <tr>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                </tr>
                            </thead>
                            <tbody>
                                {newArray.map((r) => (
                                    <tr key={r?.id}>
                                        <th scope="row">{r?.id}</th>
                                        <td>{JSON.parse(getStorage("user")).username}</td>
                                        <td>{r?.children_need_after_care_male_15_18_quarterly}</td>
                                        <td>{cumulative.children_need_after_care_male_15_18_quarterly}</td>
                                        <td>{r?.children_need_after_care_female_15_18_quarterly}</td>
                                        <td>{cumulative.children_need_after_care_female_15_18_quarterly}</td>
                                        <td>{r?.after_care_plan_created_male_quarterly}</td>
                                        <td>{cumulative.after_care_plan_created_male_quarterly}</td>
                                        <td>{r?.after_care_plan_created_female_quarterly}</td>
                                        <td>{cumulative.after_care_plan_created_female_quarterly}</td>
                                        <td>{r?.psychosocial_assessment_male_quarterly}</td>
                                        <td>{cumulative.psychosocial_assessment_male_quarterly}</td>
                                        <td>{r?.psychosocial_assessment_female_quarterly}</td>
                                        <td>{cumulative.psychosocial_assessment_female_quarterly}</td>
                                        <td>{r?.send_to_after_home_male_quarterly}</td>
                                        <td>{cumulative.send_to_after_home_male_quarterly}</td>
                                        <td>{r?.send_to_after_home_female_quarterly}</td>
                                        <td>{cumulative.send_to_after_home_female_quarterly}</td>
                                        <td>{r?.children_18_year_placed_for_employment_male_quarterly}</td>
                                        <td>{cumulative.children_18_year_placed_for_employment_male_quarterly}</td>
                                        <td>{r?.children_18_year_placed_for_employment_female_quarterly}</td>
                                        <td>{cumulative.children_18_year_placed_for_employment_female_quarterly}</td>
                                        <td>{r?.compleated_vocational_training_male_quarterly}</td>
                                        <td>{cumulative.compleated_vocational_training_male_quarterly}</td>
                                        <td>{r?.compleated_vocational_training_female_quarterly}</td>
                                        <td>{cumulative.compleated_vocational_training_female_quarterly}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="modal-footer">
                    <button
                        className="btn btn-primary"
                        onClick={() => setFormOpen(true)}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                        type="button"
                    >
                        Edit
                    </button>
                    <button
                        className="btn btn-primary"
                        type="button"
                        data-bs-dismiss="modal"
                        onClick={() => downloadExcel("after_care_service")}
                    >
                        {" "}
                        Download <i class="icofont icofont-arrow-down"></i>{" "}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={createPDF}
                        type="button"
                        data-bs-dismiss="modal"
                    >
                        Download as Pdf
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
                </div>
            </Modal>
            {formOpen &&
                <Form5
                    isEdit
                    close={() => setFormOpen(false)}
                    report={props.report} Qmonth={Qmonth}
                    Qyear={Qyear}
                />
            }
        </div>
    );
}

export default Report5;
