import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";
import { Toaster, toast } from "react-hot-toast";

// FORM 4 Central sponsorship

function Form4(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { onSubmit }] = useFormStore();
    const [{ }, { updateReport }] = useFormStore();
    const [{ }, { formUpdated }] = useFormStore();
    const Qmonth = props.Qmonth;
    const Qyear = props.Qyear;
    const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
    const DisplayQuarter = Quarter[Qmonth];


    const [data, setdata] = useState({
        children_availing_sponsership_cumulative: 0,
        children_availing_sponsership_quarterly: 0,
        children_scolership_exempted: 0,
        children_scolership_added: 0,
        state_children_availing_sponsership_cumulative: 0,
        state_children_availing_sponsership_quarterly: 0,
        state_children_scolership_exempted: 0,
        state_children_scolership_added: 0,
        month: Qmonth,
        year: Qyear,
    });

    useEffect(() => {
        if ((props || {}).isEdit) {
            const { id, created_at, updated_at, username, user, report_type, ...reportSorted } = props.report.find((res) => { return res.month === Qmonth && res.year === Qyear; }) || {};
            setdata({
                ...data,
                ...reportSorted
            });
        }
    }, []);

    const openForm1 = () => {
        if (props.isEdit) {
            return toast.promise(
                updateReport(data, "update_central_sponsership"),
                {
                    loading: 'Updating Record',
                    success: 'Record Updated',
                    error: 'Error while saving'
                }
            ).then(() => formUpdated(true));
        } else {
            onSubmit(data, "central_sponsership");
            setmodalIsOpen(false);
        }
    };

    const closeButton = () => {
        props.close();
        setmodalIsOpen(false);
    };
    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <Toaster position="bottom-right" />
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {" "}
                            Details of children availing sponsorship schemes{" "}
                        </h5>
                        <button
                            className="btn-close"
                            onClick={closeButton}
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    <h4>{DisplayQuarter} {Qyear}</h4>
                    <div className="modal-body">
                        <form>

                            <div classNameName="new-expense__control">
                                <h2>centralsponsorship</h2>
                                <label>

                                    {" "}
                                    Number of children availing sponsorship (current quarter){" "}
                                </label>{" "}
                                :{" "}
                                <input
                                    type="number"
                                    value={data.children_availing_sponsership_quarterly}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            children_availing_sponsership_quarterly: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div classNameName="new-expense__control">
                                <label>
                                    {" "}
                                    Number of children whose scholarship was exempted{" "}
                                </label>{" "}
                                :{" "}
                                <input
                                    type="number"
                                    value={data.children_scolership_exempted}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            children_scolership_exempted: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div classNameName="new-expense__control">
                                <label> Number of children whose scholarship was added </label>{" "}
                                :{" "}
                                <input
                                    type="number"
                                    value={data.children_scolership_added}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            children_scolership_added: e.target.value,
                                        })
                                    }
                                />
                                <h2>statesponsorship</h2>
                                <label>

                                    {" "}
                                    Number of children availing sponsorship (current quarter){" "}
                                </label>{" "}
                                :{" "}
                                <input
                                    type="number"
                                    value={data.state_children_availing_sponsership_quarterly}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            state_children_availing_sponsership_quarterly: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div classNameName="new-expense__control">
                                <label>
                                    {" "}
                                    Number of children whose scholarship was exempted{" "}
                                </label>{" "}
                                :{" "}
                                <input
                                    type="number"
                                    value={data.state_children_scolership_exempted}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            state_children_scolership_exempted: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div classNameName="new-expense__control">
                                <label> Number of children whose scholarship was added </label>{" "}
                                :{" "}
                                <input
                                    type="number"
                                    value={data.state_children_scolership_added}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            state_children_scolership_added: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={closeButton}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={openForm1}
                            type="button"
                        >
                            Save & Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Form4;
