import React, { useState } from 'react';
import Modal from 'react-modal';

import '../assets/css/feather-icon.css';
import '../assets/css/bootstrap.css';
import '../assets/css/responsive.css';
import '../assets/css/themify.css';
import '../assets/css/style.css';
import '../assets/css/fontawesome.css';
import '../assets/css/icofont.css';
import '../assets/css/flag-icon.css';

import './Report1.css';

 // FORM 3 : State sponsorship	(Q)

function Report3 (props) {

  
  const [modalIsOpen, setmodalIsOpen] = useState(true)

  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  }

  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle"> State Sponsorship	 </h5>
          <button className="btn-close" onClick={openForm1} type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div className="modal-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" rowspan="2" >Sponsorship provider</th>
                  <th scope="col" rowspan="2">District</th>
                  <th scope="col" colspan="2">Total No. of children availing sponsorship	</th>
                  <th scope="col" rowspan="1">No. of children whose scholarship was exempted in this quarter</th>
                  <th scope="col" rowspan="1">No. of children whose scholarship was added in this quarter</th>

                </tr>

                <tr>
                  <th scope="col"> State (cumulative) </th>
                  <th scope="col">State (current quarter)</th>
                  <th scope="col">State (current quarter)</th>
                  <th scope="col">State (current quarter)</th>
                </tr>

              </thead>

              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Trivandrum</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">2</th>
                  <td>Kollam</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">3</th>
                  <td>Pathanamthitta</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">4</th>
                  <td>Alappuzha</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">5</th>
                  <td>Kottayam</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">6</th>
                  <td>Ernakulam</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">7</th>
                  <td>Idukki</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">8</th>
                  <td>Thrissur</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">9</th>
                  <td>Palakkad</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">10</th>
                  <td>Malappuram</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">11</th>
                  <td>Kozhikode</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">12</th>
                  <td>Wayanad</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">13</th>
                  <td>Kannur </td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">14</th>
                  <td>Kasargod</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">15</th>
                  <td>Total</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
                
              </tbody>  
            </table>
          </div>          
        </div>

        <div className="modal-footer">
        <button className="btn btn-primary"  type="button" data-bs-dismiss="modal"> Download <i class="icofont icofont-arrow-down"></i> </button>
          <button className="btn btn-secondary" onClick={openForm1} type="button" data-bs-dismiss="modal">Close</button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  )
}

export default Report3;