import React, { useState } from "react";
import Modal from "react-modal";
import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./dashboard.css";

function DashModal(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);

    const CloseButton = () => {
        setmodalIsOpen(false);
        props.close();
    };
    //assigned category to a variable
    const category = props.category;
    let year = "";
    let month = "";
    //assigned Q and M for fetching month and quater from array
    const Q = props.Qmonth;
    const M = props.month;
    const Quarter = ["", "January-March", "April-June", "July-September", "October-December"];
    const Month = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    //condition to assign month and year according to selected category
    if (category === "M") {
        year = props.year;
        month = Month[M];
    }
    else if (category === "Q") {
        year = props.Qyear;
        month = Quarter[Q];
    } else if (category === "Y") {
        year = props.annually;
    }
    //all data
    const Data = Array.isArray(props.report) ? props.report : [];
    //created a empty array to store submitted districts
    let district_name = [];
    (Data || []).forEach(function (obj) {
        (district_name || []).push(obj.username);
    });
    const districtsArray = ["trivandrum", "kollam", "pathanamthitta", "alappuzha", "kottayam", "idukki", "ernakulam", "thrissur", "palakkad", "malappuram", "kozhikode", "wayanad", "kannur", "kasargod"];
    var dist_not_submitted = district_name.length ? districtsArray.filter(x => !district_name.includes(x)) : districtsArray;
    //not submitted districts
    const new_not_submitted = [];
    dist_not_submitted.forEach(function (obj) {
        new_not_submitted.push({ username: obj });
    });


    return (
        <div className="dash-div">
            <Modal isOpen={modalIsOpen} ariaHideApp={false}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                        {" "}
                        {props.heading}{" "}
                    </h5>
                    <button
                        className="btn-close"
                        onClick={CloseButton}
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <h4>{month} {year}</h4>
                <div className="dash-body">
                    <div class="table-responsive">
                        <table class="table-submitted" id="submitted">
                            <thead className="submitted-table-head">
                                <tr>
                                    <th rowSpan={2}>Submitted</th>
                                </tr>

                            </thead>
                            <tbody className="submitted-table-body">
                                {(Array.isArray(props.report) ? [...new Set(props.report)] : []).map((r) => (
                                    <tr>
                                        <td>{r?.username}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <table className="table-pending" id="pending">
                            <thead className="pending-table-head">
                                <tr>
                                    <th rowSpan={2}>Pending</th>
                                </tr>

                            </thead>
                            <tbody>
                                {new_not_submitted.map((r) => (
                                    <tr>
                                        <td>{r?.username}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="modal-footer">

                    <button
                        className="btn btn-secondary"
                        onClick={CloseButton}
                        type="button"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
                </div>
            </Modal>
        </div>
    );
}

export default DashModal;
