import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import React, { useState } from "react";

// import Home from './Home';
// import Header from './Header';

import "./Login.css";

import logo from "../assets/images/login/WCD.jpg";

import { Link } from "react-router-dom";
import { useAuthStore } from "../Store/Auth";
// import history from "./history";
import { useHistory } from "react-router-dom";
function Login() {
  let history = useHistory();
  const [{ }, { onLogin }] = useAuthStore();
  const [user, setuser] = useState("admin");
  const [data, setdata] = useState({
    username: "",
    password: "",
  });
  const [error, seterror] = useState("");
  const clickHandler = () => {
    console.log("ssssssssssss");
    if (data?.username && data?.password) {
      onLogin(user, data, history, seterror);
    }
    // history.push("/home");
  };

  return (
    <div>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-5 d-flex align-items-center justify-content-center">
              <img className="w-100" src={logo} alt="looginpage" />
            </div>
            <div className="col-xl-7 p-0">
              <div className="login-card">
                <form className="theme-form login-form">
                  <h4>Login</h4>
                  <h6>
                    Welcome back ! <br /> Log in to your account.
                  </h6>
                  <div className="form-group">
                    <label>User Name</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="icon-email"></i>
                      </span>
                      <input
                        className="form-control"
                        type="text"
                        required=""
                        placeholder="user name"
                        value={data?.username}
                        onChange={(e) =>
                          setdata({ ...data, username: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="icon-lock"></i>
                      </span>
                      <input
                        className="form-control"
                        type="password"
                        name="login[password]"
                        required=""
                        placeholder="*********"
                        value={data?.password}
                        onChange={(e) =>
                          setdata({ ...data, password: e.target.value })
                        }
                      />
                      <div className="show-hide">
                        <span className="show"> </span>
                      </div>
                    </div>
                  </div>
                  <div className="select-user-con">
                    <label>User Type</label>
                    <select
                      className="select-user"
                      onChange={(e) => setuser(e.target.value)}
                    >
                      <option value="admin">Admin</option>
                      <option value="district">District Admin</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <Link
                      to="#"
                      onClick={clickHandler}
                      className="btn btn-primary btn-block"
                      type="submit"
                    >
                      Sign in
                    </Link>
                  </div>
                  {error && (
                    <div className="login-error-con">
                      <span className="alert alert-danger">{error}</span>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
