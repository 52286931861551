import React from "react";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";
import "./Home.css";
import { Link } from "react-router-dom";
import Header from "./Header";
import DashModal from "./dashModal"; // 
import { useFormStore } from "../Store/Forms";

function Dashboard() {
    const [modalIsOpen, setmodalIsOpen] = React.useState(false);
    const [heading, setHeading] = React.useState("");
    const [category, setCategory] = React.useState("");

    // const [month, setMonth] = React.useState(moment().format("M"));
    const [month, setMonth] = React.useState("");
    const [year, setYear] = React.useState("");
    const [Qmonth, setMonthQuarterly] = React.useState("");
    const [Qyear, setYearQuarterly] = React.useState("");
    const [annually, setAnnually] = React.useState("");

    const [{ report }, { getReport }] = useFormStore();

    const doubleDigit = (n) => {
        const formattedNumber = n.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
        return formattedNumber;
    };

    const openReportForm1 = () => {
        if (month === "" || year === "") {
            alert("Date not selected");
            return false;
        };
        getReport("admin_cm", doubleDigit(+month), year);
        setmodalIsOpen(true);
        setHeading("Child Marriage");
        setCategory("M");
    };

    const openReportForm2 = () => {
        if (month === "" || year === "") {
            alert("Date not selected");
            return false;
        };
        getReport("admin_childlabour", doubleDigit(+month), year);
        setmodalIsOpen(true);
        setHeading("Child Labour");
        setCategory("M");
    };

    const saranabalyamReport = () => {
        if (month === "" || year === "") {
            alert("Date not selected");
            return false;
        };
        getReport("admin_saranabalyam", doubleDigit(+month), year);
        setmodalIsOpen(true);
        setHeading("Saranabalyam");
        setCategory("M");
    };

    const violenceReport = () => {
        if (Qmonth === "" || Qyear === "") {
            alert("Date not selected");
            return false;
        };
        getReport("admin_violence_against_children", doubleDigit(+month), year);
        setmodalIsOpen(true);
        setHeading("Violence against childern");
        setCategory("M");
    };

    const centralSponsorshipReports = () => {
        if (Qmonth === "" || Qyear === "") {
            alert("Date not selected");
            return false;
        };
        getReport("admin_centralsponsorship", "", "", Qmonth, Qyear);
        setmodalIsOpen(true);
        setHeading("Details of children availing sponsorship schemes");
        setCategory("Q");
    };

    const afterCareReports = () => {
        if (Qmonth === "" || Qyear === "") {
            alert("Date not selected");
            return false;
        };
        getReport("admin_aftercareservices", "", "", Qmonth, Qyear);
        setmodalIsOpen(true);
        setHeading("Aftercare Services");
        setCategory("Q");
    };

    const afterCareCCIReports = () => {
        if (Qmonth === "" || Qyear === "") {
            alert("Date not selected");
            return false;
        };
        getReport("admin_aftercarecci", "", "", Qmonth, Qyear);
        setmodalIsOpen(true);
        setHeading("Details of aftercare offered to children (age 18-21)");
        setCategory("Q");
    };

    const crimesReports = () => {
        if (Qmonth === "" || Qyear === "") {
            alert("Date not selected");
            return false;
        };
        getReport("admin_crimecommitted", "", "", Qmonth, Qyear);
        setmodalIsOpen(true);
        setHeading("Details of crimes committed by children");
        setCategory("Q");
    };

    const resourceReports = () => {
        if (annually === "") {
            alert("Year not selected");
            return false;
        };
        getReport("admin_resource_person", "", "", "", "", annually);
        setmodalIsOpen(true);
        setHeading("Details of resource persons/experts");
        setCategory("Y");
    };

    const ChildWelfareCommitteesReports = () => {
        if (Qmonth == "" || Qyear == "") {
            alert("Date not selected");
            return false;
        };
        getReport("admin_cwc", "", "", Qmonth, Qyear);
        setmodalIsOpen(true);
        setHeading("Details of cases produced before CWC");
        setCategory("Q");
    };

    const FosterCareReports = () => {
        if (Qmonth == "" || Qyear == "") {
            alert("Date not selected");
            return false;
        };
        getReport("admin_fostercare", "", "", Qmonth, Qyear);
        setmodalIsOpen(true);
        setHeading("Number of children in foster care");
        setCategory("Q");
    };

    const RepatriationReports = () => {
        if (Qmonth == "" || Qyear == "") {
            alert("Date not selected");
            return false;
        };
        getReport("admin_repatriation", "", "", Qmonth, Qyear);
        setmodalIsOpen(true);
        setHeading("Repatriation");
        setCategory("Q");
    };

    const ParentingClinicReport = () => {
        if (month == "" || year == "") {
            alert("Date not selected");
            return false;
        };
        getReport("admin_parenting_clinic", month, year);
        setmodalIsOpen(true);
        setHeading("Parenting Clinic");
        setCategory("M");
    };
    const ParentingOutreachReport = () => {
        if (month == "" || year == "") {
            alert("Date not selected");
            return false;
        };
        getReport("admin_parenting_outreach", month, year);
        setmodalIsOpen(true);
        setHeading("Parenting Outreach");
        setCategory("M");
    };
    const NcpcrReport = () => {
        if (Qmonth == "" || Qyear == "") {
            alert("Date not selected");
            return false;
        };
        getReport("admin_ncpcr", "", "", Qmonth, Qyear);
        setmodalIsOpen(true);
        setHeading("NCPCR");
        setCategory("Q");
    };
    const QprscReport = () => {
        if (Qmonth == "" || Qyear == "") {
            alert("Date not selected");
            return false;
        };
        getReport("admin_qprsc", "", "", Qmonth, Qyear);
        setmodalIsOpen(true);
        setHeading("QPR-SC");
        setCategory("Q");
    };
    const QprhcReport = () => {
        if (Qmonth == "" || Qyear == "") {
            alert("Date not selected");
            return false;
        };
        getReport("admin_qprhc", "", "", Qmonth, Qyear);
        setmodalIsOpen(true);
        setHeading("QPR-HC");
        setCategory("Q");
    };
    const handleChangeMonth = (e) => {
        setMonth(e.target.value);
        // setMonth(e.target.value);
        // console.log(e.target.value)
    };
    const handleChangeYear = (e) => {
        setYear(e.target.value);
        // setMonth(e.target.value);
        // console.log(e.target.value)
    };
    const QuarterlyhandleChange = (e) => {
        setMonthQuarterly(e.target.value);
    };
    const QuarterlyhandleChangeYear = (e) => {
        setYearQuarterly(e.target.value);
    };
    const SelectAnnually = (e) => {
        setAnnually(e.target.value);
    };
    const date = new Date();
    const currentYear = Number(date.getFullYear());

    return (
        <div>
            <div className="page-wrapper" id="pageWrapper">
                {/* Header  */}
                <Header />

                {/* <!-- Page Body Start--> */}
                <div className="page-body-wrapper horizontal-menu">
                    {/* Sidebar  */}
                    <li><Link className="btn btn-primary" id="home-button" to="/admin"><i data-feather="home"></i><span> Home </span></Link> </li>
                    {/* Sidebar  */}

                    <div className="page-body">
                        <div className="container-fluid">
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h3> Statistics </h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Container-fluid starts--> */}
                        <div className="container-fluid general-widget">
                            <div className="row">
                                {/* Monthly */}
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header pb-0 d-flex justify-content-between">
                                            <h5> Monthly </h5>
                                            <div className="w-50 mt-15 d-flex justify-content-end">
                                                <select
                                                    class="form-select w-25"
                                                    aria-label="Default select example"
                                                    // onChange={(e) => setMonth(e.target.value)}
                                                    onChange={handleChangeMonth}
                                                >
                                                    <option value="">Select Month</option>
                                                    <option value="1">January</option>
                                                    <option value="2">February</option>
                                                    <option value="3">March</option>
                                                    <option value="4">April</option>
                                                    <option value="5">May</option>
                                                    <option value="6">June</option>
                                                    <option value="7">July</option>
                                                    <option value="8">August</option>
                                                    <option value="9">September</option>
                                                    <option value="10">October</option>
                                                    <option value="11">November</option>
                                                    <option value="12">December</option>
                                                </select>
                                                <select
                                                    class="form-select w-25"
                                                    aria-label="Default select example"
                                                    // onChange={(e) => setMonth(e.target.value)}
                                                    onChange={handleChangeYear}
                                                >
                                                    <option value="">Select Year</option>
                                                    <option value={currentYear - 5}>{currentYear - 5}</option>
                                                    <option value={currentYear - 4}>{currentYear - 4}</option>
                                                    <option value={currentYear - 3}>{currentYear - 3}</option>
                                                    <option value={currentYear - 2}>{currentYear - 2}</option>
                                                    <option value={currentYear - 1}>{currentYear - 1}</option>
                                                    <option value={currentYear}>{currentYear}</option>
                                                    <option value={currentYear + 1}>{currentYear + 1}</option>
                                                    <option value={currentYear + 2}>{currentYear + 2}</option>
                                                    <option value={currentYear + 3}>{currentYear + 3}</option>
                                                    <option value={currentYear + 4}>{currentYear + 4}</option>
                                                    <option value={currentYear + 5}>{currentYear + 5}</option>
                                                    <option value={currentYear + 6}>{currentYear + 6}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="card-body btn-showcase">
                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={openReportForm1}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                Child marriage{" "}
                                            </button>

                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={openReportForm2}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong1"
                                            >
                                                {" "}
                                                Child labour{" "}
                                            </button>

                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={saranabalyamReport}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong1"
                                            >
                                                {" "}
                                                Saranabalyam{" "}
                                            </button>

                                            {/* <!-- Scrolling long content--> */}

                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={ParentingClinicReport}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong1"
                                            >
                                                {" "}
                                                Parenting Clinic{" "}
                                            </button>

                                            <button
                                                className="btn btn-primary"
                                                onClick={ParentingOutreachReport}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong1"
                                            >
                                                {" "}
                                                Parenting Outreach{" "}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* Quarterly */}
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header pb-0 pb-0 d-flex justify-content-between">
                                            <h5> Quarterly </h5>
                                            <div className="w-50 mt-15 d-flex justify-content-end">
                                                <select
                                                    class="form-select w-25"
                                                    aria-label="Default select example"
                                                    // value={Qmonth}
                                                    onChange={QuarterlyhandleChange}
                                                >
                                                    <option value="">Select-Quarter</option>
                                                    <option value="1">January - March</option>
                                                    <option value="2">April - June</option>
                                                    <option value="3">July - September</option>
                                                    <option value="4">October - December</option>
                                                </select>
                                                <select
                                                    class="form-select w-25"
                                                    aria-label="Default select example"
                                                    // onChange={(e) => setMonth(e.target.value)}
                                                    onChange={QuarterlyhandleChangeYear}
                                                >
                                                    <option value="">Select Year</option>
                                                    <option value={currentYear - 5}>{currentYear - 5}</option>
                                                    <option value={currentYear - 4}>{currentYear - 4}</option>
                                                    <option value={currentYear - 3}>{currentYear - 3}</option>
                                                    <option value={currentYear - 2}>{currentYear - 2}</option>
                                                    <option value={currentYear - 1}>{currentYear - 1}</option>
                                                    <option value={currentYear}>{currentYear}</option>
                                                    <option value={currentYear + 1}>{currentYear + 1}</option>
                                                    <option value={currentYear + 2}>{currentYear + 2}</option>
                                                    <option value={currentYear + 3}>{currentYear + 3}</option>
                                                    <option value={currentYear + 4}>{currentYear + 4}</option>
                                                    <option value={currentYear + 5}>{currentYear + 5}</option>
                                                    <option value={currentYear + 6}>{currentYear + 6}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="card-body btn-showcase">
                                            {/* <!-- Scrolling long content--> */}

                                            <button
                                                className="btn btn-primary"
                                                onClick={centralSponsorshipReports}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                Details of children availing sponsorship schemes{" "}
                                            </button>

                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={afterCareReports}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                Aftercare Services{" "}
                                            </button>

                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={afterCareCCIReports}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                Details of aftercare offered to children (age 18-21){" "}
                                            </button>

                                            {/* <!-- Scrolling long content--> */}


                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={crimesReports}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                Details of crimes committed by children{" "}
                                            </button>

                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={ChildWelfareCommitteesReports}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                Details of cases produced before CWC{" "}
                                            </button>

                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={FosterCareReports}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                Number of children in foster care{" "}
                                            </button>

                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={RepatriationReports}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                Repatriation{" "}
                                            </button>

                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={NcpcrReport}
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong1"
                                            >
                                                {" "}
                                                NCPCR
                                            </button>
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={QprscReport}
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong1"
                                            >
                                                {" "}
                                                QPR-SC
                                            </button>
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={QprhcReport}
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong1"
                                            >
                                                {" "}
                                                QPR-HC
                                            </button>
                                            <button
                                                className="btn btn-primary"
                                                onClick={violenceReport}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong1"
                                            >
                                                {" "}
                                                Violence against Children{" "}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* Bi-annually */}
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header pb-0 d-flex justify-content-between">
                                            <h5> Bi-annually </h5>
                                            <div className="w-50 mt-15 d-flex justify-content-end">
                                                <select
                                                    class="form-select w-25"
                                                    aria-label="Default select example"
                                                    // onChange={(e) => setMonth(e.target.value)}
                                                    onChange={SelectAnnually}
                                                >
                                                    <option value="">Select Year</option>
                                                    <option value={currentYear - 5}>{currentYear - 5}</option>
                                                    <option value={currentYear - 4}>{currentYear - 4}</option>
                                                    <option value={currentYear - 3}>{currentYear - 3}</option>
                                                    <option value={currentYear - 2}>{currentYear - 2}</option>
                                                    <option value={currentYear - 1}>{currentYear - 1}</option>
                                                    <option value={currentYear}>{currentYear}</option>
                                                    <option value={currentYear + 1}>{currentYear + 1}</option>
                                                    <option value={currentYear + 2}>{currentYear + 2}</option>
                                                    <option value={currentYear + 3}>{currentYear + 3}</option>
                                                    <option value={currentYear + 4}>{currentYear + 4}</option>
                                                    <option value={currentYear + 5}>{currentYear + 5}</option>
                                                    <option value={currentYear + 6}>{currentYear + 6}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="card-body btn-showcase">
                                            {/* <!-- Scrolling long content--> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={resourceReports}
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalLong"
                                            >
                                                {" "}
                                                Details of resource persons/experts{" "}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {modalIsOpen && (
                            <DashModal close={() => setmodalIsOpen(false)} report={report} month={month}
                                year={year} Qmonth={Qmonth} Qyear={Qyear} heading={heading} annually={annually} category={category} />
                        )}

                        {/* <!-- Container-fluid Ends--> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
