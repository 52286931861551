import {
  getStorage,
  removeStorage,
  setStorage,
} from "../../common/functions/Storage";
import { onAdminLogin, onLogin, onLogout } from "../../Api/Auth";
import { useHistory } from "react-router-dom";

const actions = {
  setLoading:
    (loading) =>
      ({ setState }) => {
        setState({ loading });
      },

  setIsAuthenticated:
    (isAuthenticated) =>
      ({ setState }) => {
        setState({ isAuthenticated });
      },

  checkAuthenticate:
    () =>
      () => {
        const token = getStorage("token");
        const user = JSON.parse(getStorage("user"));
        let history = useHistory();
        if (token && user?.username) {
        } else {
          history.push("/sign-in");
          document.location.reload()
        }
      },

  onLogin:
    (user, data, history, seterror) =>
      async ({ setState, dispatch }) => {
        try {
          seterror("");
          setState({ loading: true });
          if (user === "admin") {
            var res = await onAdminLogin(data);
          } else {
            var res = await onLogin(data);
          }
          setStorage("token", res?.access_token);
          setStorage("user", JSON.stringify(res?.user));

          dispatch(actions.setIsAuthenticated(true));
          if (user === "admin") {
            history.push("/admin");
          } else {
            history.push("/home");
          }
        } catch (error) {
          console.log(error?.data, "error");
          seterror(error?.data?.message);
          // message.error(error?.data?.message);
        } finally {
          setState({ loading: false });
        }
      },

  // onSignup:
  //   (data, navigateHome) =>
  //   async ({ setState, dispatch }) => {
  //     try {
  //       setState({ loading: true });
  //       const res = await onSignup(data);
  //       setStorage("token", res?.token);
  //       setStorage(
  //         "user",
  //         JSON.stringify({
  //           email: res?.email,
  //           name: res?.name,
  //           is_admin: res?.is_admin,
  //         })
  //       );
  //       dispatch(actions.setIsAuthenticated(true));
  //       navigateHome();
  //     } catch (error) {
  //       console.log(error?.data, "error");
  //       message.error(error?.data?.message);
  //     } finally {
  //       setState({ loading: false });
  //     }
  //   },

  onLogout:
    (navigateLogin, data) =>
      async ({ dispatch }) => {
        onLogout();
        removeStorage("token");
        removeStorage("user");
        dispatch(actions.setIsAuthenticated(false));
        navigateLogin();
      },
};

export default actions;
