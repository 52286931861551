import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";
import "./Form.css";
import { useFormStore } from "../Store/Forms";
import { Toaster, toast } from "react-hot-toast";
// FORM 23  Information regarding The Prohibition of Child Marriage Act
function Form30(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { onSubmit }] = useFormStore();
    const [{ }, { updateReport }] = useFormStore();
    const [{ }, { formUpdated }] = useFormStore();
    const month = props.month;
    const year = props.year;
    const Month = ["Month not selected", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const DisplayMonth = Month[month];
    const [data, setdata] = useState({
        // during the month
        outreach_camps_held_during_the_month: 0,
        panchayaths_covered_during_the_month: 0,
        clients_attended_during_the_month: 0,
        parents_attended_during_the_month: 0,
        children_attended_during_the_month: 0,
        issues_identified_behavioural_during_the_month: 0,
        issues_identified_emotional_during_the_month: 0,
        issues_identified_somatic_during_the_month: 0,
        issues_identified_interpersonal_during_the_month: 0,
        issues_identified_cognitive_during_the_month: 0,
        issues_identified_family_discord_during_the_month: 0,
        referrals_done_during_the_month: 0,
        awareness_classes_during_the_month: 0,
        people_attended_awareness_class_during_the_month: 0,
        // up_to_last_month
        outreach_camps_held_up_to_last_month: 0,
        panchayaths_covered_up_to_last_month: 0,
        clients_attended_up_to_last_month: 0,
        parents_attended_up_to_last_month: 0,
        children_attended_up_to_last_month: 0,
        issues_identified_behavioural_up_to_last_month: 0,
        issues_identified_emotional_up_to_last_month: 0,
        issues_identified_somatic_up_to_last_month: 0,
        issues_identified_interpersonal_up_to_last_month: 0,
        issues_identified_cognitive_up_to_last_month: 0,
        issues_identified_family_discord_up_to_last_month: 0,
        referrals_done_up_to_last_month: 0,
        awareness_classes_up_to_last_month: 0,
        people_attended_awareness_up_to_last_month: 0,
        month: month,
        year: year,
    });
    useEffect(() => {
        if ((props || {}).isEdit) {
            // getReport('child_labour', month, year);
            const { id, created_at, updated_at, username, user, report_type, ...reportSorted } = props.report.find((res) => { return res.month === month && res.year === year; }) || {};
            // reportSorted = Object.keys(reportSorted).flatMap((res) => ({ ...(Object.keys(data).includes(res) && { [res]: reportSorted[res] }) }));
            setdata({
                ...data,
                ...reportSorted
            });
        }
    }, []);
    const openForm1 = () => {
        if (props.isEdit) {
            return toast.promise(
                updateReport(data, "update_parenting_outreach"),
                {
                    loading: 'Updating Record',
                    success: 'Record Updated',
                    error: 'Error while saving'
                }
            ).then(() => formUpdated(true));
        } else {
            onSubmit(data, "parenting_outreach");
            setmodalIsOpen(false);
        }
    };
    const openForm = () => {
        props.close();
        setmodalIsOpen(false);
    };

    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <Toaster position="bottom-right" />
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {" "}
                            Parenting Outreach Camps- Monthly reporting format for DCPU{" "}
                        </h5>
                        <button
                            className="btn-close"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <h4>{DisplayMonth} {year}</h4>
                    <div className="modal-body">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>During the month</th>
                                    {/* <th>Cumulative</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>1</th>
                                    <th>No of outreach camps held</th>
                                    <th>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.outreach_camps_held_during_the_month}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    outreach_camps_held_during_the_month: e.target.value,
                                                })
                                            }
                                        />
                                    </th>
                                    {/* <th>
                    <input
                      type="number"
                      min="1"
                      value={data.outreach_camps_held_up_to_last_month}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          outreach_camps_held_up_to_last_month: e.target.value,
                        })
                      }
                    />
                  </th> */}
                                </tr>
                                <tr>
                                    <th>2</th>
                                    <th>No of Panchayaths covered</th>
                                    <th>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.panchayaths_covered_during_the_month}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    panchayaths_covered_during_the_month: e.target.value,
                                                })
                                            }
                                        />
                                    </th>
                                    {/* <th>
                    <input
                      type="number"
                      min="1"
                      value={data.panchayaths_covered_up_to_last_month}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          panchayaths_covered_up_to_last_month: e.target.value,
                        })
                      }
                    />
                  </th> */}
                                </tr>
                                <tr>
                                    <th>3</th>
                                    <th>Number of people attended the camps during the month</th>
                                    <th>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.clients_attended_during_the_month}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    clients_attended_during_the_month: e.target.value,
                                                })
                                            }
                                        />
                                    </th>
                                    {/* <th>
                    <input
                      type="number"
                      min="1"
                      value={data.clients_attended_up_to_last_month}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          clients_attended_up_to_last_month: e.target.value,
                        })
                      }
                    />
                  </th> */}
                                </tr>
                                <tr>
                                    <th>4</th>
                                    <th>No of parents attended</th>
                                    <th>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.parents_attended_during_the_month}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    parents_attended_during_the_month: e.target.value,
                                                })
                                            }
                                        />
                                    </th>
                                    {/* <th>
                    <input
                      type="number"
                      min="1"
                      value={data.parents_attended_up_to_last_month}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          parents_attended_up_to_last_month: e.target.value,
                        })
                      }
                    />
                  </th> */}
                                </tr>
                                <tr>
                                    <th>5</th>
                                    <th>No of children attended</th>
                                    <th>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.children_attended_during_the_month}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    children_attended_during_the_month: e.target.value,
                                                })
                                            }
                                        />
                                    </th>
                                    {/* <th>
                    <input
                      type="number"
                      min="1"
                      value={data.children_attended_up_to_last_month}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          children_attended_up_to_last_month: e.target.value,
                        })
                      }
                    /></th> */}
                                </tr>
                                <tr>
                                    <th>6</th>
                                    <th>Issues identified</th>
                                    <th>
                                        No s
                                    </th>
                                    {/* <th>
                    No s
                  </th> */}
                                </tr>
                                <tr>
                                    <th>I</th>
                                    <th>Behavioural</th>
                                    <th>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.issues_identified_behavioural_during_the_month}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    issues_identified_behavioural_during_the_month: e.target.value,
                                                })
                                            }
                                        />
                                    </th>
                                    {/* <th>
                    <input
                      type="number"
                      min="1"
                      value={data.issues_identified_behavioural_up_to_last_month}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          issues_identified_behavioural_up_to_last_month: e.target.value,
                        })
                      }
                    />
                  </th> */}
                                </tr>
                                <tr>
                                    <th>II</th>
                                    <th>Emotional</th>
                                    <th>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.issues_identified_emotional_during_the_month}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    issues_identified_emotional_during_the_month: e.target.value,
                                                })
                                            }
                                        />
                                    </th>
                                    {/* <th>
                    <input
                      type="number"
                      min="1"
                      value={data.issues_identified_emotional_up_to_last_month}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          issues_identified_emotional_up_to_last_month: e.target.value,
                        })
                      }
                    />
                  </th> */}
                                </tr>
                                <tr>
                                    <th>III</th>
                                    <th>Somatic (physical problems)</th>
                                    <th>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.issues_identified_somatic_during_the_month}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    issues_identified_somatic_during_the_month: e.target.value,
                                                })
                                            }
                                        />
                                    </th>
                                    {/* <th>
                    <input
                      type="number"
                      min="1"
                      value={data.issues_identified_somatic_up_to_last_month}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          issues_identified_somatic_up_to_last_month: e.target.value,
                        })
                      }
                    />
                  </th> */}
                                </tr>
                                <tr>
                                    <th>IV</th>
                                    <th>Interpersonal (love, relationships etc)</th>
                                    <th>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.issues_identified_interpersonal_during_the_month}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    issues_identified_interpersonal_during_the_month: e.target.value,
                                                })
                                            }
                                        />
                                    </th>
                                    {/* <th>
                    <input
                      type="number"
                      min="1"
                      value={data.issues_identified_interpersonal_up_to_last_month}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          issues_identified_interpersonal_up_to_last_month: e.target.value,
                        })
                      }
                    />
                  </th> */}
                                </tr>
                                <tr>
                                    <th>V</th>
                                    <th>Cognitive (thinking learning related)</th>
                                    <th>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.issues_identified_cognitive_during_the_month}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    issues_identified_cognitive_during_the_month: e.target.value,
                                                })
                                            }
                                        />
                                    </th>
                                    {/* <th>
                    <input
                      type="number"
                      min="1"
                      value={data.issues_identified_cognitive_up_to_last_month}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          issues_identified_cognitive_up_to_last_month: e.target.value,
                        })
                      }
                    />
                  </th> */}
                                </tr>
                                <tr>
                                    <th>VI</th>
                                    <th>Family discord</th>
                                    <th>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.issues_identified_family_discord_during_the_month}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    issues_identified_family_discord_during_the_month: e.target.value,
                                                })
                                            }
                                        />
                                    </th>
                                    {/* <th>
                    <input
                      type="number"
                      min="1"
                      value={data.issues_identified_family_discord_up_to_last_month}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          issues_identified_family_discord_up_to_last_month: e.target.value,
                        })
                      }
                    />
                  </th> */}
                                </tr>
                                <tr>
                                    <th>7</th>
                                    <th>
                                        No of referrals done by the counsellor to Parenting
                                        clinics/DRC or others
                                    </th>
                                    <th>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.referrals_done_during_the_month}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    referrals_done_during_the_month: e.target.value,
                                                })
                                            }
                                        />
                                    </th>
                                    {/* <th>
                    <input
                      type="number"
                      min="1"
                      value={data.referrals_done_up_to_last_month}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          referrals_done_up_to_last_month: e.target.value,
                        })
                      }
                    />
                  </th> */}
                                </tr>
                                <tr>
                                    <th>8</th>
                                    <th>No of awareness classes organised</th>
                                    <th>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.awareness_classes_during_the_month}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    awareness_classes_during_the_month: e.target.value,
                                                })
                                            }
                                        />
                                    </th>
                                    {/* <th>
                    <input
                      type="number"
                      min="1"
                      value={data.awareness_classes_up_to_last_month}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          awareness_classes_up_to_last_month: e.target.value,
                        })
                      }
                    />
                  </th> */}
                                </tr>
                                <tr>
                                    <th>9</th>
                                    <th>No of people attended in the awareness class</th>
                                    <th>
                                        <input
                                            type="number"
                                            min="1"
                                            value={data.people_attended_awareness_class_during_the_month}
                                            onChange={(e) =>
                                                setdata({
                                                    ...data,
                                                    people_attended_awareness_class_during_the_month: e.target.value,
                                                })
                                            }
                                        />
                                    </th>
                                    {/* <th>
                    <input
                      type="number"
                      min="1"
                      value={data.people_attended_awareness_up_to_last_month}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          people_attended_awareness_up_to_last_month: e.target.value,
                        })
                      }
                    />
                  </th> */}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={openForm1}
                            type="button"
                        >
                            Save & Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default Form30;
