import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";
import { Toaster, toast } from "react-hot-toast";

// FORM 10  Saranabalyam

function Form10(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { onSubmit }] = useFormStore();
    const [{ }, { updateReport }] = useFormStore();
    const [{ }, { formUpdated }] = useFormStore();
    const month = props.month;
    const year = props.year;
    const Month = ["Month not selected", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const DisplayMonth = Month[month];

    const [data, setdata] = useState({
        rescued_by_sb_team: 0,
        case_reported_in_dist: 0,
        male_0_3: 0,
        female_0_3: 0,
        male_4_7: 0,
        female_4_7: 0,
        male_8_11: 0,
        female_8_11: 0,
        male_12_15: 0,
        female_12_15: 0,
        male_16_18: 0,
        female_16_18: 0,
        fir_male: 0,
        fir_female: 0,
        male_children_dna_need: 0,
        female_children_dna_need: 0,
        male_children_dna_conducted: 0,
        female_children_dna_conducted: 0,
        male_repatriation_need: 0,
        female_repatriation_need: 0,
        male_repatriated: 0,
        female_repatriated: 0,
        male_restored_to_family: 0,
        female_restored_to_family: 0,
        cci_male: 0,
        cci_female: 0,
        outside_state_male: 0,
        outside_state_female: 0,
        counselling_male: 0,
        counselling_female: 0,
        month: month,
        year: year,
    });

    useEffect(() => {
        if ((props || {}).isEdit) {
            // getReport('child_labour', month, year);
            const { id, created_at, updated_at, username, user, report_type, ...reportSorted } = props.report.find((res) => { return res.month === month && res.year === year; }) || {};
            // reportSorted = Object.keys(reportSorted).flatMap((res) => ({ ...(Object.keys(data).includes(res) && { [res]: reportSorted[res] }) }));
            setdata({
                ...data,
                ...reportSorted
            });
        }
    }, []);

    const openForm1 = () => {
        if (props.isEdit) {
            return toast.promise(
                updateReport(data, "update_saranabalyam"),
                {
                    loading: 'Updating Record',
                    success: 'Record Updated',
                    error: 'Error while saving'
                }
            ).then(() => formUpdated(true));
        } else {
            onSubmit(data, "saranabalyam");
            setmodalIsOpen(false);
        }
    };
    const openForm = () => {
        props.close();
        setmodalIsOpen(false);
    };

    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <Toaster position="bottom-right" />
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {" "}
                            Information regarding Juvenile Justice (Care and Protection of
                            Children) Act{" "}
                        </h5>
                        <button
                            className="btn-close"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    <h4>{DisplayMonth} {year}</h4>
                    <div className="modal-body">
                        <form>
                            <div className="new-expense__control">
                                <label> Total number of children rescued by the SB team </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.rescued_by_sb_team}
                                    onChange={(e) =>
                                        setdata({ ...data, rescued_by_sb_team: e.target.value })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> Total cases reported in the dist </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.case_reported_in_dist}
                                    onChange={(e) =>
                                        setdata({ ...data, case_reported_in_dist: e.target.value })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    <b> Age </b>{" "}
                                </label>
                                <label> Male (Age between 0-3) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.male_0_3}
                                    onChange={(e) =>
                                        setdata({ ...data, male_0_3: e.target.value })
                                    }
                                />
                                <label> Female (Age between 0-3) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.female_0_3}
                                    onChange={(e) =>
                                        setdata({ ...data, female_0_3: e.target.value })
                                    }
                                />
                                <label> Male (Age between 4-7) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.male_4_7}
                                    onChange={(e) =>
                                        setdata({ ...data, male_4_7: e.target.value })
                                    }
                                />
                                <label> Female (Age between 4-7) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.female_4_7}
                                    onChange={(e) =>
                                        setdata({ ...data, female_4_7: e.target.value })
                                    }
                                />
                                <label> Male (Age between 8-11) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.male_8_11}
                                    onChange={(e) =>
                                        setdata({ ...data, male_8_11: e.target.value })
                                    }
                                />
                                <label> Female (Age between 8-11) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.female_8_11}
                                    onChange={(e) =>
                                        setdata({ ...data, female_8_11: e.target.value })
                                    }
                                />
                                <label> Male (Age between 12-15) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.male_12_15}
                                    onChange={(e) =>
                                        setdata({ ...data, male_12_15: e.target.value })
                                    }
                                />
                                <label> Female (Age between 12-15) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.female_12_15}
                                    onChange={(e) =>
                                        setdata({ ...data, female_12_15: e.target.value })
                                    }
                                />
                                <label> Male (Age between 16-18) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.male_16_18}
                                    onChange={(e) =>
                                        setdata({ ...data, male_16_18: e.target.value })
                                    }
                                />
                                <label> Female (Age between 16-18) </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.female_16_18}
                                    onChange={(e) =>
                                        setdata({ ...data, female_16_18: e.target.value })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    <b> FIR registered </b>{" "}
                                </label>
                                <label> Male </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.fir_male}
                                    onChange={(e) =>
                                        setdata({ ...data, fir_male: e.target.value })
                                    }
                                />
                                <label> Female </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.fir_female}
                                    onChange={(e) =>
                                        setdata({ ...data, fir_female: e.target.value })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    <b> No. of children in need of DNA Test </b>{" "}
                                </label>
                                <label> Male </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.male_children_dna_need}
                                    onChange={(e) =>
                                        setdata({ ...data, male_children_dna_need: e.target.value })
                                    }
                                />
                                <label> Female </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.female_children_dna_need}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            female_children_dna_need: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    <b>
                                        {" "}
                                        No. of children for whom DNA tests were conducted{" "}
                                    </b>{" "}
                                </label>
                                <label> Male </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.male_children_dna_conducted}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            male_children_dna_conducted: e.target.value,
                                        })
                                    }
                                />
                                <label> Female </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.female_children_dna_conducted}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            female_children_dna_conducted: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    <b> No. of children in need of repatriation </b>{" "}
                                </label>
                                <label> Male </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.male_repatriation_need}
                                    onChange={(e) =>
                                        setdata({ ...data, male_repatriation_need: e.target.value })
                                    }
                                />
                                <label> Female </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.female_repatriation_need}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            female_repatriation_need: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    <b> No. of children repatriated </b>{" "}
                                </label>
                                <label> Male </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.male_repatriated}
                                    onChange={(e) =>
                                        setdata({ ...data, male_repatriated: e.target.value })
                                    }
                                />
                                <label> Female </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.female_repatriated}
                                    onChange={(e) =>
                                        setdata({ ...data, female_repatriated: e.target.value })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    <b> Rehabilitated / restored to family </b>{" "}
                                </label>
                                <label> Male </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.male_restored_to_family}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            male_restored_to_family: e.target.value,
                                        })
                                    }
                                />
                                <label> Female </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.female_restored_to_family}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            female_restored_to_family: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    <b> Children in CCIs </b>{" "}
                                </label>
                                <label> Male </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.cci_male}
                                    onChange={(e) =>
                                        setdata({ ...data, cci_male: e.target.value })
                                    }
                                />
                                <label> Female </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.cci_female}
                                    onChange={(e) =>
                                        setdata({ ...data, cci_female: e.target.value })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    <b> No. of children outside the state </b>{" "}
                                </label>
                                <label> Male </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.outside_state_male}
                                    onChange={(e) =>
                                        setdata({ ...data, outside_state_male: e.target.value })
                                    }
                                />
                                <label> Female </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.outside_state_female}
                                    onChange={(e) =>
                                        setdata({ ...data, outside_state_female: e.target.value })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    <b> Counselling provided </b>{" "}
                                </label>
                                <label> Male </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.counselling_male}
                                    onChange={(e) =>
                                        setdata({ ...data, counselling_male: e.target.value })
                                    }
                                />
                                <label> Female </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.counselling_female}
                                    onChange={(e) =>
                                        setdata({ ...data, counselling_female: e.target.value })
                                    }
                                />
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={openForm1}
                            type="button"
                        >
                            Save & Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Form10;
