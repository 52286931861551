import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";
import Form12 from "./Form12";

// FORM 12 JJA (Q)

function Report12(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { downloadExcel }] = useFormStore();
    const [formOpen, setFormOpen] = useState(false);

    const openForm1 = () => {
        setmodalIsOpen(false);
        props.close();
    };
    const Qyear = props.Qyear;
    const Qmonth = props.Qmonth;
    const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
    const DisplayQuarter = Quarter[Qmonth];
    let newArray = props.report.filter(function (d) {
        return d.year == Qyear && d.month == Qmonth;
    });
    // cumulative function
    let Cmonth = props.report.filter(function (d) {
        return d.year == Qyear && d.month <= Qmonth;
    });

    const cumulative = Cmonth.reduce((cumulative, value) => {
        for (const [key, count] of Object.entries(value)) {
            if (!cumulative[key]) {
                cumulative[key] = 0;
            }
            cumulative[key] += count;
        }
        return cumulative;
    }, {});

    console.log(props.report);
    //---------------

    function createPDF() {
        var sTable = document.getElementById('table').innerHTML;
        var style = "<style>";
        style = style + "table {width: 100%;font: 10px Calibri;}";
        style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "</style>";
        var win = window.open('', '', 'height=700,width=700');
        win.document.write('<html><head>');
        win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
        win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
        win.document.write('</body></html>');
        win.document.close(); 	// CLOSE THE CURRENT WINDOW.
        win.print();    // PRINT THE CONTENTS.
    }
    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                        Details of cases produced before CWC
                    </h5>
                    <button
                        className="btn-close"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <h4>{DisplayQuarter} {Qyear}</h4>
                <div className="modal-body">
                    <div class="table-responsive" id="table" height="100%" width="100%" media="print">
                        <table class="table" id="cwc">
                            <thead>
                                <tr>
                                    <th scope="col" rowspan="3">
                                        #
                                    </th>
                                    <th scope="col" rowspan="3">
                                        JJA
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        Total no:of cases{" "}
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        FIR Registered
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        Produced before CWC
                                    </th>
                                    <th scope="col" colspan="12">
                                        Number of children who availed the services
                                    </th>
                                </tr>

                                <tr>
                                    <th scope="col" colspan="2">
                                        Support person{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Expert service{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Counselling{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Free legal aid{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Victim compensation
                                    </th>
                                    <th scope="col" colspan="2">
                                        Institutional support le
                                    </th>
                                </tr>

                                <tr>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                </tr>
                            </thead>

                            <tbody>
                                {newArray.map((r) => (
                                    <tr key={r?.id}>
                                        <th scope="row">{r?.id}</th>
                                        <td>{JSON.parse(getStorage("user")).username}</td>
                                        <td>{r?.jja_total_cases_current_quarter}</td>
                                        <td>{cumulative.jja_total_cases_current_quarter}</td>
                                        <td>{r?.jja_fir_registered_current_quarter}</td>
                                        <td>{cumulative.jja_fir_registered_current_quarter}</td>
                                        <td>{r?.jja_produced_before_cwc_current_quarter}</td>
                                        <td>{cumulative.jja_produced_before_cwc_current_quarter}</td>
                                        <td>{r?.jja_who_avail_service_support_person_current_quarter}</td>
                                        <td>{cumulative.jja_who_avail_service_support_person_current_quarter}</td>
                                        <td>{r?.jja_expert_service_current_quarter}</td>
                                        <td>{cumulative.jja_expert_service_current_quarter}</td>
                                        <td>{r?.jja_counselling_current_quarter}</td>
                                        <td>{cumulative.jja_counselling_current_quarter}</td>
                                        <td>{r?.jja_free_legal_aid_current_quarter}</td>
                                        <td>{cumulative.jja_free_legal_aid_current_quarter}</td>
                                        <td>{r?.jja_victim_compensation_current_quarter}</td>
                                        <td>{cumulative.jja_victim_compensation_current_quarter}</td>
                                        <td>{r?.jja_institutional_support_current_quarter}</td>
                                        <td>{cumulative.jja_institutional_support_current_quarter}</td>
                                    </tr>
                                ))}
                            </tbody>
                            <br></br>
                            {/* </table> */}
                            {/* <table class="table" id="pocso"> */}
                            <thead>
                                <tr>
                                    <th scope="col" rowspan="3">
                                        #
                                    </th>
                                    <th scope="col" rowspan="3">
                                        POCSO
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        Total no:of cases{" "}
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        FIR Registered
                                    </th>
                                    <th scope="col" rowspan="2" colspan="2">
                                        Produced before CWC
                                    </th>
                                    <th scope="col" colspan="12">
                                        Number of children who availed the services
                                    </th>
                                </tr>

                                <tr>
                                    <th scope="col" colspan="2">
                                        Support person{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Expert service{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Counselling{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Free legal aid{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        Victim compensation
                                    </th>
                                    <th scope="col" colspan="2">
                                        Institutional support le
                                    </th>
                                </tr>

                                <tr>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                    <th scope="col">Current quarter</th>
                                    <th scope="col">Cumulative</th>
                                </tr>
                            </thead>

                            <tbody>
                                {newArray.map((r) => (
                                    <tr key={r?.id}>
                                        <th scope="row">{r?.id}</th>
                                        <td>{JSON.parse(getStorage("user")).username}</td>
                                        <td>{r?.pocso_total_cases_current_quarter}</td>
                                        <td>{cumulative.pocso_total_cases_current_quarter}</td>
                                        <td>{r?.pocso_fir_registered_current_quarter}</td>
                                        <td>{cumulative.pocso_fir_registered_current_quarter}</td>
                                        <td>{r?.pocso_produced_before_cwc_current_quarter}</td>
                                        <td>{cumulative.pocso_produced_before_cwc_current_quarter}</td>
                                        <td>{r?.pocso_who_avail_service_support_person_current_quarter}</td>
                                        <td>{cumulative.pocso_who_avail_service_support_person_current_quarter}</td>
                                        <td>{r?.pocso_expert_service_current_quarter}</td>
                                        <td>{cumulative.pocso_expert_service_current_quarter}</td>
                                        <td>{r?.pocso_counselling_current_quarter}</td>
                                        <td>{cumulative.pocso_counselling_current_quarter}</td>
                                        <td>{r?.pocso_free_legal_aid_current_quarter}</td>
                                        <td>{cumulative.pocso_free_legal_aid_current_quarter}</td>
                                        <td>{r?.pocso_victim_compensation_current_quarter}</td>
                                        <td>{cumulative.pocso_victim_compensation_current_quarter}</td>
                                        <td>{r?.pocso_institutional_support_current_quarter}</td>
                                        <td>{cumulative.pocso_institutional_support_current_quarter}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="modal-footer">
                    <button
                        className="btn btn-primary"
                        onClick={() => setFormOpen(true)}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                        type="button"
                    >
                        Edit
                    </button>
                    <button
                        className="btn btn-primary"
                        type="button"
                        data-bs-dismiss="modal"
                        onClick={() => downloadExcel("cwc")}
                    >
                        {" "}
                        Download <i class="icofont icofont-arrow-down"></i>{" "}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={createPDF}
                        type="button"
                        data-bs-dismiss="modal"
                    >
                        Download as Pdf
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
                </div>
            </Modal>
            {formOpen && (
                <Form12
                    isEdit
                    close={() => setFormOpen(false)}
                    report={props.report} Qmonth={Qmonth}
                    Qyear={Qyear}
                />
            )}
        </div>
    );
}

export default Report12;
