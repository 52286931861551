import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";
import { Toaster, toast } from "react-hot-toast";
import { useEffect } from "react";
import { UPDATE_FOSTER_CARE } from "../utils/constant";

// FORM 21 3. Foster Care	(Q)

function Form21(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { onSubmit }] = useFormStore();
    const [{ }, { updateReport }] = useFormStore();
    const [{ }, { formUpdated }] = useFormStore();
    const Qyear = props.Qyear;
    const Qmonth = props.Qmonth;
    const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
    const DisplayQuarter = Quarter[Qmonth];

    const [data, setdata] = useState({
        lt_st_cumulative: 0,
        lt_st_current_quarter: 0,
        vacation_cumulative: 0,
        vacation_current_quarter: 0,
        group_cumulative: 0,
        group_current_quarter: 0,
        kinship_cumulative: 0,
        kinship_current_quarter: 0,
        month: Qmonth,
        year: Qyear,
    });

    useEffect(() => {
        if ((props || {}).isEdit) {
            const { id, created_at, updated_at, username, user, report_type, ...reportSorted } = props.report.find((res) => { return res.month === Qmonth && res.year === Qyear; }) || {};
            setdata({
                ...data,
                ...reportSorted
            });
        }
    }, []);

    const openForm1 = () => {
        if (props.isEdit) {
            return toast.promise(
                updateReport(data, UPDATE_FOSTER_CARE),
                {
                    loading: 'Updating Record',
                    success: 'Record Updated',
                    error: 'Error while saving'
                }
            ).then(() => formUpdated(true));
        } else {
            props.close();
            onSubmit(data, "foster_care");
            setmodalIsOpen(false);
        }
    };

    const closeButton = () => {
        props.close();
        setmodalIsOpen(false);
    };

    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <Toaster position="bottom-right" />
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {" "}
                            Foster care{" "}
                        </h5>
                        <button
                            className="btn-close"
                            onClick={closeButton}
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <h4>{DisplayQuarter} {Qyear}</h4>
                    <div className="modal-body">
                        <form>
                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    <b> Fostercare (Long-term/short-term) </b>{" "}
                                </label>{" "}
                                {/* <br />
                <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.lt_st_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, lt_st_cumulative: e.target.value })
                  }
                />{" "} */}
                                <br />
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.lt_st_current_quarter}
                                    onChange={(e) =>
                                        setdata({ ...data, lt_st_current_quarter: e.target.value })
                                    }
                                />{" "}
                                <br />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    <b> Vacation foster care </b>{" "}
                                </label>{" "}
                                <br />
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.vacation_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, vacation_cumulative: e.target.value })
                  }
                />{" "}
                <br /> */}
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.vacation_current_quarter}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            vacation_current_quarter: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    <b> Group foster care </b>{" "}
                                </label>{" "}
                                <br />
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.group_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, group_cumulative: e.target.value })
                  }
                />{" "} */}
                                <br />
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.group_current_quarter}
                                    onChange={(e) =>
                                        setdata({ ...data, group_current_quarter: e.target.value })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label>
                                    {" "}
                                    <b> Kinship foster care </b>{" "}
                                </label>{" "}
                                <br />
                                {/* <label> Cumulative </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.kinship_cumulative}
                  onChange={(e) =>
                    setdata({ ...data, kinship_cumulative: e.target.value })
                  }
                />{" "} */}
                                <br />
                                <label> Current quarter </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.kinship_current_quarter}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            kinship_current_quarter: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={closeButton}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={openForm1}
                            type="button"
                        >
                            Save & Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Form21;
