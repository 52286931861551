import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
// import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";
import Form30 from "./Form30";
// FORM 25 PARENTING CLINIC REPORTING FORMAT

function Report30(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { downloadExcel }] = useFormStore();
    const [formOpen, setFormOpen] = useState(false);
    const openForm1 = () => {
        setmodalIsOpen(false);
        props.close();
    };
    const year = props.year;
    const month = props.month;
    const Month = ["Month not selected", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const DisplayMonth = Month[month];

    let filteredData = props.report.filter(function (d) {
        return d.year == year && d.month == month;
    });
    // cumulative function
    let Cmonth = props.report.filter(function (d) {
        return d.year == year && d.month <= month;
    });

    const cumulative = Cmonth.reduce((cumulative, value) => {
        for (const [key, count] of Object.entries(value)) {
            if (!cumulative[key]) {
                cumulative[key] = 0;
            }
            cumulative[key] += count;
        }
        return cumulative;
    }, {});
    //---------------

    let newArray = "";
    let defaultArray = [{
        awareness_classes_during_the_month
            :
            "",
        awareness_classes_up_to_last_month
            :
            "",
        children_attended_during_the_month
            :
            "",
        children_attended_up_to_last_month
            :
            "",
        clients_attended_during_the_month
            :
            "",
        clients_attended_up_to_last_month
            :
            "",
        issues_identified_behavioural_during_the_month
            :
            "",
        issues_identified_behavioural_up_to_last_month
            :
            "",
        issues_identified_cognitive_during_the_month
            :
            "",
        issues_identified_cognitive_up_to_last_month
            :
            "",
        issues_identified_emotional_during_the_month
            :
            "",
        issues_identified_emotional_up_to_last_month
            :
            "",
        issues_identified_family_discord_during_the_month
            :
            "",
        issues_identified_family_discord_up_to_last_month
            :
            "",
        issues_identified_interpersonal_during_the_month
            :
            "",
        issues_identified_interpersonal_up_to_last_month
            :
            "",
        issues_identified_somatic_during_the_month
            :
            "",
        issues_identified_somatic_up_to_last_month
            :
            "",
        outreach_camps_held_during_the_month
            :
            "",
        outreach_camps_held_up_to_last_month
            :
            "",
        panchayaths_covered_during_the_month
            :
            "",
        panchayaths_covered_up_to_last_month
            :
            "",
        parents_attended_during_the_month
            :
            "",
        parents_attended_up_to_last_month
            :
            "",
        people_attended_awareness_class_during_the_month
            :
            "",
        people_attended_awareness_up_to_last_month
            :
            "",
        referrals_done_during_the_month
            :
            "",
        referrals_done_up_to_last_month
            :
            ""

    },];
    if (filteredData.length === 0) {
        newArray = defaultArray;
    }
    else {
        newArray = filteredData;

    }
    function createPDF() {
        var sTable = document.getElementById('table').innerHTML;
        var style = "<style>";
        style = style + "table {width: 100%;font: 10px Calibri;}";
        style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "</style>";
        var win = window.open('', '', 'height=700,width=700');
        win.document.write('<html><head>');
        win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
        win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
        win.document.write('</body></html>');
        win.document.close(); 	// CLOSE THE CURRENT WINDOW.
        win.print();    // PRINT THE CONTENTS.
    }


    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                        {" "}
                        Parenting Outreach{" "}
                    </h5>
                    <button
                        className="btn-close"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <h4>{DisplayMonth} {year}</h4>
                <div className="modal-body">
                    <div class="table-responsive" id="table" height="100%" width="100%" media="print">
                        <table class="table" id="parenting_outreach">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>During the month</th>
                                    <th>Cumulative</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>1</th>
                                    <th>No of outreach camps held</th>

                                    {newArray.map((r) => (
                                        <td>
                                            {
                                                r?.outreach_camps_held_during_the_month
                                            }
                                        </td>
                                    ))}
                                    <td>
                                        {
                                            cumulative.outreach_camps_held_during_the_month
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>2</th>
                                    <th>No of Panchayaths covered</th>
                                    {newArray.map((r) => (
                                        <td>
                                            {
                                                r?.panchayaths_covered_during_the_month
                                            }
                                        </td>
                                    ))}
                                    <td>
                                        {
                                            cumulative.panchayaths_covered_during_the_month
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>3</th>
                                    <th>Number of people attended the camps during the month</th>

                                    {newArray.map((r) => (
                                        <td>
                                            {
                                                r?.clients_attended_during_the_month
                                            }
                                        </td>
                                    ))}
                                    <td>
                                        {
                                            cumulative.clients_attended_during_the_month
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>4</th>
                                    <th>No of parents attended</th>

                                    {newArray.map((r) => (
                                        <td>
                                            {
                                                r?.parents_attended_during_the_month
                                            }
                                        </td>
                                    ))}
                                    <td>
                                        {
                                            cumulative.parents_attended_during_the_month
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>5</th>
                                    <th>No of children attended</th>
                                    {newArray.map((r) => (
                                        <td>
                                            {
                                                r?.children_attended_during_the_month
                                            }
                                        </td>
                                    ))}
                                    <td>
                                        {
                                            cumulative.children_attended_during_the_month
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>6</th>
                                    <th>Issues identified</th>
                                    <th>
                                        No s
                                    </th>
                                    <th>
                                        No s
                                    </th>
                                </tr>
                                <tr>
                                    <th>I</th>
                                    <th>Behavioural</th>

                                    {newArray.map((r) => (
                                        <td>
                                            {
                                                r?.issues_identified_behavioural_during_the_month
                                            }
                                        </td>
                                    ))}
                                    <td>
                                        {
                                            cumulative.issues_identified_behavioural_during_the_month
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>II</th>
                                    <th>Emotional</th>
                                    {newArray.map((r) => (
                                        <td>
                                            {
                                                r?.issues_identified_emotional_during_the_month
                                            }
                                        </td>
                                    ))}
                                    <td>
                                        {
                                            cumulative.issues_identified_emotional_during_the_month
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>III</th>
                                    <th>Somatic (physical problems)</th>
                                    {newArray.map((r) => (
                                        <td>
                                            {
                                                r?.issues_identified_somatic_during_the_month
                                            }
                                        </td>
                                    ))}
                                    <td>
                                        {
                                            cumulative.issues_identified_somatic_during_the_month
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>IV</th>
                                    <th>Interpersonal (love, relationships etc)</th>
                                    {newArray.map((r) => (
                                        <td>
                                            {
                                                r?.issues_identified_interpersonal_during_the_month
                                            }
                                        </td>
                                    ))}
                                    <td>
                                        {
                                            cumulative.issues_identified_interpersonal_during_the_month
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>V</th>
                                    <th>Cognitive (thinking learning related)</th>
                                    {newArray.map((r) => (
                                        <td>
                                            {
                                                r?.issues_identified_cognitive_during_the_month
                                            }
                                        </td>
                                    ))}
                                    <td>
                                        {
                                            cumulative.issues_identified_cognitive_during_the_month
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>VI</th>
                                    <th>Family discord</th>
                                    {newArray.map((r) => (
                                        <td>
                                            {
                                                r?.issues_identified_family_discord_during_the_month
                                            }
                                        </td>
                                    ))}
                                    <td>
                                        {
                                            cumulative.issues_identified_family_discord_during_the_month
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>7</th>
                                    <th>
                                        No of referrals done by the counsellor to Parenting
                                        clinics/DRC or others
                                    </th>
                                    {newArray.map((r) => (
                                        <td>
                                            {
                                                r?.referrals_done_during_the_month
                                            }
                                        </td>
                                    ))}
                                    <td>
                                        {
                                            cumulative.referrals_done_during_the_month
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>8</th>
                                    <th>No of awareness classes organised</th>
                                    {newArray.map((r) => (
                                        <td>
                                            {
                                                r?.awareness_classes_during_the_month
                                            }
                                        </td>
                                    ))}
                                    <td>
                                        {
                                            cumulative.awareness_classes_during_the_month
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>9</th>
                                    <th>No of people attended in the awareness class</th>
                                    {newArray.map((r) => (
                                        <td>
                                            {
                                                r?.people_attended_awareness_class_during_the_month
                                            }
                                        </td>
                                    ))}
                                    <td>
                                        {
                                            cumulative.people_attended_awareness_class_during_the_month
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="modal-footer">
                    <button
                        className="btn btn-primary"
                        onClick={() => setFormOpen(true)}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                        type="button"
                    >
                        Edit
                    </button>
                    <button
                        className="btn btn-primary"
                        type="button"
                        data-bs-dismiss="modal"
                        onClick={() => downloadExcel("parenting_outreach")}
                    >
                        {" "}
                        Download <i class="icofont icofont-arrow-down"></i>{" "}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={createPDF}
                        type="button"
                        data-bs-dismiss="modal"
                    >
                        Download as Pdf
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
                </div>
            </Modal>
            {formOpen && (
                <Form30
                    isEdit
                    close={() => setFormOpen(false)}
                    report={props.report} month={month}
                    year={year}
                />
            )}
        </div>
    );
}

export default Report30;
