import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Home.css";
// import AdminSidenav from "./AdminSidenav";

import Header from "./Header";

import AReport1 from "./AReport1"; // Report 1  CHILD MARRIAGE (M)
import AReport2 from "./AReport2"; // Report 2  CHILD LABOUR (M)
import AReport10 from "./AReport10"; // Report 10  Saranabalyam (M)
import Report11 from "./Report11"; // Report 11 Details of Violence against Children (M)
import AReport25 from "./AReport25"; // FORM 25 PARENTING CLINIC REPORTING FORMAT	(M)
import AReport30 from "./AReport30"; // FORM 22  Parenting outreach (M)

import AReport3 from "./AReport3"; // Report 3 : State sponsorship	(Q)
import AReport4 from "./AReport4"; // Report 4 Central sponsorship	(Q)
import AReport5 from "./AReport5"; // Report 5 After Care services  (Govt) (Q)
import AReport6 from "./AReport6"; // Report 6 Aftercare offered to children in CCIs (age 18-21) (Q)
import AReport7 from "./AReport7"; // Report 7 Details of crimes committed by children (Q)
import AReport9 from "./AReport9"; // Report 9  InReportation regarding Juvenile Justice (Care and Protection of Children) Act (Q)
import AReport11 from "./AReport11"; // Report 12 JJA (Q)
import AReport12 from "./AReport12"; // Report 12 JJA (Q)
import AReport13 from "./AReport13"; // Report 13 POCSO (Q)
import AReport14 from "./AReport14"; // Report 14 Details of de-institutionalised children (Govt)	(Q)
import AReport15 from "./AReport15"; // FORM 15  Juvenile Justice Boards
import AReport16 from "./AReport16"; // FORM 16 Institutional Care of Children in Conflict with Law	(Q)
import AReport17 from "./AReport17"; // FORM 17 1. Child Welfare Committees (Constituted under section 27(1) of the JJ Act, 2015):- (Q)
import AReport18 from "./AReport18"; // FORM 18 Institutional Care of Children in Need of Care and Protection	(Q)
import AReport19 from "./AReport19"; // FORM 19 3. Adoption under section 56 of the JJ Act, 2015	(Q)
import AReport20 from "./AReport20"; // FORM 20 4. Institutional Mechanisms for protection of children	(Q)
import AReport21 from "./AReport21"; // FORM 21  Foster Care	(Q)
import AReport22 from "./AReport22"; // FORM 22  Repatriation (Q)

import AReport8 from "./AReport8"; // Report 8 Details of resource persons/experts	(Bi)
import AReport26 from "./AReport26"; // Report NCPCR(Bi)
import AReport27 from "./AReport27"; // Report NCPCR(Bi)
import AReport28 from "./AReport28"; // Report NCPCR(Bi)
import Users from "./Users"; // Report NCPCR(Bi)
import { useFormStore } from "../Store/Forms";
import { useUserStore } from "../Store/Admin";
import Sidenav from "./Sidenav";

function Admin() {
  const [modalIsOpen, setmodalIsOpen] = React.useState(false);
  const [modalIsOpen2, setmodalIsOpen2] = React.useState(false);
  const [saranabalyamReportIsOpen, setsaranabalyamReportIsOpen] =
    React.useState(false);
  const [violenceReportIsOpen, setviolenceReportIsOpen] = React.useState(false);
  const [ParentingClinicReportIsOpen, setParentingClinicReportIsOpen] =
    React.useState(false);
  const [ParentingOutreachReportIsOpen, setParentingOutreachReportIsOpen] = React.useState(false);

  const [stateSponsorshipReportsIsOpen, setstateSponsorshipReportsIsOpen] =
    React.useState(false);
  const [centralSponsorshipReportsIsOpen, setcentralSponsorshipReportsIsOpen] =
    React.useState(false);
  const [afterCareReportsIsOpen, setafterCareReportsIsOpen] =
    React.useState(false);
  const [afterCareCCIReportsIsOpen, setafterCareCCIReportsIsOpen] =
    React.useState(false);
  const [
    DeinstitutionalisedReportsIsOpen,
    setDeinstitutionalisedReportsIsOpen,
  ] = React.useState(false);
  const [crimesReportsIsOpen, setcrimesReportsIsOpen] = React.useState(false);
  const [juvenileJusticeReportsIsOpen, setjuvenileJusticeReportsIsOpen] =
    React.useState(false);
  const [JJAReportsIsOpen, setJJAReportsIsOpen] = React.useState(false);
  const [POCSOReportsIsOpen, setPOCSOReportsIsOpen] = React.useState(false);
  const [
    JuvenileJusticeBoardsReportsIsOpen,
    setJuvenileJusticeBoardsReportsIsOpen,
  ] = React.useState(false);
  const [InstitutionalCareReportsIsOpen, setInstitutionalCareReportsIsOpen] =
    React.useState(false);
  const [
    ChildWelfareCommitteesReportsIsOpen,
    setChildWelfareCommitteesReportsIsOpen,
  ] = React.useState(false);
  const [ChildrenCareReportsIsOpen, setChildrenCareReportsIsOpen] =
    React.useState(false);
  const [AdoptionReportsIsOpen, setAdoptionReportsIsOpen] =
    React.useState(false);
  const [
    ProtectionofChildrenReportsIsOpen,
    setProtectionofChildrenReportsIsOpen,
  ] = React.useState(false);
  const [FosterCareReportsIsOpen, setFosterCareReportsIsOpen] =
    React.useState(false);
  const [RepatriationReportsIsOpen, setRepatriationReportsIsOpen] =
    React.useState(false);

  const [resourceReportsIsOpen, setresourceReportsIsOpen] =
    React.useState(false);
  const [ncpcrReportsIsOpen, setncpcrReportsIsOpen] = React.useState(false);
  const [QprscReportsIsOpen, setQprscReportsIsOpen] = React.useState(false);
  const [QprhcReportsIsOpen, setQprhcReportsIsOpen] = React.useState(false);
  const [UsersIsOpen, setUsersIsOpen] = React.useState(false);
  const [month, setMonth] = React.useState("");
  const [year, setYear] = React.useState("");
  const [{ report }, { getReport }] = useFormStore();
  const [{ userList }, { getUsersList }] = useUserStore();
  const [Qmonth, setMonthQuarterly] = React.useState("");
  const [Qyear, setYearQuarterly] = React.useState("");
  const [annually, setAnnually] = React.useState("");

  const openReportForm1 = () => {
    if (month == "" || year == "") {
      alert("Date not selected")
      return false
    };
    getReport("admin_cm", month, year);
    setmodalIsOpen(true);
  };

  const openReportForm2 = () => {
    if (month == "" || year == "") {
      alert("Date not selected")
      return false
    };
    getReport("admin_childlabour", month, year);
    setmodalIsOpen2(true);
  };

  const saranabalyamReport = () => {
    if (month == "" || year == "") {
      alert("Date not selected")
      return false
    };
    getReport("admin_saranabalyam", month, year);
    setsaranabalyamReportIsOpen(true);
  };

  const violenceReport = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    getReport("admin_violence_against_children", "", "", Qmonth, Qyear);
    setviolenceReportIsOpen(true);
  };

  const stateSponsorshipReports = () => {
    setstateSponsorshipReportsIsOpen(true);
  };

  const centralSponsorshipReports = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    getReport("admin_centralsponsorship", "", "", Qmonth, Qyear);
    setcentralSponsorshipReportsIsOpen(true);
  };

  const afterCareReports = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    getReport("admin_aftercareservices", "", "", Qmonth, Qyear);
    setafterCareReportsIsOpen(true);
  };

  const afterCareCCIReports = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    getReport("admin_aftercarecci", "", "", Qmonth, Qyear);
    setafterCareCCIReportsIsOpen(true);
  };

  const DeinstitutionalisedReports = () => {
    setDeinstitutionalisedReportsIsOpen(true);
  };

  const crimesReports = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    getReport("admin_crimecommitted", "", "", Qmonth, Qyear);
    setcrimesReportsIsOpen(true);
  };

  const juvenileJusticeReports = () => {
    setjuvenileJusticeReportsIsOpen(true);
  };

  const JJAReports = () => {
    setJJAReportsIsOpen(true);
  };

  const POCSOReports = () => {
    setPOCSOReportsIsOpen(true);
  };

  const resourceReports = () => {
    if (annually == "") {
      alert("Year not selected")
      return false
    };
    getReport("admin_resource_person", "", "", "", "", annually);
    setresourceReportsIsOpen(true);
  };

  const JuvenileJusticeBoardsReports = () => {
    setJuvenileJusticeBoardsReportsIsOpen(true);
  };

  const InstitutionalCareReports = () => {
    setInstitutionalCareReportsIsOpen(true);
  };

  const ChildWelfareCommitteesReports = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    getReport("admin_cwc", "", "", Qmonth, Qyear);
    setChildWelfareCommitteesReportsIsOpen(true);
  };

  const ChildrenCareReports = () => {
    setChildrenCareReportsIsOpen(true);
  };

  const AdoptionReports = () => {
    setAdoptionReportsIsOpen(true);
  };

  const ProtectionofChildrenReports = () => {
    setProtectionofChildrenReportsIsOpen(true);
  };

  const FosterCareReports = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    getReport("admin_fostercare", "", "", Qmonth, Qyear);
    setFosterCareReportsIsOpen(true);
  };

  const RepatriationReports = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    getReport("admin_repatriation", "", "", Qmonth, Qyear);
    setRepatriationReportsIsOpen(true);
  };

  const ParentingClinicReport = () => {
    if (month == "" || year == "") {
      alert("Date not selected")
      return false
    };
    getReport("admin_parenting_clinic", month, year);
    setParentingClinicReportIsOpen(true);
  };
  const ParentingOutreachReport = () => {
    if (month == "" || year == "") {
      alert("Date not selected")
      return false
    };
    getReport("admin_parenting_outreach", month, year);
    setParentingOutreachReportIsOpen(true);
  };
  const NcpcrReport = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    getReport("admin_ncpcr", "", "", Qmonth, Qyear);
    setncpcrReportsIsOpen(true);
  };
  const QprscReport = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    getReport("admin_qprsc", "", "", Qmonth, Qyear);
    setQprscReportsIsOpen(true);
  };
  const QprhcReport = () => {
    if (Qmonth == "" || Qyear == "") {
      alert("Date not selected")
      return false
    };
    getReport("admin_qprhc", "", "", Qmonth, Qyear);
    setQprhcReportsIsOpen(true);
  };
  const handleChangeMonth = (e) => {
    setMonth(e.target.value);
    // setMonth(e.target.value);
    // console.log(e.target.value)
  };
  const handleChangeYear = (e) => {
    setYear(e.target.value);
    // setMonth(e.target.value);
    // console.log(e.target.value)
  };
  const QuarterlyhandleChange = (e) => {
    setMonthQuarterly(e.target.value);
  };
  const QuarterlyhandleChangeYear = (e) => {
    setYearQuarterly(e.target.value);
  };
  const SelectAnnually = (e) => {
    setAnnually(e.target.value);
  };
  const UserModal = () => {
    getUsersList("users");
    setUsersIsOpen(true);
  };
  const date = new Date();
  const currentYear = Number(date.getFullYear());

  return (
    <div>
      <div className="page-wrapper" id="pageWrapper">
        {/* Header  */}
        <Header />

        {/* <!-- Page Body Start--> */}
        <div className="page-body-wrapper horizontal-menu">
          {/* Sidebar  */}
          <div className="sidebar-admin">
            <li><Link className="btn btn-primary" to="/dashboard"><i data-feather="home"></i><span> STATISTICS </span></Link> </li>
            {/*  */}
            <button onClick={UserModal} className="user-button btn btn-primary">Users</button>
          </div>

          <div className="page-body">
            <div className="container-fluid">
              <div className="page-header">
                <div className="row">
                  <div className="col-sm-6">
                    <h3> Reports </h3>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid general-widget">
              <div className="row">
                {/* Monthly */}
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header pb-0 d-flex justify-content-between">
                      <h5> Monthly </h5>
                      <div className="w-50 mt-15 d-flex justify-content-end">
                        <select
                          class="form-select w-25"
                          aria-label="Default select example"
                          // onChange={(e) => setMonth(e.target.value)}
                          onChange={handleChangeMonth}
                        >
                          <option value="">Select Month</option>
                          <option value="01">January</option>
                          <option value="02">February</option>
                          <option value="03">March</option>
                          <option value="04">April</option>
                          <option value="05">May</option>
                          <option value="06">June</option>
                          <option value="07">July</option>
                          <option value="08">August</option>
                          <option value="09">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </select>
                        <select
                          class="form-select w-25"
                          aria-label="Default select example"
                          // onChange={(e) => setMonth(e.target.value)}
                          onChange={handleChangeYear}
                        >
                          <option value="">Select Year</option>
                          <option value={currentYear - 5}>{currentYear - 5}</option>
                          <option value={currentYear - 4}>{currentYear - 4}</option>
                          <option value={currentYear - 3}>{currentYear - 3}</option>
                          <option value={currentYear - 2}>{currentYear - 2}</option>
                          <option value={currentYear - 1}>{currentYear - 1}</option>
                          <option value={currentYear}>{currentYear}</option>
                          <option value={currentYear + 1}>{currentYear + 1}</option>
                          <option value={currentYear + 2}>{currentYear + 2}</option>
                          <option value={currentYear + 3}>{currentYear + 3}</option>
                          <option value={currentYear + 4}>{currentYear + 4}</option>
                          <option value={currentYear + 5}>{currentYear + 5}</option>
                          <option value={currentYear + 6}>{currentYear + 6}</option>
                        </select>
                      </div>
                    </div>

                    <div className="card-body btn-showcase">
                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        onClick={openReportForm1}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Child marriage{" "}
                      </button>

                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        onClick={openReportForm2}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Child labour{" "}
                      </button>

                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        onClick={saranabalyamReport}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Saranabalyam{" "}
                      </button>

                      {/* <!-- Scrolling long content--> */}


                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        onClick={ParentingClinicReport}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Parenting Clinic{" "}
                      </button>

                      <button
                        className="btn btn-primary"
                        onClick={ParentingOutreachReport}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Parenting Outreach{" "}
                      </button>
                    </div>
                  </div>
                </div>

                {/* Quarterly */}
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header pb-0 pb-0 d-flex justify-content-between">
                      <h5> Quarterly </h5>
                      <div className="w-50 mt-15 d-flex justify-content-end">
                        <select
                          class="form-select w-25"
                          aria-label="Default select example"
                          // value={Qmonth}
                          onChange={QuarterlyhandleChange}
                        >
                          <option value="">Select-Quarter</option>
                          <option value="1">January - March</option>
                          <option value="2">April - June</option>
                          <option value="3">July - September</option>
                          <option value="4">October - December</option>
                        </select>
                        <select
                          class="form-select w-25"
                          aria-label="Default select example"
                          // onChange={(e) => setMonth(e.target.value)}
                          onChange={QuarterlyhandleChangeYear}
                        >
                          <option value="">Select Year</option>
                          <option value={currentYear - 5}>{currentYear - 5}</option>
                          <option value={currentYear - 4}>{currentYear - 4}</option>
                          <option value={currentYear - 3}>{currentYear - 3}</option>
                          <option value={currentYear - 2}>{currentYear - 2}</option>
                          <option value={currentYear - 1}>{currentYear - 1}</option>
                          <option value={currentYear}>{currentYear}</option>
                          <option value={currentYear + 1}>{currentYear + 1}</option>
                          <option value={currentYear + 2}>{currentYear + 2}</option>
                          <option value={currentYear + 3}>{currentYear + 3}</option>
                          <option value={currentYear + 4}>{currentYear + 4}</option>
                          <option value={currentYear + 5}>{currentYear + 5}</option>
                          <option value={currentYear + 6}>{currentYear + 6}</option>
                        </select>
                      </div>
                    </div>

                    <div className="card-body btn-showcase">
                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        onClick={stateSponsorshipReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        State Sponsorship{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        onClick={centralSponsorshipReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Central Sponsorship{" "}
                      </button> */}
                      <button
                        className="btn btn-primary"
                        onClick={centralSponsorshipReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Details of children availing sponsorship schemes{" "}
                      </button>

                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        onClick={afterCareReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Aftercare Services{" "}
                      </button>

                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        onClick={afterCareCCIReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Details of aftercare offered to children (age 18-21){" "}
                      </button>

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        onClick={DeinstitutionalisedReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        De-institutionalised children{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        onClick={crimesReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Details of crimes committed by children{" "}
                      </button>

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        onClick={juvenileJusticeReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Juvenile Justice{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        onClick={JJAReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        JJA{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        onClick={POCSOReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        POCSO{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        onClick={JuvenileJusticeBoardsReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Juvenile Justice Boards{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        onClick={InstitutionalCareReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Institutional Care of Children in Conflict with Law{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        onClick={ChildWelfareCommitteesReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Details of cases produced before CWC{" "}
                      </button>

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        onClick={ChildrenCareReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Child Welfare Committees{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        onClick={AdoptionReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Adoption{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      {/* <button
                        className="btn btn-primary"
                        onClick={ProtectionofChildrenReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Protection of Children{" "}
                      </button> */}

                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        onClick={FosterCareReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Number of children in foster care{" "}
                      </button>

                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        onClick={RepatriationReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Repatriation{" "}
                      </button>

                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={NcpcrReport}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        NCPCR
                      </button>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={QprscReport}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        QPR-SC
                      </button>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={QprhcReport}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        QPR-HC
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={violenceReport}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong1"
                      >
                        {" "}
                        Violence against Children{" "}
                      </button>
                    </div>
                  </div>
                </div>

                {/* Bi-annually */}
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header pb-0 d-flex justify-content-between">
                      <h5> Bi-annually </h5>
                      <div className="w-50 mt-15 d-flex justify-content-end">
                        <select
                          class="form-select w-25"
                          aria-label="Default select example"
                          // onChange={(e) => setMonth(e.target.value)}
                          onChange={SelectAnnually}
                        >
                          <option value="">Select Year</option>
                          <option value={currentYear - 5}>{currentYear - 5}</option>
                          <option value={currentYear - 4}>{currentYear - 4}</option>
                          <option value={currentYear - 3}>{currentYear - 3}</option>
                          <option value={currentYear - 2}>{currentYear - 2}</option>
                          <option value={currentYear - 1}>{currentYear - 1}</option>
                          <option value={currentYear}>{currentYear}</option>
                          <option value={currentYear + 1}>{currentYear + 1}</option>
                          <option value={currentYear + 2}>{currentYear + 2}</option>
                          <option value={currentYear + 3}>{currentYear + 3}</option>
                          <option value={currentYear + 4}>{currentYear + 4}</option>
                          <option value={currentYear + 5}>{currentYear + 5}</option>
                          <option value={currentYear + 6}>{currentYear + 6}</option>
                        </select>
                      </div>
                    </div>
                    <div className="card-body btn-showcase">
                      {/* <!-- Scrolling long content--> */}
                      <button
                        className="btn btn-primary"
                        onClick={resourceReports}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                      >
                        {" "}
                        Details of resource persons/experts{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {modalIsOpen && (
              <AReport1 close={() => setmodalIsOpen(false)} report={report} month={month} year={year} />
            )}
            {modalIsOpen2 && (
              <AReport2 close={() => setmodalIsOpen2(false)} report={report} month={month} year={year} />
            )}
            {saranabalyamReportIsOpen && (
              <AReport10 close={() => setsaranabalyamReportIsOpen(false)} report={report} month={month} year={year} />
            )}
            {violenceReportIsOpen && (
              <AReport11
                close={() => setviolenceReportIsOpen(false)}
                report={report} Qmonth={Qmonth} Qyear={Qyear}
              />
            )}
            {ParentingClinicReportIsOpen && (
              <AReport25 close={() => setParentingClinicReportIsOpen(false)} report={report} month={month} year={year} />
            )}
            {ParentingOutreachReportIsOpen && (
              <AReport30 close={() => setParentingOutreachReportIsOpen(false)} report={report} month={month} year={year} />
            )}

            {stateSponsorshipReportsIsOpen && (
              <AReport3 close={() => setstateSponsorshipReportsIsOpen(false)} Qmonth={Qmonth} Qyear={Qyear} />
            )}
            {centralSponsorshipReportsIsOpen && (
              <AReport4
                close={() => setcentralSponsorshipReportsIsOpen(false)}
                report={report} Qmonth={Qmonth} Qyear={Qyear}
              />
            )}
            {afterCareReportsIsOpen && (
              <AReport5
                close={() => setafterCareReportsIsOpen(false)}
                report={report} Qmonth={Qmonth} Qyear={Qyear}
              />
            )}
            {afterCareCCIReportsIsOpen && (
              <AReport6
                close={() => setafterCareCCIReportsIsOpen(false)}
                report={report} Qmonth={Qmonth} Qyear={Qyear}
              />
            )}
            {DeinstitutionalisedReportsIsOpen && (
              <AReport14
                close={() => setDeinstitutionalisedReportsIsOpen(false)}
              />
            )}
            {crimesReportsIsOpen && (
              <AReport7
                close={() => setcrimesReportsIsOpen(false)}
                report={report} Qmonth={Qmonth} Qyear={Qyear}
              />
            )}
            {juvenileJusticeReportsIsOpen && (
              <AReport9 close={() => setjuvenileJusticeReportsIsOpen(false)} Qmonth={Qmonth} Qyear={Qyear} />
            )}
            {JJAReportsIsOpen && (
              <AReport12 close={() => setJJAReportsIsOpen(false)} Qmonth={Qmonth} Qyear={Qyear} />
            )}
            {POCSOReportsIsOpen && (
              <AReport13 close={() => setPOCSOReportsIsOpen(false)} Qmonth={Qmonth} Qyear={Qyear} />
            )}
            {JuvenileJusticeBoardsReportsIsOpen && (
              <AReport15
                close={() => setJuvenileJusticeBoardsReportsIsOpen(false)} Qmonth={Qmonth} Qyear={Qyear}
              />
            )}
            {InstitutionalCareReportsIsOpen && (
              <AReport16
                close={() => setInstitutionalCareReportsIsOpen(false)} Qmonth={Qmonth} Qyear={Qyear}
              />
            )}
            {ChildWelfareCommitteesReportsIsOpen && (
              <AReport12
                close={() => setChildWelfareCommitteesReportsIsOpen(false)}
                report={report} Qmonth={Qmonth} Qyear={Qyear}
              />
            )}
            {ChildrenCareReportsIsOpen && (
              <AReport18 close={() => setChildrenCareReportsIsOpen(false)} Qmonth={Qmonth} Qyear={Qyear} />
            )}
            {AdoptionReportsIsOpen && (
              <AReport19 close={() => setAdoptionReportsIsOpen(false)} Qmonth={Qmonth} Qyear={Qyear} />
            )}
            {ProtectionofChildrenReportsIsOpen && (
              <AReport20
                close={() => setProtectionofChildrenReportsIsOpen(false)} Qmonth={Qmonth} Qyear={Qyear}
              />
            )}
            {FosterCareReportsIsOpen && (
              <AReport21
                close={() => setFosterCareReportsIsOpen(false)}
                report={report} Qmonth={Qmonth} Qyear={Qyear}
              />
            )}
            {RepatriationReportsIsOpen && (
              <AReport22
                close={() => setRepatriationReportsIsOpen(false)}
                report={report} Qmonth={Qmonth} Qyear={Qyear}
              />
            )}

            {resourceReportsIsOpen && (
              <AReport8
                close={() => setresourceReportsIsOpen(false)}
                report={report} annually={annually}
              />
            )}
            {ncpcrReportsIsOpen && (
              <AReport26
                close={() => setncpcrReportsIsOpen(false)}
                report={report} Qmonth={Qmonth} Qyear={Qyear}
              />
            )}
            {QprscReportsIsOpen && (
              <AReport27
                close={() => setQprscReportsIsOpen(false)}
                report={report} Qmonth={Qmonth} Qyear={Qyear}
              />
            )}
            {QprhcReportsIsOpen && (
              <AReport28
                close={() => setQprhcReportsIsOpen(false)}
                report={report} Qmonth={Qmonth} Qyear={Qyear}
              />
            )}
            {UsersIsOpen && (
              <Users
                close={() => setUsersIsOpen(false)}
                userList={userList}
              />
            )}

            {/* <!-- Container-fluid Ends--> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;