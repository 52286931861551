import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";

// FORM 25 PARENTING CLINIC REPORTING FORMAT

function AReport27(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { downloadExcel }] = useFormStore();
    const Qyear = props.Qyear;
    const Qmonth = props.Qmonth;
    const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
    const DisplayQuarter = Quarter[Qmonth];


    const openForm1 = () => {
        setmodalIsOpen(false);
        props.close();
    };
    // object assigned to a variable to obtain total
    const values = props.report || [];
    // sum_array = total sum data of district
    // const sum_array = values.reduce((sum_array, value) => {
    //   for (const [key, count] of Object.entries(value)) {
    //     if (!sum_array[key]) {
    //       sum_array[key] = 0;
    //     }
    //     sum_array[key] += count;
    //   }
    //   return sum_array;
    // }, {});
    const sum_array = {};

    // values.forEach(value => {
    //   for (const [key, count] of Object.entries(value)) {
    //     if (!sum_array[key]) {
    //       sum_array[key] = 0;
    //     }
    //     sum_array[key] += count;
    //   }
    // });

    if (Array.isArray(values)) {
        for (const value of (values || [])) {
            for (const [key, count] of Object.entries(value)) {
                if (!sum_array[key]) {
                    sum_array[key] = 0;
                }
                sum_array[key] += count;
            }
        }
    }
    function createPDF() {
        var sTable = document.getElementById('table').innerHTML;
        var style = "<style>";
        style = style + "table {width: 100%;font: 10px Calibri;}";
        style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "</style>";
        var win = window.open('', '', 'height=700,width=700');
        win.document.write('<html><head>');
        win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
        win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
        win.document.write('</body></html>');
        win.document.close(); 	// CLOSE THE CURRENT WINDOW.
        win.print();    // PRINT THE CONTENTS.
    }
    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                        {" "}
                        QPR - High Court{" "}
                    </h5>
                    <button
                        className="btn-close"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <h4>{DisplayQuarter} {Qyear}</h4>
                <div className="modal-body">
                    <div class="table-responsive" id="table" height="100%" width="100%" media="print" style={{ position: 'absolute' }}>
                        <table className="qprhighcourt" id="qprhc">
                            <tr>
                                <th colSpan={21} className="indicator">Indicator 1: Number of cases registered during  1 Jan 2022 to 31st  March 2022, district wise</th>
                            </tr>
                            <tr>
                                <th colSpan={21}>Juvenile Justice Board (JJB) - CICL</th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Heads/Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>Petty</th>
                                <th>Serious</th>
                                <th>Heinous</th>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>

                            {props.report.map((r) => (

                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>

                                    <td>{r?.registered_total_cases_1}</td>
                                    <td>{r?.registered_petty_1}</td>
                                    <td>{r?.registered_serious_1}</td>

                                    <td>{r?.registered_heinous_1}</td>
                                    <td>{r?.registered_7_10_1}</td>
                                    <td>{r?.registered_11_13_1}</td>
                                    <td>{r?.registered_14_15_1}</td>
                                    <td>{r?.registered_16_18_1}</td>
                                    <td>{r?.registered_male_1}</td>
                                    <td>{r?.registered_female_1}</td>
                                    <td>{r?.registered_general_1}</td>
                                    <td>{r?.registered_sc_1}</td>
                                    <td>{r?.registered_st_1}</td>
                                    <td>{r?.registered_obc_1}</td>
                                    <td>{r?.registered_not_known_1}</td>
                                    <td>{r?.registered_apl_1}</td>
                                    <td>{r?.registered_bpl_1}</td>
                                    <td>{r?.registered_illiterate_1}</td>
                                    <td>{r?.registered_primary_1}</td>
                                    <td>{r?.registered_secondary_1}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>

                                <td>{sum_array.registered_total_cases_1}</td>
                                <td>{sum_array.registered_petty_1}</td>
                                <td>{sum_array.registered_serious_1}</td>

                                <td>{sum_array.registered_heinous_1}</td>
                                <td>{sum_array.registered_7_10_1}</td>
                                <td>{sum_array.registered_11_13_1}</td>
                                <td>{sum_array.registered_14_15_1}</td>
                                <td>{sum_array.registered_16_18_1}</td>
                                <td>{sum_array.registered_male_1}</td>
                                <td>{sum_array.registered_female_1}</td>
                                <td>{sum_array.registered_general_1}</td>
                                <td>{sum_array.registered_sc_1}</td>
                                <td>{sum_array.registered_st_1}</td>
                                <td>{sum_array.registered_obc_1}</td>
                                <td>{sum_array.registered_not_known_1}</td>
                                <td>{sum_array.registered_apl_1}</td>
                                <td>{sum_array.registered_bpl_1}</td>
                                <td>{sum_array.registered_illiterate_1}</td>
                                <td>{sum_array.registered_primary_1}</td>
                                <td>{sum_array.registered_secondary_1}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={21} className="indicator">Indicator 2: Number of cases disposed during 1 Jan 2022 to 31st
                                    March 2022, district wise</th>
                            </tr>
                            <tr>
                                <th colSpan={21}>Juvenile Justice Board (JJB) - CICL</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Heads/Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>Petty</th>
                                <th>Serious</th>
                                <th>Heinous</th>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.disposed_total_cases_2}</td>
                                    <td>{r?.disposed_petty_2}</td>
                                    <td>{r?.disposed_serious_2}</td>

                                    <td>{r?.disposed_heinous_2}</td>
                                    <td>{r?.disposed_7_10_2}</td>
                                    <td>{r?.disposed_11_13_2}</td>
                                    <td>{r?.disposed_14_15_2}</td>
                                    <td>{r?.disposed_16_18_2}</td>
                                    <td>{r?.disposed_male_2}</td>
                                    <td>{r?.disposed_female_2}</td>
                                    <td>{r?.disposed_general_2}</td>
                                    <td>{r?.disposed_sc_2}</td>
                                    <td>{r?.disposed_st_2}</td>
                                    <td>{r?.disposed_obc_2}</td>
                                    <td>{r?.disposed_not_known_2}</td>
                                    <td>{r?.disposed_apl_2}</td>
                                    <td>{r?.disposed_bpl_2}</td>
                                    <td>{r?.disposed_illiterate_2}</td>
                                    <td>{r?.disposed_primary_2}</td>
                                    <td>{r?.disposed_secondary_2}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.disposed_total_cases_2}</td>
                                <td>{sum_array.disposed_petty_2}</td>
                                <td>{sum_array.disposed_serious_2}</td>

                                <td>{sum_array.disposed_heinous_2}</td>
                                <td>{sum_array.disposed_7_10_2}</td>
                                <td>{sum_array.disposed_11_13_2}</td>
                                <td>{sum_array.disposed_14_15_2}</td>
                                <td>{sum_array.disposed_16_18_2}</td>
                                <td>{sum_array.disposed_male_2}</td>
                                <td>{sum_array.disposed_female_2}</td>
                                <td>{sum_array.disposed_general_2}</td>
                                <td>{sum_array.disposed_sc_2}</td>
                                <td>{sum_array.disposed_st_2}</td>
                                <td>{sum_array.disposed_obc_2}</td>
                                <td>{sum_array.disposed_not_known_2}</td>
                                <td>{sum_array.disposed_apl_2}</td>
                                <td>{sum_array.disposed_bpl_2}</td>
                                <td>{sum_array.disposed_illiterate_2}</td>
                                <td>{sum_array.disposed_primary_2}</td>
                                <td>{sum_array.disposed_secondary_2}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={17} className="indicator">Indicator 3: Number of cases pending in the state and district
                                    before JJBs as on 31st March 2022</th>
                            </tr>

                            <tr><th colSpan={17}>Juvenile Justice Board (JJB) - CICL</th></tr>

                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th></th>
                                <th colSpan={4}>Age 7-10</th>
                                <th colSpan={4}>Age 11-13</th>
                                <th colSpan={4}>Age 14-15</th>
                                <th colSpan={4}> Age 16-18</th>
                            </tr>
                            <tr>
                                <th>District</th>
                                <th>Pending for &#60;1 years</th>
                                <th>Pending for 1-2 years</th>
                                <th>Pending for 3-5 years</th>
                                <th>Pending for 5+ years</th>
                                <th>Pending for &#60;1 years</th>
                                <th>Pending for 1-2 years</th>
                                <th>Pending for 3-5 years</th>
                                <th>Pending for 5+ years</th>
                                <th>Pending for &#60;1 years</th>
                                <th>Pending for 1-2 years</th>
                                <th>Pending for 3-5 years</th>
                                <th>Pending for 5+ years</th>
                                <th>Pending for &#60;1 years</th>
                                <th>Pending for 1-2 years</th>
                                <th>Pending for 3-5 years</th>
                                <th>Pending for 5+ years</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.pending_age_7_10_pending_for_less_than_1_years_3}</td>
                                    <td>{r?.pending_age_7_10_pending_for_1_2_years_3}</td>
                                    <td>{r?.pending_age_7_10_pending_for_3_5_years_3}</td>

                                    <td>{r?.pending_age_7_10_pending_for_5_plus_years_3}</td>
                                    <td>{r?.pending_age_11_13_pending_for_less_than_1_years_3}</td>
                                    <td>{r?.pending_age_11_13_pending_for_1_2_years_3}</td>
                                    <td>{r?.pending_age_11_13_pending_for_3_5_years_3}</td>
                                    <td>{r?.pending_age_11_13_pending_for_5_plus_years_3}</td>
                                    <td>{r?.pending_age_14_15_pending_for_less_than_1_years_3}</td>
                                    <td>{r?.pending_age_14_15_pending_for_1_2_years_3}</td>
                                    <td>{r?.pending_age_14_15_pending_for_3_5_years_3}</td>
                                    <td>{r?.pending_age_14_15_pending_for_5_plus_years_3}</td>
                                    <td>{r?.pending_age_16_18_pending_for_less_than_1_years_3}</td>
                                    <td>{r?.pending_age_16_18_pending_for_1_2_years_3}</td>
                                    <td>{r?.pending_age_16_18_pending_for_3_5_years_3}</td>
                                    <td>{r?.pending_age_16_18_pending_for_5_plus_years_3}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.pending_age_7_10_pending_for_less_than_1_years_3}</td>
                                <td>{sum_array.pending_age_7_10_pending_for_1_2_years_3}</td>
                                <td>{sum_array.pending_age_7_10_pending_for_3_5_years_3}</td>

                                <td>{sum_array.pending_age_7_10_pending_for_5_plus_years_3}</td>
                                <td>{sum_array.pending_age_11_13_pending_for_less_than_1_years_3}</td>
                                <td>{sum_array.pending_age_11_13_pending_for_1_2_years_3}</td>
                                <td>{sum_array.pending_age_11_13_pending_for_3_5_years_3}</td>
                                <td>{sum_array.pending_age_11_13_pending_for_5_plus_years_3}</td>
                                <td>{sum_array.pending_age_14_15_pending_for_less_than_1_years_3}</td>
                                <td>{sum_array.pending_age_14_15_pending_for_1_2_years_3}</td>
                                <td>{sum_array.pending_age_14_15_pending_for_3_5_years_3}</td>
                                <td>{sum_array.pending_age_14_15_pending_for_5_plus_years_3}</td>
                                <td>{sum_array.pending_age_16_18_pending_for_less_than_1_years_3}</td>
                                <td>{sum_array.pending_age_16_18_pending_for_1_2_years_3}</td>
                                <td>{sum_array.pending_age_16_18_pending_for_3_5_years_3}</td>
                                <td>{sum_array.pending_age_16_18_pending_for_5_plus_years_3}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={18} className="indicator">Indicator 4: Number of Pending Cases in relation to Petty Offences
                                    as on 31st March 2022, district wise</th>
                            </tr>
                            <th colSpan={18}>Juvenile Justice Board (JJB) - CICL</th>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>

                            {(Array.isArray(props.report) ? props.report : []).map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.pending_cases_total_cases_4}</td>
                                    <td>{r?.pending_cases_7_10_4}</td>
                                    <td>{r?.pending_cases_11_13_4}</td>
                                    <td>{r?.pending_cases_14_15_4}</td>
                                    <td>{r?.pending_cases_16_18_4}</td>
                                    <td>{r?.pending_cases_male_4}</td>
                                    <td>{r?.pending_cases_female_4}</td>
                                    <td>{r?.pending_cases_general_4}</td>
                                    <td>{r?.pending_cases_sc_4}</td>
                                    <td>{r?.pending_cases_st_4}</td>
                                    <td>{r?.pending_cases_obc_4}</td>
                                    <td>{r?.pending_cases_not_known_4}</td>
                                    <td>{r?.pending_cases_apl_4}</td>
                                    <td>{r?.pending_cases_bpl_4}</td>
                                    <td>{r?.pending_cases_illiterate_4}</td>
                                    <td>{r?.pending_cases_primary_4}</td>
                                    <td>{r?.pending_cases_secondary_4}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.pending_cases_total_cases_4}</td>
                                <td>{sum_array.pending_cases_7_10_4}</td>
                                <td>{sum_array.pending_cases_11_13_4}</td>
                                <td>{sum_array.pending_cases_14_15_4}</td>
                                <td>{sum_array.pending_cases_16_18_4}</td>
                                <td>{sum_array.pending_cases_male_4}</td>
                                <td>{sum_array.pending_cases_female_4}</td>
                                <td>{sum_array.pending_cases_general_4}</td>
                                <td>{sum_array.pending_cases_sc_4}</td>
                                <td>{sum_array.pending_cases_st_4}</td>
                                <td>{sum_array.pending_cases_obc_4}</td>
                                <td>{sum_array.pending_cases_not_known_4}</td>
                                <td>{sum_array.pending_cases_apl_4}</td>
                                <td>{sum_array.pending_cases_bpl_4}</td>
                                <td>{sum_array.pending_cases_illiterate_4}</td>
                                <td>{sum_array.pending_cases_primary_4}</td>
                                <td>{sum_array.pending_cases_secondary_4}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={17} className="indicator">Indicator 5: Number of- a) POCSO cases referred to Special Courts b)
                                    convictions in POCSO cases c) POCSO cases pending for more than a
                                    year</th>
                            </tr>
                            <tr>
                                <th colSpan={17}>5.a) Number of POCSO cases referred to Special Courts during 1 Oct
                                    2021 to 31 Dec 2021 - CNCP</th>
                            </tr>

                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>

                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.pocso_total_cases_5_a}</td>
                                    <td>{r?.pocso_0_5_5_a}</td>
                                    <td>{r?.pocso_6_10_5_a}</td>
                                    <td>{r?.pocso_11_18_5_a}</td>
                                    <td>{r?.pocso_male_5_a}</td>
                                    <td>{r?.pocso_female_5_a}</td>
                                    <td>{r?.pocso_general_5_a}</td>
                                    <td>{r?.pocso_sc_5_a}</td>
                                    <td>{r?.pocso_st_5_a}</td>
                                    <td>{r?.pocso_obc_5_a}</td>
                                    <td>{r?.pocso_not_known_5_a}</td>
                                    <td>{r?.pocso_apl_5_a}</td>
                                    <td>{r?.pocso_bpl_5_a}</td>
                                    <td>{r?.pocso_illiterate_5_a}</td>
                                    <td>{r?.pocso_primary_5_a}</td>
                                    <td>{r?.pocso_secondary_5_a}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.pocso_total_cases_5_a}</td>
                                <td>{sum_array.pocso_0_5_5_a}</td>
                                <td>{sum_array.pocso_6_10_5_a}</td>
                                <td>{sum_array.pocso_11_18_5_a}</td>
                                <td>{sum_array.pocso_male_5_a}</td>
                                <td>{sum_array.pocso_female_5_a}</td>
                                <td>{sum_array.pocso_general_5_a}</td>
                                <td>{sum_array.pocso_sc_5_a}</td>
                                <td>{sum_array.pocso_st_5_a}</td>
                                <td>{sum_array.pocso_obc_5_a}</td>
                                <td>{sum_array.pocso_not_known_5_a}</td>
                                <td>{sum_array.pocso_apl_5_a}</td>
                                <td>{sum_array.pocso_bpl_5_a}</td>
                                <td>{sum_array.pocso_illiterate_5_a}</td>
                                <td>{sum_array.pocso_primary_5_a}</td>
                                <td>{sum_array.pocso_secondary_5_a}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={17}>5.b) Number of convictions in POCSO cases during 1 Jan 2022 to 31st
                                    March 2022 - CNCP</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.convictions_total_cases_5_b}</td>
                                    <td>{r?.convictions_0_5_5_b}</td>
                                    <td>{r?.convictions_6_10_5_b}</td>
                                    <td>{r?.convictions_11_18_5_b}</td>
                                    <td>{r?.convictions_male_5_b}</td>
                                    <td>{r?.convictions_female_5_b}</td>
                                    <td>{r?.convictions_general_5_b}</td>
                                    <td>{r?.convictions_sc_5_b}</td>
                                    <td>{r?.convictions_st_5_b}</td>
                                    <td>{r?.convictions_obc_5_b}</td>
                                    <td>{r?.convictions_not_known_5_b}</td>
                                    <td>{r?.convictions_apl_5_b}</td>
                                    <td>{r?.convictions_bpl_5_b}</td>
                                    <td>{r?.convictions_illiterate_5_b}</td>
                                    <td>{r?.convictions_primary_5_b}</td>
                                    <td>{r?.convictions_secondary_5_b}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.convictions_total_cases_5_b}</td>
                                <td>{sum_array.convictions_0_5_5_b}</td>
                                <td>{sum_array.convictions_6_10_5_b}</td>
                                <td>{sum_array.convictions_11_18_5_b}</td>
                                <td>{sum_array.convictions_male_5_b}</td>
                                <td>{sum_array.convictions_female_5_b}</td>
                                <td>{sum_array.convictions_general_5_b}</td>
                                <td>{sum_array.convictions_sc_5_b}</td>
                                <td>{sum_array.convictions_st_5_b}</td>
                                <td>{sum_array.convictions_obc_5_b}</td>
                                <td>{sum_array.convictions_not_known_5_b}</td>
                                <td>{sum_array.convictions_apl_5_b}</td>
                                <td>{sum_array.convictions_bpl_5_b}</td>
                                <td>{sum_array.convictions_illiterate_5_b}</td>
                                <td>{sum_array.convictions_primary_5_b}</td>
                                <td>{sum_array.convictions_secondary_5_b}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={17}>5.c) Number of POCSO cases pending for more than a year as on 31st
                                    March 2022 - CNCP</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.pocso_pending_total_cases_5_c}</td>
                                    <td>{r?.pocso_pending_0_5_5_c}</td>
                                    <td>{r?.pocso_pending_6_10_5_c}</td>
                                    <td>{r?.pocso_pending_11_18_5_c}</td>
                                    <td>{r?.pocso_pending_male_5_c}</td>
                                    <td>{r?.pocso_pending_female_5_c}</td>
                                    <td>{r?.pocso_pending_general_5_c}</td>
                                    <td>{r?.pocso_pending_sc_5_c}</td>
                                    <td>{r?.pocso_pending_st_5_c}</td>
                                    <td>{r?.pocso_pending_obc_5_c}</td>
                                    <td>{r?.pocso_pending_not_known_5_c}</td>
                                    <td>{r?.pocso_pending_apl_5_c}</td>
                                    <td>{r?.pocso_pending_bpl_5_c}</td>
                                    <td>{r?.pocso_pending_illiterate_5_c}</td>
                                    <td>{r?.pocso_pending_primary_5_c}</td>
                                    <td>{r?.pocso_pending_secondary_5_c}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.pocso_pending_total_cases_5_c}</td>
                                <td>{sum_array.pocso_pending_0_5_5_c}</td>
                                <td>{sum_array.pocso_pending_6_10_5_c}</td>
                                <td>{sum_array.pocso_pending_11_18_5_c}</td>
                                <td>{sum_array.pocso_pending_male_5_c}</td>
                                <td>{sum_array.pocso_pending_female_5_c}</td>
                                <td>{sum_array.pocso_pending_general_5_c}</td>
                                <td>{sum_array.pocso_pending_sc_5_c}</td>
                                <td>{sum_array.pocso_pending_st_5_c}</td>
                                <td>{sum_array.pocso_pending_obc_5_c}</td>
                                <td>{sum_array.pocso_pending_not_known_5_c}</td>
                                <td>{sum_array.pocso_pending_apl_5_c}</td>
                                <td>{sum_array.pocso_pending_bpl_5_c}</td>
                                <td>{sum_array.pocso_pending_illiterate_5_c}</td>
                                <td>{sum_array.pocso_pending_primary_5_c}</td>
                                <td>{sum_array.pocso_pending_secondary_5_c}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={18} className="indicator">Indicator 6: Number of cases registered of heinous nature that are -
                                    a) continuing under JJB, b) referred to Special Court - CICL</th>
                            </tr>
                            <tr>

                                <th colSpan={18}>6.a) Number of cases registered of heinous nature that are
                                    continuing under JJB during 1 Jan 2022 to 31st March 2022 - CICL</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.heinous_total_cases_6_a}</td>
                                    <td>{r?.heinous_7_10_6_a}</td>
                                    <td>{r?.heinous_11_13_6_a}</td>
                                    <td>{r?.heinous_14_15_6_a}</td>
                                    <td>{r?.heinous_16_18_6_a}</td>
                                    <td>{r?.heinous_male_6_a}</td>
                                    <td>{r?.heinous_female_6_a}</td>
                                    <td>{r?.heinous_general_6_a}</td>
                                    <td>{r?.heinous_sc_6_a}</td>
                                    <td>{r?.heinous_st_6_a}</td>
                                    <td>{r?.heinous_obc_6_a}</td>
                                    <td>{r?.heinous_not_known_6_a}</td>
                                    <td>{r?.heinous_apl_6_a}</td>
                                    <td>{r?.heinous_bpl_6_a}</td>
                                    <td>{r?.heinous_illiterate_6_a}</td>
                                    <td>{r?.heinous_primary_6_a}</td>
                                    <td>{r?.heinous_secondary_6_a}</td>
                                </tr>
                            ))}


                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.heinous_total_cases_6_a}</td>
                                <td>{sum_array.heinous_7_10_6_a}</td>
                                <td>{sum_array.heinous_11_13_6_a}</td>
                                <td>{sum_array.heinous_14_15_6_a}</td>
                                <td>{sum_array.heinous_16_18_6_a}</td>
                                <td>{sum_array.heinous_male_6_a}</td>
                                <td>{sum_array.heinous_female_6_a}</td>
                                <td>{sum_array.heinous_general_6_a}</td>
                                <td>{sum_array.heinous_sc_6_a}</td>
                                <td>{sum_array.heinous_st_6_a}</td>
                                <td>{sum_array.heinous_obc_6_a}</td>
                                <td>{sum_array.heinous_not_known_6_a}</td>
                                <td>{sum_array.heinous_apl_6_a}</td>
                                <td>{sum_array.heinous_bpl_6_a}</td>
                                <td>{sum_array.heinous_illiterate_6_a}</td>
                                <td>{sum_array.heinous_primary_6_a}</td>
                                <td>{sum_array.heinous_secondary_6_a}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={18}>6.b) Number of cases registered of heinous nature that are referred
                                    to Special Court during 1 Jan 2022 to 31st March 2022 - CIC</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.heinous_nature_total_cases_6_b}</td>
                                    <td>{r?.heinous_nature_7_10_6_b}</td>
                                    <td>{r?.heinous_nature_11_13_6_b}</td>
                                    <td>{r?.heinous_nature_14_15_6_b}</td>
                                    <td>{r?.heinous_nature_16_18_6_b}</td>
                                    <td>{r?.heinous_nature_male_6_b}</td>
                                    <td>{r?.heinous_nature_female_6_b}</td>
                                    <td>{r?.heinous_nature_general_6_b}</td>
                                    <td>{r?.heinous_nature_sc_6_b}</td>
                                    <td>{r?.heinous_nature_st_6_b}</td>
                                    <td>{r?.heinous_nature_obc_6_b}</td>
                                    <td>{r?.heinous_nature_not_known_6_b}</td>
                                    <td>{r?.heinous_nature_apl_6_b}</td>
                                    <td>{r?.heinous_nature_bpl_6_b}</td>
                                    <td>{r?.heinous_nature_illiterate_6_b}</td>
                                    <td>{r?.heinous_nature_primary_6_b}</td>
                                    <td>{r?.heinous_nature_secondary_6_b}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.heinous_nature_total_cases_6_b}</td>
                                <td>{sum_array.heinous_nature_7_10_6_b}</td>
                                <td>{sum_array.heinous_nature_11_13_6_b}</td>
                                <td>{sum_array.heinous_nature_14_15_6_b}</td>
                                <td>{sum_array.heinous_nature_16_18_6_b}</td>
                                <td>{sum_array.heinous_nature_male_6_b}</td>
                                <td>{sum_array.heinous_nature_female_6_b}</td>
                                <td>{sum_array.heinous_nature_general_6_b}</td>
                                <td>{sum_array.heinous_nature_sc_6_b}</td>
                                <td>{sum_array.heinous_nature_st_6_b}</td>
                                <td>{sum_array.heinous_nature_obc_6_b}</td>
                                <td>{sum_array.heinous_nature_not_known_6_b}</td>
                                <td>{sum_array.heinous_nature_apl_6_b}</td>
                                <td>{sum_array.heinous_nature_bpl_6_b}</td>
                                <td>{sum_array.heinous_nature_illiterate_6_b}</td>
                                <td>{sum_array.heinous_nature_primary_6_b}</td>
                                <td>{sum_array.heinous_nature_secondary_6_b}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={18} className="indicator">Indicator 7: Number of cases registered under POCSO that are - a)
                                    continuing under JJB, b) referred to Special Court</th>
                            </tr>
                            <tr>
                                <th colSpan={18}>7.a) Number of cases registered under POCSO that are continuing
                                    under JJB during1 Jan 2022 to 31st March 2022 - CICL</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.registered_under_pocso_total_cases_7_a}</td>
                                    <td>{r?.registered_under_pocso_7_10_7_a}</td>
                                    <td>{r?.registered_under_pocso_11_13_7_a}</td>
                                    <td>{r?.registered_under_pocso_14_15_7_a}</td>
                                    <td>{r?.registered_under_pocso_16_18_7_a}</td>
                                    <td>{r?.registered_under_pocso_male_7_a}</td>
                                    <td>{r?.registered_under_pocso_female_7_a}</td>
                                    <td>{r?.registered_under_pocso_general_7_a}</td>
                                    <td>{r?.registered_under_pocso_sc_7_a}</td>
                                    <td>{r?.registered_under_pocso_st_7_a}</td>
                                    <td>{r?.registered_under_pocso_obc_7_a}</td>
                                    <td>{r?.registered_under_pocso_not_known_7_a}</td>
                                    <td>{r?.registered_under_pocso_apl_7_a}</td>
                                    <td>{r?.registered_under_pocso_bpl_7_a}</td>
                                    <td>{r?.registered_under_pocso_illiterate_7_a}</td>
                                    <td>{r?.registered_under_pocso_primary_7_a}</td>
                                    <td>{r?.registered_under_pocso_secondary_7_a}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.registered_under_pocso_total_cases_7_a}</td>
                                <td>{sum_array.registered_under_pocso_7_10_7_a}</td>
                                <td>{sum_array.registered_under_pocso_11_13_7_a}</td>
                                <td>{sum_array.registered_under_pocso_14_15_7_a}</td>
                                <td>{sum_array.registered_under_pocso_16_18_7_a}</td>
                                <td>{sum_array.registered_under_pocso_male_7_a}</td>
                                <td>{sum_array.registered_under_pocso_female_7_a}</td>
                                <td>{sum_array.registered_under_pocso_general_7_a}</td>
                                <td>{sum_array.registered_under_pocso_sc_7_a}</td>
                                <td>{sum_array.registered_under_pocso_st_7_a}</td>
                                <td>{sum_array.registered_under_pocso_obc_7_a}</td>
                                <td>{sum_array.registered_under_pocso_not_known_7_a}</td>
                                <td>{sum_array.registered_under_pocso_apl_7_a}</td>
                                <td>{sum_array.registered_under_pocso_bpl_7_a}</td>
                                <td>{sum_array.registered_under_pocso_illiterate_7_a}</td>
                                <td>{sum_array.registered_under_pocso_primary_7_a}</td>
                                <td>{sum_array.registered_under_pocso_secondary_7_a}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={18}>7.b) Number of cases registered under POCSO that are referred to
                                    Special Court during 1 Jan 2022 to 31st March 2022 - CICL</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>

                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.special_court_total_cases_7_b}</td>
                                    <td>{r?.special_court_7_10_7_b}</td>
                                    <td>{r?.special_court_11_13_7_b}</td>
                                    <td>{r?.special_court_14_15_7_b}</td>
                                    <td>{r?.special_court_16_18_7_b}</td>
                                    <td>{r?.special_court_male_7_b}</td>
                                    <td>{r?.special_court_female_7_b}</td>
                                    <td>{r?.special_court_general_7_b}</td>
                                    <td>{r?.special_court_sc_7_b}</td>
                                    <td>{r?.special_court_st_7_b}</td>
                                    <td>{r?.special_court_obc_7_b}</td>
                                    <td>{r?.special_court_not_known_7_b}</td>
                                    <td>{r?.special_court_apl_7_b}</td>
                                    <td>{r?.special_court_bpl_7_b}</td>
                                    <td>{r?.special_court_illiterate_7_b}</td>
                                    <td>{r?.special_court_primary_7_b}</td>
                                    <td>{r?.special_court_secondary_7_b}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.special_court_total_cases_7_b}</td>
                                <td>{sum_array.special_court_7_10_7_b}</td>
                                <td>{sum_array.special_court_11_13_7_b}</td>
                                <td>{sum_array.special_court_14_15_7_b}</td>
                                <td>{sum_array.special_court_16_18_7_b}</td>
                                <td>{sum_array.special_court_male_7_b}</td>
                                <td>{sum_array.special_court_female_7_b}</td>
                                <td>{sum_array.special_court_general_7_b}</td>
                                <td>{sum_array.special_court_sc_7_b}</td>
                                <td>{sum_array.special_court_st_7_b}</td>
                                <td>{sum_array.special_court_obc_7_b}</td>
                                <td>{sum_array.special_court_not_known_7_b}</td>
                                <td>{sum_array.special_court_apl_7_b}</td>
                                <td>{sum_array.special_court_bpl_7_b}</td>
                                <td>{sum_array.special_court_illiterate_7_b}</td>
                                <td>{sum_array.special_court_primary_7_b}</td>
                                <td>{sum_array.special_court_secondary_7_b}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={17} className="indicator">Indicator 8: Number of POCSO victims who have been given victim
                                    compensation (interim/final)</th>
                            </tr>
                            <tr>
                                <th colSpan={17}>Interim Compensation given during 1 Jan 2022 to 31st March 2022 -
                                    CNCP</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>

                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.pocso_victims_total_cases_8}</td>
                                    <td>{r?.pocso_victims_0_5_8}</td>
                                    <td>{r?.pocso_victims_6_10_8}</td>
                                    <td>{r?.pocso_victims_11_18_8}</td>
                                    <td>{r?.pocso_victims_male_8}</td>
                                    <td>{r?.pocso_victims_female_8}</td>
                                    <td>{r?.pocso_victims_general_8}</td>
                                    <td>{r?.pocso_victims_sc_8}</td>
                                    <td>{r?.pocso_victims_st_8}</td>
                                    <td>{r?.pocso_victims_obc_8}</td>
                                    <td>{r?.pocso_victims_not_known_8}</td>
                                    <td>{r?.pocso_victims_apl_8}</td>
                                    <td>{r?.pocso_victims_bpl_8}</td>
                                    <td>{r?.pocso_victims_illiterate_8}</td>
                                    <td>{r?.pocso_victims_primary_8}</td>
                                    <td>{r?.pocso_victims_secondary_8}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.pocso_victims_total_cases_8}</td>
                                <td>{sum_array.pocso_victims_0_5_8}</td>
                                <td>{sum_array.pocso_victims_6_10_8}</td>
                                <td>{sum_array.pocso_victims_11_18_8}</td>
                                <td>{sum_array.pocso_victims_male_8}</td>
                                <td>{sum_array.pocso_victims_female_8}</td>
                                <td>{sum_array.pocso_victims_general_8}</td>
                                <td>{sum_array.pocso_victims_sc_8}</td>
                                <td>{sum_array.pocso_victims_st_8}</td>
                                <td>{sum_array.pocso_victims_obc_8}</td>
                                <td>{sum_array.pocso_victims_not_known_8}</td>
                                <td>{sum_array.pocso_victims_apl_8}</td>
                                <td>{sum_array.pocso_victims_bpl_8}</td>
                                <td>{sum_array.pocso_victims_illiterate_8}</td>
                                <td>{sum_array.pocso_victims_primary_8}</td>
                                <td>{sum_array.pocso_victims_secondary_8}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={17}>Final Compensation given during 1 Jan 2022 to 31st March 2022 - CNCP</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.compensation_total_cases_8}</td>
                                    <td>{r?.compensation_0_5_8}</td>
                                    <td>{r?.compensation_6_10_8}</td>
                                    <td>{r?.compensation_11_18_8}</td>
                                    <td>{r?.compensation_male_8}</td>
                                    <td>{r?.compensation_female_8}</td>
                                    <td>{r?.compensation_general_8}</td>
                                    <td>{r?.compensation_sc_8}</td>
                                    <td>{r?.compensation_st_8}</td>
                                    <td>{r?.compensation_obc_8}</td>
                                    <td>{r?.compensation_not_known_8}</td>
                                    <td>{r?.compensation_apl_8}</td>
                                    <td>{r?.compensation_bpl_8}</td>
                                    <td>{r?.compensation_illiterate_8}</td>
                                    <td>{r?.compensation_primary_8}</td>
                                    <td>{r?.compensation_secondary_8}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.compensation_total_cases_8}</td>
                                <td>{sum_array.compensation_0_5_8}</td>
                                <td>{sum_array.compensation_6_10_8}</td>
                                <td>{sum_array.compensation_11_18_8}</td>
                                <td>{sum_array.compensation_male_8}</td>
                                <td>{sum_array.compensation_female_8}</td>
                                <td>{sum_array.compensation_general_8}</td>
                                <td>{sum_array.compensation_sc_8}</td>
                                <td>{sum_array.compensation_st_8}</td>
                                <td>{sum_array.compensation_obc_8}</td>
                                <td>{sum_array.compensation_not_known_8}</td>
                                <td>{sum_array.compensation_apl_8}</td>
                                <td>{sum_array.compensation_bpl_8}</td>
                                <td>{sum_array.compensation_illiterate_8}</td>
                                <td>{sum_array.compensation_primary_8}</td>
                                <td>{sum_array.compensation_secondary_8}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={18} className="indicator">Indicator 9: Number of cases a) referred by SJPU to JJB in last one
                                    year b) disposed JJB in last one year</th>
                            </tr>
                            <tr>
                                <th colSpan={18}>9.a) Number of cases referred by SJPU to JJB (CICL) during 1 Jan
                                    2022 to 31st March 2022 - CICL</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.sjpu_to_jjb_total_cases_9_a}</td>
                                    <td>{r?.sjpu_to_jjb_7_10_9_a}</td>
                                    <td>{r?.sjpu_to_jjb_11_13_9_a}</td>
                                    <td>{r?.sjpu_to_jjb_14_15_9_a}</td>
                                    <td>{r?.sjpu_to_jjb_16_18_9_a}</td>
                                    <td>{r?.sjpu_to_jjb_male_9_a}</td>
                                    <td>{r?.sjpu_to_jjb_female_9_a}</td>
                                    <td>{r?.sjpu_to_jjb_general_9_a}</td>
                                    <td>{r?.sjpu_to_jjb_sc_9_a}</td>
                                    <td>{r?.sjpu_to_jjb_st_9_a}</td>
                                    <td>{r?.sjpu_to_jjb_obc_9_a}</td>
                                    <td>{r?.sjpu_to_jjb_not_known_9_a}</td>
                                    <td>{r?.sjpu_to_jjb_apl_9_a}</td>
                                    <td>{r?.sjpu_to_jjb_bpl_9_a}</td>
                                    <td>{r?.sjpu_to_jjb_illiterate_9_a}</td>
                                    <td>{r?.sjpu_to_jjb_primary_9_a}</td>
                                    <td>{r?.sjpu_to_jjb_secondary_9_a}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.sjpu_to_jjb_total_cases_9_a}</td>
                                <td>{sum_array.sjpu_to_jjb_7_10_9_a}</td>
                                <td>{sum_array.sjpu_to_jjb_11_13_9_a}</td>
                                <td>{sum_array.sjpu_to_jjb_14_15_9_a}</td>
                                <td>{sum_array.sjpu_to_jjb_16_18_9_a}</td>
                                <td>{sum_array.sjpu_to_jjb_male_9_a}</td>
                                <td>{sum_array.sjpu_to_jjb_female_9_a}</td>
                                <td>{sum_array.sjpu_to_jjb_general_9_a}</td>
                                <td>{sum_array.sjpu_to_jjb_sc_9_a}</td>
                                <td>{sum_array.sjpu_to_jjb_st_9_a}</td>
                                <td>{sum_array.sjpu_to_jjb_obc_9_a}</td>
                                <td>{sum_array.sjpu_to_jjb_not_known_9_a}</td>
                                <td>{sum_array.sjpu_to_jjb_apl_9_a}</td>
                                <td>{sum_array.sjpu_to_jjb_bpl_9_a}</td>
                                <td>{sum_array.sjpu_to_jjb_illiterate_9_a}</td>
                                <td>{sum_array.sjpu_to_jjb_primary_9_a}</td>
                                <td>{sum_array.sjpu_to_jjb_secondary_9_a}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={18}>9.b) Number of cases disposed - SJPU to JJB (CICL) during 1 Jan 2022
                                    to 31st March 2022 CICL</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>

                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.sjpu_disposed_total_cases_9_b}</td>
                                    <td>{r?.sjpu_disposed_7_10_9_b}</td>
                                    <td>{r?.sjpu_disposed_11_13_9_b}</td>
                                    <td>{r?.sjpu_disposed_14_15_9_b}</td>
                                    <td>{r?.sjpu_disposed_16_18_9_b}</td>
                                    <td>{r?.sjpu_disposed_male_9_b}</td>
                                    <td>{r?.sjpu_disposed_female_9_b}</td>
                                    <td>{r?.sjpu_disposed_general_9_b}</td>
                                    <td>{r?.sjpu_disposed_sc_9_b}</td>
                                    <td>{r?.sjpu_disposed_st_9_b}</td>
                                    <td>{r?.sjpu_disposed_obc_9_b}</td>
                                    <td>{r?.sjpu_disposed_not_known_9_b}</td>
                                    <td>{r?.sjpu_disposed_apl_9_b}</td>
                                    <td>{r?.sjpu_disposed_bpl_9_b}</td>
                                    <td>{r?.sjpu_disposed_illiterate_9_b}</td>
                                    <td>{r?.sjpu_disposed_primary_9_b}</td>
                                    <td>{r?.sjpu_disposed_secondary_9_b}</td>
                                </tr>
                            ))}


                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.sjpu_disposed_total_cases_9_b}</td>
                                <td>{sum_array.sjpu_disposed_7_10_9_b}</td>
                                <td>{sum_array.sjpu_disposed_11_13_9_b}</td>
                                <td>{sum_array.sjpu_disposed_14_15_9_b}</td>
                                <td>{sum_array.sjpu_disposed_16_18_9_b}</td>
                                <td>{sum_array.sjpu_disposed_male_9_b}</td>
                                <td>{sum_array.sjpu_disposed_female_9_b}</td>
                                <td>{sum_array.sjpu_disposed_general_9_b}</td>
                                <td>{sum_array.sjpu_disposed_sc_9_b}</td>
                                <td>{sum_array.sjpu_disposed_st_9_b}</td>
                                <td>{sum_array.sjpu_disposed_obc_9_b}</td>
                                <td>{sum_array.sjpu_disposed_not_known_9_b}</td>
                                <td>{sum_array.sjpu_disposed_apl_9_b}</td>
                                <td>{sum_array.sjpu_disposed_bpl_9_b}</td>
                                <td>{sum_array.sjpu_disposed_illiterate_9_b}</td>
                                <td>{sum_array.sjpu_disposed_primary_9_b}</td>
                                <td>{sum_array.sjpu_disposed_secondary_9_b}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={18} className="indicator">Indicator 11: Number of cases of a) juvenility plea administered b)
                                    referred to JJB</th>
                            </tr>
                            <tr>
                                <th colSpan={18}>11.a) Number of cases of juvenility plea administered during 1 Jan
                                    2022 to 31st March 2022 - CICL</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.juvenility_plea_total_cases_11_a}</td>
                                    <td>{r?.juvenility_plea_7_10_11_a}</td>
                                    <td>{r?.juvenility_plea_11_13_11_a}</td>
                                    <td>{r?.juvenility_plea_14_15_11_a}</td>
                                    <td>{r?.juvenility_plea_16_18_11_a}</td>
                                    <td>{r?.juvenility_plea_male_11_a}</td>
                                    <td>{r?.juvenility_plea_female_11_a}</td>
                                    <td>{r?.juvenility_plea_general_11_a}</td>
                                    <td>{r?.juvenility_plea_sc_11_a}</td>
                                    <td>{r?.juvenility_plea_st_11_a}</td>
                                    <td>{r?.juvenility_plea_obc_11_a}</td>
                                    <td>{r?.juvenility_plea_not_known_11_a}</td>
                                    <td>{r?.juvenility_plea_apl_11_a}</td>
                                    <td>{r?.juvenility_plea_bpl_11_a}</td>
                                    <td>{r?.juvenility_plea_illiterate_11_a}</td>
                                    <td>{r?.juvenility_plea_primary_11_a}</td>
                                    <td>{r?.juvenility_plea_secondary_11_a}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.juvenility_plea_total_cases_11_a}</td>
                                <td>{sum_array.juvenility_plea_7_10_11_a}</td>
                                <td>{sum_array.juvenility_plea_11_13_11_a}</td>
                                <td>{sum_array.juvenility_plea_14_15_11_a}</td>
                                <td>{sum_array.juvenility_plea_16_18_11_a}</td>
                                <td>{sum_array.juvenility_plea_male_11_a}</td>
                                <td>{sum_array.juvenility_plea_female_11_a}</td>
                                <td>{sum_array.juvenility_plea_general_11_a}</td>
                                <td>{sum_array.juvenility_plea_sc_11_a}</td>
                                <td>{sum_array.juvenility_plea_st_11_a}</td>
                                <td>{sum_array.juvenility_plea_obc_11_a}</td>
                                <td>{sum_array.juvenility_plea_not_known_11_a}</td>
                                <td>{sum_array.juvenility_plea_apl_11_a}</td>
                                <td>{sum_array.juvenility_plea_bpl_11_a}</td>
                                <td>{sum_array.juvenility_plea_illiterate_11_a}</td>
                                <td>{sum_array.juvenility_plea_primary_11_a}</td>
                                <td>{sum_array.juvenility_plea_secondary_11_a}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={18}>11.b) Number of cases referred to JJB during 1 Jan 2022 to 31st
                                    March 2022 - CICL</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>Discrict</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>7-10</th>
                                <th>11-13</th>
                                <th>14-15</th>
                                <th>16-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.cases_referred_total_cases_11_b}</td>
                                    <td>{r?.cases_referred_7_10_11_b}</td>
                                    <td>{r?.cases_referred_11_13_11_b}</td>
                                    <td>{r?.cases_referred_14_15_11_b}</td>
                                    <td>{r?.cases_referred_16_18_11_b}</td>
                                    <td>{r?.cases_referred_male_11_b}</td>
                                    <td>{r?.cases_referred_female_11_b}</td>
                                    <td>{r?.cases_referred_general_11_b}</td>
                                    <td>{r?.cases_referred_sc_11_b}</td>
                                    <td>{r?.cases_referred_st_11_b}</td>
                                    <td>{r?.cases_referred_obc_11_b}</td>
                                    <td>{r?.cases_referred_not_known_11_b}</td>
                                    <td>{r?.cases_referred_apl_11_b}</td>
                                    <td>{r?.cases_referred_bpl_11_b}</td>
                                    <td>{r?.cases_referred_illiterate_11_b}</td>
                                    <td>{r?.cases_referred_primary_11_b}</td>
                                    <td>{r?.cases_referred_secondary_11_b}</td>
                                </tr>
                            ))}


                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.cases_referred_total_cases_11_b}</td>
                                <td>{sum_array.cases_referred_7_10_11_b}</td>
                                <td>{sum_array.cases_referred_11_13_11_b}</td>
                                <td>{sum_array.cases_referred_14_15_11_b}</td>
                                <td>{sum_array.cases_referred_16_18_11_b}</td>
                                <td>{sum_array.cases_referred_male_11_b}</td>
                                <td>{sum_array.cases_referred_female_11_b}</td>
                                <td>{sum_array.cases_referred_general_11_b}</td>
                                <td>{sum_array.cases_referred_sc_11_b}</td>
                                <td>{sum_array.cases_referred_st_11_b}</td>
                                <td>{sum_array.cases_referred_obc_11_b}</td>
                                <td>{sum_array.cases_referred_not_known_11_b}</td>
                                <td>{sum_array.cases_referred_apl_11_b}</td>
                                <td>{sum_array.cases_referred_bpl_11_b}</td>
                                <td>{sum_array.cases_referred_illiterate_11_b}</td>
                                <td>{sum_array.cases_referred_primary_11_b}</td>
                                <td>{sum_array.cases_referred_secondary_11_b}</td>
                            </tr>

                            {/* </table> */}
                            <tr><th colSpan={4} className="indicator">Indicator 12 </th></tr>
                            <tr>
                                <th colSpan={4}>Juvenile Justice Boards (status as on 31st March 2022)</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th colSpan={3} className="bgclr">
                                    Number of vacancies in key statutory and allied bodies{" "}
                                </th>
                            </tr>
                            <tr>
                                <th>Sanctioned Positions</th>
                                <th>Filled Positions</th>
                                <th>Vacant Positions</th>
                            </tr>

                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>
                                        {
                                            r?.jjb_vac_in_key_statutory_and_al_bo_sanctioned_positions_12
                                        }
                                    </td>
                                    <td>
                                        {r?.jjb_vac_in_key_statutory_and_al_bo_filled_positions_12}
                                    </td>
                                    <td>
                                        {r?.jjb_vac_in_key_statutory_and_al_bo_vacant_positions_12}
                                    </td>
                                </tr>
                            ))}


                            <tr>
                                <th scope="row">Total</th>
                                <td>
                                    {
                                        sum_array.jjb_vac_in_key_statutory_and_al_bo_sanctioned_positions_12
                                    }
                                </td>
                                <td>
                                    {sum_array.jjb_vac_in_key_statutory_and_al_bo_filled_positions_12}
                                </td>
                                <td>
                                    {sum_array.jjb_vac_in_key_statutory_and_al_bo_vacant_positions_12}
                                </td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={4}>Special Juvenile Police Units (status as on 31st March 2022)</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th colSpan={3} className="bgclr">
                                    Number of vacancies in key statutory and allied bodies{" "}
                                </th>
                            </tr>
                            <tr>
                                <th>Sanctioned Positions</th>
                                <th>Filled Positions</th>
                                <th>Vacant Positions</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>
                                        {
                                            r?.sjpu_vac_in_key_statutory_and_al_bo_sanctioned_positions_12
                                        }
                                    </td>
                                    <td>
                                        {r?.sjpu_vac_in_key_statutory_and_al_bo_filled_positions_12}
                                    </td>
                                    <td>
                                        {r?.sjpu_vac_in_key_statutory_and_al_bo_vacant_positions_12}
                                    </td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>
                                    {
                                        sum_array.sjpu_vac_in_key_statutory_and_al_bo_sanctioned_positions_12
                                    }
                                </td>
                                <td>
                                    {sum_array.sjpu_vac_in_key_statutory_and_al_bo_filled_positions_12}
                                </td>
                                <td>
                                    {sum_array.sjpu_vac_in_key_statutory_and_al_bo_vacant_positions_12}
                                </td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={4}>JJC Secretariat (status as on 31st March 2022)</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th colSpan={3} className="bgclr">
                                    Number of vacancies in key statutory and allied bodies{" "}
                                </th>
                            </tr>
                            <tr>
                                <th>Sanctioned Positions</th>
                                <th>Filled Positions</th>
                                <th>Vacant Positions</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>
                                        {
                                            r?.jjcs_vac_in_key_statutory_and_al_bo_sanctioned_positions_12
                                        }
                                    </td>
                                    <td>
                                        {r?.jjcs_vac_in_key_statutory_and_al_bo_filled_positions_12}
                                    </td>
                                    <td>
                                        {r?.jjcs_vac_in_key_statutory_and_al_bo_vacant_positions_12}
                                    </td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>
                                    {
                                        sum_array.jjcs_vac_in_key_statutory_and_al_bo_sanctioned_positions_12
                                    }
                                </td>
                                <td>
                                    {sum_array.jjcs_vac_in_key_statutory_and_al_bo_filled_positions_12}
                                </td>
                                <td>
                                    {sum_array.jjcs_vac_in_key_statutory_and_al_bo_vacant_positions_12}
                                </td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={4}>Support persons under POCSO (status as on 31st March 2022)</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th colSpan={3} className="bgclr">
                                    Number of vacancies in key statutory and allied bodies{" "}
                                </th>
                            </tr>
                            <tr>
                                <th>Sanctioned Positions</th>
                                <th>Filled Positions</th>
                                <th>Vacant Positions</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>
                                        {
                                            r?.spu_pocso_vac_in_key_statutory_and_al_bo_vacant_positions_12
                                        }
                                    </td>
                                    <td>
                                        {
                                            r?.spu_pocso_vac_in_key_statutory_and_al_bo_vacant_positions_12
                                        }
                                    </td>
                                    <td>
                                        {
                                            r?.spu_pocso_vac_in_key_statutory_and_al_bo_vacant_positions_12
                                        }
                                    </td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>
                                    {
                                        sum_array.spu_pocso_vac_in_key_statutory_and_al_bo_vacant_positions_12
                                    }
                                </td>
                                <td>
                                    {
                                        sum_array.spu_pocso_vac_in_key_statutory_and_al_bo_vacant_positions_12
                                    }
                                </td>
                                <td>
                                    {
                                        sum_array.spu_pocso_vac_in_key_statutory_and_al_bo_vacant_positions_12
                                    }
                                </td>
                            </tr>

                            {/* </table> */}

                            <tr>
                                <th colSpan={2} className="indicator">Indicator 13: Number and name of districts having Child Friendly
                                    Courts (status as on 31st March 2022)</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th>District</th>
                                <th className="bgclr">No. of Child Friendly Courts</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.no_of_child_friendly_courts_13}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.no_of_child_friendly_courts_13}</td>
                            </tr>



                            {/* </table> */}
                            {/* police */}
                            <br></br>
                            <tr><th colSpan={18} className="dcpupolice">DCPU-CWC_POLICE</th></tr>

                            <tr>
                                <th colSpan={18} className="indicator">Indicator 1: Number of cases registered during 1 Jan 2022 to 31st
                                    March 2022, district wise</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th></th>
                                <th colSpan={17} className="bgclr">
                                    Child Welfare Committee (CWC)
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>
                                    Total Number of Children in Need of Care and Protection (CNCP)
                                </th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-14</th>
                                <th>15-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.cases_registered_cncp_1}</td>
                                    <td>{r?.cases_registered_0_5_1}</td>
                                    <td>{r?.cases_registered_6_10_1}</td>
                                    <td>{r?.cases_registered_11_14_1}</td>
                                    <td>{r?.cases_registered_15_18_1}</td>
                                    <td>{r?.cases_registered_male_1}</td>
                                    <td>{r?.cases_registered_female_1}</td>
                                    <td>{r?.cases_registered_general_1}</td>
                                    <td>{r?.cases_registered_sc_1}</td>
                                    <td>{r?.cases_registered_st_1}</td>
                                    <td>{r?.cases_registered_obc_1}</td>
                                    <td>{r?.cases_registered_not_known_1}</td>
                                    <td>{r?.cases_registered_apl_1}</td>
                                    <td>{r?.cases_registered_bpl_1}</td>
                                    <td>{r?.cases_registered_illiterate_1}</td>
                                    <td>{r?.cases_registered_primary_1}</td>
                                    <td>{r?.cases_registered_secondary_1}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.cases_registered_cncp_1}</td>
                                <td>{sum_array.cases_registered_0_5_1}</td>
                                <td>{sum_array.cases_registered_6_10_1}</td>
                                <td>{sum_array.cases_registered_11_14_1}</td>
                                <td>{sum_array.cases_registered_15_18_1}</td>
                                <td>{sum_array.cases_registered_male_1}</td>
                                <td>{sum_array.cases_registered_female_1}</td>
                                <td>{sum_array.cases_registered_general_1}</td>
                                <td>{sum_array.cases_registered_sc_1}</td>
                                <td>{sum_array.cases_registered_st_1}</td>
                                <td>{sum_array.cases_registered_obc_1}</td>
                                <td>{sum_array.cases_registered_not_known_1}</td>
                                <td>{sum_array.cases_registered_apl_1}</td>
                                <td>{sum_array.cases_registered_bpl_1}</td>
                                <td>{sum_array.cases_registered_illiterate_1}</td>
                                <td>{sum_array.cases_registered_primary_1}</td>
                                <td>{sum_array.cases_registered_secondary_1}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={18} className="indicator">Indicator 2: Number of cases disposed during 1 Jan 2022 to 31st
                                    March 2022, district wise</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th></th>
                                <th colSpan={17} className="bgclr">
                                    Child Welfare Committee (CWC)
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>
                                    Total Number of Children in Need of Care and Protection (CNCP)
                                </th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-14</th>
                                <th>15-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.cases_disposed_cncp_2}</td>
                                    <td>{r?.cases_disposed_0_5_2}</td>
                                    <td>{r?.cases_disposed_6_10_2}</td>
                                    <td>{r?.cases_disposed_11_14_2}</td>
                                    <td>{r?.cases_disposed_15_18_2}</td>
                                    <td>{r?.cases_disposed_male_2}</td>
                                    <td>{r?.cases_disposed_female_2}</td>
                                    <td>{r?.cases_disposed_general_2}</td>
                                    <td>{r?.cases_disposed_sc_2}</td>
                                    <td>{r?.cases_disposed_st_2}</td>
                                    <td>{r?.cases_disposed_obc_2}</td>
                                    <td>{r?.cases_disposed_not_known_2}</td>
                                    <td>{r?.cases_disposed_apl_2}</td>
                                    <td>{r?.cases_disposed_bpl_2}</td>
                                    <td>{r?.cases_disposed_illiterate_2}</td>
                                    <td>{r?.cases_disposed_primary_2}</td>
                                    <td>{r?.cases_disposed_secondary_2}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.cases_disposed_cncp_2}</td>
                                <td>{sum_array.cases_disposed_0_5_2}</td>
                                <td>{sum_array.cases_disposed_6_10_2}</td>
                                <td>{sum_array.cases_disposed_11_14_2}</td>
                                <td>{sum_array.cases_disposed_15_18_2}</td>
                                <td>{sum_array.cases_disposed_male_2}</td>
                                <td>{sum_array.cases_disposed_female_2}</td>
                                <td>{sum_array.cases_disposed_general_2}</td>
                                <td>{sum_array.cases_disposed_sc_2}</td>
                                <td>{sum_array.cases_disposed_st_2}</td>
                                <td>{sum_array.cases_disposed_obc_2}</td>
                                <td>{sum_array.cases_disposed_not_known_2}</td>
                                <td>{sum_array.cases_disposed_apl_2}</td>
                                <td>{sum_array.cases_disposed_bpl_2}</td>
                                <td>{sum_array.cases_disposed_illiterate_2}</td>
                                <td>{sum_array.cases_disposed_primary_2}</td>
                                <td>{sum_array.cases_disposed_secondary_2}</td>
                            </tr>

                            {/* </table> */}

                            <tr>
                                <th colSpan={17} className="indicator">Indicator 3: Number of cases pending in the state and district
                                    before CWCs as on 31st March 2022</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th></th>
                                <th colSpan={16} className="bgclr">
                                    Child Welfare Committee (CWC)
                                </th>
                            </tr>
                            <tr>
                                <th></th>
                                <th colSpan={4}>Age 0-5</th>
                                <th colSpan={4}>Age 6-10</th>
                                <th colSpan={4}> Age 11-14</th>
                                <th colSpan={4}> Age 15-18</th>
                            </tr>
                            <tr>
                                <th>District</th>
                                <th>Pending for &lt;6 months</th>
                                <th>Pending for 7 monhts-1 year</th>
                                <th>Pending for 1-2 years</th>
                                <th>Pending for 2+ years</th>
                                <th>Pending for &lt;6 months</th>
                                <th>Pending for 7 monhts-1 year</th>
                                <th>Pending for 1-2 years</th>
                                <th>Pending for 2+ years</th>
                                <th>Pending for &lt;6 months</th>
                                <th>Pending for 7 monhts-1 year</th>
                                <th>Pending for 1-2 years</th>
                                <th>Pending for 2+ years</th>
                                <th>Pending for &lt;6 months</th>
                                <th>Pending for 7 monhts-1 year</th>
                                <th>Pending for 1-2 years</th>
                                <th>Pending for 2+ years</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.cases_pending_0_5_for_less_than_6_months_3}</td>
                                    <td>{r?.cases_pending_0_5_for_7_months_1_year_3}</td>
                                    <td>{r?.cases_pending_0_5_for_1_2_years_3}</td>

                                    <td>{r?.cases_pending_0_5_for_2_years_3}</td>
                                    <td>{r?.cases_pending_6_10_for_less_than_6_months_3}</td>
                                    <td>{r?.cases_pending_6_10_for_7_months_1_year_3}</td>
                                    <td>{r?.cases_pending_6_10_for_1_2_years_3}</td>
                                    <td>{r?.cases_pending_6_10_for_2_years_3}</td>
                                    <td>{r?.cases_pending_11_14_for_less_than_6_months_3}</td>
                                    <td>{r?.cases_pending_11_14_for_7_months_1_year_3}</td>
                                    <td>{r?.cases_pending_11_14_for_1_2_years_3}</td>
                                    <td>{r?.cases_pending_11_14_for_2_years_3}</td>
                                    <td>{r?.cases_pending_15_18_for_less_than_6_months_3}</td>
                                    <td>{r?.cases_pending_15_18_for_7_months_1_year_3}</td>
                                    <td>{r?.cases_pending_15_18_for_1_2_years_3}</td>
                                    <td>{r?.cases_pending_15_18_for_2_years_3}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.cases_pending_0_5_for_less_than_6_months_3}</td>
                                <td>{sum_array.cases_pending_0_5_for_7_months_1_year_3}</td>
                                <td>{sum_array.cases_pending_0_5_for_1_2_years_3}</td>

                                <td>{sum_array.cases_pending_0_5_for_2_years_3}</td>
                                <td>{sum_array.cases_pending_6_10_for_less_than_6_months_3}</td>
                                <td>{sum_array.cases_pending_6_10_for_7_months_1_year_3}</td>
                                <td>{sum_array.cases_pending_6_10_for_1_2_years_3}</td>
                                <td>{sum_array.cases_pending_6_10_for_2_years_3}</td>
                                <td>{sum_array.cases_pending_11_14_for_less_than_6_months_3}</td>
                                <td>{sum_array.cases_pending_11_14_for_7_months_1_year_3}</td>
                                <td>{sum_array.cases_pending_11_14_for_1_2_years_3}</td>
                                <td>{sum_array.cases_pending_11_14_for_2_years_3}</td>
                                <td>{sum_array.cases_pending_15_18_for_less_than_6_months_3}</td>
                                <td>{sum_array.cases_pending_15_18_for_7_months_1_year_3}</td>
                                <td>{sum_array.cases_pending_15_18_for_1_2_years_3}</td>
                                <td>{sum_array.cases_pending_15_18_for_2_years_3}</td>
                            </tr>

                            {/* </table> */}

                            <tr>
                                <th colSpan={18} className="indicator">Indicator 9: Number of cases a) referred by SJPU to CWC in last one
                                    year b) disposed - CWC in last one year</th>
                            </tr>
                            {/* <table className="qprhighcourt" id="qprhc"> */}
                            <tr>
                                <th></th>
                                <th colSpan={17} className="bgclr">
                                    9.a) Number of cases referred by SJPU to CWC (CNCP) during 1 Jan
                                    2022 to 31st March 2022
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>
                                    Total Number of Children in Need of Care and Protection (CNCP)
                                </th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-14</th>
                                <th>15-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.referred_by_sjpu_cncp_9_a}</td>
                                    <td>{r?.referred_by_sjpu_0_5_9_a}</td>
                                    <td>{r?.referred_by_sjpu_6_10_9_a}</td>
                                    <td>{r?.referred_by_sjpu_11_14_9_a}</td>
                                    <td>{r?.referred_by_sjpu_15_18_9_a}</td>
                                    <td>{r?.referred_by_sjpu_male_9_a}</td>
                                    <td>{r?.referred_by_sjpu_female_9_a}</td>
                                    <td>{r?.referred_by_sjpu_general_9_a}</td>
                                    <td>{r?.referred_by_sjpu_sc_9_a}</td>
                                    <td>{r?.referred_by_sjpu_st_9_a}</td>
                                    <td>{r?.referred_by_sjpu_obc_9_a}</td>
                                    <td>{r?.referred_by_sjpu_not_known_9_a}</td>
                                    <td>{r?.referred_by_sjpu_apl_9_a}</td>
                                    <td>{r?.referred_by_sjpu_bpl_9_a}</td>
                                    <td>{r?.referred_by_sjpu_illiterate_9_a}</td>
                                    <td>{r?.referred_by_sjpu_primary_9_a}</td>
                                    <td>{r?.referred_by_sjpu_secondary_9_a}</td>
                                </tr>
                            ))}


                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.referred_by_sjpu_cncp_9_a}</td>
                                <td>{sum_array.referred_by_sjpu_0_5_9_a}</td>
                                <td>{sum_array.referred_by_sjpu_6_10_9_a}</td>
                                <td>{sum_array.referred_by_sjpu_11_14_9_a}</td>
                                <td>{sum_array.referred_by_sjpu_15_18_9_a}</td>
                                <td>{sum_array.referred_by_sjpu_male_9_a}</td>
                                <td>{sum_array.referred_by_sjpu_female_9_a}</td>
                                <td>{sum_array.referred_by_sjpu_general_9_a}</td>
                                <td>{sum_array.referred_by_sjpu_sc_9_a}</td>
                                <td>{sum_array.referred_by_sjpu_st_9_a}</td>
                                <td>{sum_array.referred_by_sjpu_obc_9_a}</td>
                                <td>{sum_array.referred_by_sjpu_not_known_9_a}</td>
                                <td>{sum_array.referred_by_sjpu_apl_9_a}</td>
                                <td>{sum_array.referred_by_sjpu_bpl_9_a}</td>
                                <td>{sum_array.referred_by_sjpu_illiterate_9_a}</td>
                                <td>{sum_array.referred_by_sjpu_primary_9_a}</td>
                                <td>{sum_array.referred_by_sjpu_secondary_9_a}</td>
                            </tr>

                            {/* </table> */}
                            {/* <table className="qprhighcourt" id="qpr"> */}
                            <tr>
                                <th></th>
                                <th colSpan={17} className="bgclr">
                                    9.b) Number of cases disposed - SJPU to CWC (CNCP) during 1 Jan
                                    2022 to 31st March 2022{" "}
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>
                                    Total Number of Children in Need of Care and Protection (CNCP)
                                </th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-14</th>
                                <th>15-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.cases_disposed_cncp_9_b}</td>
                                    <td>{r?.cases_disposed_0_5_9_b}</td>
                                    <td>{r?.cases_disposed_6_10_9_b}</td>
                                    <td>{r?.cases_disposed_11_14_9_b}</td>
                                    <td>{r?.cases_disposed_15_18_9_b}</td>
                                    <td>{r?.cases_disposed_male_9_b}</td>
                                    <td>{r?.cases_disposed_female_9_b}</td>
                                    <td>{r?.cases_disposed_general_9_b}</td>
                                    <td>{r?.cases_disposed_sc_9_b}</td>
                                    <td>{r?.cases_disposed_st_9_b}</td>
                                    <td>{r?.cases_disposed_obc_9_b}</td>
                                    <td>{r?.cases_disposed_not_known_9_b}</td>
                                    <td>{r?.cases_disposed_apl_9_b}</td>
                                    <td>{r?.cases_disposed_bpl_9_b}</td>
                                    <td>{r?.cases_disposed_illiterate_9_b}</td>
                                    <td>{r?.cases_disposed_primary_9_b}</td>
                                    <td>{r?.cases_disposed_secondary_9_b}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.cases_disposed_cncp_9_b}</td>
                                <td>{sum_array.cases_disposed_0_5_9_b}</td>
                                <td>{sum_array.cases_disposed_6_10_9_b}</td>
                                <td>{sum_array.cases_disposed_11_14_9_b}</td>
                                <td>{sum_array.cases_disposed_15_18_9_b}</td>
                                <td>{sum_array.cases_disposed_male_9_b}</td>
                                <td>{sum_array.cases_disposed_female_9_b}</td>
                                <td>{sum_array.cases_disposed_general_9_b}</td>
                                <td>{sum_array.cases_disposed_sc_9_b}</td>
                                <td>{sum_array.cases_disposed_st_9_b}</td>
                                <td>{sum_array.cases_disposed_obc_9_b}</td>
                                <td>{sum_array.cases_disposed_not_known_9_b}</td>
                                <td>{sum_array.cases_disposed_apl_9_b}</td>
                                <td>{sum_array.cases_disposed_bpl_9_b}</td>
                                <td>{sum_array.cases_disposed_illiterate_9_b}</td>
                                <td>{sum_array.cases_disposed_primary_9_b}</td>
                                <td>{sum_array.cases_disposed_secondary_9_b}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={18} className="indicator">Indicator 10: Number of- a) cases of missing children in last one
                                    year b) cases of recovered children in last one year c) cases of
                                    trafficked children in last one year</th>
                            </tr>
                            {/* <table className="qprhighcourt" id="qprhc"> */}
                            <tr>
                                <th></th>
                                <th colSpan={17} className="bgclr">
                                    10.a) Number of cases of missing children during 1 Jan 2022 to
                                    31st March 2022
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>
                                    Total Number of Children in Need of Care and Protection (CNCP)
                                </th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-14</th>
                                <th>15-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.cncp_missing_children_10_a}</td>
                                    <td>{r?.age_group_0_5_missing_children_10_a}</td>
                                    <td>{r?.age_group_6_10_missing_children_10_a}</td>
                                    <td>{r?.age_group_11_14_missing_children_10_a}</td>
                                    <td>{r?.age_group_15_18_missing_children_10_a}</td>
                                    <td>{r?.sex_male_missing_children_10_a}</td>
                                    <td>{r?.sex_female_missing_children_10_a}</td>
                                    <td>{r?.social_class_gen_missing_children_10_a}</td>
                                    <td>{r?.social_class_sc_missing_children_10_a}</td>
                                    <td>{r?.social_class_st_missing_children_10_a}</td>
                                    <td>{r?.social_class_obc_missing_children_10_a}</td>
                                    <td>{r?.social_class_nk_missing_children_10_a}</td>
                                    <td>{r?.economic_class_apl_missing_children_10_a}</td>
                                    <td>{r?.economic_class_bpl_missing_children_10_a}</td>
                                    <td>{r?.edu_bg_illiterate_missing_children_10_a}</td>
                                    <td>{r?.edu_bg_primary_missing_children_10_a}</td>
                                    <td>{r?.edu_bg_secondary_missing_children_10_a}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.cncp_missing_children_10_a}</td>
                                <td>{sum_array.age_group_0_5_missing_children_10_a}</td>
                                <td>{sum_array.age_group_6_10_missing_children_10_a}</td>
                                <td>{sum_array.age_group_11_14_missing_children_10_a}</td>
                                <td>{sum_array.age_group_15_18_missing_children_10_a}</td>
                                <td>{sum_array.sex_male_missing_children_10_a}</td>
                                <td>{sum_array.sex_female_missing_children_10_a}</td>
                                <td>{sum_array.social_class_gen_missing_children_10_a}</td>
                                <td>{sum_array.social_class_sc_missing_children_10_a}</td>
                                <td>{sum_array.social_class_st_missing_children_10_a}</td>
                                <td>{sum_array.social_class_obc_missing_children_10_a}</td>
                                <td>{sum_array.social_class_nk_missing_children_10_a}</td>
                                <td>{sum_array.economic_class_apl_missing_children_10_a}</td>
                                <td>{sum_array.economic_class_bpl_missing_children_10_a}</td>
                                <td>{sum_array.edu_bg_illiterate_missing_children_10_a}</td>
                                <td>{sum_array.edu_bg_primary_missing_children_10_a}</td>
                                <td>{sum_array.edu_bg_secondary_missing_children_10_a}</td>
                            </tr>

                            {/* </table> */}
                            {/* <table className="qprhighcourt" id="qpr"> */}
                            <tr>
                                <th></th>
                                <th colSpan={17} className="bgclr">
                                    10.b) Number of cases of recovered children during 1 Jan 2022 to
                                    31st March 2022{" "}
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>
                                    Total Number of Children in Need of Care and Protection (CNCP)
                                </th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-14</th>
                                <th>15-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.cncp_recovered_children_10_b}</td>
                                    <td>{r?.age_group_0_5_recovered_children_10_b}</td>
                                    <td>{r?.age_group_6_10_recovered_children_10_b}</td>
                                    <td>{r?.age_group_11_14_recovered_children_10_b}</td>
                                    <td>{r?.age_group_15_18_recovered_children_10_b}</td>
                                    <td>{r?.sex_male_recovered_children_10_b}</td>
                                    <td>{r?.sex_female_recovered_children_10_b}</td>
                                    <td>{r?.social_class_gen_recovered_children_10_b}</td>
                                    <td>{r?.social_class_sc_recovered_children_10_b}</td>
                                    <td>{r?.social_class_st_recovered_children_10_b}</td>
                                    <td>{r?.social_class_obc_recovered_children_10_b}</td>
                                    <td>{r?.social_class_nk_recovered_children_10_b}</td>
                                    <td>{r?.economic_class_apl_recovered_children_10_b}</td>
                                    <td>{r?.economic_class_bpl_recovered_children_10_b}</td>
                                    <td>{r?.edu_bg_illiterate_recovered_children_10_b}</td>
                                    <td>{r?.edu_bg_primary_recovered_children_10_b}</td>
                                    <td>{r?.edu_bg_secondary_recovered_children_10_b}</td>
                                </tr>
                            ))}


                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.cncp_recovered_children_10_b}</td>
                                <td>{sum_array.age_group_0_5_recovered_children_10_b}</td>
                                <td>{sum_array.age_group_6_10_recovered_children_10_b}</td>
                                <td>{sum_array.age_group_11_14_recovered_children_10_b}</td>
                                <td>{sum_array.age_group_15_18_recovered_children_10_b}</td>
                                <td>{sum_array.sex_male_recovered_children_10_b}</td>
                                <td>{sum_array.sex_female_recovered_children_10_b}</td>
                                <td>{sum_array.social_class_gen_recovered_children_10_b}</td>
                                <td>{sum_array.social_class_sc_recovered_children_10_b}</td>
                                <td>{sum_array.social_class_st_recovered_children_10_b}</td>
                                <td>{sum_array.social_class_obc_recovered_children_10_b}</td>
                                <td>{sum_array.social_class_nk_recovered_children_10_b}</td>
                                <td>{sum_array.economic_class_apl_recovered_children_10_b}</td>
                                <td>{sum_array.economic_class_bpl_recovered_children_10_b}</td>
                                <td>{sum_array.edu_bg_illiterate_recovered_children_10_b}</td>
                                <td>{sum_array.edu_bg_primary_recovered_children_10_b}</td>
                                <td>{sum_array.edu_bg_secondary_recovered_children_10_b}</td>
                            </tr>

                            {/* </table> */}
                            {/* <table className="qprhighcourt" id="qpr"> */}
                            <tr>
                                <th></th>
                                <th colSpan={17} className="bgclr">
                                    10.c) Number of cases of trafficked children during 1 Jan 2022
                                    to 31st March 2022{" "}
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>
                                    Total Number of Children in Need of Care and Protection (CNCP)
                                </th>
                                <th colSpan={4}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-14</th>
                                <th>15-18</th>
                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{sum_array.cncp_trafficked_children_10_c}</td>
                                    <td>{sum_array.age_group_0_5_trafficked_children_10_c}</td>
                                    <td>{sum_array.age_group_6_10_trafficked_children_10_c}</td>
                                    <td>{sum_array.age_group_11_14_trafficked_children_10_c}</td>
                                    <td>{sum_array.age_group_15_18_trafficked_children_10_c}</td>
                                    <td>{sum_array.sex_male_trafficked_children_10_c}</td>
                                    <td>{sum_array.sex_female_trafficked_children_10_c}</td>
                                    <td>{sum_array.social_class_gen_trafficked_children_10_c}</td>
                                    <td>{sum_array.social_class_sc_trafficked_children_10_c}</td>
                                    <td>{sum_array.social_class_st_trafficked_children_10_c}</td>
                                    <td>{sum_array.social_class_obc_trafficked_children_10_c}</td>
                                    <td>{sum_array.social_class_nk_trafficked_children_10_c}</td>
                                    <td>{sum_array.economic_class_apl_trafficked_children_10_c}</td>
                                    <td>{sum_array.economic_class_bpl_trafficked_children_10_c}</td>
                                    <td>{sum_array.edu_bg_illiterate_trafficked_children_10_c}</td>
                                    <td>{sum_array.edu_bg_primary_trafficked_children_10_c}</td>
                                    <td>{sum_array.edu_bg_secondary_trafficked_children_10_c}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.cncp_trafficked_children_10_c}</td>
                                <td>{sum_array.age_group_0_5_trafficked_children_10_c}</td>
                                <td>{sum_array.age_group_6_10_trafficked_children_10_c}</td>
                                <td>{sum_array.age_group_11_14_trafficked_children_10_c}</td>
                                <td>{sum_array.age_group_15_18_trafficked_children_10_c}</td>
                                <td>{sum_array.sex_male_trafficked_children_10_c}</td>
                                <td>{sum_array.sex_female_trafficked_children_10_c}</td>
                                <td>{sum_array.social_class_gen_trafficked_children_10_c}</td>
                                <td>{sum_array.social_class_sc_trafficked_children_10_c}</td>
                                <td>{sum_array.social_class_st_trafficked_children_10_c}</td>
                                <td>{sum_array.social_class_obc_trafficked_children_10_c}</td>
                                <td>{sum_array.social_class_nk_trafficked_children_10_c}</td>
                                <td>{sum_array.economic_class_apl_trafficked_children_10_c}</td>
                                <td>{sum_array.economic_class_bpl_trafficked_children_10_c}</td>
                                <td>{sum_array.edu_bg_illiterate_trafficked_children_10_c}</td>
                                <td>{sum_array.edu_bg_primary_trafficked_children_10_c}</td>
                                <td>{sum_array.edu_bg_secondary_trafficked_children_10_c}</td>
                            </tr>

                            {/* </table> */}
                            <tr><th colSpan={4} className="indicator">Indicator 12</th></tr>
                            <tr>
                                <th colSpan={4}>Child Welfare Committees (status as on 31st March 2022)</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th colSpan={3} className="bgclr">
                                    Number of vacancies in key statutory and allied bodies{" "}
                                </th>
                            </tr>
                            <tr>
                                <th>Sanctioned Positions</th>
                                <th>Filled Positions</th>
                                <th>Vacant Positions</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>
                                        {r?.cwc_vac_in_key_sat_allied_bod_sanctioned_positions_12}
                                    </td>
                                    <td>{r?.cwc_vac_in_key_sat_allied_bod_filled_positions_12}</td>
                                    <td>{r?.cwc_vac_in_key_sat_allied_bod_vacant_positions_12}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>
                                    {sum_array.cwc_vac_in_key_sat_allied_bod_sanctioned_positions_12}
                                </td>
                                <td>{sum_array.cwc_vac_in_key_sat_allied_bod_filled_positions_12}</td>
                                <td>{sum_array.cwc_vac_in_key_sat_allied_bod_vacant_positions_12}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={4}>District Child Protection Units (status as on 31st March 2022)</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th colSpan={3} className="bgclr">
                                    Number of vacancies in key statutory and allied bodies{" "}
                                </th>
                            </tr>
                            <tr>
                                <th>Sanctioned Positions</th>
                                <th>Filled Positions</th>
                                <th>Vacant Positions</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>
                                        {r?.dcpu_vac_in_key_sat_allied_bod_sanctioned_positions_12}
                                    </td>
                                    <td>{r?.dcpu_vac_in_key_sat_allied_bod_filled_positions_12}</td>
                                    <td>{r?.dcpu_vac_in_key_sat_allied_bod_vacant_positions_12}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>
                                    {sum_array.dcpu_vac_in_key_sat_allied_bod_sanctioned_positions_12}
                                </td>
                                <td>{sum_array.dcpu_vac_in_key_sat_allied_bod_filled_positions_12}</td>
                                <td>{sum_array.dcpu_vac_in_key_sat_allied_bod_vacant_positions_12}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={4}>Special Juvenile Police Units (status as on 31st March 2022)</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th colSpan={3} className="bgclr">
                                    Number of vacancies in key statutory and allied bodies{" "}
                                </th>
                            </tr>
                            <tr>
                                <th>Sanctioned Positions</th>
                                <th>Filled Positions</th>
                                <th>Vacant Positions</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>
                                        {r?.sjpu_vac_in_key_sat_allied_bod_sanctioned_positions_12}
                                    </td>
                                    <td>{r?.sjpu_vac_in_key_sat_allied_bod_filled_positions_12}</td>
                                    <td>{r?.sjpu_vac_in_key_sat_allied_bod_vacant_positions_12}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>
                                    {sum_array.sjpu_vac_in_key_sat_allied_bod_sanctioned_positions_12}
                                </td>
                                <td>{sum_array.sjpu_vac_in_key_sat_allied_bod_filled_positions_12}</td>
                                <td>{sum_array.sjpu_vac_in_key_sat_allied_bod_vacant_positions_12}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={4}>State Special Adoption Agencies (status as on 31st March 2022)</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th colSpan={3} className="bgclr">
                                    Number of vacancies in key statutory and allied bodies{" "}
                                </th>
                            </tr>
                            <tr>
                                <th>Sanctioned Positions</th>
                                <th>Filled Positions</th>
                                <th>Vacant Positions</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>
                                        {r?.ssaa_vac_in_key_sat_allied_bod_sanctioned_positions_12}
                                    </td>
                                    <td>{r?.ssaa_vac_in_key_sat_allied_bod_filled_positions_12}</td>
                                    <td>{r?.ssaa_vac_in_key_sat_allied_bod_vacant_positions_12}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>
                                    {sum_array.ssaa_vac_in_key_sat_allied_bod_sanctioned_positions_12}
                                </td>
                                <td>{sum_array.ssaa_vac_in_key_sat_allied_bod_filled_positions_12}</td>
                                <td>{sum_array.ssaa_vac_in_key_sat_allied_bod_vacant_positions_12}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={6} className="indicator">Indicator 14: Number and name of districts having Child Friendly
                                    Police Stations (status as on 31st March 2022)</th>
                            </tr>
                            <br></br>
                            <tr>
                                <th colSpan={6} className="indicator">Indicator 15: Number of residential care institutions (observation
                                    home, children's home, SAA, open shelter) (status as on 31st March
                                    2022)</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2} className="bgclr">
                                    No. of Child Friendly Police Stations/One Stop Centers as per
                                    state guidelines
                                </th>
                                <th colSpan={4} className="bgclr">
                                    Number of residential care institutions
                                </th>
                            </tr>
                            <tr>
                                <th>Observation Home</th>
                                <th>Children's Home</th>
                                <th>SAA</th>
                                <th>Open Shelter</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>
                                        {r?.rci_child_friendly_police_stations_one_stop_centers_15}
                                    </td>
                                    <td>{r?.rci_observation_home_15}</td>
                                    <td>{r?.rci_childrens_home_15}</td>
                                    <td>{r?.rci_saa_15}</td>
                                    <td>{r?.rci_open_shelter_15}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>
                                    {sum_array.rci_child_friendly_police_stations_one_stop_centers_15}
                                </td>
                                <td>{sum_array.rci_observation_home_15}</td>
                                <td>{sum_array.rci_childrens_home_15}</td>
                                <td>{sum_array.rci_saa_15}</td>
                                <td>{sum_array.rci_open_shelter_15}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                <th colSpan={17} className="indicator">Indicator 16: Number of children a) in residential care institutions
                                    b) staying for more than a year in residential care institutions</th>
                            </tr>
                            {/* <table className="qprhighcourt" id="qprhc"> */}
                            <tr>
                                <th></th>
                                <th colSpan={16} className="bgclr">
                                    16.a) Number of children in residential care institutions
                                    (observation home, children’s home, SAA, open shelter) as on
                                    31st March 2022
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-18</th>

                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>

                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.total_cases_childern_in_rci_16_a}</td>
                                    <td>{r?.age_group_0_5_childern_in_rci_16_a}</td>
                                    <td>{r?.age_group_6_10_childern_in_rci_16_a}</td>
                                    <td>{r?.age_group_11_14_childern_in_rci_16_a}</td>
                                    {/* <td>{r?.age_group_15_18_childern_in_rci_16_a}</td> */}
                                    <td>{r?.sex_male_childern_in_rci_16_a}</td>
                                    <td>{r?.sex_female_childern_in_rci_16_a}</td>
                                    <td>{r?.social_class_gen_childern_in_rci_16_a}</td>
                                    <td>{r?.social_class_sc_childern_in_rci_16_a}</td>
                                    <td>{r?.social_class_st_childern_in_rci_16_a}</td>
                                    <td>{r?.social_class_obc_childern_in_rci_16_a}</td>
                                    <td>{r?.social_class_nk_childern_in_rci_16_a}</td>
                                    <td>{r?.economic_class_apl_childern_in_rci_16_a}</td>
                                    <td>{r?.economic_class_bpl_childern_in_rci_16_a}</td>
                                    <td>{r?.edu_bg_illiterate_childern_in_rci_16_a}</td>
                                    <td>{r?.edu_bg_primary_childern_in_rci_16_a}</td>
                                    <td>{r?.edu_bg_secondary_childern_in_rci_16_a}</td>
                                </tr>
                            ))}


                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.total_cases_childern_in_rci_16_a}</td>
                                <td>{sum_array.age_group_0_5_childern_in_rci_16_a}</td>
                                <td>{sum_array.age_group_6_10_childern_in_rci_16_a}</td>
                                <td>{sum_array.age_group_11_14_childern_in_rci_16_a}</td>
                                {/* <td>{sum_array.age_group_15_18_childern_in_rci_16_a}</td> */}
                                <td>{sum_array.sex_male_childern_in_rci_16_a}</td>
                                <td>{sum_array.sex_female_childern_in_rci_16_a}</td>
                                <td>{sum_array.social_class_gen_childern_in_rci_16_a}</td>
                                <td>{sum_array.social_class_sc_childern_in_rci_16_a}</td>
                                <td>{sum_array.social_class_st_childern_in_rci_16_a}</td>
                                <td>{sum_array.social_class_obc_childern_in_rci_16_a}</td>
                                <td>{sum_array.social_class_nk_childern_in_rci_16_a}</td>
                                <td>{sum_array.economic_class_apl_childern_in_rci_16_a}</td>
                                <td>{sum_array.economic_class_bpl_childern_in_rci_16_a}</td>
                                <td>{sum_array.edu_bg_illiterate_childern_in_rci_16_a}</td>
                                <td>{sum_array.edu_bg_primary_childern_in_rci_16_a}</td>
                                <td>{sum_array.edu_bg_secondary_childern_in_rci_16_a}</td>
                            </tr>

                            {/* </table> */}
                            {/* <table className="qprhighcourt" id="qpr"> */}
                            <tr>
                                <th></th>
                                <th colSpan={16} className="bgclr">
                                    16.b) Number of children staying for more than a year in
                                    residential care institutions (observation home, children’s
                                    home, SAA, open shelter) as on 31st March 2022{" "}
                                </th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>District</th>
                                <th rowSpan={2}>Total Cases</th>
                                <th colSpan={3}>Age Group</th>
                                <th colSpan={2}>Sex</th>
                                <th colSpan={5}>Social Class</th>
                                <th colSpan={2}>Economic Class</th>
                                <th colSpan={3}>Educational Background</th>
                            </tr>
                            <tr>
                                <th>0-5</th>
                                <th>6-10</th>
                                <th>11-18</th>

                                <th>Male</th>
                                <th>Female</th>
                                <th>General</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>OBC</th>
                                <th>Not Known</th>
                                <th>APL</th>
                                <th>BPL</th>
                                <th>Illiterate</th>
                                <th>Primary</th>
                                <th>Secondary</th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.total_cases_childern_staying_rci_16_b}</td>
                                    <td>{r?.age_group_0_5_childern_staying_rci_16_b}</td>
                                    <td>{r?.age_group_6_10_childern_staying_rci_16_b}</td>
                                    <td>{r?.age_group_11_14_childern_staying_rci_16_b}</td>
                                    {/* <td>{r?.age_group_15_18_childern_staying_rci_16_b}</td> */}
                                    <td>{r?.sex_male_childern_staying_rci_16_b}</td>
                                    <td>{r?.sex_female_childern_staying_rci_16_b}</td>
                                    <td>{r?.social_class_gen_childern_staying_rci_16_b}</td>
                                    <td>{r?.social_class_sc_childern_staying_rci_16_b}</td>
                                    <td>{r?.social_class_st_childern_staying_rci_16_b}</td>
                                    <td>{r?.social_class_obc_childern_staying_rci_16_b}</td>
                                    <td>{r?.social_class_nk_childern_staying_rci_16_b}</td>
                                    <td>{r?.economic_class_apl_childern_staying_rci_16_b}</td>
                                    <td>{r?.economic_class_bpl_childern_staying_rci_16_b}</td>
                                    <td>{r?.edu_bg_illiterate_childern_staying_rci_16_b}</td>
                                    <td>{r?.edu_bg_primary_childern_staying_rci_16_b}</td>
                                    <td>{r?.edu_bg_secondary_childern_staying_rci_16_b}</td>
                                </tr>
                            ))}

                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.total_cases_childern_staying_rci_16_b}</td>
                                <td>{sum_array.age_group_0_5_childern_staying_rci_16_b}</td>
                                <td>{sum_array.age_group_6_10_childern_staying_rci_16_b}</td>
                                <td>{sum_array.age_group_11_14_childern_staying_rci_16_b}</td>
                                {/* <td>{sum_array.age_group_15_18_childern_staying_rci_16_b}</td> */}
                                <td>{sum_array.sex_male_childern_staying_rci_16_b}</td>
                                <td>{sum_array.sex_female_childern_staying_rci_16_b}</td>
                                <td>{sum_array.social_class_gen_childern_staying_rci_16_b}</td>
                                <td>{sum_array.social_class_sc_childern_staying_rci_16_b}</td>
                                <td>{sum_array.social_class_st_childern_staying_rci_16_b}</td>
                                <td>{sum_array.social_class_obc_childern_staying_rci_16_b}</td>
                                <td>{sum_array.social_class_nk_childern_staying_rci_16_b}</td>
                                <td>{sum_array.economic_class_apl_childern_staying_rci_16_b}</td>
                                <td>{sum_array.economic_class_bpl_childern_staying_rci_16_b}</td>
                                <td>{sum_array.edu_bg_illiterate_childern_staying_rci_16_b}</td>
                                <td>{sum_array.edu_bg_primary_childern_staying_rci_16_b}</td>
                                <td>{sum_array.edu_bg_secondary_childern_staying_rci_16_b}</td>
                            </tr>

                            {/* </table> */}
                            <tr>
                                {/* {" "} */}
                                <th colSpan={3} className="indicator">Indicator 17: Number of designated institutions that have been monitored at least once per quarter by the inspection committee as
                                    per state rules during the period 1 Jan 2022 to 31st March 2022</th>
                            </tr>
                            {/* <table className="qprhighcourt"> */}
                            <tr>
                                <th>District</th>
                                <th>Number of such institutions</th>
                                <th>
                                    Number of institutions which are monitored as per state rules
                                </th>
                            </tr>
                            {props.report.map((r) => (
                                <tr key={r?.id}>
                                    <th scope="row">{r?.username}</th>
                                    <td>{r?.design_inst_no_of_institution}</td>
                                    <td>{r?.design_inst_no_of_inst_monit_as_per_state_rule}</td>
                                </tr>
                            ))}


                            <tr>
                                <th scope="row">Total</th>
                                <td>{sum_array.design_inst_no_of_institution}</td>
                                <td>{sum_array.design_inst_no_of_inst_monit_as_per_state_rule}</td>
                            </tr>

                        </table>

                    </div>

                    <div className="modal-footer" style={{ position: 'fixed', "bottom": "0", "right": "0" }}>
                        <button
                            className="btn btn-primary"
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={() => downloadExcel("qprhc")}
                        >
                            {" "}
                            Download <i class="icofont icofont-arrow-down"></i>{" "}
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={createPDF}
                            type="button"
                            data-bs-dismiss="modal"

                        >
                            Download as Pdf
                        </button>
                        <button
                            className="btn btn-secondary"
                            onClick={openForm1}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default AReport27;
