import React, { useState } from "react";
import Modal from "react-modal";
import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";

// FORM 1  CHILD MARRIAGE (M)

function Report1(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);

    const [{ }, { downloadExcel }] = useFormStore();
    const year = props.year;
    const month = props.month;
    const Month = ["Month not selected", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const DisplayMonth = Month[month];

    const openForm1 = () => {
        setmodalIsOpen(false);
        props.close();
    };
    // object assigned to a variable to obtain total
    const values = props.report;
    // sum_array = total sum data of district
    const sum_array = values.reduce((sum_array, value) => {
        for (const [key, count] of Object.entries(value)) {
            if (!sum_array[key]) {
                sum_array[key] = 0;
            }
            sum_array[key] += count;
        }
        return sum_array;
    }, {});
    function createPDF() {
        var sTable = document.getElementById('table').innerHTML;
        var style = "<style>";
        style = style + "table {width: 100%;font: 10px Calibri;}";
        style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "</style>";
        var win = window.open('', '', 'height=700,width=700');
        win.document.write('<html><head>');
        win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
        win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
        win.document.write('</body></html>');
        win.document.close(); 	// CLOSE THE CURRENT WINDOW.
        win.print();    // PRINT THE CONTENTS.
    }


    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                        {" "}
                        DETAILS OF CHILD MARRIAGE{" "}
                    </h5>
                    <button
                        className="btn-close"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <h4>{DisplayMonth} {year}</h4>
                <div className="modal-body">
                    <div class="table-responsive" id="table" height="100%" width="100%" media="print">
                        <table class="table" id="child_marriage">
                            <thead>
                                <tr>
                                    <th rowSpan={2}>#</th>
                                    <th rowSpan={2}></th>
                                    <th colSpan={2}>Gender</th>

                                    {/* <th scope="col">Female</th> */}
                                    <th rowSpan={2}>No. of child marriages happened</th>
                                    <th rowSpan={2}>No. of complaints received</th>
                                    <th rowSpan={2}>No. of enquiries conducted</th>
                                    <th rowSpan={2}>No. of report submitted to the court</th>
                                    <th rowSpan={2}>No. of injunction order received</th>
                                    <th rowSpan={2}>No. of violations made by parties</th>
                                    <th rowSpan={2}>No. of maintenance order issued</th>
                                    <th rowSpan={2}>No. of punishment order made</th>
                                    <th rowSpan={2}>
                                        No. of child marriage successfully prevented
                                    </th>
                                    <th rowSpan={2}>No. of residence orders made</th>
                                    <th rowSpan={2}>No. of awareness programs conducted</th>
                                    <th rowSpan={2}>
                                        No. of review meetings of assistance conducted
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col">Male</th>
                                    <th scope="col">Female</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.report.map((r) => (
                                    <tr key={r?.id}>
                                        <th scope="row">{r?.id}</th>
                                        <td>{r?.username}</td>
                                        <td>{r?.male}</td>
                                        <td>{r?.female}</td>
                                        <td>{r?.child_marriage}</td>
                                        <td>{r?.complaints_recieved}</td>
                                        <td>{r?.enquiries_conducted}</td>
                                        <td>{r?.report_submitted_to_court}</td>
                                        <td>{r?.injuction_order_recieved}</td>
                                        <td>{r?.violation_made_by_parties}</td>
                                        <td>{r?.maintanance_order_issued}</td>
                                        <td>{r?.punishment_order_made}</td>
                                        <td>{r?.child_marriage_prevented}</td>
                                        <td>{r?.residence_order_made}</td>
                                        <td>{r?.awarness_program_conductted}</td>
                                        <td>{r?.review_meeting_of_assistance_conductted}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <th scope="row"></th>
                                    <td>Total</td>

                                    <td>{sum_array.male}</td>
                                    <td>{sum_array.female}</td>
                                    <td>{sum_array.child_marriage}</td>
                                    <td>{sum_array.complaints_recieved}</td>
                                    <td>{sum_array.enquiries_conducted}</td>
                                    <td>{sum_array.report_submitted_to_court}</td>
                                    <td>{sum_array.injuction_order_recieved}</td>
                                    <td>{sum_array.violation_made_by_parties}</td>
                                    <td>{sum_array.maintanance_order_issued}</td>
                                    <td>{sum_array.punishment_order_made}</td>
                                    <td>{sum_array.child_marriage_prevented}</td>
                                    <td>{sum_array.residence_order_made}</td>
                                    <td>{sum_array.awarness_program_conductted}</td>
                                    <td>
                                        {sum_array.review_meeting_of_assistance_conductted}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="modal-footer">
                    <button
                        className="btn btn-primary"
                        type="button"
                        data-bs-dismiss="modal"
                        onClick={() => downloadExcel("child_marriage")}
                    >
                        {" "}
                        Download <i class="icofont icofont-arrow-down"></i>{" "}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={createPDF}
                        type="button"
                        data-bs-dismiss="modal"
                    >
                        Download as Pdf
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
                </div>
            </Modal>
        </div>
    );
}

export default Report1;
