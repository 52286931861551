import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { useFormStore } from "../Store/Forms";

// FORM 18 Institutional Care of Children in Need of Care and Protection	(Q)

function Report18(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{}, { downloadExcel }] = useFormStore();
  const Qyear = props.Qyear
  const Qmonth = props.Qmonth
  const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
  const DisplayQuarter = Quarter[Qmonth]


  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };

  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {" "}
            Institutional Care of Children in Need of Care and Protection{" "}
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <h4>{DisplayQuarter} {Qyear}</h4>
        <div className="modal-body">
          <div class="table-responsive">
            <h5>
              a) Children’s Homes (established under section 50 of the JJ Act,
              2015):-{" "}
            </h5>
            <table class="table" id="institutional_care_protection">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" rowspan="2">
                    Particulars
                  </th>
                  <th scope="col">As on 31.12.21 </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col">1</th>
                  <th scope="col">
                    No. of Children’s Homes in the district maintained by the
                    state govt itself
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.childrens_home_district_by_state
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">2</th>
                  <th scope="col">
                    No. of Children’s Homes in the district maintained by the
                    state govt through voluntary or non – governmental
                    organization
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.childrens_home_district_by_state_by_ngo
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">3</th>
                  <th scope="col">
                    No. of children placed in Children’s Homes in the district
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.placed_in_children_home_in_district
                    } */}
                  </th>
                </tr>
              </tbody>
            </table>{" "}
            <br /> <br />
            <h5>b) Staffs in Children’s Home </h5>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Particulars</th>
                  <th scope="col">Strength of staff </th>
                  <th scope="col"> </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col" rowspan="3">
                    1
                  </th>
                  <th scope="col" rowspan="3">
                    Total no. of full time staff in all the children’s home in
                    the district
                  </th>
                  <th scope="col">Sanctioned strength</th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.full_time_staff_in_child_home_in_district_sanctioned
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">Working strength</th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.full_time_staff_in_child_home_in_district_working_strength
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">Vacancies</th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.full_time_staff_in_child_home_in_district_vacancies
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col" rowspan="3">
                    2
                  </th>
                  <th scope="col" rowspan="3">
                    Total no. of part time staff in all the children’s home in
                    the district
                  </th>
                  <th scope="col">Sanctioned strength</th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.part_time_staff_sanctioned
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">Working strength</th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.part_time_staff_working_strength
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">Vacancies</th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.part_time_staff_vacancies
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">3</th>
                  <th scope="col">
                    No. of staff given Additional charge of children’s home in
                    the district
                  </th>
                  <th> </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.give_additional_charge
                    } */}
                  </th>
                </tr>
              </tbody>
            </table>{" "}
            <br /> <br />
            <h5>
              {" "}
              c) Fit Facility (Recognised under section 51 of the JJ Act,
              2015):-{" "}
            </h5>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Particulars</th>
                  <th scope="col">As on 31.12.21 </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col">1</th>
                  <th scope="col">
                    No. of Governmental Organization recognised as Fit Facility
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.gov_org_rec_fit_facility
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">2</th>
                  <th scope="col">
                    No. of Non-Governmental Organization recognised as Fit
                    Facility
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.non_gov_org_rec_fit_facility
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">3</th>
                  <th scope="col">
                    No. of children placed in Fit Facility in the district
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.no_of_children_fit_facility
                    } */}
                  </th>
                </tr>
              </tbody>
            </table>{" "}
            <br /> <br />
            <h5>
              {" "}
              d) Fit Persons (Recognised under section 52 of the JJ Act, 2015):-{" "}
            </h5>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Particulars</th>
                  <th scope="col">As on 31.12.21 </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col">1</th>
                  <th scope="col">
                    No. of persons in the district recognised as Fit Facility
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.person_in_district_recog_as_fit_facility
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">2</th>
                  <th scope="col">
                    No. of children placed with Fit persons in the district
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.children_placed_with_fit_person
                    } */}
                  </th>
                </tr>
              </tbody>
            </table>{" "}
            <br /> <br />
            <h5>
              {" "}
              e) Open Shelter (established under section 43 of the JJ Act,
              2015):-{" "}
            </h5>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Particulars</th>
                  <th scope="col">As on 31.12.21 </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col">1</th>
                  <th scope="col">
                    No. of Open Shelters in the district maintained by the state
                    govt itself
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.open_shelter_in_district_by_state_govt_itself
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">2</th>
                  <th scope="col">
                    No. of Open Shelters in the district maintained by the state
                    govt through voluntary or non – governmental organization
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.open_shelter_in_district_by_state_govt_trough_ngo
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">3</th>
                  <th scope="col">
                    Total no. of Open Shelters in the district
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.open_shelter_in_district
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">4</th>
                  <th scope="col">
                    No. of children placed in Open Shelters in the district
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.children_in_open_shelter_in_district
                    } */}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("institutional_care_protection")}
          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default Report18;
