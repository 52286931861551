import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";

// FORM 6 Aftercare offered to children in CCIs (age 18-21) (Q)

function AReport6(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { downloadExcel }] = useFormStore();
  const Qyear = props.Qyear
  const Qmonth = props.Qmonth
  const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
  const DisplayQuarter = Quarter[Qmonth]


  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };
  // object assigned to a variable to obtain total

  let newArray = props.report.filter(function (d) {
    return d.year == Qyear && d.month == Qmonth;
  })

  // sum_array = total sum data of district
  const sum_array = newArray.reduce((sum_array, value) => {
    for (const [key, count] of Object.entries(value)) {
      if (!sum_array[key]) {
        sum_array[key] = 0;
      }
      sum_array[key] += count;
    }
    return sum_array;
  }, {});

  let newArrayCumulative = props.report.filter(function (d) {
    return d.year == Qyear && d.month <= Qmonth;
  })

  let result = [];

  newArrayCumulative.forEach(function (a) {
    if (!this[a.username]) {
      this[a.username] = {
        username: a.username, male_current_quarter: 0,
        female_current_quarter: 0,
        home_employed_or_other_income_male_current_quarter: 0,
        home_employed_or_other_income_female_current_quarter: 0,
        vocational_or_placement_training_male_current_quarter: 0,
        vocational_or_placement_training_female_current_quarter: 0,
        youth_exitted_from_home_for_employment_male_current_quarter: 0,
        youth_exitted_from_home_for_employment_female_current_quarter: 0,

      };


      result.push(this[a.username]);
    }
    this[a.username].male_current_quarter += a.male_current_quarter;
    this[a.username].female_current_quarter += a.female_current_quarter;
    this[a.username].home_employed_or_other_income_male_current_quarter += a.home_employed_or_other_income_male_current_quarter;
    this[a.username].home_employed_or_other_income_female_current_quarter += a.home_employed_or_other_income_female_current_quarter;
    this[a.username].vocational_or_placement_training_male_current_quarter += a.vocational_or_placement_training_male_current_quarter;
    this[a.username].vocational_or_placement_training_female_current_quarter += a.vocational_or_placement_training_female_current_quarter;
    this[a.username].youth_exitted_from_home_for_employment_male_current_quarter += a.youth_exitted_from_home_for_employment_male_current_quarter;
    this[a.username].youth_exitted_from_home_for_employment_female_current_quarter += a.youth_exitted_from_home_for_employment_female_current_quarter;


  }, Object.create(null));

  // console.log(result);
  for (let object of newArray) {
    for (let i of result) {
      if (object.username === i.username) {
        object.male_cumulative = i.male_current_quarter
        object.female_cumulative = i.female_current_quarter
        object.home_employed_or_other_income_male_cumulative = i.home_employed_or_other_income_male_current_quarter
        object.home_employed_or_other_income_female_cumulative = i.home_employed_or_other_income_female_current_quarter
        object.vocational_or_placement_training_male_cummulative = i.vocational_or_placement_training_male_current_quarter
        object.vocational_or_placement_training_female_cummulative = i.vocational_or_placement_training_female_current_quarter
        object.youth_exitted_from_home_for_employment_male_cumulative = i.youth_exitted_from_home_for_employment_male_current_quarter
        object.youth_exitted_from_home_for_employment_female_cumulative = i.youth_exitted_from_home_for_employment_female_current_quarter


      }
    }
  }
  console.log(props.report)
  function createPDF() {
    var sTable = document.getElementById('table').innerHTML;
    var style = "<style>";
    style = style + "table {width: 100%;font: 10px Calibri;}";
    style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "</style>";
    var win = window.open('', '', 'height=700,width=700');
    win.document.write('<html><head>');
    win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
    win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
    win.document.write('</head>');
    win.document.write('<body>');
    win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
    win.document.write('</body></html>');
    win.document.close(); 	// CLOSE THE CURRENT WINDOW.
    win.print();    // PRINT THE CONTENTS.
  }
  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {" "}
            Details of aftercare offered to children in CCIs of age 18-21 (Govt){" "}
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <h4>{DisplayQuarter} {Qyear}</h4>
        <div className="modal-body">
          <div class="table-responsive" id="table" height="100%" width="100%" media="print">
            <table class="table" id="after_care_children_cci">
              <thead>
                <tr>
                  <th scope="col" rowspan="3">
                    #
                  </th>
                  <th scope="col" rowspan="3">
                    District
                  </th>
                  <th scope="col" colspan="4">
                    Total No. of children{" "}
                  </th>
                  <th scope="col" colspan="4">
                    No. of children currently at home employed/involved in
                    income generating options
                  </th>
                  <th scope="col" colspan="4">
                    No. of children who completed vocational/placement training?
                  </th>
                  <th scope="col" colspan="4">
                    No. of youth who exited from the homes got gainful
                    employment?{" "}
                  </th>
                </tr>

                <tr>
                  <th scope="col" colspan="2">
                    Male
                  </th>
                  <th scope="col" colspan="2">
                    Female
                  </th>
                  <th scope="col" colspan="2">
                    Male
                  </th>
                  <th scope="col" colspan="2">
                    Female
                  </th>
                  <th scope="col" colspan="2">
                    Male
                  </th>
                  <th scope="col" colspan="2">
                    Female
                  </th>
                  <th scope="col" colspan="2">
                    Male
                  </th>
                  <th scope="col" colspan="2">
                    Female
                  </th>
                </tr>

                <tr>

                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                  <th scope="col">Current quarter</th>
                  <th scope="col">Cumulative</th>
                </tr>
              </thead>
              <tbody>
                {newArray.map((r) => (
                  <tr key={r?.id}>
                    <th scope="row"></th>
                    <td>{r?.username}</td>
                    <td>{r?.male_current_quarter}</td>
                    <td>{r?.male_cumulative}</td>
                    <td>{r?.female_current_quarter}</td>
                    <td>{r?.female_cumulative}</td>
                    <td>
                      {r?.home_employed_or_other_income_male_current_quarter}
                    </td>
                    <td>{r?.home_employed_or_other_income_male_cumulative}</td>
                    <td>
                      {r?.home_employed_or_other_income_female_current_quarter}
                    </td>
                    <td>
                      {r?.home_employed_or_other_income_female_cumulative}
                    </td>
                    <td>
                      {r?.vocational_or_placement_training_male_current_quarter}
                    </td>
                    <td>
                      {r?.vocational_or_placement_training_male_cummulative}
                    </td>
                    <td>
                      {
                        r?.vocational_or_placement_training_female_current_quarter
                      }
                    </td>
                    <td>
                      {r?.vocational_or_placement_training_female_cummulative}
                    </td>
                    <td>
                      {
                        r?.youth_exitted_from_home_for_employment_male_current_quarter
                      }
                    </td>
                    <td>
                      {
                        r?.youth_exitted_from_home_for_employment_male_cumulative
                      }
                    </td>
                    <td>
                      {
                        r?.youth_exitted_from_home_for_employment_female_current_quarter
                      }
                    </td>
                    <td>
                      {
                        r?.youth_exitted_from_home_for_employment_female_cumulative
                      }
                    </td>
                  </tr>
                ))}
                <tr>
                  <th scope="row"></th>
                  <td>Total</td>
                  <td>{sum_array.male_current_quarter}</td>
                  <td>{sum_array.male_cumulative}</td>
                  <td>{sum_array.female_current_quarter}</td>
                  <td>{sum_array.female_cumulative}</td>
                  <td>{sum_array.home_employed_or_other_income_male_current_quarter}</td>
                  <td>{sum_array.home_employed_or_other_income_male_cumulative}</td>
                  <td>
                    {sum_array.home_employed_or_other_income_female_current_quarter}
                  </td>
                  <td>
                    {sum_array.home_employed_or_other_income_female_cumulative}
                  </td>
                  <td>
                    {sum_array.vocational_or_placement_training_male_current_quarter}
                  </td>
                  <td>
                    {sum_array.vocational_or_placement_training_male_cummulative}
                  </td>
                  <td>
                    {
                      sum_array.vocational_or_placement_training_female_current_quarter
                    }
                  </td>
                  <td>
                    {sum_array.vocational_or_placement_training_female_cummulative}
                  </td>
                  <td>
                    {
                      sum_array.youth_exitted_from_home_for_employment_male_current_quarter
                    }
                  </td>
                  <td>
                    {
                      sum_array.youth_exitted_from_home_for_employment_male_cumulative
                    }
                  </td>
                  <td>
                    {
                      sum_array.youth_exitted_from_home_for_employment_female_current_quarter
                    }
                  </td>
                  <td>
                    {
                      sum_array.youth_exitted_from_home_for_employment_female_cumulative
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("after_care_children_cci")}
          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-primary"
            onClick={createPDF}
            type="button"
            data-bs-dismiss="modal"
          >
            Download as Pdf
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default AReport6;
