import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";
import { Toaster, toast } from "react-hot-toast";
import { UPDATE_VIOLENCE_AGAINST_CHILDREN } from "../utils/constant";

// FORM 11 Details of Violence against Children

function Form11(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { onSubmit }] = useFormStore();
    const [{ }, { updateReport }] = useFormStore();
    const [{ }, { formUpdated }] = useFormStore();
    const Qyear = props.Qyear;
    const Qmonth = props.Qmonth;
    const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
    const DisplayQuarter = Quarter[Qmonth];


    const [data, setdata] = useState({
        total_murder_male_0_3: 0,
        total_murder_female_0_3: 0,
        total_murder_male_4_7: 0,
        total_murder_female_4_7: 0,
        total_murder_male_8_11: 0,
        total_murder_female_8_11: 0,
        total_murder_male_12_15: 0,
        total_murder_female_12_15: 0,
        total_murder_male_16_18: 0,
        total_murder_female_16_18: 0,
        total_murder_cci_male: 0,
        total_murder_cci_female: 0,

        section_4_6_male_0_3: 0,
        section_4_6_female_0_3: 0,
        section_4_6_male_4_7: 0,
        section_4_6_female_4_7: 0,
        section_4_6_male_8_11: 0,
        section_4_6_female_8_11: 0,
        section_4_6_male_12_15: 0,
        section_4_6_female_12_15: 0,
        section_4_6_male_16_18: 0,
        section_4_6_female_16_18: 0,
        section_4_6_cci_male: 0,
        section_4_6_cci_female: 0,

        kidnapping_male_0_3: 0,
        kidnapping_female_0_3: 0,
        kidnapping_male_4_7: 0,
        kidnapping_female_4_7: 0,
        kidnapping_male_8_11: 0,
        kidnapping_female_8_11: 0,
        kidnapping_male_12_15: 0,
        kidnapping_female_12_15: 0,
        kidnapping_male_16_18: 0,
        kidnapping_female_16_18: 0,
        kidnapping_cci_male: 0,
        kidnapping_cci_female: 0,

        foeticide_male_0_3: 0,
        foeticide_female_0_3: 0,
        foeticide_male_4_7: 0,
        foeticide_female_4_7: 0,
        foeticide_male_8_11: 0,
        foeticide_female_8_11: 0,
        foeticide_male_12_15: 0,
        foeticide_female_12_15: 0,
        foeticide_male_16_18: 0,
        foeticide_female_16_18: 0,
        foeticide_cci_male: 0,
        foeticide_cci_female: 0,

        abetment_male_0_3: 0,
        abetment_female_0_3: 0,
        abetment_male_4_7: 0,
        abetment_female_4_7: 0,
        abetment_male_8_11: 0,
        abetment_female_8_11: 0,
        abetment_male_12_15: 0,
        abetment_female_12_15: 0,
        abetment_male_16_18: 0,
        abetment_female_16_18: 0,
        abetment_cci_male: 0,
        abetment_cci_female: 0,

        exposure_male_0_3: 0,
        exposure_female_0_3: 0,
        exposure_male_4_7: 0,
        exposure_female_4_7: 0,
        exposure_male_8_11: 0,
        exposure_female_8_11: 0,
        exposure_male_12_15: 0,
        exposure_female_12_15: 0,
        exposure_male_16_18: 0,
        exposure_female_16_18: 0,
        exposure_cci_male: 0,
        exposure_cci_female: 0,

        procuration_male_0_3: 0,
        procuration_female_0_3: 0,
        procuration_male_4_7: 0,
        procuration_female_4_7: 0,
        procuration_male_8_11: 0,
        procuration_female_8_11: 0,
        procuration_male_12_15: 0,
        procuration_female_12_15: 0,
        procuration_male_16_18: 0,
        procuration_female_16_18: 0,
        procuration_cci_male: 0,
        procuration_cci_female: 0,

        buying_girls_male_0_3: 0,
        buying_girls_female_0_3: 0,
        buying_girls_male_4_7: 0,
        buying_girls_female_4_7: 0,
        buying_girls_male_8_11: 0,
        buying_girls_female_8_11: 0,
        buying_girls_male_12_15: 0,
        buying_girls_female_12_15: 0,
        buying_girls_male_16_18: 0,
        buying_girls_female_16_18: 0,
        buying_girls_cci_male: 0,
        buying_girls_cci_female: 0,

        selling_girls_male_0_3: 0,
        selling_girls_female_0_3: 0,
        selling_girls_male_4_7: 0,
        selling_girls_female_4_7: 0,
        selling_girls_male_8_11: 0,
        selling_girls_female_8_11: 0,
        selling_girls_male_12_15: 0,
        selling_girls_female_12_15: 0,
        selling_girls_male_16_18: 0,
        selling_girls_female_16_18: 0,
        selling_girls_cci_male: 0,
        selling_girls_cci_female: 0,

        prohibition_male_0_3: 0,
        prohibition_female_0_3: 0,
        prohibition_male_4_7: 0,
        prohibition_female_4_7: 0,
        prohibition_male_8_11: 0,
        prohibition_female_8_11: 0,
        prohibition_male_12_15: 0,
        prohibition_female_12_15: 0,
        prohibition_male_16_18: 0,
        prohibition_female_16_18: 0,
        prohibition_cci_male: 0,
        prohibition_cci_female: 0,

        other_crimes_male_0_3: 0,
        other_crimes_female_0_3: 0,
        other_crimes_male_4_7: 0,
        other_crimes_female_4_7: 0,
        other_crimes_male_8_11: 0,
        other_crimes_female_8_11: 0,
        other_crimes_male_12_15: 0,
        other_crimes_female_12_15: 0,
        other_crimes_male_16_18: 0,
        other_crimes_female_16_18: 0,
        other_crimes_cci_male: 0,
        other_crimes_cci_female: 0,

        total_male_0_3: 0,
        total_female_0_3: 0,
        total_male_4_7: 0,
        total_female_4_7: 0,
        total_male_8_11: 0,
        total_female_8_11: 0,
        total_male_12_15: 0,
        total_female_12_15: 0,
        total_male_16_18: 0,
        total_female_16_18: 0,
        total_cci_male: 0,
        total_cci_female: 0,
        month: Qmonth,
        year: Qyear,

    });

    useEffect(() => {
        if ((props || {}).isEdit) {
            const { id, created_at, updated_at, username, user, report_type, ...reportSorted } = props.report.find((res) => { return res.month === Qmonth && res.year === Qyear; }) || {};
            setdata({
                ...data,
                ...reportSorted
            });
        }
    }, []);
    const openForm1 = () => {
        if (props.isEdit) {
            return toast.promise(
                updateReport(data, UPDATE_VIOLENCE_AGAINST_CHILDREN),
                {
                    loading: 'Updating Record',
                    success: 'Record Updated',
                    error: 'Error while saving'
                }
            ).then(() => formUpdated(true));
        } else {
            props.close();
            onSubmit(data, "violence_against_children");
            setmodalIsOpen(false);
        }
    };
    const openForm = () => {
        props.close();
        setmodalIsOpen(false);
    };

    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <Toaster position="bottom-right" />
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {" "}
                            Details of violence against Children{" "}
                        </h5>
                        <button
                            className="btn-close"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    <h4>{DisplayQuarter} {Qyear}</h4>
                    <div className="modal-body" id="violence-form">
                        <table>
                            <tr>
                                <th rowSpan={3}>No.</th>
                                <th rowSpan={3}>Category</th>
                                <th colSpan={12}>Age</th>
                            </tr>
                            <tr>

                                <td colSpan={2}>0-3</td>
                                <td colSpan={2}>4-7</td>
                                <td colSpan={2}>8-11</td>
                                <td colSpan={2}>12-15</td>
                                <td colSpan={2}>16-18</td>
                                <td colSpan={2}>Children in CCIs</td>
                            </tr>
                            <tr>
                                <td>Male</td>
                                <td>Female</td>
                                <td>Male</td>
                                <td>Female</td>
                                <td>Male</td>
                                <td>Female</td>
                                <td>Male</td>
                                <td>Female</td>
                                <td>Male</td>
                                <td>Female</td>
                                <td>Male</td>
                                <td>Female</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Total Murder (Infanticide and Other)</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_murder_male_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_murder_male_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_murder_female_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_murder_female_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_murder_male_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_murder_male_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_murder_female_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_murder_female_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_murder_male_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_murder_male_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_murder_female_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_murder_female_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_murder_male_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_murder_male_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_murder_female_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_murder_female_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_murder_male_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_murder_male_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_murder_female_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_murder_female_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_murder_cci_male}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_murder_cci_male: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_murder_cci_female}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_murder_cci_female: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Section 4 & 6 of  PoCSO Act r/w Sec. 376 IPC</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.section_4_6_male_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                section_4_6_male_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.section_4_6_female_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                section_4_6_female_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.section_4_6_male_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                section_4_6_male_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.section_4_6_female_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                section_4_6_female_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.section_4_6_male_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                section_4_6_male_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.section_4_6_female_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                section_4_6_female_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.section_4_6_male_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                section_4_6_male_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.section_4_6_female_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                section_4_6_female_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.section_4_6_male_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                section_4_6_male_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.section_4_6_female_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                section_4_6_female_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.section_4_6_cci_male}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                section_4_6_cci_male: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.section_4_6_cci_female}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                section_4_6_cci_female: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Kidnapping and Abduction</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.kidnapping_male_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                kidnapping_male_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>

                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.kidnapping_female_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                kidnapping_female_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.kidnapping_male_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                kidnapping_male_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.kidnapping_female_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                kidnapping_female_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.kidnapping_male_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                kidnapping_male_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.kidnapping_female_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                kidnapping_female_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.kidnapping_male_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                kidnapping_male_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.kidnapping_female_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                kidnapping_female_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.kidnapping_male_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                kidnapping_male_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.kidnapping_female_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                kidnapping_female_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.kidnapping_cci_male}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                kidnapping_cci_male: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.kidnapping_cci_female}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                kidnapping_cci_female: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Foeticide</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.foeticide_male_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                foeticide_male_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.foeticide_female_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                foeticide_female_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.foeticide_male_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                foeticide_male_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.foeticide_female_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                foeticide_female_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.foeticide_male_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                foeticide_male_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.foeticide_female_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                foeticide_female_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.foeticide_male_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                foeticide_male_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.foeticide_female_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                foeticide_female_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.foeticide_male_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                foeticide_male_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.foeticide_female_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                foeticide_female_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.foeticide_cci_male}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                foeticide_cci_male: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.foeticide_cci_female}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                foeticide_cci_female: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Abetment of Suicide</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.abetment_male_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                abetment_male_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.abetment_female_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                abetment_female_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.abetment_male_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                abetment_male_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.abetment_female_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                abetment_female_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.abetment_male_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                abetment_male_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.abetment_female_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                abetment_female_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.abetment_male_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                abetment_male_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.abetment_female_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                abetment_female_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.abetment_male_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                abetment_male_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.abetment_female_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                abetment_female_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.abetment_cci_male}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                abetment_cci_male: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.abetment_cci_female}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                abetment_cci_female: e.target.value,
                                            })
                                        }
                                    />

                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Exposure and Abandonment</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.exposure_male_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                exposure_male_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.exposure_female_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                exposure_female_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.exposure_male_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                exposure_male_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.exposure_female_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                exposure_female_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.exposure_male_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                exposure_male_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.exposure_female_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                exposure_female_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.exposure_male_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                exposure_male_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.exposure_female_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                exposure_female_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.exposure_male_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                exposure_male_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.exposure_female_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                exposure_female_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.exposure_cci_male}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                exposure_cci_male: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.exposure_cci_female}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                exposure_cci_female: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Procuration of Minor Girls</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.procuration_male_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                procuration_male_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.procuration_female_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                procuration_female_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.procuration_male_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                procuration_male_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.procuration_female_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                procuration_female_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.procuration_male_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                procuration_male_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.procuration_female_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                procuration_female_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.procuration_male_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                procuration_male_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.procuration_female_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                procuration_female_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.procuration_male_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                procuration_male_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.procuration_female_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                procuration_female_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.procuration_cci_male}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                procuration_cci_male: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.procuration_cci_female}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                procuration_cci_female: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>Buying Girls for Prostitution</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.buying_girls_male_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                buying_girls_male_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.buying_girls_female_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                buying_girls_female_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.buying_girls_male_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                buying_girls_male_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.buying_girls_female_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                buying_girls_female_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.buying_girls_male_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                buying_girls_male_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.buying_girls_female_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                buying_girls_female_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.buying_girls_male_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                buying_girls_male_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.buying_girls_female_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                buying_girls_female_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.buying_girls_male_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                buying_girls_male_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.buying_girls_female_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                buying_girls_female_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.buying_girls_cci_male}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                buying_girls_cci_male: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.buying_girls_cci_female}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                buying_girls_cci_female: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>Selling Girls for Prostitution</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.selling_girls_male_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                selling_girls_male_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.selling_girls_female_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                selling_girls_female_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.selling_girls_male_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                selling_girls_male_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.selling_girls_female_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                selling_girls_female_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.selling_girls_male_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                selling_girls_male_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.selling_girls_female_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                selling_girls_female_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.selling_girls_male_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                selling_girls_male_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.selling_girls_female_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                selling_girls_female_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.selling_girls_male_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                selling_girls_male_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.selling_girls_female_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                selling_girls_female_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.selling_girls_cci_male}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                selling_girls_cci_male: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.selling_girls_cci_female}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                selling_girls_cci_female: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>Prohibition of Child marriage Act</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.prohibition_male_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                prohibition_male_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.prohibition_female_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                prohibition_female_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.prohibition_male_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                prohibition_male_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.prohibition_female_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                prohibition_female_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.prohibition_male_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                prohibition_male_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.prohibition_female_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                prohibition_female_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.prohibition_male_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                prohibition_male_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.prohibition_female_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                prohibition_female_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.prohibition_male_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                prohibition_male_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.prohibition_female_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                prohibition_female_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.prohibition_cci_male}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                prohibition_cci_male: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.prohibition_cci_female}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                prohibition_cci_female: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>Other Crimes against Children</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.other_crimes_male_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                other_crimes_male_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.other_crimes_female_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                other_crimes_female_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.other_crimes_male_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                other_crimes_male_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td><input
                                    type="number"
                                    min="1"
                                    value={data.other_crimes_female_4_7}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            other_crimes_female_4_7: e.target.value,
                                        })
                                    }
                                /></td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.other_crimes_male_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                other_crimes_male_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.other_crimes_female_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                other_crimes_female_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.other_crimes_male_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                other_crimes_male_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.other_crimes_female_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                other_crimes_female_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.other_crimes_male_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                other_crimes_male_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td><input
                                    type="number"
                                    min="1"
                                    value={data.other_crimes_female_16_18}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            other_crimes_female_16_18: e.target.value,
                                        })
                                    }
                                /></td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.other_crimes_cci_male}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                other_crimes_cci_male: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.other_crimes_cci_female}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                other_crimes_cci_female: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>Total</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_male_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_male_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_female_0_3}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_female_0_3: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_male_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_male_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_female_4_7}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_female_4_7: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_male_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_male_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_female_8_11}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_female_8_11: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td><input
                                    type="number"
                                    min="1"
                                    value={data.total_male_12_15}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            total_male_12_15: e.target.value,
                                        })
                                    }
                                /></td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_female_12_15}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_female_12_15: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_male_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_male_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_female_16_18}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_female_16_18: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_cci_male}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_cci_male: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={data.total_cci_female}
                                        onChange={(e) =>
                                            setdata({
                                                ...data,
                                                total_cci_female: e.target.value,
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        </table>
                        {/* <form>
              <div className="new-expense__control">
                <label>
                  {" "}
                  <b> Age </b>{" "}
                </label>
                <label> Male (Age between 0-3) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male_0_3}
                  onChange={(e) =>
                    setdata({ ...data, male_0_3: e.target.value })
                  }
                />
                <label> Female (Age between 0-3) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.female_0_3}
                  onChange={(e) =>
                    setdata({ ...data, female_0_3: e.target.value })
                  }
                />
                <label> Male (Age between 4-7) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male_4_7}
                  onChange={(e) =>
                    setdata({ ...data, male_4_7: e.target.value })
                  }
                />
                <label> Female (Age between 4-7) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.female_4_7}
                  onChange={(e) =>
                    setdata({ ...data, female_4_7: e.target.value })
                  }
                />
                <label> Male (Age between 8-11) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male_8_11}
                  onChange={(e) =>
                    setdata({ ...data, male_8_11: e.target.value })
                  }
                />
                <label> Female (Age between 8-11) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.female_8_11}
                  onChange={(e) =>
                    setdata({ ...data, female_8_11: e.target.value })
                  }
                />
                <label> Male (Age between 12-15) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male_12_15}
                  onChange={(e) =>
                    setdata({ ...data, male_12_15: e.target.value })
                  }
                />
                <label> Female (Age between 12-15) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.female_12_15}
                  onChange={(e) =>
                    setdata({ ...data, female_12_15: e.target.value })
                  }
                />
                <label> Male (Age between 16-18) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.male_16_18}
                  onChange={(e) =>
                    setdata({ ...data, male_16_18: e.target.value })
                  }
                />
                <label> Female (Age between 16-18) </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.female_16_18}
                  onChange={(e) =>
                    setdata({ ...data, female_16_18: e.target.value })
                  }
                />
              </div>

              <div className="new-expense__control">
                <label>
                  {" "}
                  <b> Children in CCIs </b>{" "}
                </label>
                <label> Male </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.cci_male}
                  onChange={(e) =>
                    setdata({ ...data, cci_male: e.target.value })
                  }
                />
                <label> Female </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.cci_female}
                  onChange={(e) =>
                    setdata({ ...data, cci_female: e.target.value })
                  }
                />
              </div>
            </form> */}
                    </div>

                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={openForm}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={openForm1}
                            type="button"
                        >
                            Save & Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Form11;
