import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";

// FORM 25 PARENTING CLINIC REPORTING FORMAT

function AReport26(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { downloadExcel }] = useFormStore();
    const Qyear = props.Qyear;
    const Qmonth = props.Qmonth;
    const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
    const DisplayQuarter = Quarter[Qmonth];


    const openForm1 = () => {
        setmodalIsOpen(false);
        props.close();
    };
    const values = Array.isArray(props.report) ? props.report : []
    // sum_array = total sum data of district
    // const sum_array = values.reduce((sum_array, value) => {
    //   for (const [key, count] of Object.entries(value)) {
    //     if (!sum_array[key]) {
    //       sum_array[key] = 0;
    //     }
    //     sum_array[key] += count;
    //   }
    //   return sum_array;
    // }, {});
    const sum_array = values.reduce((sum_array, value) => {
        Object.entries(value).forEach(([key, count]) => {
            sum_array[key] = (sum_array[key] || 0) + count;
        });
        return sum_array;
    }, {});

    function createPDF() {
        var sTable = document.getElementById('table').innerHTML;
        var style = "<style>";
        style = style + "table {width: 100%;font: 10px Calibri;}";
        style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "</style>";
        var win = window.open('', '', 'height=700,width=700');
        win.document.write('<html><head>');
        win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
        win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
        win.document.write('</body></html>');
        win.document.close(); 	// CLOSE THE CURRENT WINDOW.
        win.print();    // PRINT THE CONTENTS.
    }
    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                        {" "}
                        NCPCR{" "}
                    </h5>
                    <button
                        className="btn-close"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <h4>{DisplayQuarter} {Qyear}</h4>
                <div className="modal-body">
                    <div class="table-responsive" id="table" height="100%" width="100%" media="print" style={{ position: 'absolute' }}>
                        <table class="table" id="ncpcr">
                            <tr>
                                <th>Category Code</th>
                                <th>S. No.</th>
                                <th colSpan={2}>Details</th>
                                <th colSpan={12}></th>
                            </tr>
                            <tr>
                                <td colSpan={4}></td>
                                <td >State</td>
                                <td>districts </td>
                            </tr>


                            {props.report.map((r) => (
                                <>
                                    <tr>


                                        <td colSpan={6} className="extra">
                                            {" "}
                                            Information regarding Juvenile Justice (Care and Protection of
                                            Children) Act
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={10}>SJJ1WCD</td>
                                        <td>1</td>
                                        <td colSpan={2}>
                                            Whether State Child Protection on Society set up u/s 106 of JJ
                                            Act, 2015. If yes, Copy of notification
                                        </td>
                                        <td>--</td>
                                        <td>
                                            {
                                                r?.state_child_protection_on_society_setup_u_s_106_jj_act_2015
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td colSpan={2}>
                                            District Child Protection Unit u/s 106 of JJ Act, 2015
                                        </td>
                                        <td>
                                            --
                                        </td>
                                        <td>
                                            {r?.district_child_protection_unit_u_s_106_jj_act_2015}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td colSpan={2}>
                                            No. of SJPU & No. of Child Welfare Police Officers in District
                                            u/s 107 of JJ Act, 2015
                                        </td>


                                        <td>
                                            {
                                                sum_array.sjpu_child_welfare_police_in_district_u_s_107_jj_act_2015
                                            }
                                        </td>
                                        <td>
                                            {
                                                r?.sjpu_child_welfare_police_in_district_u_s_107_jj_act_2015
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td colSpan={2}>
                                            Total No. of JJBs in the District (u/s 4 of the JJ Act 2015)
                                        </td>

                                        <td>{sum_array.jjbs_in_the_district_u_s_4_of_the_jj_act_2015}</td>
                                        <td>{r?.jjbs_in_the_district_u_s_4_of_the_jj_act_2015}</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td colSpan={2}>
                                            Total No. of Children Courts u/s 25 of the CPCR Act, 2005
                                        </td>

                                        <td>{sum_array.children_courts_u_s_25_cpcr_act_2005}</td>
                                        <td>{r?.children_courts_u_s_25_cpcr_act_2005}</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td colSpan={2}>
                                            Total No. of CWCs in the District (u/s 27 of the JJ Act 2015)
                                        </td>

                                        <td>{sum_array.cwcs_in_the_district_u_s_27_of_the_jj_act_2015}</td>
                                        <td>{r?.cwcs_in_the_district_u_s_27_of_the_jj_act_2015}</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td colSpan={2}>
                                            Total No. of Reg. CCIs u/s 41 of JJ Act, 2015 (Run by Govt.)
                                            18(a)
                                        </td>
                                        <td>{sum_array.reg_ccis_u_s_41_jj_act_2015_run_by_govt_18_a}</td>
                                        <td>{r?.reg_ccis_u_s_41_jj_act_2015_run_by_govt_18_a}</td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td colSpan={2}>
                                            Total No. of Reg. CCIs u/s 41 of JJ Act, 2015 (Run by NGOs)
                                            18(b)
                                        </td>

                                        <td>{sum_array.reg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_b}</td>
                                        <td>{r?.reg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_b}</td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td colSpan={2}>
                                            Total No. of Unreg. CCIs u/s 41 of JJ Act, 2015 (Run by Govt.)
                                            18(c )
                                        </td>

                                        <td>{sum_array.unreg_ccis_u_s_41_jj_act_2015_run_by_govt_18_c}</td>
                                        <td>{r?.unreg_ccis_u_s_41_jj_act_2015_run_by_govt_18_c}</td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td colSpan={2}>
                                            Total No. of Unreg. CCIs u/s 41 of JJ Act, 2015 (Run by NGOs)
                                            18(d)
                                        </td>
                                        <td>{sum_array.unreg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_d}</td>
                                        <td>{r?.unreg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_d}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="extra"></td>
                                        <td className="extra"></td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={7}>SJJ2WCD</td>
                                        <td>1</td>
                                        <td colSpan={2}>
                                            Total No. of persons penalized for non-registration of CCI u/s
                                            42
                                        </td>

                                        <td>
                                            {sum_array.persons_penalized_for_non_registration_of_cci_u_s_42}
                                        </td>
                                        <td>
                                            {r?.persons_penalized_for_non_registration_of_cci_u_s_42}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td colSpan={2}>
                                            Total No. of open shelter Shelter set up U/s 43 of the JJ Act,
                                            2015
                                        </td>

                                        <td>
                                            {sum_array.open_shelter_shelter_set_up_u_s_43_of_the_jj_act_2015}
                                        </td>
                                        <td>
                                            {r?.open_shelter_shelter_set_up_u_s_43_of_the_jj_act_2015}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td colSpan={2}>
                                            Total capacity of open shelter Shelter U/s 43 of the JJ Act,
                                            2015
                                        </td>

                                        <td>{sum_array.open_shelter_shelter_u_s_43_of_the_jj_act_2015}</td>
                                        <td>{r?.open_shelter_shelter_u_s_43_of_the_jj_act_2015}</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td colSpan={2}>
                                            Total No. of children in Open Shelter U/s 43 of the JJ Act,
                                            2015
                                        </td>
                                        <td>
                                            {sum_array.children_in_open_shelter_u_s_43_of_the_jj_act_2015}
                                        </td>
                                        <td>
                                            {r?.children_in_open_shelter_u_s_43_of_the_jj_act_2015}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td colSpan={2}>
                                            Total no. of Observation Homes set up U/s 47 of the JJ Act,
                                            2015
                                        </td>
                                        <td>
                                            {sum_array.observation_homes_set_up_u_s_47_of_the_jj_Act_2015}
                                        </td>
                                        <td>
                                            {r?.observation_homes_set_up_u_s_47_of_the_jj_Act_2015}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td colSpan={2}>
                                            Total capacity of Observation Homes U/s 43 of the JJ Act, 2015
                                        </td>
                                        <td>{sum_array.observation_homes_u_s_43_of_the_jj_act_2015}</td>
                                        <td>{r?.observation_homes_u_s_43_of_the_jj_act_2015}</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td colSpan={2}>
                                            Total Children in Home who are staying for more than 3 months
                                        </td>

                                        <td>
                                            {sum_array.children_in_home_who_are_staying_more_than_3_months}
                                        </td>
                                        <td>
                                            {r?.children_in_home_who_are_staying_more_than_3_months}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="extra"></td>
                                        <td className="extra"></td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={9}>SJJ3WCD</td>
                                        <td>1</td>
                                        <td colSpan={2}>
                                            Total No. of Special Home set up u/s 48 of the JJ Act, 2015
                                        </td>

                                        <td>{sum_array.special_home_set_up_u_s_48_of_the_jj_act_2015}</td>
                                        <td>{r?.special_home_set_up_u_s_48_of_the_jj_act_2015}</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td colSpan={2}>
                                            Total capacity of Special Home u/s 48 of the JJ Act, 2015
                                        </td>

                                        <td>{sum_array.special_home_u_s_48_of_the_jj_act_2015}</td>
                                        <td>{r?.special_home_u_s_48_of_the_jj_act_2015}</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td colSpan={2}>
                                            Total No. of children in Special Home u/s 48 of the JJ Act,
                                            2015
                                        </td>

                                        <td>
                                            {sum_array.children_in_special_home_u_s_48_of_the_jj_act_2015}
                                        </td>
                                        <td>
                                            {r?.children_in_special_home_u_s_48_of_the_jj_act_2015}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td colSpan={2}>
                                            Total No. of place of safety u/s 49 of the JJ Act, 2015{" "}
                                        </td>
                                        <td>{sum_array.place_of_safety_u_s_49_of_the_jj_act_2015}</td>
                                        <td>{r?.place_of_safety_u_s_49_of_the_jj_act_2015}</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td colSpan={2}>Total Capacity of Place of Safety</td>
                                        <td>{sum_array.capacity_of_place_of_safety}</td>
                                        <td>{r?.capacity_of_place_of_safety}</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td colSpan={2}>Total No. of children in Place of Safety</td>
                                        <td>{sum_array.children_in_place_of_safety}</td>
                                        <td>{r?.children_in_place_of_safety}</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td colSpan={2}>
                                            Total No. of children home set up u/s 50 of the JJ Act, 2015
                                        </td>
                                        <td>{sum_array.children_home_set_up_u_s_50_of_the_jj_act_2015}</td>
                                        <td>{r?.children_home_set_up_u_s_50_of_the_jj_act_2015}</td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td colSpan={2}>
                                            Total No. of Foster Care recognized u/s 44 of the JJ Act 2015
                                        </td>
                                        <td>{sum_array.foster_care_recognized_u_s_44_of_the_jj_act_2015}</td>
                                        <td>
                                            {r?.foster_care_recognized_u_s_44_of_the_jj_act_2015}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td colSpan={2}>
                                            Total No. of Fit facility recognized u/s 51 of the Act 2015
                                        </td>
                                        <td>{sum_array.fit_facility_recognized_u_s_51_of_the_act_2015}</td>
                                        <td>{r?.fit_facility_recognized_u_s_51_of_the_act_2015}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="extra"></td>
                                        <td className="extra"></td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={6}>SJJ4WCD</td>
                                        <td>1</td>
                                        <td colSpan={2}>
                                            Total No. of Fit Person recognized u / s 52 of JJ Act 2015
                                        </td>
                                        <td>{sum_array.fit_person_recognized_u_s_52_of_jj_act_2015}</td>
                                        <td>{r?.fit_person_recognized_u_s_52_of_jj_act_2015}</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td colSpan={2}>
                                            Total No. of Specialised Adoption Agency Organised u/s 65 of
                                            JJ Act, 2015
                                        </td>
                                        <td>
                                            {
                                                sum_array.specialised_adoption_agency_organised_u_s_65_of_jj_act_2015
                                            }

                                        </td>
                                        <td>
                                            {
                                                r?.specialised_adoption_agency_organised_u_s_65_of_jj_act_2015
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td colSpan={2}>
                                            Appointment of Inspection Committee by State u / s 54 of the
                                            JJ Act 2015
                                        </td>
                                        <td>
                                            --
                                        </td>
                                        <td>
                                            {r?.inspection_committee_by_state_u_s_54_of_jj_act_2015}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td colSpan={2}>
                                            Whether Juvenile Justice Fund has been created u / s 105 of
                                            the JJ Act 2015
                                        </td>
                                        <td>
                                            --
                                        </td>
                                        <td>
                                            {
                                                r?.juvenile_justice_fund_has_been_created_u_s_105_of_jj_act_2015
                                            }
                                        </td>
                                    </tr>
                                    <td>5</td>
                                    <td colSpan={2}>
                                        No. of inspections conducted by inspection committee to all
                                        housing facilities of children u / s 54(2) of the JJ Act 15?
                                    </td><td>{sum_array.all_housing_facilities_of_children_u_s_54_2_of_jj_act_2015}</td>
                                    <td>
                                        {
                                            r?.all_housing_facilities_of_children_u_s_54_2_of_jj_act_2015
                                        }
                                    </td>
                                    <tr>
                                        <td>6</td>
                                        <td colSpan={2}>
                                            Whether the State Govt evaluated the functioning of JJB CWC
                                            SJPU regd. Or recognized institutions u / s 55(1) of JJ Act
                                            2015?{" "}
                                        </td>
                                        <td>--</td>
                                        <td>
                                            {
                                                r?.jjb_cwc_sjpu_regd_institutions_u_s_55_1_of_jj_act_2015
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="extra">
                                            MIS on JJB
                                        </td>
                                        <td className="extra"></td>
                                    </tr>
                                    <td rowSpan={12}>SJJ5WCD</td>
                                    <td>1</td>
                                    <td colSpan={2}>
                                        Total No. of JJBs in the District u/s 4 of JJ Act, 2015
                                    </td><td>{sum_array.jjb_in_the_district_u_s_4_of_jj_act_2015}</td>
                                    <td>
                                        {
                                            r?.jjb_in_the_district_u_s_4_of_jj_act_2015
                                        }
                                    </td>
                                    <tr>
                                        <td>2</td>
                                        <td colSpan={2}>
                                            Whether induction training & sensitisation to JJB has been
                                            provided within 60 days from the dates of appointment u/s 4(5)
                                            of JJ Act, 2015 Yes/No if no reason thereof
                                        </td><td>--</td>
                                        <td>
                                            {
                                                r?.induction_training_sensitisation_to_jjb_has_been_provided
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td colSpan={2}>
                                            Whether pendency for JJB is quarterly reviewed by CJM/CMM
                                            yes/No if no reason thereof u/s 16(1) of JJ Act, 2015
                                        </td><td>--</td>
                                        <td>
                                            {
                                                r?.jjb_in_the_district_u_s_4_of_jj_act_2015
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td colSpan={2}>
                                            Whether pendency of the board is reviewed by the High Level
                                            Committee as per sec. 16(2) of JJ Act, 2015 if no reason
                                            thereof
                                        </td><td>Null</td>
                                        <td>
                                            {
                                                r?.cjm_cmm_yes_no_if_no_reason_thereof_u_s_16_1_of_jj_act_2015
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td colSpan={2}>
                                            No. of children in Observation Home in case of petty offences
                                        </td><td>{sum_array.children_in_observation_home_in_case_of_petty_offences}</td>
                                        <td>
                                            {r?.children_in_observation_home_in_case_of_petty_offences}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td colSpan={2}>
                                            No. of Children staying in Observation Home for more than 3
                                            months in case of petty offence{" "}
                                        </td><td>{sum_array.children_staying_in_observation_home_for_more_than_3_months}</td>
                                        <td>
                                            {
                                                r?.children_staying_in_observation_home_for_more_than_3_months
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td colSpan={2}>
                                            No. of run away children (Home-wise) U / s 26(2)
                                        </td><td>{sum_array.run_away_children_home_wise_u_s_26_2}</td>
                                        <td>{r?.run_away_children_home_wise_u_s_26_2}</td>

                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td colSpan={2}>
                                            No. of Homes where (CNCP) Child in need of care and protection
                                            & Child in conflict with Law(CCL) are kept together
                                        </td><td>{sum_array.homes_where_cncp_child_in_need_of_care}</td>
                                        <td>{r?.homes_where_cncp_child_in_need_of_care}</td>

                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td colSpan={2}>
                                            Total No. of cases presented before the Session Court where
                                            the Court felt or the persons claims himself / herself to be a
                                            minor?
                                        </td><td>{sum_array.cases_presented_before_the_session_court_where_the_court_felt}</td>
                                        <td>
                                            {
                                                r?.cases_presented_before_the_session_court_where_the_court_felt
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td colSpan={2}>
                                            Total No. of children residing in the Special Home for more
                                            than 3 yrs
                                        </td><td>{sum_array.children_residing_in_the_special_home_for_more_than_3_yrs}</td>
                                        <td>
                                            {
                                                r?.children_residing_in_the_special_home_for_more_than_3_yrs
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>11</td>
                                        <td colSpan={2}>
                                            Total No. of & Child in conflict with Law (CCL) placed in
                                            place of safety
                                        </td><td>{sum_array.child_in_conflict_with_law_ccl_placed_in_place_of_safety}</td>
                                        <td>
                                            {
                                                r?.child_in_conflict_with_law_ccl_placed_in_place_of_safety
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>12</td>
                                        <td colSpan={2}>
                                            Whether Reformative services like education skill development
                                            vocational training counselling behaviour modification therapy
                                            and psychiatric support have been provided to each and every
                                            child during their stay in the Special Home{" "}
                                        </td><td>--</td>
                                        <td>
                                            {
                                                r?.reformative_services_like_education_skill_development_training
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="extra">
                                            MIS on JJB
                                        </td>
                                        <td className="extra"></td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={13}>SJJ6WCD</td>
                                        <td>1</td>
                                        <td colSpan={2}>No. of sittings in a week by JJB</td>
                                        <td>
                                            {sum_array.sittings_in_a_week_by_jjb}
                                        </td>
                                        <td>{r?.sittings_in_a_week_by_jjb}</td>

                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td colSpan={2}>
                                            Whether sittings of JJB are held in the premises of Home Yes /
                                            No if not reasons thereof
                                        </td><td>--</td>
                                        <td>{r?.sittings_of_jjb_are_held_in_the_premises_of_home}</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td colSpan={2}>
                                            Date of appointment of the Board (Dist-wise)
                                        </td><td>{sum_array.date_of_appointment_of_the_board_dist_wise}</td>
                                        <td>{r?.date_of_appointment_of_the_board_dist_wise}</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td colSpan={2}>
                                            Date of Superannuation of the Board (Dist-wise)
                                        </td><td>{sum_array.date_of_superannuation_of_the_board_dist_wise}</td>
                                        <td>{r?.date_of_superannuation_of_the_board_dist_wise}</td>

                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td colSpan={2}>
                                            Whether Principal Magistrate of JJB is exclusive or given
                                            additional charge of the Board Yes / No & No. accordingly
                                        </td><td>--</td>
                                        <td>
                                            {
                                                r?.magistrate_of_jjb_is_exclusive_or_additional_charge_board
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td colSpan={2}>
                                            Whether JJB conducting monthly visit/inspection of residential
                                            facilities off CCL u/s 8(3)(j) of JJ Act, 2015 Yes/No if no
                                            reason thereof
                                        </td><td>--</td>
                                        <td>{r?.monthly_visit_of_residential_facilities_of_ccl}</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td colSpan={2}>
                                            No. of cases where JJB has ordered registration of FIR for
                                            offences committed against any where (CNCP) Child in need of
                                            care and protection & Child in conflict with Law(CCL) u/s
                                            8(3)(K)(l) of JJ Act, 2015 *
                                        </td>
                                        <td>
                                            {sum_array.jjb_has_ordered_registration_for_offences_committed_child}
                                        </td>
                                        <td>
                                            {
                                                r?.jjb_has_ordered_registration_for_offences_committed_child
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td colSpan={2}>
                                            Whether regular inspections of adult jails is being conducted
                                            by JJB u/s 8(3)(m) of JJ Act, 2015 yes/no if no reason
                                            thereof
                                        </td><td>
                                            --
                                        </td>
                                        <td>
                                            {r?.regular_inspections_of_adult_jails_is_being_conducted}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td colSpan={2}>
                                            Whether any Child below 18 yrs was found to be confined in the
                                            Adult jail during inspection Yes / No if yes details thereof
                                        </td><td>
                                            --
                                        </td>
                                        <td>
                                            {
                                                r?.child_below_18_yrs_was_found_to_be_confined_in_the_adult_jail
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td colSpan={2}>
                                            Whether such children were transferred to Observation Home
                                            yes/no, if no reason thereof u/s 8(3)(m) of JJ Act, 2015
                                        </td><td>--</td>
                                        <td>{r?.children_were_transferred_to_observation_home}</td>
                                    </tr>
                                    <tr>
                                        <td>11</td>
                                        <td colSpan={2}>
                                            No. of cases where legal aid provided to CCL (Child Conflict
                                            with Law)
                                        </td><td>{sum_array.cases_where_legal_aid_provided_to_ccl}</td>
                                        <td>{r?.cases_where_legal_aid_provided_to_ccl}</td>
                                    </tr>
                                    <tr>
                                        <td>12</td>
                                        <td colSpan={2}>
                                            No. of cases where there is no Advocate to represent CCL
                                        </td>
                                        <td>{sum_array.cases_where_there_is_no_dvocate_to_represent_ccl}</td>
                                        <td>{r?.cases_where_there_is_no_dvocate_to_represent_ccl}</td>

                                    </tr>
                                    <tr>
                                        <td>13</td>
                                        <td colSpan={2}>
                                            Whether facility of interpreter or translators available is
                                            with the JJB U / s (3)(d) Yes / No
                                        </td><td>--</td>
                                        <td>{r?.facility_of_interpreter_or_translators_available}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="extra">
                                            MIS on JJB
                                        </td>
                                        <td className="extra"></td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={15}>SJJ7WCD</td>
                                        <td>1</td>
                                        <td colSpan={2}>Total No. of cases with JJB</td>
                                        <td>{sum_array.cases_with_jjb}</td>
                                        <td>{r?.cases_with_jjb}</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td colSpan={2}>No. of pending cases</td>
                                        <td>{sum_array.no_of_pending_cases}</td>
                                        <td>{r?.no_of_pending_cases}</td>
                                    </tr>
                                    <tr></tr>
                                    <tr>
                                        <td>3</td>
                                        <td colSpan={2}>Reason of pendency, please specify</td>

                                        <td>--</td>
                                        <td>{r?.reason_of_pendency}</td>

                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td colSpan={2}>No. of cases disposed off</td>

                                        <td>{sum_array.cases_disposed_off}</td>
                                        <td>{r?.cases_disposed_off}</td>

                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td colSpan={2}>
                                            Whether pendency is being reported to DM u/s 16(3) of JJ Act,
                                            2015 Yes/no, if no reason thereof
                                        </td>

                                        <td>
                                            --
                                        </td>
                                        <td>
                                            {
                                                r?.pendency_is_being_reported_to_dm_u_s_16_3_of_jj_act_2015
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td colSpan={2}>
                                            No. of cases where bail is granted to CCL u/s 12 of JJ Act,
                                            2015
                                        </td>

                                        <td>
                                            {
                                                sum_array.cases_where_bail_is_granted_to_ccl_u_s_12_of_jj_act_2015
                                            }
                                        </td>
                                        <td>
                                            {
                                                r?.cases_where_bail_is_granted_to_ccl_u_s_12_of_jj_act_2015
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td colSpan={2}>
                                            Whether inquiry in case by board is completed within time u/s
                                            14(2) of JJ Act, 2015 yes/no if no, reason thereof
                                        </td>

                                        <td>
                                            --
                                        </td>
                                        <td>
                                            {
                                                r?.inquiry_in_case_by_board_is_completed_within_time_u_s_14_2
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td colSpan={2}>
                                            Whether any case of ill treatment by the police towards the
                                            child has been reported before the JJB? If yes, action taken
                                            against the police official
                                        </td>

                                        <td>
                                            --
                                        </td>
                                        <td>
                                            {r?.case_of_ill_treatment_by_the_police_towards_the_child}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td colSpan={2}>
                                            No. of cases which are pending due to want of bail bond U/s 12
                                        </td>

                                        <td>
                                            {sum_array.cases_which_are_pending_due_to_want_of_bail_bond_u_s_12}
                                        </td>
                                        <td>
                                            {r?.cases_which_are_pending_due_to_want_of_bail_bond_u_s_12}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td colSpan={2}>
                                            Whether preliminary assessment in heinous offences is
                                            completed within time u/s 14(3) i.e 3 months
                                        </td>

                                        <td>
                                            --
                                        </td>
                                        <td>
                                            {r?.preliminary_assessment_in_heinous_offences_is_completed}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>11</td>
                                        <td colSpan={2}>
                                            No. of Cases of Heinous Offences pending with Board
                                        </td>

                                        <td>{sum_array.Cases_of_heinous_offences_pending_with_board}</td>
                                        <td>{r?.Cases_of_heinous_offences_pending_with_board}</td>
                                    </tr>
                                    <tr>
                                        <td>12</td>
                                        <td colSpan={2}>
                                            No. of Cases of Serious Offences pending with Board
                                        </td>

                                        <td>{sum_array.Cases_of_Serious_offences_pending_with_board}</td>
                                        <td>{r?.Cases_of_Serious_offences_pending_with_board}</td>
                                    </tr>
                                    <tr>
                                        <td>13</td>
                                        <td colSpan={2}>
                                            No. of Cases of Petty Offences pending with Board
                                        </td>

                                        <td>{sum_array.Cases_of_Petty_Offences_pending_with_board}</td>
                                        <td>{r?.Cases_of_Petty_Offences_pending_with_board}</td>
                                    </tr>
                                    <tr>
                                        <td>14</td>
                                        <td colSpan={2}>
                                            No. of cases where CCL are tried with Adults
                                        </td>

                                        <td>{sum_array.cases_where_ccl_are_tried_with_adults}</td>
                                        <td>{r?.cases_where_ccl_are_tried_with_adults}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="extra">
                                            MIS on JJB
                                        </td>
                                        <td className="extra"></td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={10}>SJJ8WCD</td>
                                        <td>1</td>
                                        <td colSpan={2}>
                                            No. of children produced before JJB after recovery. u / s
                                            26(2) of JJ Act, 2015
                                        </td>

                                        <td>{sum_array.children_produced_before_jjb_after_recovery}</td>
                                        <td>{r?.children_produced_before_jjb_after_recovery}</td>

                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td colSpan={2}>
                                            No. of cases where child is not found to be in conflict with
                                            law u / s 17 of JJ Act, 2015
                                        </td>

                                        <td>
                                            {
                                                sum_array.cases_where_child_is_not_found_to_be_in_conflict_with_law
                                            }
                                        </td>
                                        <td>
                                            {
                                                r?.cases_where_child_is_not_found_to_be_in_conflict_with_law
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td colSpan={2}>
                                            No. of children who are found to be in CCL u/s 18 of JJ Act,
                                            2015
                                        </td>

                                        <td>
                                            {
                                                sum_array.children_who_are_found_to_be_in_ccl_u_s_18_of_jjb_act_2015
                                            }
                                        </td>
                                        <td>
                                            {
                                                r?.children_who_are_found_to_be_in_ccl_u_s_18_of_jjb_act_2015
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td colSpan={2}>
                                            No. of cases where CCL was found CNCP & transferred to CWC
                                        </td>

                                        <td>
                                            {sum_array.cases_where_ccl_was_found_cncp_transferred_to_cwc}
                                        </td>
                                        <td>
                                            {r?.cases_where_ccl_was_found_cncp_transferred_to_cwc}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td colSpan={2}>
                                            Total No. of bail applications received in the JJB
                                        </td>

                                        <td>{sum_array.bail_applications_received_in_the_jjb}</td>
                                        <td>{r?.bail_applications_received_in_the_jjb}</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td colSpan={2}>Total no. of bails been granted by JJB</td>

                                        <td>{sum_array.bails_been_granted_by_jjb}</td>
                                        <td>{r?.bails_been_granted_by_jjb}</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td colSpan={2}>
                                            Total No. of children been transferred from JJB to the Court
                                        </td>

                                        <td>{sum_array.children_been_transferred_from_jjb_to_the_court}</td>
                                        <td>{r?.children_been_transferred_from_jjb_to_the_court}</td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td colSpan={2}>
                                            No. of cases in which inquiry completed within 4 months
                                        </td>

                                        <td>{sum_array.cases_in_which_inquiry_completed_within_4_months}</td>
                                        <td>{r?.cases_in_which_inquiry_completed_within_4_months}</td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td colSpan={2}>
                                            Total No. of cases of petty offences disposed of due to time
                                        </td>

                                        <td>{sum_array.cases_of_petty_offences_disposed_of_due_to_time}</td>
                                        <td>{r?.cases_of_petty_offences_disposed_of_due_to_time}</td>

                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td colSpan={2}>
                                            Total No. of children released on probation of good conduct
                                        </td>

                                        <td>{sum_array.children_released_on_probation_of_good_conduct}</td>
                                        <td>{r?.children_released_on_probation_of_good_conduct}</td>
                                    </tr>

                                    <tr>
                                        <td colSpan={5} className="extra">
                                            Information regarding The Prohibition of Child Marriage Act
                                        </td>
                                        <td className="extra"></td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={11}>SPCMA1WCD</td>
                                        <td>1</td>
                                        <td colSpan={2}>No. of Child Marriages reported to Police</td>

                                        <td>{sum_array.child_marriages_reported_to_police}</td>
                                        <td>{r?.child_marriages_reported_to_police}</td>

                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td colSpan={2}>
                                            No. of Child Marriages reported to Child Marriage Prohibition
                                            Officers{" "}
                                        </td>

                                        <td>
                                            {
                                                sum_array.child_marriages_reported_to_child_marriage_prohibition_officers
                                            }
                                        </td>
                                        <td>
                                            {
                                                r?.child_marriages_reported_to_child_marriage_prohibition_officers
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td colSpan={2}>
                                            No. of Child Marriages reported to First Class Judicial
                                            Magistrate or Metropolitan Magistrate
                                        </td>

                                        <td>
                                            {
                                                sum_array.child_marriages_reported_to_first_class_judicial_magistrate
                                            }
                                        </td>
                                        <td>
                                            {
                                                r?.child_marriages_reported_to_first_class_judicial_magistrate
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td colSpan={2}>
                                            No. of Child Marriages reported to Child Welfare Committee
                                        </td>

                                        <td>
                                            {sum_array.child_marriages_reported_to_child_welfare_committee}
                                        </td>
                                        <td>
                                            {r?.child_marriages_reported_to_child_welfare_committee}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td colSpan={2}>
                                            No. of Child Marriages reported to District Magistrate
                                        </td>

                                        <td>{sum_array.child_marriages_reported_to_district_magistrate}</td>
                                        <td>{r?.child_marriages_reported_to_district_magistrate}</td>

                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td colSpan={2}>No. of CMPOs appointed U / s 6 of the Act</td>

                                        <td>{sum_array.cmpos_appointed_u_s_6_of_the_act}</td>
                                        <td>{r?.cmpos_appointed_u_s_6_of_the_act}</td>

                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td colSpan={2}>
                                            No. of Child Marriages prevented by DM u / s 13(5) of the Act
                                        </td>

                                        <td>{sum_array.child_marriages_prevented_by_dm_u_s_13_5_of_the}</td>
                                        <td>{r?.child_marriages_prevented_by_dm_u_s_13_5_of_the}</td>

                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td colSpan={2}>
                                            No. of Child Marriages prevented by CMPOs Under section 16 (3)
                                            (a) of the Act
                                        </td>

                                        <td>{sum_array.child_marriages_prevented_by_cmpos_under_section}</td>
                                        <td>{r?.child_marriages_prevented_by_cmpos_under_section}</td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td colSpan={2}>
                                            No. of Mass Child Marriages prevented by Under section 16 (3)
                                            (a) of the Act
                                        </td>

                                        <td>{sum_array.mass_child_marriages_prevented_by_under_section}</td>
                                        <td>{r?.mass_child_marriages_prevented_by_under_section}</td>

                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td colSpan={2}>
                                            No. of Awareness Programmes conducted Under section 16(3)(d)
                                        </td>

                                        <td>
                                            {sum_array.awareness_programmes_conducted_under_section_16_3_d}
                                        </td>
                                        <td>
                                            {r?.awareness_programmes_conducted_under_section_16_3_d}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>11</td>
                                        <td colSpan={2}>
                                            No. of Sensitization Programmes conducted Under section 16(3)e
                                        </td>

                                        <td>
                                            {sum_array.sensitization_programmes_conducted_under_section_16_3_e}
                                        </td>
                                        <td>
                                            {r?.sensitization_programmes_conducted_under_section_16_3_e}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} className="extra">
                                            Information regarding Protection of Children from Sexual
                                            Offences (POCSO) Act{" "}
                                        </td>
                                        <td className="extra"></td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={17}>SPOCSO1WCD</td>
                                        <td rowSpan={1}>1</td>
                                        <td colSpan={1} rowSpan={6}>
                                            Infrastructure facilities in Special Courts u/s 28 POCSO Act
                                            2012
                                        </td>
                                        <td>No of Districts</td>

                                        <td>{sum_array.no_of_districts}</td>
                                        <td>{r?.no_of_districts}</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={1}>2</td>
                                        <td>No of Special Courts established u/s 28 of POCSO Act</td>

                                        <td>{sum_array.special_courts_established_u_s_28_of_pocso_act}</td>
                                        <td>{r?.special_courts_established_u_s_28_of_pocso_act}</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={1}>3</td>
                                        <td>
                                            No of Special Courts dealing exclusively with POCSO cases
                                        </td>

                                        <td>
                                            {sum_array.special_courts_dealing_exclusively_with_pocso_cases}
                                        </td>
                                        <td>
                                            {r?.special_courts_dealing_exclusively_with_pocso_cases}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={1}>4</td>
                                        <td>
                                            No of Special Courts designated with additional charge other
                                            than POCSO cases
                                        </td>

                                        <td>{sum_array.special_courts_designated_with_additional_charge}</td>
                                        <td>{r?.special_courts_designated_with_additional_charge}</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={1}>5</td>
                                        <td>
                                            No of Special courts equipped for following provisions u/s 36
                                            POCSO Act 2012 (for video conferencing)
                                        </td>

                                        <td>{sum_array.special_courts_equipped_for_following_provisions}</td>
                                        <td>{r?.special_courts_equipped_for_following_provisions}</td>

                                    </tr>
                                    <tr>
                                        <td rowSpan={1}>6</td>
                                        <td>
                                            No of Special courts having availability of interpreter or
                                            expert u/s 38 POCSO Act 2012
                                        </td>

                                        <td>
                                            {sum_array.special_courts_having_availability_of_interpreter}
                                        </td>
                                        <td>
                                            {r?.special_courts_having_availability_of_interpreter}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={1}>7</td>
                                        <td colSpan={1} rowSpan={3}>
                                            Special Public Prosecutors u/s 32 POCSO Act 2012
                                        </td>
                                        <td>
                                            No of Special Public Prosecutors appointed u/s 32 of POCSO Act
                                        </td>

                                        <td>
                                            {
                                                sum_array.special_public_prosecutors_appointed_u_s_32_of_pocso_act
                                            }
                                        </td>
                                        <td>
                                            {
                                                r?.special_public_prosecutors_appointed_u_s_32_of_pocso_act
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={1}>8</td>
                                        <td>
                                            Special Public Prosecutors conducting exclusively with POCSO
                                            cases
                                        </td>

                                        <td>
                                            {sum_array.special_public_prosecutors_conducting_exclusively}
                                        </td>
                                        <td>
                                            {r?.special_public_prosecutors_conducting_exclusively}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={1}>9</td>
                                        <td>
                                            Special Public Prosecutors conducting POCSO cases in addition
                                            to other cases
                                        </td>
                                        <td>
                                            --
                                        </td>
                                        <td>
                                            {
                                                r?.special_public_prosecutors_conducting_pocso_cases_in_addition
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={1}>10</td>
                                        <td colSpan={1} rowSpan={3}>
                                            Public Awareness about the Act u/s 43 POCSO Act
                                        </td>
                                        <td>
                                            Whether any awareness programs/campaigns have been conducted
                                            for provisions of POCSO Act
                                        </td>
                                        <td>
                                            --
                                        </td>
                                        <td>
                                            {r?.programs_campaigns_have_been_conducted_for_provisions}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={1}>11</td>
                                        <td>
                                            No of trainings specifically imparted on POCSO Act to State
                                            Government officers (Yes or No) if yes, how many? If no,
                                            reasons thereof
                                        </td>
                                        <td>
                                            --
                                        </td>
                                        <td>
                                            {r?.training_imparted_on_pocso_act_to_state_govt_officer}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={1}>12</td>
                                        <td>
                                            No of trainings specifically imparted on POCSO Act to Police
                                            officers
                                        </td>
                                        <td>
                                            {sum_array.trainings_imparted_on_pocso_act_to_police_officers}
                                        </td>
                                        <td>
                                            {r?.trainings_imparted_on_pocso_act_to_police_officers}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={1}>13</td>
                                        <td colSpan={1} rowSpan={2}>
                                            Implementation of Rule 6 of POCSO Rules
                                        </td>
                                        <td>
                                            Whether Guidelines for professionals to assist the child at
                                            pre trial and trial stage have been prepared u/s 39 of POCSO
                                            Act, 2012
                                        </td>
                                        <td>
                                            --
                                        </td>
                                        <td>
                                            {
                                                r?.professionals_to_assist_the_child_at_pre_trial_and_trial_stag
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={1}>14</td>
                                        <td>
                                            Whether Training modules have been prepared for effective
                                            discharge of functions under the Act. [Rule 6 (d) POCSO Rules
                                            2012]
                                        </td>
                                        <td>
                                            --
                                        </td>
                                        <td>
                                            {
                                                r?.training_modules_prepared_for_effective_discharge_of_functions
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={1}>15</td>
                                        <td colSpan={1} rowSpan={3}>
                                            Implementation of Rule 7 of POCSO Rules 2012
                                        </td>
                                        <td>
                                            Whether any victim compensation fund/scheme has been created?
                                        </td>
                                        <td>
                                            --
                                        </td>
                                        <td>
                                            {r?.any_victim_compensation_fund_scheme_has_been_created}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={1}>16</td>
                                        <td>
                                            No of cases where compensation has been provided to victim
                                            (interim and final)
                                        </td>
                                        <td>
                                            {sum_array.cases_where_compensation_has_been_provided_to_victim}
                                        </td>
                                        <td>
                                            {r?.cases_where_compensation_has_been_provided_to_victim}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={1}>17</td>
                                        <td>
                                            No of cases where compensation has been paid within 30 days of
                                            order of Special Court u/r 7 (5) of POCSO Rules 2012)
                                        </td>
                                        <td>
                                            {
                                                sum_array.compensation_paid_within_30_days_of_order_of_Special_Court
                                            }
                                        </td>
                                        <td>
                                            {
                                                r?.compensation_paid_within_30_days_of_order_of_Special_Court
                                            }
                                        </td>
                                    </tr>
                                </>
                            ))}
                            {/* <tbody>
                {props.report.map((r) => (
                  <tr key={r?.id}>
                    <th scope="row">{r?.id}</th>
                    <td>{JSON.parse(getStorage("user")).username}</td>
                    <td>
                      {
                        r?.state_child_protection_on_society_setup_u_s_106_jj_act_2015
                      }
                    </td>
                    <td>
                      {r?.district_child_protection_unit_u_s_106_jj_act_2015}
                    </td>
                    <td>
                      {
                        r?.sjpu_child_welfare_police_in_district_u_s_107_jj_act_2015
                      }
                    </td>
                    <td>{r?.jjbs_in_the_district_u_s_4_of_the_jj_act_2015}</td>
                    <td>{r?.cwcs_in_the_district_u_s_27_of_the_jj_act_2015}</td>
                    <td>{r?.children_courts_u_s_25_cpcr_act_2005}</td>
                    <td>{r?.reg_ccis_u_s_41_jj_act_2015_run_by_govt_18_a}</td>
                    <td>{r?.reg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_b}</td>
                    <td>{r?.unreg_ccis_u_s_41_jj_act_2015_run_by_govt_18_c}</td>
                    <td>{r?.unreg_ccis_u_s_41_jj_act_2015_run_by_ngos_18_d}</td>
                    <td>
                      {r?.persons_penalized_for_non_registration_of_cci_u_s_42}
                    </td>
                    <td>
                      {r?.open_shelter_shelter_set_up_u_s_43_of_the_jj_act_2015}
                    </td>
                    <td>{r?.open_shelter_shelter_u_s_43_of_the_jj_act_2015}</td>
                    <td>
                      {r?.children_in_open_shelter_u_s_43_of_the_jj_act_2015}
                    </td>
                    <td>
                      {r?.observation_homes_set_up_u_s_47_of_the_jj_Act_2015}
                    </td>
                    <td>{r?.observation_homes_u_s_43_of_the_jj_act_2015}</td>
                    <td>
                      {r?.children_in_home_who_are_staying_more_than_3_months}
                    </td>
                    <td>{r?.special_home_set_up_u_s_48_of_the_jj_act_2015}</td>
                    <td>{r?.special_home_u_s_48_of_the_jj_act_2015}</td>
                    <td>
                      {r?.children_in_special_home_u_s_48_of_the_jj_act_2015}
                    </td>
                    <td>{r?.place_of_safety_u_s_49_of_the_jj_act_2015}</td>
                    <td>{r?.capacity_of_place_of_safety}</td>
                    <td>{r?.children_in_place_of_safety}</td>
                    <td>{r?.children_home_set_up_u_s_50_of_the_jj_act_2015}</td>
                    <td>
                      {r?.foster_care_recognized_u_s_44_of_the_jj_act_2015}
                    </td>
                    <td>{r?.fit_facility_recognized_u_s_51_of_the_act_2015}</td>
                    <td>{r?.fit_person_recognized_u_s_52_of_jj_act_2015}</td>
                    <td>
                      {
                        r?.specialised_adoption_agency_organised_u_s_65_of_jj_act_2015
                      }
                    </td>
                    <td>
                      {r?.inspection_committee_by_state_u_s_54_of_jj_act_2015}
                    </td>
                    <td>
                      {
                        r?.juvenile_justice_fund_has_been_created_u_s_105_of_jj_act_2015
                      }
                    </td>
                    <td>
                      {
                        r?.all_housing_facilities_of_children_u_s_54_2_of_jj_act_2015
                      }
                    </td>
                    <td>
                      {
                        r?.jjb_cwc_sjpu_regd_institutions_u_s_55_1_of_jj_act_2015
                      }
                    </td>
                  </tr>
                ))}
              </tbody> */}
                        </table>
                    </div>
                </div>

                <div className="modal-footer" style={{ position: 'fixed', "bottom": "0", "right": "0" }}>
                    <button
                        className="btn btn-primary"
                        type="button"
                        data-bs-dismiss="modal"
                        onClick={() => downloadExcel("ncpcr")}
                    >
                        {" "}
                        Download <i class="icofont icofont-arrow-down"></i>{" "}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={createPDF}
                        type="button"
                        data-bs-dismiss="modal"
                    >
                        Download as Pdf
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
                </div>
            </Modal>
        </div>
    );
}

export default AReport26;