import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { useFormStore } from "../Store/Forms";

// FORM 10  Saranabalyam (M)

function AReport10(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { downloadExcel }] = useFormStore();
  const year = props.year
  const month = props.month
  const Month = ["Month not selected", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const DisplayMonth = Month[month]


  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };
  // object assigned to a variable to obtain total
  const values = props.report
  // sum_array = total sum data of district
  const sum_array = values.reduce((sum_array, value) => {
    for (const [key, count] of Object.entries(value)) {
      if (!sum_array[key]) {
        sum_array[key] = 0;
      }
      sum_array[key] += count;
    }
    return sum_array;
  }, {});
  function createPDF() {
    var sTable = document.getElementById('table').innerHTML;
    var style = "<style>";
    style = style + "table {width: 100%;font: 10px Calibri;}";
    style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "</style>";
    var win = window.open('', '', 'height=700,width=700');
    win.document.write('<html><head>');
    win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
    win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
    win.document.write('</head>');
    win.document.write('<body>');
    win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
    win.document.write('</body></html>');
    win.document.close(); 	// CLOSE THE CURRENT WINDOW.
    win.print();    // PRINT THE CONTENTS.
  }
  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {" "}
            Saranabalyam (District wise){" "}
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <h4>{DisplayMonth} {year}</h4>
        <div className="modal-body">
        <div class="table-responsive" id="table" height="100%" width="100%" media="print">
            <table class="table" id="saranabalyam">
              <thead>
                <tr>
                  <th scope="col" rowspan="3">
                    District
                  </th>

                  <th scope="col" rowspan="3">
                    Total number of children rescued by the SB team
                  </th>
                  <th scope="col" rowspan="3">
                    Total cases reported in the dist
                  </th>
                  <th scope="col" colspan="10">
                    Age
                  </th>
                  <th scope="col" colspan="2" rowspan="2">
                    FIR registered
                  </th>
                  <th scope="col" colspan="2" rowspan="2">
                    No. of children in need of DNA Test
                  </th>
                  <th scope="col" colspan="2" rowspan="2">
                    No. of children for whom DNA tests were conducted{" "}
                  </th>
                  <th scope="col" colspan="2" rowspan="2">
                    No. of children in need of repatriation
                  </th>
                  <th scope="col" colspan="2" rowspan="2">
                    No. of children repatriated
                  </th>
                  <th scope="col" colspan="2" rowspan="2">
                    Rehabilitated/ restored to family
                  </th>
                  <th scope="col" colspan="2" rowspan="2">
                    Children in CCIs
                  </th>
                  <th scope="col" colspan="2" rowspan="2">
                    No of children outside the state
                  </th>
                  <th scope="col" colspan="2" rowspan="2">
                    Counselling provided
                  </th>
                </tr>

                <tr>
                  <th scope="col" colspan="2">
                    {" "}
                    0-3{" "}
                  </th>
                  <th scope="col" colspan="2">
                    4-7
                  </th>
                  <th scope="col" colspan="2">
                    8-11
                  </th>
                  <th scope="col" colspan="2">
                    12-15
                  </th>
                  <th scope="col" colspan="2">
                    16-18
                  </th>
                </tr>

                <tr>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                </tr>
              </thead>
              <tbody>
                {props.report.map((r) => (
                  <tr key={r?.id}>
                    <th scope="row">{r?.username}</th>
                    <td>{r?.rescued_by_sb_team}</td>
                    <td>{r?.case_reported_in_dist}</td>
                    <td>{r?.male_0_3}</td>
                    <td>{r?.female_0_3}</td>
                    <td>{r?.male_4_7}</td>
                    <td>{r?.female_4_7}</td>
                    <td>{r?.male_8_11}</td>
                    <td>{r?.female_8_11}</td>
                    <td>{r?.male_12_15}</td>
                    <td>{r?.female_12_15}</td>
                    <td>{r?.male_16_18}</td>
                    <td>{r?.female_16_18}</td>
                    <td>{r?.fir_male}</td>
                    <td>{r?.fir_female}</td>
                    <td>{r?.male_children_dna_need}</td>
                    <td>{r?.female_children_dna_need}</td>
                    <td>{r?.male_children_dna_conducted}</td>
                    <td>{r?.female_children_dna_conducted}</td>
                    <td>{r?.male_repatriation_need}</td>
                    <td>{r?.female_repatriation_need}</td>
                    <td>{r?.male_repatriated}</td>
                    <td>{r?.female_repatriated}</td>
                    <td>{r?.male_restored_to_family}</td>
                    <td>{r?.female_restored_to_family}</td>
                    <td>{r?.cci_male}</td>
                    <td>{r?.cci_female}</td>
                    <td>{r?.outside_state_male}</td>
                    <td>{r?.outside_state_female}</td>
                    <td>{r?.counselling_male}</td>
                    <td>{r?.counselling_female}</td>
                  </tr>
                ))}
                <tr>
                  <th scope="row">Total</th>
                  <td>{sum_array.rescued_by_sb_team}</td>
                  <td>{sum_array.case_reported_in_dist}</td>
                  <td>{sum_array.male_0_3}</td>
                  <td>{sum_array.female_0_3}</td>
                  <td>{sum_array.male_4_7}</td>
                  <td>{sum_array.female_4_7}</td>
                  <td>{sum_array.male_8_11}</td>
                  <td>{sum_array.female_8_11}</td>
                  <td>{sum_array.male_12_15}</td>
                  <td>{sum_array.female_12_15}</td>
                  <td>{sum_array.male_16_18}</td>
                  <td>{sum_array.female_16_18}</td>
                  <td>{sum_array.fir_male}</td>
                  <td>{sum_array.fir_female}</td>
                  <td>{sum_array.male_children_dna_need}</td>
                  <td>{sum_array.female_children_dna_need}</td>
                  <td>{sum_array.male_children_dna_conducted}</td>
                  <td>{sum_array.female_children_dna_conducted}</td>
                  <td>{sum_array.male_repatriation_need}</td>
                  <td>{sum_array.female_repatriation_need}</td>
                  <td>{sum_array.male_repatriated}</td>
                  <td>{sum_array.female_repatriated}</td>
                  <td>{sum_array.male_restored_to_family}</td>
                  <td>{sum_array.female_restored_to_family}</td>
                  <td>{sum_array.cci_male}</td>
                  <td>{sum_array.cci_female}</td>
                  <td>{sum_array.outside_state_male}</td>
                  <td>{sum_array.outside_state_female}</td>
                  <td>{sum_array.counselling_male}</td>
                  <td>{sum_array.counselling_female}</td>
                </tr>


              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("saranabalyam")}
          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-primary"
            onClick={createPDF}
            type="button"
            data-bs-dismiss="modal"
          >
            Download as Pdf
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default AReport10;
