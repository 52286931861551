import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";

// FORM 3 : State sponsorship

function Form3(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { onSubmit }] = useFormStore();

  const [data, setdata] = useState({
    children_availing_sponsership_cumulative: 0,
    children_availing_sponsership_quarterly: 0,
    children_scolership_exempted: 0,
    children_scolership_added: 0,
  });

  const openForm1 = () => {
    props.close();
    onSubmit(data, "state_sponsership");
    setmodalIsOpen(false);
  };
  const closeButton = () => {
    props.close();
    setmodalIsOpen(false);
  };
  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {" "}
              State Sponsorship{" "}
            </h5>
            <button
              className="btn-close"
              onClick={closeButton}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <div classNameName="new-expense__control">
                <label>
                  {" "}
                  Number of children availing sponsorship (cumulative){" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  value={data.children_availing_sponsership_cumulative}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      children_availing_sponsership_cumulative: e.target.value,
                    })
                  }
                />
              </div>

              <div classNameName="new-expense__control">
                <label>
                  {" "}
                  Number of children availing sponsorship (current quarter){" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  value={data.children_availing_sponsership_quarterly}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      children_availing_sponsership_quarterly: e.target.value,
                    })
                  }
                />
              </div>

              <div classNameName="new-expense__control">
                <label>
                  {" "}
                  Number of children whose scholarship was exempted{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  value={data.children_scolership_exempted}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      children_scolership_exempted: e.target.value,
                    })
                  }
                />
              </div>

              <div classNameName="new-expense__control">
                <label> Number of children whose scholarship was added </label>{" "}
                :{" "}
                <input
                  type="number"
                  value={data.children_scolership_added}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      children_scolership_added: e.target.value,
                    })
                  }
                />
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              onClick={closeButton}
              type="button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              onClick={openForm1}
              type="button"
            >
              Save & Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Form3;
