import { getReportList } from "../../Api/Home";

const actions = {
  setLoading:
    (loading) =>
    ({ setState }) => {
      setState({ loading });
    },

  getReportList:
    () =>
    async ({ setState }) => {
      try {
        setState({ loading: true });
        const res = await getReportList();
        setState({ reportList: res });

        const saranyabalam = res.filter((r) => r.category === "Saranabalyam");
        setState({ saranyabalam });

        const violenceAgainstChildren = res.filter(
          (r) => r.category === "Violence against Children"
        );
        setState({ violenceAgainstChildren });
      } catch (error) {
        console.log(error?.data, "error");
      } finally {
        setState({ loading: false });
      }
    },
};

export default actions;
