import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";

// FORM 4 Central sponsorship	(Q)
function AReport4(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { downloadExcel }] = useFormStore();
  const Qyear = props.Qyear
  const Qmonth = props.Qmonth
  const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
  const DisplayQuarter = Quarter[Qmonth]


  // object assigned to a variable to obtain total

  let newArray = props.report.filter(function (d) {
    return d.year == Qyear && d.month == Qmonth;
  })
  console.log(newArray)
  // sum_array = total sum data of district
  const sum_array = newArray.reduce((sum_array, value) => {
    for (const [key, count] of Object.entries(value)) {
      if (!sum_array[key]) {
        sum_array[key] = 0;
      }
      sum_array[key] += count;
    }
    return sum_array;
  }, {});

  let newArrayCumulative = props.report.filter(function (d) {
    return d.year == Qyear && d.month <= Qmonth;
  })

  let result = [];

  newArrayCumulative.forEach(function (a) {
    if (!this[a.username]) {
      this[a.username] = { username: a.username, children_availing_sponsership_quarterly: 0 };
      result.push(this[a.username]);
    }
    this[a.username].children_availing_sponsership_quarterly += a.children_availing_sponsership_quarterly;
  }, Object.create(null));

  // console.log(result);
  for (let object of newArray) {
    for (let i of result) {
      if (object.username === i.username) {
        object.children_availing_sponsership_cumulative = i.children_availing_sponsership_quarterly
      }
    }
  }
  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };
  function createPDF() {
    var sTable = document.getElementById('table').innerHTML;
    var style = "<style>";
    style = style + "table {width: 100%;font: 10px Calibri;}";
    style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "</style>";
    var win = window.open('', '', 'height=700,width=700');
    win.document.write('<html><head>');
    win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
    win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
    win.document.write('</head>');
    win.document.write('<body>');
    win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
    win.document.write('</body></html>');
    win.document.close(); 	// CLOSE THE CURRENT WINDOW.
    win.print();    // PRINT THE CONTENTS.
  }
  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {" "}
            Details of children availing sponsorship schemes{" "}
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <h4>{DisplayQuarter} {Qyear}</h4>
        <div className="modal-body">
          <div class="table-responsive" id="table" height="100%" width="100%" media="print">
            <table class="table" id="central_sponsership">
              <thead>
                <tr>
                  <th scope="col" rowspan="2">
                    Sponsorship provider
                  </th>
                  <th scope="col" rowspan="2">
                    District
                  </th>
                  <th scope="col" colspan="2">
                    Total No. of children availing sponsorship{" "}
                  </th>
                  <th scope="col" rowspan="1">
                    No. of children whose scholarship was exempted in this
                    quarter
                  </th>
                  <th scope="col" rowspan="1">
                    No. of children whose scholarship was added in this quarter
                  </th>
                  <th scope="col" colspan="2">
                    Total No. of children availing sponsorship{" "}
                  </th>
                  <th scope="col" rowspan="1">
                    No. of children whose scholarship was exempted in this
                    quarter
                  </th>
                  <th scope="col" rowspan="1">
                    No. of children whose scholarship was added in this quarter
                  </th>
                </tr>

                <tr>
                  <th scope="col">Central (current quarter)</th>
                  <th scope="col"> Central (cumulative) </th>
                  <th scope="col">Central (current quarter)</th>
                  <th scope="col">Central (current quarter)</th>
                  <th scope="col">state (current quarter)</th>
                  <th scope="col"> state (cumulative) </th>
                  <th scope="col">state (current quarter)</th>
                  <th scope="col">state(current quarter)</th>
                </tr>
              </thead>
              <tbody>
                {newArray.map((r) => (
                  <tr key={r?.id}>
                    <th scope="row"></th>
                    <td>{r?.username}</td>
                    <td>{r?.children_availing_sponsership_quarterly}</td>
                    <td>{r?.children_availing_sponsership_cumulative}</td>
                    <td>{r?.children_scolership_exempted}</td>
                    <td>{r?.children_scolership_added}</td>
                    <td>{r?.state_children_availing_sponsership_quarterly}</td>
                    <td>{r?.state_children_availing_sponsership_cumulative}</td>
                    <td>{r?.state_children_scolership_exempted}</td>
                    <td>{r?.state_children_scolership_added}</td>
                  </tr>
                ))}
                <tr>
                  <th scope="row"></th>
                  <td>Total</td>
                  <td>{sum_array.children_availing_sponsership_quarterly}</td>
                  <td>{sum_array.children_availing_sponsership_cumulative}</td>
                  <td>{sum_array.children_scolership_exempted}</td>
                  <td>{sum_array.children_scolership_added}</td>
                  <td>{sum_array.state_children_availing_sponsership_quarterly}</td>
                  <td>{sum_array.state_children_availing_sponsership_cumulative}</td>
                  <td>{sum_array.state_children_scolership_exempted}</td>
                  <td>{sum_array.state_children_scolership_added}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("central_sponsership")}
          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-primary"
            onClick={createPDF}
            type="button"
            data-bs-dismiss="modal"
          >
            Download as Pdf
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default AReport4;
