import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";

// FORM 19 3. Adoption under section 56 of the JJ Act, 2015	(Q)

function Form19(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{ }, { onSubmit }] = useFormStore();

  const [data, setdata] = useState({
    ssa_in_district: 0,
    opening_balance_in_quarter: 0,
    cases_filled_during_quarter: 0,
    cases_disposed_during_quarter: 0,
    cases_pending_end_of_quarter: 0,
    pending_case_age_less_2_year_end_of_quarter: 0,
    pending_case_more_2_month_age_less_2_year_end_of_quarter: 0,
    pending_case_age_above_2_year_end_of_quarter: 0,
    pending_case_more_4_month_age_more_2_year_end_of_quarter: 0,
    child_info_uploaded_to_carigs: 0,
    adoption_order_opening_balance_start_of_quarter: 0,
    adoption_order_filed_during_quarter: 0,
    adoption_order_disposed_during_quarter: 0,
    adoption_order_pending_end_quarter: 0,
    adoption_order_pending_more_2_month_end_quarter: 0,
  });

  const openForm1 = () => {
    props.close();
    onSubmit(data, "adoption");
    setmodalIsOpen(false);
  };
  const closeButton = () => {
    props.close();
    setmodalIsOpen(false);
  };
  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {" "}
              3. Adoption under section 56 of the JJ Act, 2015{" "}
            </h5>
            <button
              className="btn-close"
              onClick={closeButton}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <form>
              <h5>
                {" "}
                a) Specialised Adoption Agencies (Recognised under section 65 of
                the JJ Act, 2015){" "}
              </h5>
              <div className="new-expense__control">
                <label> No. of SAA in the district </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.ssa_in_district}
                  onChange={(e) =>
                    setdata({ ...data, ssa_in_district: e.target.value })
                  }
                />
              </div>
              <br /> <br /> <br />
              <h5>
                {" "}
                b) Pendency of cases before the CWC for declaring a child free
                for Adoption{" "}
              </h5>
              <div className="new-expense__control">
                <label> Opening balance at the start of the quarter </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.opening_balance_in_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      opening_balance_in_quarter: e.target.value,
                    })
                  }
                />
                <label> No. of cases filed during the quarter </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.cases_filled_during_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      cases_filled_during_quarter: e.target.value,
                    })
                  }
                />
                <label> No. of cases disposed of during the quarter </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.cases_disposed_during_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      cases_disposed_during_quarter: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  Total no. of cases pending at the end of the quarter{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.cases_pending_end_of_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      cases_pending_end_of_quarter: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of pending cases pertaining to children below the age of 2
                  years at the end of the quarter{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.pending_case_age_less_2_year_end_of_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pending_case_age_less_2_year_end_of_quarter:
                        e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of the cases pertaining to children below the age of 2
                  years that have been pending for more than 2 months at the end
                  of the quarter (from the date of production of the child
                  before the CWC){" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.pending_case_more_2_month_age_less_2_year_end_of_quarter
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pending_case_more_2_month_age_less_2_year_end_of_quarter:
                        e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of pending cases pertaining to children above the age of 2
                  years at the end of the quarter{" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.pending_case_age_above_2_year_end_of_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pending_case_age_above_2_year_end_of_quarter:
                        e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of the cases pertaining to children above the age of 2
                  years that have been pending for more than 4 months at the end
                  of the quarter (from the date of production of the child
                  before the CWC){" "}
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={
                    data.pending_case_more_4_month_age_more_2_year_end_of_quarter
                  }
                  onChange={(e) =>
                    setdata({
                      ...data,
                      pending_case_more_4_month_age_more_2_year_end_of_quarter:
                        e.target.value,
                    })
                  }
                />
              </div>
              <br /> <br /> <br />
              <h5>
                {" "}
                c) Number of children in the Child Care Institution in the
                districts whose information has been uploaded to the Child
                Adoption Resource Information and Guidance System (CARIGS){" "}
              </h5>
              <div className="new-expense__control">
                <label> </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.child_info_uploaded_to_carigs}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      child_info_uploaded_to_carigs: e.target.value,
                    })
                  }
                />
              </div>
              <br /> <br /> <br />
              <h5> d) Adoption Orders (as per section 61, JJ Act, 2015) </h5>
              <div className="new-expense__control">
                <label> Opening balance at the start of the quarter </label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.adoption_order_opening_balance_start_of_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      adoption_order_opening_balance_start_of_quarter:
                        e.target.value,
                    })
                  }
                />
                <label> No. of cases filed during the quarter</label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.adoption_order_filed_during_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      adoption_order_filed_during_quarter: e.target.value,
                    })
                  }
                />
                <label> No. of cases disposed of during the quarter</label> :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.adoption_order_disposed_during_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      adoption_order_disposed_during_quarter: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  Total no. of cases pending at the end of the quarter
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.adoption_order_pending_end_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      adoption_order_pending_end_quarter: e.target.value,
                    })
                  }
                />
                <label>
                  {" "}
                  No. of cases that have been pending for more than 2 months at
                  the end of the quarter
                </label>{" "}
                :{" "}
                <input
                  type="number"
                  min="1"
                  value={data.adoption_order_pending_more_2_month_end_quarter}
                  onChange={(e) =>
                    setdata({
                      ...data,
                      adoption_order_pending_more_2_month_end_quarter:
                        e.target.value,
                    })
                  }
                />
              </div>
              <br /> <br /> <br />
            </form>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              onClick={closeButton}
              type="button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              onClick={openForm1}
              type="button"
            >
              Save & Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Form19;
