import React from "react";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";
import logo from "../assets/images/logo1.png";
import "./Header.css";
import { getStorage } from "../common/functions/Storage";
// import logo from '../assets/images/login/3.jpg';
import { useAuthStore } from "../Store/Auth";
import { Link } from "react-router-dom";

function Header() {
  const [{ }, { onLogout }] = useAuthStore();
  const [{ user }, { checkAuthenticate }] = useAuthStore();
  const clickHandler = () => {
    onLogout();
    // history.push("/home");
  };
  React.useEffect(() => {
    console.log("inside useEffect")
  }, []);
  checkAuthenticate();

  return (

    <div>

      {/* <!-- Page Header Start--> */}
      <div className="page-main-header">
        <div className="main-header-right row m-0">
          <div className="main-header-left">
            <div className="logo-wrapper">
              <a className="d-flex align-items-center">
                <img className="img-fluid" src={logo} alt="logo" width="40px" />
                <h2 className="px-2 pt-1 ">ICPS</h2>
              </a>
            </div>
            <div className="dark-logo-wrapper">
              <a href="index.html">
                <img
                  className="img-fluid"
                  src="/../assets/images/logo/dark-logo.png"
                  alt="logo"
                />
              </a>
            </div>
            <div className="toggle-sidebar">
              <i
                className="status_toggle middle"
                data-feather="align-center"
                id="sidebar-toggle"
              ></i>
            </div>
          </div>

          <div className="nav-right col pull-right right-menu p-0">
            <ul className="nav-menus">
              <li>
                <h5 className="username">
                  {JSON.parse(getStorage("user")).username}
                </h5>
              </li>
              <li className="onhover-dropdown p-0">
                <button className="btn btn-primary-light" type="button">
                  <Link onClick={clickHandler} to="#">
                    <i data-feather="log-out"></i>Log out
                  </Link>
                </button>
              </li>
            </ul>
          </div>
          <div className="d-lg-none mobile-toggle pull-right w-auto">
            <i data-feather="more-horizontal"></i>
          </div>
        </div>
      </div>
      {/* <!-- Page Header Ends --> */}
    </div>
  );
}

export default Header;
