import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { useFormStore } from "../Store/Forms";

// FORM 16 Institutional Care of Children in Conflict with Law	(Q)

function Report16(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{}, { downloadExcel }] = useFormStore();
  const Qyear = props.Qyear
  const Qmonth = props.Qmonth
  const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
  const DisplayQuarter = Quarter[Qmonth]


  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };

  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {" "}
            Institutional Care of Children in Conflict with Law{" "}
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <h4>{DisplayQuarter} {Qyear}</h4>
        <div className="modal-body">
          <div class="table-responsive">
            <h5>
              a) Observation Homes (established under section 42(1) of the JJ
              (Care and Protection of Children) Act, 2015):-{" "}
            </h5>
            <table class="table" id="institutional_care">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" rowspan="2">
                    Particulars
                  </th>
                  <th scope="col">As on 31.12.21 </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col">1</th>
                  <th scope="col">
                    No. of Observation Homes in the district maintained by the
                    state govt itself
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.observation_home_district_by_state
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">2</th>
                  <th scope="col">
                    No. of Observation Homes in the district maintained by the
                    state govt through voluntary or non – governmental
                    organization
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.bservation_home_district_by_state_trough_ngo
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">3</th>
                  <th scope="col">
                    No. of children placed in Observation Home in the district
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.children_in_observation_home_in_district
                    } */}
                  </th>
                </tr>
              </tbody>
            </table>{" "}
            <br /> <br />
            <h5>
              b) Special Homes (established under section 48(1) of the JJ Act,
              2015):-{" "}
            </h5>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" rowspan="2">
                    Particulars
                  </th>
                  <th scope="col">As on 31.12.21 </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col">1</th>
                  <th scope="col">
                    No. of Special Homes in the district maintained by the state
                    govt itself
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.special_home_district_by_state
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">2</th>
                  <th scope="col">
                    No. of Special Homes in the district maintained by the state
                    govt through voluntary or non- governmental organization
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.special_home_district_by_state_trough_ngo
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">3</th>
                  <th scope="col">
                    No. of children placed in Special Homes in the district
                  </th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.special_in_observation_home_in_district
                    } */}
                  </th>
                </tr>
              </tbody>
            </table>{" "}
            <br /> <br />
            <h5>
              {" "}
              c) Place of Safety (established under section 49 of the JJ Act,
              2015):-{" "}
            </h5>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" rowspan="2">
                    Particulars
                  </th>
                  <th scope="col">As on 31.12.21 </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col">1</th>
                  <th scope="col">No. of Place of Safety in the district</th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.place_of_saftey_in_district
                    } */}
                  </th>
                </tr>

                <tr>
                  <th scope="col">2</th>
                  <th scope="col">No. of children placed in Place of Safety</th>
                  <th scope="col">
                    {/* {
                      props.report[props.report.length - 1]
                        ?.children_placed_in_place_of_saftey
                    } */}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("institutional_care")}
          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default Report16;
