import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Form.css";
import { useFormStore } from "../Store/Forms";
import { Toaster, toast } from "react-hot-toast";
import { UPDATE_RESOURCE_PERSON } from "../utils/constant";
import { useEffect } from "react";

// FORM 8 Details of resource persons/experts

function Form8(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { onSubmit }] = useFormStore();
    const [{ }, { updateReport }] = useFormStore();
    const [{ }, { formUpdated }] = useFormStore();
    const annually = props.annually;
    const [data, setdata] = useState({
        no_of_resource_person_interpretor: 0,
        no_of_resource_person_special_educator: 0,
        no_of_resource_person_support_person: 0,
        no_of_resource_person_translator: 0,
        no_of_resource_person_expert: 0,
        no_of_sesson_demanded_interpretor: 0,
        no_of_sesson_demanded_special_educator: 0,
        no_of_sesson_demanded_support_person: 0,
        no_of_sesson_demanded_translator: 0,
        no_of_sesson_demanded_expert: 0,
        no_of_sesson_offered_person_interpretor: 0,
        no_of_sesson_offered_person_special_educator: 0,
        no_of_sesson_offered_person_support_person: 0,
        no_of_sesson_offered_person_translator: 0,
        no_of_sesson_offered_person_expert: 0,
        year: annually
    });
    useEffect(() => {
        if ((props || {}).isEdit) {
            const { id, created_at, updated_at, username, user, report_type, month, ...reportSorted } = props.report.find((res) => { return res.year === annually; }) || {};
            setdata({
                ...data,
                ...reportSorted
            });
        }
    }, []);
    const closeButton = () => {
        props.close();
        setmodalIsOpen(false);
    };
    const openForm1 = () => {
        if (props.isEdit) {
            return toast.promise(
                updateReport(data, UPDATE_RESOURCE_PERSON),
                {
                    loading: 'Updating Record',
                    success: 'Record Updated',
                    error: 'Error while saving'
                }
            ).then(() => formUpdated(true));
        } else {
            props.close();
            onSubmit(data, "resource_person");
            setmodalIsOpen(false);
        }
    };

    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <Toaster position="bottom-right" />
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {" "}
                            Details of resource persons/experts{" "}
                        </h5>
                        <button
                            className="btn-close"
                            onClick={closeButton}
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    <div className="modal-body">
                        <h4>{annually}</h4>
                        <form>
                            <div className="new-expense__control">
                                <label> Total number of resource persons in district</label>
                                <label> Interpretor </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.no_of_resource_person_interpretor}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            no_of_resource_person_interpretor: e.target.value,
                                        })
                                    }
                                />
                                <label> Special educator </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.no_of_resource_person_special_educator}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            no_of_resource_person_special_educator: e.target.value,
                                        })
                                    }
                                />
                                <label> Support person </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.no_of_resource_person_support_person}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            no_of_resource_person_support_person: e.target.value,
                                        })
                                    }
                                />
                                <label> Translator </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.no_of_resource_person_translator}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            no_of_resource_person_translator: e.target.value,
                                        })
                                    }
                                />
                                <label> Expert </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.no_of_resource_person_expert}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            no_of_resource_person_expert: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> No. of sessions demanded </label>
                                <label> Interpretor </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.no_of_sesson_demanded_interpretor}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            no_of_sesson_demanded_interpretor: e.target.value,
                                        })
                                    }
                                />
                                <label> Special educator </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.no_of_sesson_demanded_special_educator}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            no_of_sesson_demanded_special_educator: e.target.value,
                                        })
                                    }
                                />
                                <label> Support person </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.no_of_sesson_demanded_support_person}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            no_of_sesson_demanded_support_person: e.target.value,
                                        })
                                    }
                                />
                                <label> Translator </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.no_of_sesson_demanded_translator}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            no_of_sesson_demanded_translator: e.target.value,
                                        })
                                    }
                                />
                                <label> Expert </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.no_of_sesson_demanded_expert}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            no_of_sesson_demanded_expert: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="new-expense__control">
                                <label> No. of sessions offered </label>
                                <label> Interpretor </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.no_of_sesson_offered_person_interpretor}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            no_of_sesson_offered_person_interpretor: e.target.value,
                                        })
                                    }
                                />
                                <label> Special educator </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.no_of_sesson_offered_person_special_educator}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            no_of_sesson_offered_person_special_educator:
                                                e.target.value,
                                        })
                                    }
                                />
                                <label> Support person </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.no_of_sesson_offered_person_support_person}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            no_of_sesson_offered_person_support_person:
                                                e.target.value,
                                        })
                                    }
                                />
                                <label> Translator </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.no_of_sesson_offered_person_translator}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            no_of_sesson_offered_person_translator: e.target.value,
                                        })
                                    }
                                />
                                <label> Expert </label> :{" "}
                                <input
                                    type="number"
                                    min="1"
                                    value={data.no_of_sesson_offered_person_expert}
                                    onChange={(e) =>
                                        setdata({
                                            ...data,
                                            no_of_sesson_offered_person_expert: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary"
                            onClick={closeButton}
                            type="button"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={openForm1}
                            type="button"
                        >
                            Save & Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Form8;
