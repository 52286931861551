import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { useFormStore } from "../Store/Forms";

// FORM 17 1. Child Welfare Committees (Constituted under section 27(1) of the JJ Act, 2015):- (Q)

function Report17(props) {
  const [modalIsOpen, setmodalIsOpen] = useState(true);
  const [{}, { downloadExcel }] = useFormStore();

  const openForm1 = () => {
    setmodalIsOpen(false);
    props.close();
  };

  return (
    <div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {" "}
            Child Welfare Committees (Constituted under section 27(1) of the JJ
            Act, 2015):-{" "}
          </h5>
          <button
            className="btn-close"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <div class="table-responsive">
            <table class="table" id="cwc">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" rowspan="2">
                    Particulars
                  </th>
                  <th scope="col">As on 31.12.21 </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th scope="col">1</th>
                  <th scope="col">No. of CWCs in the district</th>
                  <th scope="col">
                    {props.report[props.report.length - 1]?.cwc_in_district}
                  </th>
                </tr>

                <tr>
                  <th scope="col">2</th>
                  <th scope="col">
                    No. of Districts in the district where no CWC is operating
                  </th>
                  <th scope="col">
                    {props.report[props.report.length - 1]?.no_cwc_in_district}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => downloadExcel("cwc")}
          >
            {" "}
            Download <i class="icofont icofont-arrow-down"></i>{" "}
          </button>
          <button
            className="btn btn-secondary"
            onClick={openForm1}
            type="button"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default Report17;
