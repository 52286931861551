import React, { useState } from "react";
import Modal from "react-modal";

import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import "./Report1.css";
import { getStorage } from "../common/functions/Storage";
import { useFormStore } from "../Store/Forms";
import Form11 from "./Form11";

// FORM 11 Details of Violence against Children (M)

function Report11(props) {
    const [modalIsOpen, setmodalIsOpen] = useState(true);
    const [{ }, { downloadExcel }] = useFormStore();
    const [formOpen, setFormOpen] = useState(false);

    const openForm1 = () => {
        setmodalIsOpen(false);
        props.close();
    };
    const Qyear = props.Qyear;
    const Qmonth = props.Qmonth;
    const Quarter = ["Quarter not selected", "January-March", "April-June", "July-September", "October-December"];
    const DisplayQuarter = Quarter[Qmonth];
    let newArray = props.report.filter(function (d) {
        return d.year == Qyear && d.month == Qmonth;
    });
    function createPDF() {
        var sTable = document.getElementById('table').innerHTML;
        var style = "<style>";
        style = style + "table {width: 100%;font: 10px Calibri;}";
        style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "</style>";
        var win = window.open('', '', 'height=700,width=700');
        win.document.write('<html><head>');
        win.document.write('<title>Profile</title>');   // <title> FOR PDF HEADER.
        win.document.write(style);          // ADD STYLE INSIDE THE HEAD TAG.
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write(sTable);         // THE TABLE CONTENTS INSIDE THE BODY TAG.
        win.document.write('</body></html>');
        win.document.close(); 	// CLOSE THE CURRENT WINDOW.
        win.print();    // PRINT THE CONTENTS.
    }

    return (
        <div>
            <Modal isOpen={modalIsOpen}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                        {" "}
                        Violence against children{" "}
                    </h5>
                    <button
                        className="btn-close"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <h4>{DisplayQuarter} {Qyear}</h4>
                <div className="modal-body">
                    <div class="table-responsive" id="table" height="100%" width="100%" media="print">
                        <table class="table" id="violence_against_children">
                            <thead>
                                <tr>
                                    <th scope="col" rowspan="3">
                                        #
                                    </th>
                                    <th rowSpan={3}>District</th>
                                    <th scope="col" rowSpan="3">
                                        Category
                                    </th>
                                    <th scope="col" colspan="10">
                                        Age
                                    </th>
                                    <th scope="col" colspan="2" rowspan="2">
                                        Children in CCIs
                                    </th>
                                </tr>

                                <tr>
                                    <th scope="col" colspan="2">
                                        {" "}
                                        0-3{" "}
                                    </th>
                                    <th scope="col" colspan="2">
                                        4-7
                                    </th>
                                    <th scope="col" colspan="2">
                                        8-11
                                    </th>
                                    <th scope="col" colspan="2">
                                        12-15
                                    </th>
                                    <th scope="col" colspan="2">
                                        16-18
                                    </th>
                                </tr>

                                <tr>
                                    <th scope="col">Male</th>
                                    <th scope="col">Female</th>
                                    <th scope="col">Male</th>
                                    <th scope="col">Female</th>
                                    <th scope="col">Male</th>
                                    <th scope="col">Female</th>
                                    <th scope="col">Male</th>
                                    <th scope="col">Female</th>
                                    <th scope="col">Male</th>
                                    <th scope="col">Female</th>
                                    <th scope="col">Male</th>
                                    <th scope="col">Female</th>
                                </tr>
                            </thead>
                            <tbody>
                                {newArray.map((r) => (
                                    <tr key={r?.id}>
                                        <th scope="row" rowSpan={12}>{r?.id}</th>
                                        <td rowSpan={12}>{JSON.parse(getStorage("user")).username}</td>
                                        <td>Total Murder (Infanticide and Other)</td>
                                        <td>{r?.total_murder_male_0_3}</td>
                                        <td>{r?.total_murder_female_0_3}</td>
                                        <td>{r?.total_murder_male_4_7}</td>
                                        <td>{r?.total_murder_female_4_7}</td>
                                        <td>{r?.total_murder_male_8_11}</td>
                                        <td>{r?.total_murder_female_8_11}</td>
                                        <td>{r?.total_murder_male_12_15}</td>
                                        <td>{r?.total_murder_female_12_15}</td>
                                        <td>{r?.total_murder_male_16_18}</td>
                                        <td>{r?.total_murder_female_16_18}</td>
                                        <td>{r?.total_murder_cci_male}</td>
                                        <td>{r?.total_murder_cci_female}</td>
                                    </tr>
                                ))}

                                {newArray.map((r) => (
                                    <tr>
                                        <td>Section 4 & 6 of  PoCSO Act r/w Sec. 376 IPC</td>
                                        <td>{r?.section_4_6_male_0_3}</td>
                                        <td>{r?.section_4_6_female_0_3}</td>
                                        <td>{r?.section_4_6_male_4_7}</td>
                                        <td>{r?.section_4_6_female_4_7}</td>
                                        <td>{r?.section_4_6_male_8_11}</td>
                                        <td>{r?.section_4_6_female_8_11}</td>
                                        <td>{r?.section_4_6_male_12_15}</td>
                                        <td>{r?.section_4_6_female_12_15}</td>
                                        <td>{r?.section_4_6_male_16_18}</td>
                                        <td>{r?.section_4_6_female_16_18}</td>
                                        <td>{r?.section_4_6_cci_male}</td>
                                        <td>{r?.section_4_6_cci_female}</td>
                                    </tr>
                                ))}

                                {newArray.map((r) => (
                                    <tr>
                                        <td>Kidnapping and Abduction</td>
                                        <td>{r?.kidnapping_male_0_3}</td>
                                        <td>{r?.kidnapping_female_0_3}</td>
                                        <td>{r?.kidnapping_male_4_7}</td>
                                        <td>{r?.kidnapping_female_4_7}</td>
                                        <td>{r?.kidnapping_male_8_11}</td>
                                        <td>{r?.kidnapping_female_8_11}</td>
                                        <td>{r?.kidnapping_male_12_15}</td>
                                        <td>{r?.kidnapping_female_12_15}</td>
                                        <td>{r?.kidnapping_male_16_18}</td>
                                        <td>{r?.kidnapping_female_16_18}</td>
                                        <td>{r?.kidnapping_cci_male}</td>
                                        <td>{r?.kidnapping_cci_female}</td>
                                    </tr>
                                ))}

                                {newArray.map((r) => (
                                    <tr>
                                        <td>Foeticide</td>
                                        <td>{r?.foeticide_male_0_3}</td>
                                        <td>{r?.foeticide_female_0_3}</td>
                                        <td>{r?.foeticide_male_4_7}</td>
                                        <td>{r?.foeticide_female_4_7}</td>
                                        <td>{r?.foeticide_male_8_11}</td>
                                        <td>{r?.foeticide_female_8_11}</td>
                                        <td>{r?.foeticide_male_12_15}</td>
                                        <td>{r?.foeticide_female_12_15}</td>
                                        <td>{r?.foeticide_male_16_18}</td>
                                        <td>{r?.foeticide_female_16_18}</td>
                                        <td>{r?.foeticide_cci_male}</td>
                                        <td>{r?.foeticide_cci_female}</td>
                                    </tr>
                                ))}

                                {newArray.map((r) => (
                                    <tr>
                                        <td>Abetment of Suicide</td>
                                        <td>{r?.abetment_male_0_3}</td>
                                        <td>{r?.abetment_female_0_3}</td>
                                        <td>{r?.abetment_male_4_7}</td>
                                        <td>{r?.abetment_female_4_7}</td>
                                        <td>{r?.abetment_male_8_11}</td>
                                        <td>{r?.abetment_female_8_11}</td>
                                        <td>{r?.abetment_male_12_15}</td>
                                        <td>{r?.abetment_female_12_15}</td>
                                        <td>{r?.abetment_male_16_18}</td>
                                        <td>{r?.abetment_female_16_18}</td>
                                        <td>{r?.abetment_cci_male}</td>
                                        <td>{r?.abetment_cci_female}</td>
                                    </tr>
                                ))}

                                {newArray.map((r) => (
                                    <tr>
                                        <td>Exposure and Abandonment</td>
                                        <td>{r?.exposure_male_0_3}</td>
                                        <td>{r?.exposure_female_0_3}</td>
                                        <td>{r?.exposure_male_4_7}</td>
                                        <td>{r?.exposure_female_4_7}</td>
                                        <td>{r?.exposure_male_8_11}</td>
                                        <td>{r?.exposure_female_8_11}</td>
                                        <td>{r?.exposure_male_12_15}</td>
                                        <td>{r?.exposure_female_12_15}</td>
                                        <td>{r?.exposure_male_16_18}</td>
                                        <td>{r?.exposure_female_16_18}</td>
                                        <td>{r?.exposure_cci_male}</td>
                                        <td>{r?.exposure_cci_female}</td>
                                    </tr>
                                ))}

                                {newArray.map((r) => (
                                    <tr>
                                        <td>Procuration of Minor Girls</td>
                                        <td>{r?.procuration_male_0_3}</td>
                                        <td>{r?.procuration_female_0_3}</td>
                                        <td>{r?.procuration_male_4_7}</td>
                                        <td>{r?.procuration_female_4_7}</td>
                                        <td>{r?.procuration_male_8_11}</td>
                                        <td>{r?.procuration_female_8_11}</td>
                                        <td>{r?.procuration_male_12_15}</td>
                                        <td>{r?.procuration_female_12_15}</td>
                                        <td>{r?.procuration_male_16_18}</td>
                                        <td>{r?.procuration_female_16_18}</td>
                                        <td>{r?.procuration_cci_male}</td>
                                        <td>{r?.procuration_cci_female}</td>
                                    </tr>
                                ))}

                                {newArray.map((r) => (
                                    <tr>
                                        <td>Buying Girls for Prostitution</td>
                                        <td>{r?.buying_girls_male_0_3}</td>
                                        <td>{r?.buying_girls_female_0_3}</td>
                                        <td>{r?.buying_girls_male_4_7}</td>
                                        <td>{r?.buying_girls_female_4_7}</td>
                                        <td>{r?.buying_girls_male_8_11}</td>
                                        <td>{r?.buying_girls_female_8_11}</td>
                                        <td>{r?.buying_girls_male_12_15}</td>
                                        <td>{r?.buying_girls_female_12_15}</td>
                                        <td>{r?.buying_girls_male_16_18}</td>
                                        <td>{r?.buying_girls_female_16_18}</td>
                                        <td>{r?.buying_girls_cci_male}</td>
                                        <td>{r?.buying_girls_cci_female}</td>
                                    </tr>
                                ))}

                                {newArray.map((r) => (
                                    <tr>
                                        <td>Selling Girls for Prostitution</td>
                                        <td>{r?.selling_girls_male_0_3}</td>
                                        <td>{r?.selling_girls_female_0_3}</td>
                                        <td>{r?.selling_girls_male_4_7}</td>
                                        <td>{r?.selling_girls_female_4_7}</td>
                                        <td>{r?.selling_girls_male_8_11}</td>
                                        <td>{r?.selling_girls_female_8_11}</td>
                                        <td>{r?.selling_girls_male_12_15}</td>
                                        <td>{r?.selling_girls_female_12_15}</td>
                                        <td>{r?.selling_girls_male_16_18}</td>
                                        <td>{r?.selling_girls_female_16_18}</td>
                                        <td>{r?.selling_girls_cci_male}</td>
                                        <td>{r?.selling_girls_cci_female}</td>
                                    </tr>
                                ))}

                                {newArray.map((r) => (
                                    <tr>
                                        <td>Prohibition of Child marriage Act</td>
                                        <td>{r?.prohibition_male_0_3}</td>
                                        <td>{r?.prohibition_female_0_3}</td>
                                        <td>{r?.prohibition_male_4_7}</td>
                                        <td>{r?.prohibition_female_4_7}</td>
                                        <td>{r?.prohibition_male_8_11}</td>
                                        <td>{r?.prohibition_female_8_11}</td>
                                        <td>{r?.prohibition_male_12_15}</td>
                                        <td>{r?.prohibition_female_12_15}</td>
                                        <td>{r?.prohibition_male_16_18}</td>
                                        <td>{r?.prohibition_female_16_18}</td>
                                        <td>{r?.prohibition_cci_male}</td>
                                        <td>{r?.prohibition_cci_female}</td>
                                    </tr>
                                ))}

                                {newArray.map((r) => (
                                    <tr>
                                        <td>Other Crimes against Children</td>
                                        <td>{r?.other_crimes_male_0_3}</td>
                                        <td>{r?.other_crimes_female_0_3}</td>
                                        <td>{r?.other_crimes_male_4_7}</td>
                                        <td>{r?.other_crimes_female_4_7}</td>
                                        <td>{r?.other_crimes_male_8_11}</td>
                                        <td>{r?.other_crimes_female_8_11}</td>
                                        <td>{r?.other_crimes_male_12_15}</td>
                                        <td>{r?.other_crimes_female_12_15}</td>
                                        <td>{r?.other_crimes_male_16_18}</td>
                                        <td>{r?.other_crimes_female_16_18}</td>
                                        <td>{r?.other_crimes_cci_male}</td>
                                        <td>{r?.other_crimes_cci_female}</td>
                                    </tr>
                                ))}

                                {newArray.map((r) => (
                                    <tr>
                                        <td>Total</td>
                                        <td>{r?.total_male_0_3}</td>
                                        <td>{r?.total_female_0_3}</td>
                                        <td>{r?.total_male_4_7}</td>
                                        <td>{r?.total_female_4_7}</td>
                                        <td>{r?.total_male_8_11}</td>
                                        <td>{r?.total_female_8_11}</td>
                                        <td>{r?.total_male_12_15}</td>
                                        <td>{r?.total_female_12_15}</td>
                                        <td>{r?.total_male_16_18}</td>
                                        <td>{r?.total_female_16_18}</td>
                                        <td>{r?.total_cci_male}</td>
                                        <td>{r?.total_cci_female}</td>
                                    </tr>
                                ))}
                            </tbody>
                            {/* <thead>
                <tr>
                  <th scope="col" rowspan="3">
                    #
                  </th>
                  <th scope="col" rowspan="3">
                    Category
                  </th>
                  <th scope="col" colspan="10">
                    Age
                  </th>
                  <th scope="col" colspan="2" rowspan="2">
                    Children in CCIs
                  </th>
                </tr>

                <tr>
                  <th scope="col" colspan="2">
                    {" "}
                    0-3{" "}
                  </th>
                  <th scope="col" colspan="2">
                    4-7
                  </th>
                  <th scope="col" colspan="2">
                    8-11
                  </th>
                  <th scope="col" colspan="2">
                    12-15
                  </th>
                  <th scope="col" colspan="2">
                    16-18
                  </th>
                </tr>

                <tr>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                  <th scope="col">Male</th>
                  <th scope="col">Female</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Total Murder (Infanticide and Other)</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">2</th>
                  <td>Section 4 & 6 of PoCSO Act r/w Sec. 376 IPC</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">3</th>
                  <td>Kidnapping and Abduction</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">4</th>
                  <td>Foeticide</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">5</th>
                  <td>Abetment of Suicide</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">6</th>
                  <td>Exposure and Abandonment</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">7</th>
                  <td> Procuration of Minor Girls</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">8</th>
                  <td>Buying Girls for Prostitution</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">9</th>
                  <td>Selling Girls for Prostitution</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">10</th>
                  <td>Prohibition of Child marriage Act</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">11</th>
                  <td>Other Crimes against Children</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>

                <tr>
                  <th scope="row">12</th>
                  <td>Total </td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
              </tbody> */}
                        </table>
                    </div>
                </div>

                <div className="modal-footer">
                    <button
                        className="btn btn-primary"
                        onClick={() => setFormOpen(true)}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalLong"
                        type="button"
                    >
                        Edit
                    </button>
                    <button
                        className="btn btn-primary"
                        type="button"
                        data-bs-dismiss="modal"
                        onClick={() => downloadExcel("violence_against_children")}
                    >
                        {" "}
                        Download <i class="icofont icofont-arrow-down"></i>{" "}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={createPDF}
                        type="button"
                        data-bs-dismiss="modal"
                    >
                        Download as Pdf
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={openForm1}
                        type="button"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    {/* <button className="btn btn-primary" onClick={openForm1} type="button">Save & Submit</button> */}
                </div>
            </Modal>
            {formOpen && (
                <Form11
                    isEdit
                    close={() => setFormOpen(false)}
                    report={props.report} Qmonth={Qmonth}
                    Qyear={Qyear}
                />
            )}
        </div>
    );
}

export default Report11;
