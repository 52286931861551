import "../assets/css/feather-icon.css";
import "../assets/css/bootstrap.css";
import "../assets/css/responsive.css";
import "../assets/css/themify.css";
import "../assets/css/style.css";
import "../assets/css/fontawesome.css";
import "../assets/css/icofont.css";
import "../assets/css/flag-icon.css";

import React from "react";

import "./Login.css";
import logo from "../assets/images/login/WCD.jpg";

function PageNotFound() {
    return (
        <div>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-5 d-flex align-items-center justify-content-center">
                            <img className="w-100" src={logo} alt="looginpage" />
                        </div>
                        <div className="col-xl-7 p-0">
                            <div className="login-card">
                                <form className="theme-form login-form">
                                    <h4>OOPS..</h4>
                                    <h6>
                                        The page you are looking for does not exist
                                    </h6>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default PageNotFound;
